<template>
  <a-drawer
    :title="inNew"
    :width="'50%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <h2>流程名称:&nbsp;{{dataSoure.name}}&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;流程id:&nbsp;{{dataSoure.id}}</h2>
    <a-table
      :columns="columns"
      :data-source="dataList"
      :pagination="false"
      :row-key="(record) => record.id"
      :loading="loading"
    >
    </a-table>
  </a-drawer>
</template>

<script>
import {computed, defineComponent, reactive, toRefs} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
export default defineComponent({
  setup() {
    const state = reactive({
      visible: false,
      dataList: [],
      dataSoure: {},
      loading: false,
      selectedRowKeys: [],
      lastFilter: {},
      columns: [
        {
          title: '节点排序',
          width: 100,
          fixed: 'left',
          align: 'center',
          key: 'sort',
          dataIndex: 'sort',
        },
        {
          title: '节点名称',
          width: 100,
          align: 'center',
          key: 'name',
          dataIndex: 'name',
        },
        {
          title: '节点id',
          width: 100,
          align: 'center',
          key: 'id',
          dataIndex: 'id',
        },
        {
          title: '界面样式',
          width: 100,
          align: 'center',
          key: 'pageStyleName',
          dataIndex: 'pageStyleName',
        },
        {
          title: '关联模板',
          width: 100,
          align: 'center',
          key: 'associatedTemplateName',
          dataIndex: 'associatedTemplateName',
        }
      ],
    })
    const inNew = computed(() => {
      return '流程详情'
    })
    const showDrawer = function(oldData = {}) {
      console.log(oldData)
      handleSearch(oldData)
      state.visible = true
    }
    // const {
    //   data: dataSource,
    //   run,
    //   loading,
    //   current,
    //   pageSize,
    //   total,
    // } = requestUsePagination(getRequestURI('process_processDetails'))
    // const onSelectChange = (selectedRowKeys,selectedRow) => {
    //   state.selectedRowKeys = selectedRowKeys
    //   console.log('selectedRowKeys changed: ', selectedRow)
    // }
    const handleSearch = function(filters) {
      state.loading = true
      request(getRequestURI('process_processDetails'), {id: filters.progressId}).then(res => {
        if (res.code === 1) {
          state.dataSoure = res.result
          state.dataList = res.result.processDictList
        }
      })
      state.loading = false
    }
    // const pagination = computed(() => ({
    //   current: current.value,
    //   pageSize: pageSize.value,
    //   total: total.value,
    //   // pageSizeOptions: [10, 20, 50],
    // }))
    // const handleTableChange = (pag, filters, sorter) => {
    //   console.log('handleTableChange', pag, filters, sorter)
    //   run({
    //     pagination: {
    //       current: pag?.current,
    //       pageSize: pag?.pageSize
    //     },
    //     sorter,
    //     data: state.lastFilter,
    //   })
    // }

    const onClose = function() {
      state.visible = false
    }
    return {
      onClose,
      inNew,
      showDrawer,
      ...toRefs(state),
      handleSearch,
    }
  }
})
</script>

<style scoped>

</style>
