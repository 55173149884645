<style scoped>
</style>

<template>
  <a-drawer
    :title="isTitle"
    :width="'60%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
  <a-form :model="data" :rules="formRules" layout="vertical" ref="formRef" autocomplete="off">
    <a-row :gutter="10">
     <a-col span="12">
      <a-form-item name="informationType" label="信息类型: ">
       <a-select v-model:value="data.informationType" placeholder="请选择信息类型" :disabled="isAddUpdate">
         <a-select-option :value="info.id" v-for="info in formData.informationType"  :key="info.id">{{info.value}}</a-select-option>
      </a-select>
     </a-form-item>
    </a-col>
    <a-col span="12">
      <a-form-item name="displayTypeName" label="显示方式: ">
       <a-select v-model:value="data.displayType" placeholder="请选择显示方式" :disabled="isAddUpdate">
         <a-select-option :value="infoName.id" v-for="infoName in formData.displayType" :key="infoName.id">{{infoName.value}}</a-select-option>
      </a-select>
     </a-form-item>
      </a-col>
      <a-col span="12">
        <a-form-item style="margin-top: 15px" label="显示信息: " name="displayInformation" required>
          <a-textarea v-model:value="data.displayInformation" placeholder="请输入显示信息" :disabled="isAddUpdate"/>
        </a-form-item>
    </a-col>
   </a-row>
  </a-form>

    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button html-type="submit" type="primary" @click="onSave" v-if="!isAddUpdate">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
    
  </a-drawer>
</template>

<script>
import {CloseOutlined,} from '@ant-design/icons-vue'
import {defineComponent, reactive, toRefs, ref} from 'vue'
import {getRequestURI,request} from '../../../utils/request'
import { message} from 'ant-design-vue'

export default defineComponent({
  components: {
    CloseOutlined
  },
  props:{
    handleSearch: {type: Function}
  },
  setup(props) {
    const addDrawer = ref()
    const formRef = ref()
    const data = reactive({
      productInfoId: 0,
      informationType: undefined,
      displayType: undefined,
      displayInformation: undefined,
    })
    const formData = reactive({
      //信息类型
      informationType: [
        {id: 1, value: 'HealthyShow-健康须知'},
        {id: 2, value: 'ClaimProcess-理赔流程'},
        {id: 3, value: 'CommonProblem-常见问题'},
        {id: 4, value: 'InsureTips-投保提示'},
      ],
      //显示方式
      displayType: [
        {id: 1, value: '不显示'},
        {id: 2, value: '弹出'},
        {id: 3, value: '内嵌'},
      ],
      displayMode: '',
      //显示信息
      displayInformation: ' '
    })
    const formRules = {
      informationType: [
        {required: true, message: '请选择信息类型'}
      ],
      displayType: [
        {required: true, message: '请选择显示方式'}
      ],
      displayInformation: [
        {required: true, message: '请输入显示信息'}
      ]
    }
    const state = reactive({
      isTitle: '产品投保须知信息管理新增',
      visible: false,
      isUpdate: false,
      isAddUpdate: false
    })
     
    const showDrawer = (oldData, num) => {
      state.visible = true
      if(num === 0){
        data.productInfoId = oldData
        state.isTitle = '产品投保须知信息管理新增'
      }else if(num === 1){
        state.isTitle = '产品投保须知信息管理编辑'
        state.isUpdate = true
        Object.assign(data, {...oldData,
          informationType : oldData.informationTypeSign + '-' + oldData.informationTypeSignName, 
          productInfoId: oldData.productInfoId
        })
      } else {
        state.isAddUpdate = true
        console.log(oldData, state.isAddUpdate)
        Object.assign(data, {
        informationType : oldData[0].informationTypeSign + '-' + oldData[0].informationTypeSignName,
        displayType : oldData[0].displayTypeName,
        displayInformation : oldData[0].displayInformation  
      })
      }
      
    }

    const onClose = () => {
      state.isUpdate = false
      console.log(formRef)
      formRef.value.clearValidate()  //清除验证信息
      state.visible = false
      Object.assign(data, {
        id: 0,
        productInfoId: 0,
        informationType: undefined,
        displayType: undefined,
        displayInformation: undefined,
      })
    }
  
    const onSave = async () => {
      console.log(88888, data, state.isUpdate)
      try {
        await formRef.value.validate()
        let url = getRequestURI('productInsureTotice_insert')
        if(state.isUpdate){
          if(data.informationType === 'HealthyShow-健康须知'){
            data.informationType = 1
          }else if(data.informationType === 'ClaimProcess-理赔流程'){
            data.informationType = 2
          }else if(data.informationType === 'CommonProblem-常见问题'){
            data.informationType = 3
          }else if(data.informationType === 'InsureTips-投保提示'){
            data.informationType = 4
          }
          url = getRequestURI('productInsureTotice_update')
        }
        console.log(data)
        request(url, {data}).then(res => {
          if(res.code === 1){
            message.success('保存成功')
            onClose()
            props.handleSearch({})
          } else {
            message['warning'](res.message)
          }
        })
      } catch(error) {
        message['error'](error)
      }
    }


    return {
      ...toRefs(state),
      onClose,
      onSave,
      showDrawer,
      data,
      formRef,
      formData,
      formRules,
      addDrawer
    }
  },

})
</script>
