<template>
  <a-drawer
    :title="isShow ? '通用模板编辑' : '通用模板新增'"
    :width="'65%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form :model="formData" ref="formRef"  layout="vertical">
      <a-row>
        <a-col span="20">
          <a-form-item label="模板名称" name="templateName" required>
            <a-input style="width: 420px" v-model:value="formData.templateName" placeholder="请输入模板名称"></a-input>
          </a-form-item>
        </a-col>
        <a-col span="20">
          <a-form-item label="是否配置代理人" name="haveAgent" required>
            <a-select
              v-model:value="formData.haveAgent"
              placeholder="请选择"
              show-search
              :options="agentList"
              :filter-option="filterOption"
              style="width: 420px"
            ></a-select>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <a-table :columns="columns2" :data-source="summaryFormList"
             :pagination="false"
             :row-key="(record) => record.id" :row-selection="{
            selectedRowKeys: selectedRowKeys2,
            onChange: onSelectChanges2}">
      <template #bodyCell="{ column,index,  record }">
        <template v-if="column.key === 'index'">
          {{ index + 1 }}
        </template>
        <template v-if="column.key === 'summaryType'">
          <a-select
            v-model:value="record.summaryType"
            placeholder="请选择"
            show-search
            @change="handleChange"
            :options="materialTypeList"
            :filter-option="filterOption"
            style="width: 200px"
          ></a-select>
        </template>
        <template v-if="column.key === 'summaryName'">
          <a-select
            v-model:value="record.summaryName"
            placeholder="请选择"
            show-search
            :options="materialNameList"
            :filter-option="filterOption"
            style="width: 200px"
          ></a-select>
        </template>
        <template v-if="column.key === 'sort'">
          <a-input v-model:value="record.sort" placeholder="请输入">
          </a-input>
        </template>

      </template>
    </a-table>
    <a-button @click="add" >增加一行</a-button>&nbsp;
    <a-button @click="del" >删除</a-button>&nbsp;
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          取消
        </a-button>
        <a-button type="primary" @click="onSave">
          <template #icon>
            <SaveOutlined/>
          </template>
          保存
        </a-button>
      </a-space>
    </template>

  </a-drawer>
</template>

<script>
import {message} from 'ant-design-vue'
import {SaveOutlined, CloseOutlined} from '@ant-design/icons-vue'
import {defineComponent, onBeforeMount, reactive, ref, toRefs} from 'vue'
import {getDictionaryData} from '@/utils/request_extend'
import {getRequestURI, request} from '@/utils/request'

export default defineComponent({
  components:{
    SaveOutlined,
    CloseOutlined
  },
  emits:['handleSearch'],
  setup(props, {emit}){
    const state = reactive({
      visible: false,
      isShow: false,
      selectedRowKeys2: [],
      formData:{
        id: null,
        templateName: '',
        haveAgent: 'N',
        summaryFormList: []
      },
      summaryFormList: [
        {
          id: '',
          summaryType: null,
          summaryName: null,
          sort: ''
        }
      ],
      columns2: [
        {
          title: '序号',
          width: 80,
          key: 'index',
          dataIndex: 'index'
        },
        {
          title: '素材类型',
          width: 240,
          align: 'center',
          key: 'summaryType',
          dataIndex: 'summaryType'
        },
        {
          title: '素材名称',
          width: 240,
          align: 'center',
          key: 'summaryName',
          dataIndex: 'summaryName'
        },
        {
          fixed: 'right',
          title: '展示顺序',
          width: 120,
          align: 'center',
          key: 'sort',
          dataIndex: 'sort',
        },
      ],
    })
    const materialTypeList = ref([])
    const materialNameList = ref([])
    const agentList = ref([])
    const formRef = ref()

    onBeforeMount(() => {
      getDictionaryData(materialTypeList, 'general_materials')
      getDictionaryData(agentList, 'yes_no')

    })
    const showData = reactive({
      isShowDrag: false,
    })
    const showDrawer = (data= null) =>{
      if(data && data.id){
        state.isShow = true
        console.log('编辑的值', data)
      }
      state.visible = true
    }


// 增加一行
    let id = 1
    const add = () => {
      id++
      if (state.summaryFormList.length === 0) {
        state.summaryFormList.push({
          id: 0,
          summaryType: null,
          summaryName: null,
          sort:''
        })
      } else {
        state.summaryFormList.push({
          id,
          summaryType: null,
          summaryName: null,
          sort:''
        })
      }
    }
    const onSelectChanges2 = selectedRowKeys => {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys2 = selectedRowKeys
    }
    const del = () => {
      if (!state.selectedRowKeys2.length) message.warning('请选择数据')
      state.selectedRowKeys2.forEach(item => {
        state.summaryFormList.forEach((t, index) => {
          if (t.id === item) {
            state.summaryFormList.splice(index, 1)
          }
        })
      })
    }
    // 素材类型发生变化的时候
    const handleChange = (value) => {
      request(getRequestURI('templateSummaryList'), {
        data: {
          summaryType: value
        },
        pagination: {
          current: 1,
          pageSize: 999
        },
      }).then(res =>{
        if(res.code === 1){
          if(res.result.length){
            res.result.forEach(item =>{
              materialNameList.value.push({
                label: item.summaryName,
                value: item.summaryType,
                children: [],
                code: item.summaryCode
              })
            })
          }
        }else{
          message.error(res.message)
        }
      })
    }

    const onSave =async  () =>{
      // insertTemplate
      await formRef.value.validate()
      state.formData.summaryFormList = state.summaryFormList
      request(getRequestURI('insertTemplate'), {data: state.formData}).then(res =>{
        if(res.code === 1){
          message.success(`${state.isShow ? '编辑成功' : '新增成功'}`)
          onClose()
          emit('handleSearch')
        }else{
          message.error(res.message)
        }
      })

    }


    const onClose = () => {
      state.visible = false
      state.isShow = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
      state.selectedRowKeys2 = []
      state.summaryFormList = [{
        id: '',
        summaryType: null,
        summaryName: null,
        sort:''
      }]
      Object.assign(state.formData, {
        id: '',
        templateName: '',
        haveAgent: 'N',
        summaryFormList: []
      })
    }

    const filterOption = (input, option) => {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    return {
      filterOption,
      ...toRefs(state),
      ...toRefs(showData),
      showDrawer,
      add,
      onSelectChanges2,
      onClose,
      del,
      onSave,
      agentList,
      formRef,
      materialNameList,
      materialTypeList,
      handleChange
    }
  }


})
</script>

<style scoped>
</style>
