<template>
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @callback="handleSearch"
  ></search-form>
  <div class="butt">
    <a-button class="a-butt" type="primary" @click="saveMaterial">
      <template #icon>
        <FileAddOutlined />
      </template>
      新增
    </a-button>
  </div>
  <a-divider></a-divider>
  <template v-if="!coverList.length">
    <a-empty />
  </template>
  <div class="table_list" v-if="coverList.length > 0">
    <div class="table_content" v-for="cover in coverList" :key="cover.id">
      <p class="table_title">{{ cover.coverName }}</p>
      <p class="table_time">更新时间: {{ cover.updateTime }}</p>
      <div class="table_img">
        <a-image alt="" class="phoneClass" :src="cover.coverUrl"></a-image>
        <!--        <img alt="" class="phoneClass" :src="cover.coverUrl"/>-->
      </div>
      <div class="table_button">
        <span class="edit" @click="saveMaterial(cover)"><EditOutlined />编辑</span>
        <span class="del" @click="onDeleteCover(cover)"><DeleteOutlined />删除</span>
      </div>
    </div>
  </div>
  <div class="a-pag">
    <div></div>
    <a-pagination
      v-model:pageSize="pagination.pageSize"
      v-model:current="pagination.current"
      :total="pagination.total"
      @change="onShowSizeChange"
    />
  </div>
  <cover-edit-and-save ref="coverRef" @handleSearch="handleSearch(searchFilters, pagination)"></cover-edit-and-save>
</template>

<script>

import {DeleteOutlined, EditOutlined, FileAddOutlined} from '@ant-design/icons-vue'
import {message, Modal} from 'ant-design-vue'
import {getRequestURI, request} from '@/utils/request.js'
import {defineComponent, onMounted, reactive, ref, toRefs} from 'vue'
import SearchForm from '@/components/extended/search_form'
import CoverEditAndSave from './coverEditAndSave.vue'
import SuccessIcon from '@/assets/img/sign/sign_success.png'

export default defineComponent({
  components: {
    SearchForm,
    FileAddOutlined,
    EditOutlined,
    DeleteOutlined,
    CoverEditAndSave
  },
  setup () {
    const coverRef = ref()
    const state = reactive({
      idList: [],
      status: 'status',
      selectedKeys: [],
      coverList: [],
      // 用于在外面展示 已选标签
      condition: {
        coverName: {
          label: '封面图名称',
          placeholder: '请输入',
          type: 'input',
        },
        coverType: {
          label: '封面图类型',
          type: 'select',
          default: '',
          urlKey: 'queryDictionaryType',
          queryParam: { type: 'cover_type' }
        },
      }

    })
    const pagination = reactive({
      current: 1,
      pageSize: 8,
      total: 0
    })
    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    // 存储搜索组件传过来的数据
    const searchFilters = ref({})
    // 查询
    const handleSearch = (filters, page = null) => {
      if (filters) {
        searchFilters.value = filters
      }
      request(getRequestURI('prospectusCoverSelectList'), {
        data: {
          ...filters
        },
        pagination: {
          current: page? page.current : pagination.current,
          pageSize: page? page.pageSize : pagination.pageSize
        }
      }).then(res => {
        if (res.code === 1) {
          state.coverList = res.result
          Object.assign(pagination, res.pagination)
        } else {
          message.warning(res.message)
        }
      })
    }
    // 分页
    const onShowSizeChange = (current, pageSize) => {
      pagination.current = current
      pagination.pageSize = pageSize
      handleSearch({})
    }

    // 新增
    const saveMaterial = (cover = {}) => {
      coverRef.value.showDrawer(cover)
    }
    const onDeleteCover = (cover) =>{
      Modal.confirm({
        title: '删除提示',
        content: '确定删除此数据吗?',
        onOk: () => {
          request(getRequestURI('prospectusCoverDelete'),
            {
              data: { id: cover.id }
            }
          ).then(res => {
            if (res.code === 1) {
              message.success('删除成功')
              handleSearch({})
            } else {
              message.warning(res.message)
            }
          })
        },
        onCancel: () => {
          Modal.destroyAll()   // 清除弹出框
        }
      })
    }



    onMounted(() => {
    })

    return {
      SuccessIcon,
      ...toRefs(state),
      close,
      onDeleteCover,
      onShowSizeChange,
      handleSearch,
      saveMaterial,
      pagination,
      searchFilters,
      coverRef
    }

  }
})


</script>

<style scoped>
.butt {
  width: 600px;
  margin-left: 30px;
}
.butt .a-butt {
  margin-right: 15px;
}
.table_list {
  width: 94%;
  margin: 20px 0 20px 32px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.table_list .table_content{
  border: 1px solid #cccccc;
  border-radius: 10px;
  padding: 10px 10px 0;
  width: 23%;
  /*height: 270px;*/
  margin-right: 10px;
  margin-bottom: 18px;
  position: relative;
}

.table_content .table_title{
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 3px;
}
.table_content .table_time{
  margin: 0 0 5px !important;
  font-size: 12px;
  color: #cccccc;

}
.table_content .table_img{
  height: 350px;
  margin: 10px 0 50px;
  overflow: hidden; /* 隐藏超出容器的部分 */
}

.table_content .table_button{
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  border-top: 1px solid #cccccc;

}
.table_content .table_button span{
  display: inline-block;
  height: 35px;
  text-align: center;
  line-height: 35px;
  cursor: pointer;
}
.table_content .table_button .edit{
  width: 49%;
  border-right: 1px solid #cccccc;
}
.table_content .table_button .del{
  width: 49%;
}
.a-pag {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
:deep(.a-tabs){
  height: auto;
}

.phoneClass {
  width: auto;
  height: 100%;
  display: block; /* 确保图片是块级元素 */
  max-width: 100%; /* 防止图片过大超出容器 */
  max-height: 100%; /* 防止图片过大超出容器 */
  object-fit: cover; /* 图片自适应容器大小 */
  object-position: top; /* 图片居中 */
}

</style>
