const defaultValues = {
  id: '',
  supplierId: '',
  productDescription: undefined,
  supplierName: undefined,
  productType: undefined,
  underwriteCompanyName: '',
  productCode: undefined,//产品编码
  supProductCode: '',//供应商产品编码
  productName: '',	//产品名称
  productShortName: '',	//产品简称
  productEngName: '',	//产品英文名称
  healthNotice: undefined,//是否有健康告知
  healthNoticeType: undefined,//健康告知方式
  checkInsuranceType: undefined,	//智能核保方式
  receiptType: 'Y',	//回执方式
  payType: '',
  productStatus: '1',//	产品状态
  productFeature: '',	//产品特色
  ageGroup: '',
  productStartPrice: '',
  productCoverImg: '',
  productBackImg: '',
  prospectus: 'N', // 建议书
  sameCustomerFlag: 'N',//是否相似客户
  needSign: 'N',//是否需要签名
  signType: undefined,//是否供应商提供签名文件
  needDoubleRecord: 'N',//是否需要双录
  doubleRecordMode: undefined,//双录方式
  doubleRecordSystemDisplayMode: undefined,//双录系统展示方式
  bankCardFlag: undefined,//是否需要签约key值
  supplierPayment: 'Y',
  executeSequence: undefined,//签约节点key值
  payUrlType: '1', // 支付链接形式
  isMultiPage: 'N', // 是否展示多被保人预览页
  shareChat: 'N',//是否允许微信分享
  shareTitle: '',//微信分享标题
  shareSecondTitle: '',//微信分享副标题
  sharePhoto: '',//微信分享图标
  productCategory: '1', // 产品类别
  listPageSkipType: '1',
  detailPageSkipType: '1',
  listOtherPageSkipUrl:'',
  detailOtherPageSkipUrl: '',
  detailPageSkipButtonName: '立即购买',
  detailPagePremiumCountFlag: 'Y',
  isFloating: 'N',
  isVerifyMobile: 'N',
  floatingContent: '',
  productOnOffLineType: 'A',
  templateCode: 'detail'
}

const defaultListData = {
  gongyingshangmingcheng: [],
  productSkipAddressList: [],
  //
  renewalBeforeQuerySyncList: [],
  gracePeriodHolidayList: [],
  renewalOncePayList: [],
  refundInsuranceProcessList: [],
  refundInsuranceProtocolList: [],
  lapsedPolicyInterfaceList: [],
  nextDayTransferList: [],
  cityInsuranceList: [],
  //
  renewalList: [],
  supplierQueryInterfaceList: [],
  //
  // refundInsuranceTypeList: [],
  // refundPremiumTypeList: []
}

const defaultState = {
  parentData: {},
  isUpdateNum: null,
  ziDuanOne: false,//核保判断
  ziDuan: false,//判断健康告知
  daiShou: false,//是否代收
  sign: false,//是否签名
  doubleRecord: false,//
  execute: false,//签约节点
  look: false,
  data: [],//多选
  tuozhanxinxi: [],
  dataSource: [],
  visible: false,
  supId: '',
  isNew: '',
  transferStyle: {width: '50%'},
  roleTitles: ['未添加授权角色列表', '已添加角色列表'],
  roleData: [],
  columns: [
    {
      title: '险种编码',
      width: 100,
      align: 'center',
      key: 'riskCode',
      dataIndex: 'riskCode'
    },
    {
      title: '险种名称',
      width: 200,
      align: 'center',
      key: 'riskName',
      dataIndex: 'riskName'
    },
    {
      title: '互斥关键字',
      width: 200,
      align: 'center',
      key: 'repulsionKey',
      dataIndex: 'repulsionKey'
    },
    {
      title: '操作',
      width: 150,
      align: 'center',
      key: 'operation',
      dataIndex: 'operation'
    }
  ],
  condition2: {
    riskCode: '险种编码',
    riskName: {
      label: '险种名称',
      type: 'select',
      default: '',
      data: []
    }
  },
  selectedRowKeys: [],
  result: [],
  zhuangTai2: false
}
const defaultFormData4 = {
  cityInsurance: '',
  customerServiceUrl: '',
  gracePeriodHoliday: '',
  id: '',
  lapsedPolicyInterface: '',
  nextDayTransfer: '',
  productInfoId: '',
  refundInsuranceProcess: '',
  refundInsuranceProtocol: '',
  refundInsuranceType: '',
  refundPremiumType: '',
  renewal: '',
  renewalBeforeQuerySync: '',
  renewalOncePay: '',
  renewalYear: '',
  supplierQueryInterface: '',
  productSkipAddress: '',
  outAddressLink: '',
  jsCheckStatus: 'N', //是否使用校验文件
  jsCheckUrl: '', //上传地址值
  cpsId: '', // CPS推广代码
}
// 重置操作
const resetData = (data) => {
  Object.keys(data).forEach(key => {
    data[key] = defaultValues[key]
  })
}

const resetData2 = (data) => {
  Object.keys(data).forEach(key => {
    data[key] = defaultFormData4[key]
  })
}


export {
  defaultValues,
  defaultListData,
  defaultState,
  defaultFormData4,
  resetData,
  resetData2
}
