<style scoped></style>

<template>
  <search-form
    ref="searchRef"
    :Condition="condition"
    :ColSpan="12"
    @resetAll="resetAll"
    @callback="handleSearch"
    @nameLinked="changes"
  ></search-form>
  <a-table
    id="table"
    :columns="columns"
    :data-source="dataSource ? changTableList(dataSource['result']) : []"
    :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }"
    :row-key="(record) => record.id"
    :pagination="pagination"
    @change="handleTableChange"
    :loading="loading"
  >
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <div>
          <a href="javascript:void(0)" style="color: orange" @click="checkProduct(record)">渠道配置</a>&nbsp;&nbsp;
        </div>
      </template>
    </template>
  </a-table>
  <a-modal
    v-model:visible="visible"
    title="提示"
    @ok="handleOk"
    okText="确定"
    cancelText="取消"
  >
    <h2>是否确定删除该信息</h2>
  </a-modal>
  <product-channel ref="channelRef"></product-channel>
</template>

<script>
import {computed, defineComponent, onMounted, reactive, ref, toRefs} from 'vue'
import SearchForm from '../../../components/extended/search_form'
import {getRequestURI, request, requestUsePagination} from '../../../utils/request.js'
import {getDictionaryData, getDictionaryValue} from '../../../utils/request_extend'
import ProductChannel from '../productChannel/productChannel'

export default defineComponent({
  components: {
    ProductChannel,
    SearchForm,
  },
  setup () {
    const userDrawerRef = ref()//新增编辑
    const productSkipAddressList = ref()//新增编辑
    const environmentsRef = ref()//环境同步
    const state = reactive({
      refreshDom: true,
      isCopy: false,
      export1: '',//导出
      export2: '',//导出
      data: [],//批量删除
      condition: {
        supplierId: {
          label: '供应商名称',
          type: 'tree-select',
          urlKey: 'querySupplier',
          value: 'id',
          default: '',
          data: []
        },
        productId: {
          label: '产品名称',
          type: 'select',
          default: '',
          data: []
        },
        supProductCode: '供应商产品编码',
        productCode: '产品编码',
      },
      columns: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left',
        },
        {
          title: '产品编码',
          width: 100,
          align: 'center',
          key: 'productCode',
          dataIndex: 'productCode',
        },
        {
          title: '供应商产品编码',
          width: 100,
          align: 'center',
          key: 'supProductCode',
          dataIndex: 'supProductCode',
        },
        {
          title: '产品名称',
          width: 120,
          align: 'center',
          key: 'productName',
          dataIndex: 'productName',
        },
        {
          title: '产品类型',
          width: 100,
          align: 'center',
          key: 'productType',
          dataIndex: 'productType',
          customRender: (text)=> getProducrType(text.value)
        },
        {
          title: '产品类别',
          width: 120,
          align: 'center',
          key: 'productCategory',
          dataIndex: 'productCategory',
          customRender: (text)=> getDictionaryValue('product_category', text.value)
        },
        {
          title: '供应商名称',
          width: 100,
          align: 'center',
          key: 'supplierName',
          dataIndex: 'supplierName',
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 120,
        },
      ],
      // 服务id
      serviceName: '',
      tableList: [],
      selectedRowKeys: [],
      searchNow: {},//分页
      productTypeList: []
    })
    const productData = reactive({
      productCode: '',
      channel: undefined,
      channelUserId: '',
      agentId: ''
    })
    const channelRef = ref()
    const getProducrType = (value = '') =>{
      if(value){
        const a = state.productTypeList['value'].filter(item => item.value === value)
        return a? a[0].label : ''
      }
    }

    const cahnpintiaozhuandizhi = function () {
      getDictionaryData(productSkipAddressList, 'productSkipAddress')
    }
    cahnpintiaozhuandizhi()
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('ppqa_selectInfo'))
    const changTableList = (params) => {
      let list = []
      if(params) {
        list =  params.map(item => {
          const productValue =  productSkipAddressList.value.find(d=> d.value == item.productSkipAddress )
          return {
            ...item,
            productSkipAddress: productValue ? productValue.label : (item.productSkipAddress == 1 ? '内部' : (item.productSkipAddress == 2 ? '外部' : item.productSkipAddress))
          }
        })
      }
      return list
    }
    //页面挂载调用数据
    onMounted(() => {
      getDictionaryData(state.productTypeList, 'ProductType')
      handleSearch({})
    })
    // 存储搜索组件传过来的数据
    const searchfFilters = ref({})
    //查询
    const handleSearch = function (filters, num = '') {
      if (filters) {
        searchfFilters.value = filters
      }
      state.searchNow = filters
      run({
        pagination: {
          current: num? num : 1,
          pageSize: 10
        },
        data: filters,
      })
    }

    // provide('handleMainSearch',handleSearch())
    //产品下拉查询
    const changes = function (value) {
      request(getRequestURI('select'), { data: { supplierId: value } }).then(result => {
        console.log(result)
        const arr = []
        const res = result.result
        const label = 'id'
        const sysName = res.reduce((all, next) => all.some((atom) => atom[label] == next[label]) ? all : [...all, next], [])
        for (let i = 0; i < sysName.length; i++) {
          arr.push({ label: sysName[i].productName, value: sysName[i].id })
        }
        state.condition.productId.data = arr
      })
    }
    const init = function () {
      request(getRequestURI('select'), { data: {} }).then(result => {
        const arr = []
        const res = result.result
        const label = 'id'
        const sysName = res.reduce((all, next) => all.some((atom) => atom[label] == next[label]) ? all : [...all, next], [])
        for (let i = 0; i < sysName.length; i++) {
          arr.push({ label: sysName[i].productName, value: sysName[i].id })
        }
        state.condition.productId.data = arr
      })
    }
    const resetAll = () => {
      state.condition.productId.data = []
      init()
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.log('===', filters)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.searchNow,
      })
    }
    // 复选的回调
    const onSelectChange = (selectedRowKeys, selectedRowrode) => {
      state.selectedRowKeys = selectedRowKeys
      state.export1 = selectedRowrode.supplierId
      state.export2 = selectedRowrode.id
    }
    const handleOk = e => {
      console.log(e)
      visible.value = false
    }
    const searchRef = ref()
    //渠道配置编辑
    const checkProduct = function (oldData) {
      channelRef.value.showDrawer(oldData)
    }

    const visible = ref(false)
    init()
    const filterOption = (input, option) => {
      return option.label.indexOf(input) > -1
    }
    const productRef = ref()
    const getChannelName = () => {
      let data = {}
      request(getRequestURI('channel_select'), { data }, true, 'get').then(result => {
        ChannelTypeList.value = result.result
      })
    }
    const ChannelTypeList = ref([])
    onMounted(() => {
    })
    return {
      channelRef,
      productRef,
      getChannelName,
      ChannelTypeList,
      productData,
      filterOption,
      searchRef,
      resetAll,
      ...toRefs(state),
      handleSearch,
      onSelectChange,
      handleTableChange,
      userDrawerRef,
      environmentsRef,
      pagination,
      total,
      loading,
      dataSource,
      checkProduct,
      visible,
      handleOk,
      changes,
      searchfFilters,
      changTableList,
      productSkipAddressList,
    }
  },
})
</script>
