<style scoped>
</style>

<template>
  <a-drawer
    :title="isNew"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <!--    <search-form :Condition="condition" :ColSpan="12" @callback="handleSearch" v-if="clean"></search-form>-->
    <a-button danger @click="delMultiple" v-if="butshow" style="margin-bottom:15px">
      <template #icon>
        <DeleteOutlined/>
      </template>
      批量删除
    </a-button>&nbsp;
    <a-button type="primary" v-if="!ischeck" @click="addforms(record,1)">
      <template #icon>
        <SaveOutlined/>
      </template>
      新增
    </a-button>&nbsp;
    <a-button danger @click="handelexportFile">
      <template #icon>
        <VerticalAlignBottomOutlined/>
      </template>
      下载模板
    </a-button>&nbsp;
    <a-button primary v-if="!ischeck" @click="importformss(query.data.riskId)">
      <template #icon>
        <SaveOutlined/>
      </template>
      根据费率表生成投保要素
    </a-button>&nbsp;
    <a-button primary v-if="!ischeck" @click="handleSearch({},'reset')">
      <template #icon>
        <ReloadOutlined />
      </template>
      刷新
    </a-button>
    <div v-if="tableshow">
      <a-table :columns="columns" :data-source="list"
               :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChanges }"
               :row-key="record => record.id"
               :scroll="{ x: 300 }"
               :pagination="false"
               :loading="loading"
      >
        <template #bodyCell="{ column, index, record }">
          <template v-if="column.key === 'index'">
            {{ index + 1 }}
          </template>
          <template v-if="column.key === 'operation'">
            <a-button type="text" @click="revise(record,2)" size="small" style="margin-right: 10px; color: orange;">
              修改
            </a-button>
          </template>
        </template>
      </a-table>
      <!-- <add-shoulder-list ref="addShoulderListRef"></add-shoulder-list> -->
    </div>
    <div v-if="!tableshow">
      <a-table :columns="columns" :data-source="list"
               :row-key="record => record.id"
               :loading="loading"
               :scroll="{ x: 1500 }"
      >
        <template #bodyCell="{ column, index, record}">
          <template v-if="column.key === 'index'">
            {{ index + 1 }}
          </template>
          <template v-if="column.key === 'operation'">
            <a-button type="text" @click="revise(record,3)" size="small" style="margin-right: 10px; color: orange;">
              查看
            </a-button>
          </template>
        </template>
      </a-table>
    </div>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
<!--        <a-button type="primary" v-if="!ischeck" @click="onSave">-->
<!--          <template #icon>-->
<!--            <SaveOutlined/>-->
<!--          </template>-->
<!--          提交-->
<!--        </a-button>-->
      </a-space>
    </template>
  </a-drawer>
  <add-form ref="addDrawerRef" :handleSearch="handleSearch" :riskId="data.riskId" :riskSign="riskSign"></add-form>
  <ratetableimport-file @search="handleSearch({})" ref="ratetableimportFileRef"></ratetableimport-file>
</template>

<script>
import {CloseOutlined, SaveOutlined, DeleteOutlined, VerticalAlignBottomOutlined} from '@ant-design/icons-vue'
import {defineComponent, reactive, toRefs, ref, onMounted} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
import {getDictionaryValue, getExtendUri} from '../../../utils/request_extend'
import ratetableimportFile from './ratetableimportFile'
// import SearchForm from '../../../components/extended/search_form'
import addForm from './addForm'
import {Modal, message} from 'ant-design-vue'
import RatetableimportFile from './ratetableimportFile'
// import AddShoulderList from './addShoulderList'

export default defineComponent({
  components: {
    RatetableimportFile,
    // AddShoulderList,
    CloseOutlined, SaveOutlined, DeleteOutlined, addForm, ratetableimportFile,
    VerticalAlignBottomOutlined
  },
  props: {
    oldDataId: {
      type: String,
      default: ''
    }
  },
  setup(props, {expose}) {
    // const addShoulderListRef = ref()
    const ratetableimportFileRef = ref()
    const ischeck = ref(false)//输入框不能用
    const addDrawerRef = ref()
    const userDrawerRef = ref()
    const formRef = ref()
    const data = reactive({
      dataType: undefined,
      elementDescription: undefined,
      elementName: undefined,
      elementType: undefined,
      id: 0,
      isEdit: undefined,
      isMust: undefined,
      isPremiumCal: undefined,
      isShowStatus: undefined,
      productElementListForm: [
        {
          calculated: undefined,
          calculatedValue: undefined,
          defaultShow: undefined,
          id: 0,
          isShowStatus: undefined,
          productElementId: 0,
          sort: undefined
        }
      ],
      riskId: 0,
      sort: '',
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      sorters: [
        {
          field: '',
          order: ''
        }
      ]
    })
    const state = reactive({
      clean: false,
      tableshow: false,
      butshow: false,//按钮显示
      datasource: [
        {
          calculated: '',
          sort: '',
          calculatedValue: ''
        }
      ],//要素值列表
      list: [],//表格数据
      columns2: [
        {
          title: '序号',
          width: 80,
          fixed: 'left',
          key: 'index',
          dataIndex: 'index'
        },
        {
          title: '投保要素值序号',
          width: 140,
          fixed: 'left',
          key: 'sort',
          dataIndex: 'sort'
        },
        {
          title: '投保要素值计算值',
          width: 140,
          align: 'center',
          key: 'calculated',
          dataIndex: 'calculated'
        },
        {
          title: '投保要素值显示值',
          width: 120,
          align: 'center',
          key: 'calculatedValue',
          dataIndex: 'calculatedValue'
        },
        {
          title: '是否显示',
          width: 80,
          align: 'center',
          key: 'isShowStatus',
          dataIndex: 'isShowStatus'
        },
        {
          title: '默认显示',
          width: 80,
          align: 'center',
          key: 'defaultShow',
          dataIndex: 'defaultShow'
        }

      ],
      condition: {
        elementType: {
          label: '要素类别',
          type: 'select',
          // default: '',
          data: [],
          placeholder: '请选择'
        },
        isShowStatus: {
          label: '是否显示',
          type: 'select',
          default: '',
          data: [
            {value: 'Y', label: '是'},
            {value: 'N', label: '否'}
          ]
        }
      },
      columns: [
        {
          title: '序号',
          width: 80,
          fixed: 'left',
          key: 'index',
          dataIndex: 'index'
        },
        {
          title: '要素类别',
          width: 140,
          align: 'center',
          key: 'elementType',
          dataIndex: 'elementType',
          customRender: (text) => getDictionaryValue('element_type', text.value)
        },
        {
          title: '要素类别名称',
          width: 140,
          align: 'center',
          key: 'elementName',
          dataIndex: 'elementName'
        },
        {
          title: '是否显示',
          width: 100,
          align: 'center',
          key: 'isShowStatus',
          dataIndex: 'isShowStatus'
        },
        {
          title: '是否必填',
          width: 100,
          align: 'center',
          key: 'isMust',
          dataIndex: 'isMust'
        },
        {
          title: '是否费率表列',
          width: 100,
          align: 'center',
          key: 'isPremiumCal',
          dataIndex: 'isPremiumCal'
        },
        {
          title: '是否可编辑',
          width: 100,
          align: 'center',
          key: 'isEdit',
          dataIndex: 'isEdit'
        },
        {
          title: '显示顺序',
          width: 100,
          align: 'center',
          key: 'sort',
          dataIndex: 'sort'
        },
        {
          title: '操作',
          key: 'operation',
          fixed: 'right',
          width: 180
        }
      ],
      selectedRowKeys2: [],
      selectedRowKeys: [],
      loading: false,
      visible: false,
      isNew: '',//title名字
      isisis: true,//编辑还是新增
      checkids: [],
      dataLis: [],
      riskSign:'',
      query: {
        data: {
          dataType: '',
          elementDescription: '',
          elementName: '',
          elementType: '',
          id: 0,
          isEdit: '',
          isMust: '',
          isPremiumCal: '',
          isShowStatus: '',
          productElementListForm: [
            {
              calculated: '',
              calculatedValue: '',
              defaultShow: '',
              id: 0,
              isShowStatus: '',
              productElementId: 0,
              sort: 0
            }
          ],
          riskId: 0,
          sort: 0
        },
        pagination: {
          current: 1,
          pageSize: 10,
          total: 0
        },
        sorters: [
          {
            field: '',
            order: ''
          }
        ]
      }
    })
    const handleSearch = function (filters,str='') {
      state.loading = true
      state.query.data.isShowStatus = filters.isShowStatus
      state.query.data.elementType = filters.elementType
      state.query.pagination={
        current: 1,
          pageSize: 999999,
          total: 0
      }
      request(getRequestURI('tbys_list'),state.query).then(res => {
        state.loading = false
        if(res.code === 1 && str === 'reset'){
          message.success('刷新成功')
        }
        if(res.result.length>0) {
           state.list = res.result
          state.datasource = res.productElementListEntityList
          state.list.filter(item => {
            if (item.isShowStatus == 'Y') {
              item.isShowStatus = '是'
            } else {
              item.isShowStatus = '否'
            }
          })
          state.list.filter(item => {
            if (item.isMust == 'Y') {
              item.isMust = '是'
            } else {
              item.isMust = '否'
            }
          })
          state.list.filter(item => {
            if (item.isPremiumCal == 'Y') {
              item.isPremiumCal = '是'
            } else {
              item.isPremiumCal = '否'
            }
          })
          state.list.filter(item => {
            if (item.isEdit == 'Y') {
              item.isEdit = '是'
            } else {
              item.isEdit = '否'
            }
          })
          state.list.filter(item => {
            if (item.dataType == '1') {
              item.dataType = '文本'
            } else if (item.dataType === '2') {
              item.dataType = '数字'
            } else if (item.dataType == '3') {
              item.dataType = '字母'
            } else if (item.dataType == '4') {
              item.dataType = '单选项'
            } else if (item.dataType == '5') {
              item.dataType = '多选项'
            } else if (item.dataType == '6') {
              item.dataType = '多文本'
            } else if (item.dataType == '7') {
              item.dataType = '日期'
            } else if (item.dataType == '8') {
              item.dataType = '下拉选框'
            }
          })
        }

      })
    }
    // provide('')
    // 批量删除
    const delMultiple = () => {
      request(getRequestURI('select_ListRateTable'), { data: { id: props.oldDataId } }).then(res => {
        if (res.code !== 1) {
          message['warning'](res.message)
        }
        else{
          let keys = state.selectedRowKeys
          if (keys.length === 0) {
            message.warning('请选择数据')
            return
          }
          Modal.confirm({
            title: '确认删除吗?',
            async onOk() {
              // visible.value = true
              request(getRequestURI('tbys_del'), {
                data: {
                  ids: keys
                },
                pagination: {
                  current: 1,
                  pageSize: 10,
                  total: 0
                },
                sorters: [
                  {
                    field: '',
                    order: ''
                  }
                ]
              }, true, 'post').then(
                () => {
                  // console.log('批量删除的结果:', result)
                  // state.selectedRowKeys = []
                  // handleSearch()
                  state.selectedRowKeys = []

                  message.success('删除成功')
                  state.list = []
                  handleSearch({})
                }
              )
            },
            onCancel() {
            }
          })
        }
      })


    }
    // 新增
    const addforms = function (oldData, num) {
      // console.log('----------9----------',props.oldDataId)
      request(getRequestURI('select_ListRateTable'), { data: { id: props.oldDataId } }).then(res => {
        if (res.code !== 1) {
          message['warning'](res.message)
        }else{
          addDrawerRef.value.showDrawer(oldData, num)
        }
      })
    }


    const importformss = function(value) {
      console.log(value)
      ratetableimportFileRef.value.showDrawer(value)
    }
    onMounted(() => {
      getElemengtype()
    //  element_type
    })
    //  要素类别下拉
    const getElemengtype = () => {
      request(getExtendUri('dictionary_query_byType'), {data: {types: ['element_type']}}).then(res => {
        console.log(res)
        state.condition.elementType.data = res.result.element_type
      })
    }
    // 编辑
    const revise = function (oldData, num) {
      if(num===2){
        request(getRequestURI('select_ListRateTable'), { data: { id: props.oldDataId } }).then(res => {
          if (res.code !== 1) {
            message['warning'](res.message)
          }else{
            addDrawerRef.value.showDrawer(oldData, num)
          }
        })
      }else{
        addDrawerRef.value.showDrawer(oldData, num)
      }
    }
    const showDrawer = (oldData = {}, num,riskSign) => {
      state.dataLis = oldData
      // console.log(oldData)
      console.log('111111111111222',riskSign)
      state.riskSign=riskSign
      state.query.data.riskId = oldData.id
      data.riskId = state.query.data.riskId
      // console.log(data.productInfoId,'zazazazazaz')
      state.visible = true
      if (num === 1) {
        state.isNew = '查看页面'
        state.clean = true
        state.butshow = false
        ischeck.value = true
        state.tableshow = false
        handleSearch({})
      } else if (num === 2) {
        state.isNew = '险种投保要素编辑'
        state.clean = true
        state.butshow = true
        ischeck.value = false
        state.tableshow = true
        handleSearch({})
        // state.columns.push({
        //   title: '操作',
        //   key: 'operation',
        //   fixed: 'right',
        //   width: 160
        // })
      }
      console.log('editNewUser', state.isNew, oldData)
      Object.keys(data).forEach(key => {
        data[key] = oldData[key] || data[key]
      })
    }
    const onSelectChange = (selectedRowKeys, selectedRows) => {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      addDrawerRef.value.showDrawer(selectedRows)
      // state.selectedRowKeys = checkids
      state.isisis = false
      console.log(666)
      Object.assign(data, selectedRows[0])
      console.log(data)

    }
    const onClose = () => {
      state.visible = false
      state.clean = false
      state.checkids = []
      state.visible = false
      state.selectedRowKeys = []
      state.list = []
      data.dataType = undefined
      data.elementDescription = undefined
      data.elementName = undefined
      data.elementType = undefined
      data.isEdit = undefined
      data.isMust = undefined
      data.isPremiumCal = undefined
      data.isShowStatus = undefined
      data.sort = undefined
      data.productElementListForm = [
        {
          calculated: undefined,
          calculatedValue: undefined,
          defaultShow: undefined,
          id: 0,
          isShowStatus: undefined,
          productElementId: 0,
          sort: undefined
        }
      ]
      state.columns2 = [
        {
          title: '序号',
          width: 140,
          fixed: 'left',
          key: 'index',
          dataIndex: 'index'
        },
        {
          title: '投保要素值序号',
          width: 140,
          fixed: 'left',
          key: 'sort',
          dataIndex: 'sort'
        },
        {
          title: '投保要素值计算值',
          width: 120,
          align: 'center',
          key: 'calculated',
          dataIndex: 'calculated'
        },
        {
          title: '投保要素值显示值',
          width: 120,
          align: 'center',
          key: 'calculatedValue',
          dataIndex: 'calculatedValue'
        },
        {
          title: '是否显示',
          width: 120,
          align: 'center',
          key: 'isShowStatus',
          dataIndex: 'isShowStatus'
        },
        {
          title: '默认显示',
          width: 120,
          align: 'center',
          key: 'defaultShow',
          dataIndex: 'defaultShow'
        }

      ]
// state.columns=[
//         {
//           title: '序号',
//           width: 140,
//           fixed: 'left',
//           key: 'index',
//           dataIndex: 'index'
//         },
//         {
//           title: '要素类别',
//           width: 120,
//           align: 'center',
//           key: 'elementType',
//           dataIndex: 'elementType'
//         },
//         {
//           title: '要素类别名称',
//           width: 140,
//           align: 'center',
//           key: 'elementName',
//           dataIndex: 'elementName'
//         },
//         {
//           title: '是否显示',
//           width: 250,
//           align: 'center',
//           key: 'isShowStatus',
//           dataIndex: 'isShowStatus'
//         },
//         {
//           title: '是否必填',
//           width: 160,
//           align: 'center',
//           key: 'isMust',
//           dataIndex: 'isMust'
//         },
//         {
//           title: '是否用于保费计算',
//           width: 100,
//           align: 'center',
//           key: 'isPremiumCal',
//           dataIndex: 'isPremiumCal'
//         },
//         {
//           title: '是否可编辑',
//           width: 120,
//           align: 'center',
//           key: 'isEdit',
//           dataIndex: 'isEdit'
//         },
//         {
//           title: '显示顺序',
//           width: 120,
//           align: 'center',
//           key: 'sort',
//           dataIndex: 'sort'
//         }]

    }
    // 增加一行
    let id = 1
    const add = () => {
      id++
      data.productElementListForm.push({
        calculated: undefined,
        calculatedValue: undefined,
        defaultShow: undefined,
        id: id,
        isShowStatus: undefined,
        productElementId: 0,
        sort: undefined
      })
    }
    // 下载模板
    const handelexportFile = function () {
      const url = 'https://hb-product-test-obs.obs.cn-north-219.jrzq.huaweicloud.com/upload/common/product/2023/07/12/2a7f3661_39e6_48d1_85ae_aa0d7b7b64e7/%E6%8A%95%E4%BF%9D%E8%A6%81%E7%B4%A0%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx'
      window.open(url, '_blank')
    }
    // 删除
    const del = () => {
      state.selectedRowKeys2.forEach((item) => {
        data.productElementListForm.forEach((item1, index) => {
          if (item1.id == item) {
            data.productElementListForm.splice(index, 1)
          }
        })
      })
    }
    // 保存
    const onSave = async () => {
      //  const values = await formRef.value.validate()
      try {
        const values = await formRef.value.validate()

        if (state.isisis) {
          console.log(999999, data)
          if (data.sort.length > 3) {
            console.log(222)
            message['warning']('显示顺序输入长度不能超过3位数')
            return
          }
          if (data.isShowStatus == '是') {
            data.isShowStatus = 'Y'
          } else if (data.isShowStatus == '否') {
            data.isShowStatus = 'N'
          }
          if (data.isEdit == '是') {
            data.isEdit = 'Y'
          } else if (data.isEdit == '否') {
            data.isEdit = 'N'
          }
          if (data.isMust == '是') {
            data.isMust = 'Y'
          } else if (data.isMust == '否') {
            data.isMust = 'N'
          }
          if (data.isPremiumCal == '是') {
            data.isPremiumCal = 'Y'
          } else if (data.isPremiumCal == '否') {
            data.isPremiumCal = 'N'
          }
          request(getRequestURI('tbys_add'), {data}, true, 'post').then(
            (res) => {
              console.log(res)
              if (res.message == '显示顺序不能重复') {
                message['warning']('显示顺序重复，请修改后重新保存')
              } else if (res.code == 1) {
                message['success']('保存成功')
                formRef.value.resetFields()
                data.productElementListForm = [
                  {
                    calculated: undefined,
                    calculatedValue: undefined,
                    defaultShow: undefined,
                    id: 0,
                    isShowStatus: undefined,
                    productElementId: 0,
                    sort: undefined
                  }
                ]
              }
              //  message['warning'](res.message)

              // console.log('post success:', result)
              handleSearch({})
            })

        } else {
          if (data.isShowStatus == '是') {
            data.isShowStatus = 'Y'
          } else if (data.isShowStatus == '否') {
            data.isShowStatus = 'N'
          }
          if (data.isEdit == '是') {
            data.isEdit = 'Y'
          } else if (data.isEdit == '否') {
            data.isEdit = 'N'
          }
          if (data.isMust == '是') {
            data.isMust = 'Y'
          } else if (data.isMust == '否') {
            data.isMust = 'N'
          }
          if (data.isPremiumCal == '是') {
            data.isPremiumCal = 'Y'
          } else if (data.isPremiumCal == '否') {
            data.isPremiumCal = 'N'
          }
          // 要素类别格式不对
          request(getRequestURI('tbys_updata'), {data}, true, 'post').then(
            (result) => {
              console.log(result)
              if (result.code !== 1) {
                message['warning'](result.message)
              } else{
                message['success']('修改成功')
                formRef.value.resetFields()
                data.productElementListForm = [{
                  calculated: undefined,
                  calculatedValue: undefined,
                  defaultShow: undefined,
                  id: 0,
                  isShowStatus: undefined,
                  productElementId: 0,
                  sort: undefined
                }]
              }
              handleSearch({})
              state.isisis = true
            }
          )
        }
        console.log('Success:', values)
        // state.visible = false

      } catch (errorInfo) {
        console.log('Failed:', errorInfo)
      }

    }
    expose({
      showDrawer
    })
    const onSelectChanges = selectedRowKeys => {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
    }
    const onSelectChanges2 = selectedRowKeys => {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys2 = selectedRowKeys
    }
    return {
      addforms,
      getElemengtype,
      ischeck,
      onSelectChange,
      onSelectChanges,
      data,
      userDrawerRef,

      handleSearch,
      formRef,
      // formData,
      // formRules,
      ...toRefs(state),
      onClose,
      onSave,
      delMultiple,
      revise,
      add,
      del,
      onSelectChanges2,
      addDrawerRef,
      importformss,
      ratetableimportFileRef,
      handelexportFile
    }
  }
})
</script>
