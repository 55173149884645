<template>
  <a-drawer
    :title="inNew"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <search-form
      v-if="visible"
      :Condition="condition"
      :ColSpan="12"
      @callback="handleSearch"
    ></search-form>
      <a-table
        :columns="columns"
        :data-source="dataSourceList"
        :key="time"
        :scroll="{ x: 1500 }"
        :row-selection="{
			selectedRowKeys: selectedRowKeys,
			onChange: onSelectChange,
		}"
        :row-key="(record) => record.id"
        :pagination="pagination"
        @change="handleTableChange"
        :loading="loading"
      >
      </a-table>
    <template #extra>
      <a-space v-if="!disabled">
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button type="primary" @click="onSave">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>

<script>
import {ReloadOutlined} from '@ant-design/icons-vue'
import {defineComponent, reactive, toRefs, ref, computed} from 'vue'
import {getRequestURI, request, requestUsePagination} from '../../../utils/request'
import SearchForm from '../../../components/extended/search_form'
import {message} from 'ant-design-vue'
// import {message} from 'ant-design-vue'
// import {message} from 'ant-design-vue'

// const countyData = {
//   双鸭山:['集贤县', '友谊县']
// }
export default defineComponent({
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ReloadOutlined,
    SearchForm,
    // CloseOutlined,
    // UploadOutlined
  },
  props: {
    status: {
      type: String,
      required: true,
      default() {
        return '123'
      }
    },
    idd: {
      type: String,
      required: true,
      default() {
        return 'ss'
      }
    }
  },
  setup(props, {expose}) {
    const userDearw = ref()
    const formRef = ref()
    const formData = reactive({
      id: 0,
      productBasicsId: '',
      factorType: '',
      dataType: '',
      ynCheck: 'Y',
      checkNumber: '',
      ynStart: 'Y',
      remarks: ''
    })
    const formRules = {
      startDate: [
        {
          required: true,
          type: 'date'
        }
      ]
    }
    const listDate = reactive({
      statuJudge: [
        {
          label: '是',
          value: 'Y'
        },
        {
          label: '否',
          value: 'N'
        }
      ],
      essential: [
        {
          label: 'insureAgeRange-投保年龄范围',
          value: 1
        },
        {
          label: 'insuredAgeRange-被保人年龄范围',
          value: 2
        },
        {
          label: 'sexLimit-性别购买限制',
          value: 3
        },
        {
          label: 'ageDiffer-投保人被保人年龄差',
          value: 4
        },
        {
          label: 'startDateRange-保险起期范围',
          value: 5
        }
      ],
      informationType: [
        {
          label: '文本',
          value: 1
        },
        {
          label: '数字',
          value: 2
        },
        {
          label: '字母',
          value: 3
        },
        {
          label: '单选项',
          value: 4
        },
        {
          label: '多选项',
          value: 5
        },
        {
          label: '多文本',
          value: 6
        },
        {
          label: '日期',
          value: 7
        },
        {
          label: '下拉列表',
          value: 8
        },
        {
          label: '弹出框',
          value: 9
        },
        {
          label: '特殊类型',
          value: 10
        }
      ],
      checkJudge: [
        {
          label: '是',
          value: 'Y'
        },
        {
          label: '否',
          value: 'N'
        }
      ]
    })
    const state = reactive({
      time: null,
      visible: false,
      dataSourceList: [],
      visible1: false,
      isNew: true,
      checkids: [],
      transferStyle: {width: '50%'},
      roleTitles: ['未添加授权角色列表', '已添加角色列表'],
      roleData: [],
      delQuery: [],
      query: {
        data: {
          productBasicsId: '',
          factorType: '',
          dataType: 0,
          ynCheck: '',
          checkNumber: 0,
          ynStart: '',
          remarks: ''
          // checkNumber: 0,
          // createTime: '',
          // createUser: '',
          // dataType: 0,
          // delNumber: 0,
          // factorType: 0,
          // id: 0,
          // isDel: 0,
          // productBasicsId: 2,
          // productCode: '',
          // productId: 0,
          // productName: '',
          // remarks: '',
          // supName: '',
          // supProductCode: '',
          // updateTime: '',
          // updateUser: '',
          // ynCheck: '',
          // ynStart: ''
        },
        sorters: [
          {
            field: '',
            order: ''
          }
        ]
      },
      columns: [
        {
          title: '名称',
          width: 80,
          fixed: 'center',
          key: 'collectionName',
          dataIndex: 'collectionName',
        },
        {
          title: 'id',
          width: 80,
          align: 'center',
          key: 'id',
          dataIndex: 'id'
        },
        // {
        //   title: '描述',
        //   width: 80,
        //   align: 'center',
        //   key: 'creator',
        //   dataIndex: 'creator',
        // },
        {
          title: '执行版本号',
          width: 80,
          align: 'center',
          key: 'version',
          dataIndex: 'version',
        },
        {
          title: '状态',
          width: 80,
          align: 'center',
          key: 'releaseStatus',
          dataIndex: 'releaseStatus',
          customRender: (text) => {
            if (text.value === 1){
              return '已发布'
            }else {
              return  '未发布'
            }
          }
        },
      ],
      result1: [],
      statuss: '',
      selectedRowKeys: [],
      lastFilter:{},
      condition: {
        collectionName: '规则集名称',

      }
    })
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('ruleCollection129_list'))
    // const onSelect = (checkids,check) => {
    //   console.log(check)
    //   state.checkids = checkids
    //   // check.forEach(item => {
    //   //   formData.dataType = item.dataType
    //   //   formData.checkNumber = item.checkNumber
    //   //   formData.ynStart = item.ynStart
    //   //   formData.ynCheck = item.ynCheck
    //   //   formData.factorType = item.factorType
    //   //   formData.remarks = item.remarks
    //   // })
    //   // userDearw.value.showDrawer1(formData)
    // }
    const onSelectChange = (selectedRowKeys, selectedRow) => {
      console.log(selectedRow)
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys

    }
    const modifyUser = (record, statuss) => {
      state.statuss = statuss
      formData.id = record.id
      formData.factorType = record.factorType
      formData.dataType = record.dataType
      formData.ynCheck = record.ynCheck
      formData.checkNumber = record.checkNumber
      formData.ynStart = record.ynStart
      formData.remarks = record.remarks
      userDearw.value.showDrawer1(record)
    }
    const isDisable = computed(() => {
      if (props.status == 'select') {
        return true
      } else {
        return false
      }
    })
    // eslint-disable-next-line vue/return-in-computed-property
    const inNew = computed(() => {
      if (props.status == 'select') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        state.columns.splice(6,1)
        return '产品投保规则查看'
      } else if(props.status == 'update') {
        if (state.columns.length == 6) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          state.columns.push(
            {
              title: '操作',
              key: 'operation',
              fixed: 'right',
              width: 80
            }
          )
        }
        return '产品投保规则新增'
      } else {
        return
      }
    })
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.log('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.lastFilter,
      }).then(res => {
        state.dataSourceList = res.result
        console.log(state.dataSourceList)
      })
    }
    const handleSearch = function (filters) {
      console.log('handleSearch', filters)
      console.log(state.lastFilter)
      state.lastFilter=filters
      state.loading = true
      state.lastFilter=filters
      select()
      state.loading = false
    }
    const select = function() {
      run({
        pagination: {
          current: current.value,
          pageSize: pageSize.value
        },
        data: state.lastFilter,
      }).then(res => {
        state.dataSourceList = res.result
        console.log(state.dataSourceList)
      })
    }
    const showDrawer1 = (oldData = {}) => {
      state.visible = true
      state.isNew = !(typeof oldData.id === 'string' && oldData.id.length > 0)
      console.log('editNewUser', state.isNew, oldData)
      Object.keys(formData).forEach(key => {
        formData[key] = oldData[key] || formData[key]
      })
    }
    const onClose = () => {
      state.visible = false
      state.dataSourceList = []
      pagination.value.total = 0
      formData.id = 0,
        formData.productBasicsId = '',
        formData.factorType = '',
        formData.dataType = '',
        formData.ynCheck = 'Y',
        formData.checkNumber = '',
        formData.ynStart = 'Y',
        formData.remarks = ''
      state.selectedRowKeys = []
      state.time = new Date().getTime()
    }
    const deleteUser = (record) => {
      state.delQuery.splice(0)
      state.delQuery.push(record)
      state.visible1 = true
    }
    const onFormClean = () => {
      formRef.value.resetFields()
      state.targetKeys = []
    }
    const onSave = () => {
      request(getRequestURI('productInfoRuleLink_save1'),{data: {infoId: props.idd, ruleCollectionIds: state.selectedRowKeys}}).then(res => {
        console.log(res)
        if (res.code === 1) {
          message.success(res.message)
          onClose()
        }else{
          message['error'](res.message)
        }
      })
      // formData.productBasicsId = props.idd
      // let obj = {
      //   data: formData
      // }
      // if (state.statuss == 'bianji') {
      //   const uri = getRequestURI('productInsureRule_update')
      //   request(uri, obj).then(res => {
      //     state.statuss = ''
      //     if (res.code === 1) {
      //       message['success'](res.message)
      //       select()
      //     }else {
      //       message['error'](res.message)
      //     }
      //     console.log(res)
      //   })
      // } else {
      //   const uri = getRequestURI('productInsureRule_insert')
      //   request(uri, obj).then(res => {
      //     console.log(res)
      //     if (res.code == 1) {
      //       message['success'](res.message)
      //       select()
      //       console.log(res)
      //     } else {
      //       message['error'](res.message)
      //     }
      //   })
      // }
    }
    const fileList = ref([])
    expose({
      showDrawer1
    })
    return {
      formRef,
      formRules,
      ...toRefs(state),
      formData,
      inNew,
      run,
      loading,
      current,
      pageSize,
      total,
      dataSource,
      select,
      handleTableChange,
      onClose,
      onSave,
      pagination,
      modifyUser,
      fileList,
      listDate,
      onSelectChange,
      isDisable,
      onFormClean,
      deleteUser,
      handleSearch,
      userDearw,
      headers: {
        authorization: 'authorization-text'
      }
    }
  }

})
</script>
<style scoped>
.djsc {
  width: 128px;
  height: 128px;
}
</style>
