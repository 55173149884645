<template>
  <a-drawer
    :title="inNew"
    :width="'75%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <h2>订单信息</h2>
    <!-- :rules="formRules" -->
    <a-form
      :model="formData"
      layout="vertical"
      ref="formRef"
      autocomplete="off"
    >
      <!--      <a-input v-model="formData.id" v-show="false"/>-->
      <a-row :gutter="10">
        <a-col span="12">
          <a-form-item label="投保单号:" name="prtNo">
            <a-input v-model:value="formData.insuranceSlip.prtNo" :disabled="isDisable" clearable/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="订单号:" name="orderId">
            <a-input v-model:value="formData.insuranceSlip.orderId" :disabled="isDisable" clearable/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="订单状态:" name="prtStatusName">
            <a-input v-model:value="formData.insuranceSlip.prtStatusName" :disabled="isDisable" clearable/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="供应商名称:" name="supName">
            <a-input v-model:value="formData.insuranceSlip.supName" :disabled="isDisable" clearable/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="产品名称:" name="productInfoName">
            <a-input v-model:value="formData.insuranceSlip.productInfoName" :disabled="isDisable" clearable/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="渠道名:" name="channelName">
            <a-input v-model:value="formData.insuranceSlip.channelName" :disabled="isDisable" clearable/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="支付金额(元):" name="payMoney">
            <a-input v-model:value="formData.insuranceSlip.payMoney" :disabled="isDisable" clearable/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="支付流水号:" name="payNumber">
            <a-input v-model:value="formData.insuranceSlip.payNumber" :disabled="isDisable" clearable/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="保额(元):" name="coverage">
            <a-input v-model:value="formData.insuranceSlip.coverage" :disabled="isDisable" clearable/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="支付状态:">
            <a-input v-model:value="payStateLabel" :disabled="isDisable" clearable/>
          </a-form-item>
        </a-col>
      </a-row>
      <h2>保单信息</h2>
      <a-row :gutter="10">
        <a-col span="12">
          <a-form-item label="保单号:" name="contNo">
            <a-input v-model:value="formData.guaranteeSlip.contNo" :disabled="isDisable" clearable/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="保单状态:" name="contStatusName">
            <a-input v-model:value="formData.guaranteeSlip.contStatusName" :disabled="isDisable" clearable/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="产品名称:" name="productInfoName">
            <a-input v-model:value="formData.guaranteeSlip.productInfoName" :disabled="isDisable" clearable/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="保费(元):" name="premium">
            <a-input v-model:value="formData.guaranteeSlip.premium" :disabled="isDisable" clearable/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="缴费方式:" name="payWayName">
            <a-input v-model:value="formData.guaranteeSlip.payWayName" :disabled="isDisable" clearable/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="缴费期间:" name="payDuring">
            <a-input v-model:value="formData.guaranteeSlip.payDuringName" :disabled="isDisable" clearable/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="保险期间:" name="insuranceDuring">
            <a-input v-model:value="formData.guaranteeSlip.insuranceDuringName" :disabled="isDisable" clearable/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="申请日期:" name="applyDate">
            <a-input v-model:value="formData.guaranteeSlip.applyDate" :disabled="isDisable" clearable/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="承保日期:" name="insuredDate">
            <a-input v-model:value="formData.guaranteeSlip.insuredDate" :disabled="isDisable" clearable/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="生效日:" name="validDate">
            <a-input v-model:value="formData.guaranteeSlip.validDate" :disabled="isDisable" clearable/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="终止日:" name="invalidDate">
            <a-input v-model:value="formData.guaranteeSlip.invalidDate" :disabled="isDisable" clearable/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="核保状态:" name="underwritingStatus">
            <a-input v-model:value="underwritingStatusLabel" :disabled="isDisable" clearable/>
          </a-form-item>
        </a-col>
      </a-row>
      <h2 v-if="formData.risks.length">险种信息</h2>
      <a-row :gutter="10">
        <a-col span="12" v-for="(item,index) in formData.risks" :key="index">
          <a-form-item label="险种名称:" name="riskName">
            <a-input v-model:value="item.riskName" :disabled="isDisable" clearable/>
          </a-form-item>
        </a-col>
      </a-row>
      <div v-if="false">
        <h2 v-if="formData.signFileList.length">签名信息</h2>
        <div  style="width: 100%" v-for="(item,index) in formData.signFileList" :key="index">
          <a-row :gutter="10">
            <a-col span="12">
              <a-form-item label="文件名称:" name="fileName">
                <!--            <a-input v-model:value="item.fileName" :disabled="isDisable" clearable/>-->
                <a-input-group compact>
                  <a-input v-model:value="item.fileName" :disabled="isDisable" clearable style="width: calc(100% - 100px)"/>
                  <a-button type="primary" @click="downLoadFile(item.fileUrl,item.fileName)">下载文件</a-button>
                </a-input-group>
              </a-form-item>
            </a-col>
            <!--        <a-col span="12">-->
            <!--          <a-form-item label="文件url:" name="fileUrl">-->

            <!--            <a-input-group compact>-->
            <!--              <a-input v-model:value="item.fileUrl" :disabled="isDisable" clearable style="width: calc(100% - 100px)" />-->
            <!--              <a-button type="primary" @click="downLoadFile(item.fileUrl,item.fileName)">下载文件</a-button>-->
            <!--            </a-input-group>-->
            <!--&lt;!&ndash;            <a-input v-model:value="item.fileUrl" :disabled="isDisable" clearable/>&ndash;&gt;-->
            <!--&lt;!&ndash;            <a-button @click="downLoadFile(item.fileUrl,item.fileName)">下载文件</a-button>&ndash;&gt;-->
            <!--          </a-form-item>-->
            <!--        </a-col>-->
          </a-row>
        </div>
      </div>


      <h2>投保人信息</h2>
      <a-row :gutter="10">
        <a-col span="12">
          <a-form-item label="投保人名称:" name="appntName">
            <a-input v-model:value="formData.applicant.appntName" :disabled="isDisable" clearable/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="手机号码:" name="mobile">
            <a-input v-model:value="formData.applicant.mobile" :disabled="isDisable" clearable/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="证件类型:" name="appntIdTypeName">
            <a-input v-model:value="formData.applicant.appntIdTypeName" :disabled="isDisable" clearable/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="证件号:" name="appntIdNo">
            <a-input v-model:value="formData.applicant.appntIdNo" :disabled="isDisable" clearable/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="出生日期:" name="appntBirthday">
            <a-date-picker
            style="width: 100%;"
            mode="date"
            v-model:value="formData.applicant.appntBirthday"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            :disabled="isDisable"
            clearable
            ></a-date-picker>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="性别:" name="appntSexName">
            <a-input v-model:value="formData.applicant.appntSexName" :disabled="isDisable" clearable/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="通讯地址:" name="agreementCode">
            <a-input v-model:value="formData.applicant.province" :disabled="isDisable" clearable/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="户籍地址:" name="agreementCode">
            <a-input v-model:value="formData.applicant.homeProvince" :disabled="isDisable" clearable/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="电子邮箱:" name="email">
            <a-input v-model:value="formData.applicant.email" :disabled="isDisable" clearable/>
          </a-form-item>
        </a-col>
      </a-row>
      <h2>被保人信息</h2>
      <a-table
        :columns="columns"
        :scroll="{ x: 1500 }"
        :pagination="false"
        :data-source="formData.productProtectedmanList"
        style="margin-top: 10px"
      >
        <a-spin :delay="200"/>
        <template #bodyCell="{ column, index }">
          <template v-if="column.key === 'index'">
            {{ index + 1 }}
          </template>
        </template>
      </a-table>
    </a-form>
  </a-drawer>
</template>

<script>
import {defineComponent, reactive, toRefs} from 'vue'
import {downloadFileByUrl} from '../../../utils/download_file'
import {getDictionaryDataInApi} from '../../../utils/request_extend'

export default defineComponent({
  setup(props, context) {
    const state = reactive({
      payStateDicList: [],
      underwritingStatusDicList: [],
      underwritingStatusLabel: '',
      payStateLabel: '',
      visible: false,
      inNew: '保单详情',
      isDisable: true,
      columns: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left'
        },
        {
          title: '与投保人关系',
          width: 140,
          align: 'center',
          key: 'relationToAppntName',
          dataIndex: 'relationToAppntName'
        },
        {
          title: '被保人名称',
          width: 140,
          align: 'center',
          key: 'name',
          dataIndex: 'name'
        },
        {
          title: '手机号码',
          width: 140,
          align: 'center',
          key: 'mobile',
          dataIndex: 'mobile'
        },
        {
          title: '证件类型',
          width: 140,
          align: 'center',
          key: 'idTypeName',
          dataIndex: 'idTypeName'
        },
        {
          title: '证件号',
          width: 140,
          align: 'center',
          key: 'idNo',
          dataIndex: 'idNo'
        },
        {
          title: '出生日期',
          width: 140,
          align: 'center',
          key: 'birthday',
          dataIndex: 'birthday',
          customRender: (text)=>{
            let obj = text.value?.split(' ')
            return obj ? obj[0] : text.value
          }
        },
        {
          title: '性别',
          width: 140,
          align: 'center',
          key: 'sexName',
          dataIndex: 'sexName'
        },
        {
          title: '通讯地址',
          width: 140,
          align: 'center',
          key: 'province',
          dataIndex: 'province'
        },
        {
          title: '户籍地址',
          width: 140,
          align: 'center',
          key: 'homeProvince',
          dataIndex: 'homeProvince'
        },
        {
          title: '电子邮箱',
          width: 140,
          align: 'center',
          key: 'email',
          dataIndex: 'email'
        },
        {
          title: '保费',
          width: 140,
          align: 'center',
          key: 'premium',
          dataIndex: 'premium'
        }
      ]
    })
    const formData = reactive({
      // 投保人信息
      applicant: {},
      // 保单信息
      guaranteeSlip: {},
      // 险种信息
      risks: [],
      // 签名信息
      signFileList: [],
      // 订单信息
      insuranceSlip: {},
      // 被保人信息
      productProtectedmanList: []
    })
    const onClose = () => {
      state.visible = false
      Object.assign(formData, {
        // 投保人信息
        applicant: {},
        // 保单信息
        guaranteeSlip: {},
        // 险种信息
        risks: [],
        // 签名信息
        signFileList: [],
        // 订单信息
        insuranceSlip: {},
        // 被保人信息
        productProtectedmanList: []
      })
    }
    const findStatusLabel = (list, valueToFind) => {
      const label = list.find(item => item.value === valueToFind)?.label
      return label
    }
    const showDrawer = (oldData = {}, payStateDicList = [], underwritingStatusDicList = []) => {
      if (payStateDicList.length > 0) {
        state.payStateDicList = payStateDicList
      } else {
        getDictionaryDataInApi(state.payStateDicList, 'pay_status')
      }
      if (underwritingStatusDicList.length > 0) {
        state.underwritingStatusDicList = underwritingStatusDicList
      } else {
        getDictionaryDataInApi(state.underwritingStatusDicList, 'underwriting_status')
      }
      console.log(oldData)
      state.visible = true
      Object.keys(formData).forEach((key) => {
        formData[key] = oldData[key] || formData[key]
      })
      formData.applicant.homeProvince =
        (oldData.applicant.homeProvinceName ? oldData.applicant.homeProvinceName : '') +
        (formData.applicant.homeCityName ? formData.applicant.homeCityName : '') +
        (formData.applicant.homeProvinceName ? formData.applicant.homeProvinceName : '') +
        (formData.applicant.homeAddress ? formData.applicant.homeAddress : '')

// formData.applicant.province = formData.applicant.province + formData.applicant.city + formData.applicant.postalAddress
      formData.applicant.province = (oldData.applicant.provinceName? oldData.applicant.provinceName :'' )+
        (oldData.applicant.cityName? oldData.applicant.cityName: '')+
        (oldData.applicant.countyName? oldData.applicant.countyName: '')+
        (oldData.applicant.postalAddress? oldData.applicant.postalAddress: '')
      if (formData.productProtectedmanList.length !== 0) {
        formData.productProtectedmanList.forEach(item => {
          item.homeProvince =
            (item.homeProvinceName? item.homeProvinceName: '' )+
            (item.homeCityName ? item.homeCityName : '') +
            (item.homeCountyName ? item.homeCountyName : '') +
            (item.homeAddress ? item.homeAddress : '')
          item.province =
            (item.provinceName ? item.provinceName : '') +
            (item.cityName ? item.cityName : '') +
            (item.countyName ? item.countyName : '') +
            (item.postalAddress ? item.postalAddress : '')
        })
      }
      if (formData.guaranteeSlip.underwritingStatus) {
        state.underwritingStatusLabel = findStatusLabel(state.underwritingStatusDicList.value, formData.guaranteeSlip.underwritingStatus)
      }
      if (formData.guaranteeSlip?.insuredDate) {
        let obj = formData.guaranteeSlip?.insuredDate?.split(' ')
        formData.guaranteeSlip.insuredDate = obj ? obj[0] : formData.guaranteeSlip.insuredDate
      }
      if (formData.insuranceSlip.payStatus) {
        state.payStateLabel = findStatusLabel(state.payStateDicList.value, formData.insuranceSlip.payStatus)
      }
      console.log(formData)
    }
    const downLoadFile = (fileUrl, fileName) => {
      downloadFileByUrl(fileUrl, fileName)
    }
    context.expose({
      showDrawer
    })
    return {
      ...toRefs(state),
      onClose,
      showDrawer,
      downLoadFile,
      formData,
      findStatusLabel
    }
  }

})
</script>

<style scoped>

</style>
