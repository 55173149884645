<style scoped>
.iframeDiv{
  min-height: 800px;
}
</style>
<template>
  <a-drawer
    title="查看建议书详情"
    :width="'40%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <div class="iframeDiv">
      <iframe :src="iframeUrl" width="375px" height="800px"></iframe>
    </div>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>
<script>
import {defineComponent, nextTick, reactive, toRefs} from 'vue'
import {CloseOutlined} from '@ant-design/icons-vue'
export default defineComponent({
  components: {
    CloseOutlined
  },
  setup() {
    const state = reactive({
      visible: false,
      iframeUrl: null
    })
    const showDrawer = (oldData) =>{
      console.log(oldData)
      state.visible = true
      nextTick(()=>{
        state.iframeUrl = `https://api.test.ysmart.tech/planbook/proposalDetail?prospectusCode=${oldData.prospectusCode}&prospectusCodeTemporary=${oldData.prospectusCodeTemporary}&process=pc`

      })

    }

    const onClose = () => {
      state.visible = false
    }

    return{
      ...toRefs(state),
      showDrawer,
      onClose
    }
  }

})
</script>
