<template>
  <!--  公式-条件预览-->
  <div>
    <h3>条件预览(关系均为且): </h3>
    <a-divider style="height: 2px; background-color: #7cb305;margin: 0 0 24px"/>
    <div class="condition-content">
      <h3>已经配置的条件: </h3>
      <div v-if="conditionContentList.length > 0">
        <p v-for="(item, index) in conditionContentList" :key="item.calculateType">
          <span style>{{index+1}}.{{item.label}}</span>
          <span @click="delConditionContent(index)">删除</span>
        </p>
      </div>
      <div v-else class="flex_class">
        <a-empty />
      </div>
    </div>
    <div>
      <h3>选择要素: </h3>
      <div  class="element_radio">
        <a-radio-group v-model:value="conditionType">
          <template v-for="(item,idx) in elementSelectList" :key="'element_radio_' + idx">
            <a-radio :value="item.value" >{{item.label}}</a-radio>
          </template>
        </a-radio-group>
      </div>
<!--      投保要素的-->
      <div class="p1" v-if="conditionType === '1'">
        <a-form
          :model="insuranceElements"
          layout="horizontal"
          autocomplete="off"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 12 }"
        >
          <a-row :gutter="10">
            <a-col span="12">
              <a-form-item label="投保要素类型" name="elementType"
              >
                <a-select v-model:value="insuranceElements.factorId" placeholder="请选择投保要素类型"
                          :options="insuranceElements.elementTypeList"
                          :field-names="{ label: 'elementName', value: 'factorId' }"
                          @change="handleElementTypeChange"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="最小值" name="calculateMinValue">
                <a-input @change="handelMinMaxChange" v-model:value="insuranceElements.calculateMinValue" placeholder="请输入最小值"></a-input>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="最大值" name="calculateMaxValue">
                <a-input @change="handelMinMaxChange" v-model:value="insuranceElements.calculateMaxValue" placeholder="请输入最大值"></a-input>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="选择要素值" name="selectionMethod"
              >
                <a-select v-model:value="insuranceElements.selectionMethod"  placeholder="请选择选择方式"
                          :options="insuranceElements.selectionMethodList" :field-names="{ label: 'calculatedValue', value: 'calculated' }"
                          :disabled= "insuranceElements.calculateMaxValue !=='' || insuranceElements.calculateMinValue!=='' "
                          @change="handleElementValueChange"

                >
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>

      </div>
<!-- 可选责任-->
      <div class="option-checkbox" v-if="conditionType === '2'">
        <div>可选责任: </div>
        <div class="p1">
          <a-checkbox-group style="margin-bottom: 12px" v-model:value="optionalDutys" :options="optionalDutyList" />
        </div>
      </div>
<!--      利益演示参与计算的值-->
      <div v-if="conditionType === '3'">
        <a-form
          :model="benefitData"
          layout="horizontal"
          autocomplete="off"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 12 }"
        >
          <a-row :gutter="10">
            <a-col span="12">
              <a-form-item label="利益演示参与计算的其他值" name="benefitType"
              >
                <a-select v-model:value="insuranceElements.benefitType"  placeholder="请选择利益演示参与计算的其他值"
                          :options="insuranceElements.benefitTypeList">
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>

      </div>

      <div class="footer-save">
        <span @click="handelOkClick">确定</span>
      </div>

    </div>
  </div>
</template>
<script>
import {nextTick, onMounted, reactive, ref, toRefs} from 'vue'
import {getDictionaryData} from '@/utils/request_extend'
import {getRequestURI, request} from '@/utils/request'
import {message, Modal} from 'ant-design-vue'

export default {
  name: 'FormulaConfiguration',
  props: {
    formulaDetailData: {
      type: Object,
      default: () => {}
    },
    conditionTypeList: {
      type: Array,
      default: () => []
    },
    sonConditionList: {
      type: Array,
      default: () => []
    },
    operationType: {
      type: String,
      default: () => ''
    },
  },
  emits: ['update:sonConditionList'],
  setup(props, context) {
    const state = reactive({
      conditionContentList: props.sonConditionList,
      conditionType: '1',
      tempSelectElement: {},
      insuranceElements: {
        factorId: '',
        elementType: undefined,
        elementTypeList: [],
        // 输入框 最小值
        calculateMinValue: '',
        // 输入框 最大值
        calculateMaxValue: '',
        // 用来接收下拉框选择的值
        calculateFixValue: '',
        selectionMethod: undefined,
        selectionMethodList: [],
      },
      optionalDutys: [],
      optionalDutyList: [],
      benefitData: {
        benefitType: undefined,
        benefitTypeList: []
      },
    })


    const elementSelectList = ref()

    // 投保要素类型改变
    const handleElementTypeChange = (value, data) =>{
      console.log(value)
      console.log(data)
      state.insuranceElements.elementType = data.factorType
      state.insuranceElements.selectionMethod = undefined
      state.insuranceElements.selectionMethodList = data.calculateValueList
      state.tempSelectElement = data
    }
    // 最大值 或者 最小值发生改变
    const handelMinMaxChange = () =>{
      if(state.insuranceElements.selectionMethod !== undefined)
      Modal.confirm({
        title: '温馨提示',
        content: '选择方式存在值, 是否清空?',
        onOk: () => {
          state.insuranceElements.selectionMethod = undefined
        },
        onCancel: () => {
          state.insuranceElements.calculateMinValue = ''
          state.insuranceElements.calculateMaxValue = ''
          Modal.destroyAll()   // 清除弹出框
        }
      })
    }

    // 确定添加条件
    const handelOkClick = () =>{
      let obj = {
        id: '',
        haveParent: '',
        conditionType: '',
        calculateType: '',
        calculateId: '',
        showValue: '',
        elementName: '',
        calculateMaxValue: '',
        calculateMinValue: '',
        calculateFixValue: '',
        dutyCode: [],
        label: '',
        sonConditions: []
      }
      if(state.conditionType === '1'){
        if(state.conditionContentList.length > 0){
          const arr = state.conditionContentList.filter(item => item.calculateId === state.tempSelectElement.factorId)
          console.log('state.conditionContentList', state.conditionContentList)
          // const arr = state.conditionContentList.filter(item => item.calculateType === state.insuranceElements.elementType)
          if(arr.length > 0) return message.warning('已选择了相同要素, 请先删除已选要素!')
        }
        if(state.insuranceElements.calculateMaxValue === '' && state.insuranceElements.calculateMinValue === '' && state.insuranceElements.selectionMethod === undefined) return message.warning('请先完善最大最小值或选择方式!')
        // let str = state.insuranceElements.elementTypeList.filter(item => item.factorType === state.insuranceElements.elementType)[0].elementName
        let str = state.insuranceElements.elementTypeList.filter(item => item.factorId === state.tempSelectElement.factorId)[0].elementName
        // let calculateId  = state.insuranceElements.elementTypeList.filter(item => item.factorType === state.insuranceElements.elementType)[0].factorId
        let calculateId = state.insuranceElements.elementTypeList.filter(item => item.factorId === state.tempSelectElement.factorId)[0].factorId
        if(state.insuranceElements.calculateMaxValue !== '' && state.insuranceElements.calculateMinValue !== ''){
          str += ` [${state.insuranceElements.calculateMinValue}, ${state.insuranceElements.calculateMaxValue})`
        }else if ( state.insuranceElements.selectionMethod !== undefined) {
          str += ' [' + state.insuranceElements.selectionMethodList.filter(item => item.calculated === state.insuranceElements.selectionMethod)[0].calculatedValue + ')'
        }
        obj.conditionType =  state.conditionType
        obj.calculateType = getValueType(state.insuranceElements.elementType)
        obj.calculateId = calculateId
        if (state.insuranceElements.selectionMethodList && state.insuranceElements.selectionMethodList.length > 0) {
          // obj.showValue = state.insuranceElements.selectionMethodList.find(item => item.calculated === state.insuranceElements.selectionMethod)?.calculatedValue
          // 列表选择后，对应的值去附给最大值和最小值
          let item_temp = state.insuranceElements.selectionMethodList.find(item => item.calculated === state.insuranceElements.selectionMethod)
          if (item_temp) {
            obj.showValue = item_temp.calculatedValue || item_temp.calculated || ''
            str = state.tempSelectElement.elementName + '[' + obj.showValue + ']'
            state.insuranceElements.calculateFixValue = obj.showValue
            obj.calculateFixValue = obj.showValue
            // state.insuranceElements.calculateMaxValue =  state.insuranceElements.calculateMinValue = obj.showValue
          }
        } else {
          obj.showValue = str
        }
        obj.label = str
        obj.calculateMaxValue = state.insuranceElements.calculateMaxValue
        obj.calculateMinValue = state.insuranceElements.calculateMinValue
        state.conditionContentList.push(obj)

        state.insuranceElements.elementType = undefined
        state.insuranceElements.calculateMaxValue = ''
        state.insuranceElements.calculateMinValue = ''
        state.insuranceElements.selectionMethod = undefined

      }else if(state.conditionType === '2'){
        if(!state.optionalDutys.length) return message.warning('请选择责任!')
        if(state.conditionContentList.length > 0){
          const dutyArr = state.conditionContentList.filter(item => item.conditionType === '2')
          if(dutyArr.length > 0) {
            state.optionalDutys = []
            return message.warning('已选择了责任, 请先删除已选条件!')
          }
        }
        let arr= new Array(state.optionalDutys.length)
        state.optionalDutys.forEach((item, index) => {
          arr[index] = state.optionalDutyList.filter(duty => item === duty.value)[0].label
        })
        obj.conditionType =  state.conditionType,
        obj.dutyCode = state.optionalDutys,
        obj.label =  '选择了' + arr.join(',')
        state.conditionContentList.push(obj)
        state.optionalDutys = []
      }else{
        // 这个是其他  之后看看加啥
        console.log(333333, state)
      }

      context.emit('update:sonConditionList', state.conditionContentList)
    }

    // 删除已选条件
    const delConditionContent = (index) =>{
      state.conditionContentList.splice(index, 1)
    }




    // 获取投保要素类型 可选责任
    const getSelectFactorsAndDuties = () => {
      request(getRequestURI('selectFactorsAndDuties'), {data: {code: props.formulaDetailData.id}}).then(res =>{
        if(res.code === 1){
          state.optionalDutyList = res.result.duties.map(item => {return {value: item.dutyCode, label: item.dutyName}})
          state.insuranceElements.elementTypeList = res.result.factors
        }
      })
    }

    // const getNew = (list) => {
    //   for (let i = 0; i < list.length; i++) {
    //     list[i].factorType = list[i].factorType+'^'+list[i].factorId
    //   }
    // }

    const getValueType = (str) => {
      if (str.indexOf('^') > -1) {
        return str.split('^')[0]
      }
      return str
    }

    const handleElementValueChange = (value, data) => {
      console.log(value)
      console.log(data)

    }
    //页面挂载调用数据
    onMounted(() => {
      getDictionaryData(elementSelectList, 'condition_type')
      // getDictionaryData(elementSelectList, 'factorType')
      nextTick(() => {
        getSelectFactorsAndDuties()
      })
      console.log(233333333, props.sonConditionList)
    })
    return {
      ...toRefs(state),
      elementSelectList,
      handelMinMaxChange,
      getSelectFactorsAndDuties,
      handleElementTypeChange,
      handelOkClick,
      delConditionContent,
      handleElementValueChange
    }
  }
}

</script>

<style scoped>
.condition-content{
  padding: 0 12px;
}
.condition-content p{
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
}
.condition-content p span:first-child{
  display: inline-block;
  max-width: 80%;
  flex-wrap: wrap;
}
.condition-content p span:last-child{
  display: inline-block;
  width: 64px;
  height: 28px;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #ff4d4f;
  color: #ff4d4f;
  cursor: pointer;
}
.element_radio{
  padding: 0 18px;
  margin-bottom: 22px;
}
.p1{
  padding-left: 16px;
  padding-top: 16px;
}
.flex_class {
  margin: 30px auto 0;
  color: #cecece;
}
.footer-save{
  margin-top: 20px;
  text-align: right;
}
.footer-save span{
  display: inline-block;
  width: 72px;
  height: 32px;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  border-radius: 10px;
  background-color: #40a9ff;
  color: #fff;
  cursor: pointer;
}
.footer-save span:last-child{
  margin-left: 12px;
}
.option-checkbox :deep(.ant-checkbox-group-item) {
    margin-bottom: 8px;
}
</style>
