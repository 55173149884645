<template>
  <div>
    <a-table style="margin-top: 10px" :columns="columns" :data-source="formulas" :pagination="false" bordered>
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'operation'">
          <div>
            <a-button type="link" @click="checkRecord(record)">查看</a-button>
            <a-button type="link" :disabled="!isEditFlag" @click="editRecord(record)">编辑</a-button>
            <a-button type="link" :disabled="!isEditFlag" @click="deleteRecord(record)">删除</a-button>
          </div>
        </template>
        <template v-else-if="column.key === 'formula'">
          <div v-if="record.rowSpan > 0" :rowspan="record.rowSpan">
            {{ record.formula }}
          </div>
        </template>
        <template v-else>
          <div>{{ record[column.key] }}</div>
        </template>
      </template>
    </a-table>
  </div>
</template>
<script>
import {defineComponent, reactive, toRefs, watch, watchEffect} from 'vue'
import {message, Modal} from 'ant-design-vue'
import {getRequestURI, request} from '../../../../utils/request.js'

export default defineComponent({
  props: {
    formulaList: {
      type: Object,
      default: () => {
      }
    },
    isEdit: {
      type: Boolean,
      default: () => true
    },
    getSelectFormulasForGuarantee: {
      type: Function,
      default: () => {}
    }
  },
  setup(props, {emit}) {
    const state = reactive({
      formulas: [],
      isEditFlag: props.isEdit,
      columns: [
        {
          title: '公式',
          width: 250,
          align: 'center',
          key: 'formulas_str',
          dataIndex: 'formulas_str',
          customCell: (_, index) => {
            const rowSpan = state.formulas[index].rowSpan
            return {
              rowSpan: rowSpan,
            }
          }
        },
        {
          title: '场景',
          width: 400,
          key: 'conditionStr',
          dataIndex: 'conditionStr',
          align: 'center',
        },
        {
          title: '操作',
          key: 'operation',
          ellipsis: true,
          align: 'center',
          fixed: 'right',
          width: 180,
          scopedSlots: {customRender: 'operation'},
          customCell: (_, index) => {
            const rowSpan = state.formulas[index].rowSpan
            return {
              rowSpan: rowSpan,
            }
          }
        },
      ]
    })
    const checkRecord = () => {
      let formulaList = JSON.parse(JSON.stringify(props.formulaList))
      emit('opFormula', formulaList, 'check')
    }
    const editRecord = () => {
      let formulaList = JSON.parse(JSON.stringify(props.formulaList))
      emit('opFormula', formulaList, 'edit')
    }

    const deleteRecord = (record) => {
      console.log(record)
      // TODO: 删除确认框
      Modal.confirm({
        title: '删除',
        content: '确认删除这个公式及关联条件吗?',
        onOk: () => {
          request(getRequestURI('deleteFormula'), {data: {code: record.formulaId}}).then(res => {
            if (res.code === 1) {
              message.success('删除成功')
              emit('getSelectFormulasForGuarantee')
            } else {
              message['error'](res.message)
            }
          })
        },
        onCancel: () => {

        }
      })
    }
    const processFormulaList = (item) => {
      console.log('公式对象', item)
      let result = []
      if (item.relationORConditions && item.relationORConditions.length > 0) {
        item.relationORConditions.forEach((condition, index) => {
          result.push({
            formulaId: item.id,
            formulas_str: item.formulas_str,
            conditionStr: condition.conditionStr,
            rowSpan: index === 0 ? item.relationORConditions.length : 0,
          })
        })
      } else {
        result.push({
          formulaId: item.id,
          formulas_str: item.formulas_str,
          conditionStr: '无',
          rowSpan: 1,
        })
      }

      return result
    }
    watch(
        () => props.formulaList, (newVal) => {
          console.log('formulaList', newVal)
          state.formulas = processFormulaList(newVal)
        },
        {immediate: true}
    )
    watchEffect(() => {
      state.isEditFlag = props.isEdit
    })
    return {
      ...toRefs(state),
      checkRecord,
      editRecord,
      deleteRecord,
    }
  }
})
</script>


<style scoped>
.operation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.operation-buttons {
  display: flex;
  flex-direction: column;
}

.operation-buttons .ant-btn {
  margin-bottom: 8px;
}

.operation-buttons .ant-btn:last-child {
  margin-bottom: 0;
}
</style>
