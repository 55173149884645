<style scoped>
.bg {
  margin-bottom: 20px;
}
</style>

<template>
  <a-drawer
    title="环境产品同步"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <search-form
      :Condition="condition"
      :ColSpan="12"
      @callback="handleSearch"
      v-if="look"
      ></search-form>
      <!-- @nameLinked="changes" -->

    <a-table
      class="bg"
      :columns="columns"
      :key="time"
      :data-source="dataSourceList"
      :row-selection="{
			selectedRowKeys: selectedRowKeys,
			onChange: onSelectChange,
		}"
      :row-key="(record) => record.id"
      :pagination="pagination"
      @change="handleTableChange"
      :loading="loading"
    >
                <template #bodyCell="{ column, index,  }">
                  <template v-if="column.key === 'index'">
                    {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
                  </template>
                </template>
    </a-table>

    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button type="primary" @click="synchronous">
          <template #icon>
            <SaveOutlined/>
          </template>
          同步
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>

<script>
import {CloseOutlined, SaveOutlined,} from '@ant-design/icons-vue'
import {defineComponent, reactive, toRefs, ref, computed, onMounted} from 'vue'
import {getRequestURI, request, requestUsePagination} from '../../../utils/request'
// import {getRequestURI, requestUsePagination} from '../../../utils/request'
import SearchForm from '../../../components/extended/search_form.vue'
import {message} from 'ant-design-vue'

export default defineComponent({
  components: {
    CloseOutlined,
    SaveOutlined,
    SearchForm,
  },

  setup(props, {expose}) {
    const formRef = ref()
    const isCheckInfo = ref(false)
    const formData = reactive({})

    const state = reactive({
      time:null,
      look:false,
      searchNow: {},
      visible: false,
      columns: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left',
        },
        {
          title: '产品编号',
          width: 100,
          align: 'center',
          key: 'productCode',
          dataIndex: 'productCode',
        },
        {
          title: '供应商产品编码',
          width: 100,
          align: 'center',
          key: 'supProductCode',
          dataIndex: 'supProductCode',
        },
        {
          title: '产品名称',
          width: 200,
          align: 'center',
          key: 'productName',
          dataIndex: 'productName',
        },
        {
          title: '供应商名称',
          width: 100,
          align: 'center',
          key: 'supplierName',
          dataIndex: 'supplierName',
        },
      ],
      condition: {
        systemCode: {
          label: '数据来源:',
          type: 'select1',
          default: '',
          data: []
        },
        supplierName: {
          label: '供应商名称',
        },
        productName: {
          label: '产品名称:',
        },
      },
      selectedRowKeys: [],
      dataSourceList:[]
    })
    const serveNameShow = ref(false)

    //产品名称下拉
    const changes = function(value) {
      request(getRequestURI('select'),{data: { supplierId: value}}).then(result =>{
        const arr = []
        const res = result.result
        const label = 'productName'
        const sysName = res.reduce((all, next) => all.some((atom) => atom[label] == next[label]) ? all : [...all, next], [])
        for (let i = 0; i < sysName.length; i++) {
          arr.push({label: sysName[i].productName, value: sysName[i].id})
        }
        // 这个逻辑没啥用啊~算了先消除报错
        // state.condition.productId.data = arr
      })
    }
    //数据来源 下拉
    const getDataSource = () => {
      request(getRequestURI('env_sync_query'), {
        // systemCode: '',
        // systemName: ''
      }, true, 'get').then(
        (result) => {
          const arr = []
          const res = result.result
          const label = 'systemName'
          const sysName = res.reduce((all, next) => all.some((atom) => atom[label] == next[label]) ? all : [...all, next], [])
          for (let i = 0; i < sysName.length; i++) {
            arr.push({label: sysName[i].systemName, value: sysName[i].systemCode})
          }
          state.condition.systemCode.data = arr
          console.log(state.condition.systemCode.data)
        }
      )
    }
    onMounted(() => {
      // getProductName()
      // getSupName()
      getDataSource()
    })
    const onSelectChange = (selectedRowKeys) => {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
    }

    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('syncSelectListForPage'))
//查询
    const handleSearch = function (filters) {
      if(filters.systemCode === undefined){
        return message.error('请选择数据来源')
      }
      console.log('查询参数==', filters)
      state.searchNow = filters
      run({
        pagination: {
          current: 1,
          pageSize: 10
        },
        data: filters,
      }).then(res=>{
        state.dataSourceList=res.result
      })

    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.log('===', filters)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },

        sorter,
        data: state.searchNow,
      }).then(res=>{
        state.dataSourceList=res.result
      })
    }
    //抽屉
    const showDrawer = (oldData) => {
      state.look= true
      state.visible = true
      Object.keys(formData).forEach((key) => {
        formData[key] = oldData[key] || formData[key]
      })
    }
    //关闭 清空
    const onClose = () => {
      state.look = false,
      state.visible = false
      state.dataSourceList = []
      pagination.value.total=0
      state.time=new Date().getTime()
      state.selectedRowKeys=[]
    }
    //重置
    const onFormClean = () => {
      formRef.value.resetFields()
      state.targetKeys = []
      state.visible = false
    }

    //环境 同步
    const synchronous = () => {
      let keys = state.selectedRowKeys
      if (keys.length === 0) {
        message.warning('请先选择数据')
        return
      }
      loading.value = true
      request(getRequestURI('syncProductInfo'),
        {
          data: {
            ids: keys,
            systemCode: state.searchNow.systemCode
          },
        }, true, 'post').then(
        (result) => {
          if(result.code===1){

            message.success('产品同步成功')
            state.result = result.result
          }
          if(result.code===0){
            message.error(result.message)

          }
          loading.value = false
        }
      ).catch(e => {
        console.log(e)
      })

    }
    const init = function () {
      request(getRequestURI('select'),{data: {}}).then(result =>{
        const arr = []
        const res = result.result
        const label = 'productName'
        const sysName = res.reduce((all, next) => all.some((atom) => atom[label] == next[label]) ? all : [...all, next], [])
        for (let i = 0; i < sysName.length; i++) {
          arr.push({label: sysName[i].productName, value: sysName[i].id})
        }
        // 这个逻辑没啥用啊~算了先消除报错
        // state.condition.productId.data = arr
      })
    }
    init()
    expose({
      showDrawer,
    })
    return {
      formRef,
      formData,
      ...toRefs(state),
      onClose,
      synchronous,
      onFormClean,
      activeKey: ref('1'),
      loading,
      dataSource,
      total,
      handleSearch,
      onSelectChange,
      handleTableChange,
      pagination,
      serveNameShow,
      checked: ref(false),
      isCheckInfo,
      getDataSource,
      changes
    }
  },
})
</script>
