<style scoped>
</style>

<template>
  <a-drawer
    :title="isShoutitle"
    :width="'70%'"
    :visible="visible3"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form :model="data"  layout="vertical" ref="formRef" autocomplete="off">
      <!--      <div style="border-left: 1px solid  #afb1be;height: 231px;position: absolute;left: 50%;top: 94px"></div>-->
      <a-row :gutter="10">
        <a-col span="12">
          <a-form-item name="elementType1"
                       :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <template #label>
              <span>投保要素类别1：</span>
              <span>
                <a-popover placement="right">
                  <template #content>
                  <p>
                    1. 将两两投保要素值互相关联在一起，当选择其中的一个投保要素值时，另外一个投保要素值会随之而变化
                    <br>2. 通过两两关联，最终可以达到多个投保要素值联动的效果
                  </p>
                  </template>
                  <template #title>
                  <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
                </span>
            </template>
            <a-select @change="next" v-model:value="data.elementType1" placeholder="请选择">
              <a-select-option :value="cd.elementType" v-for="cd in formData.elementType1" :key="cd.elementType">{{ cd.elementTypeName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="投保要素类别2：" name="elementType2"
                       :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-select @change="next2" v-model:value="data.elementType2" placeholder="请选择">
              <a-select-option :value="cd.elementType" v-for="cd in formData.elementType2" :key="cd.elementType">{{ cd.elementTypeName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="投保要素计算值1：" name="calculated1"
                       :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-select v-model:value="data.calculated1" placeholder="请选择" @change="formData123">
              <a-select-option :value="cd.calculated" v-for="cd in formData.calculated1" :key="cd.calculated">
                {{ cd.calculated }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="投保要素计算值2：" name="calculated2"
                       :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-select v-model:value="data.calculated2" placeholder="请选择" @change="jisuzhi2Change">
              <a-select-option :value="cd.calculated" v-for="cd in formData.calculated2" :key="cd.calculated">
                {{ cd.calculated }}
              </a-select-option>

            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="投保要素显示值1：" name="calculated1"
                       :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-select v-model:value="data.calculatedValue1" placeholder="请选择" @change="formData1234">
              <a-select-option :value="cd.calculatedValue" v-for="cd in formData.calculatedValue1"
                               :key="cd.calculatedValue">{{ cd.calculatedValue }}
              </a-select-option>

            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="投保要素显示值2：" name="calculated2"
                       :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-select v-model:value="data.calculatedValue2" placeholder="请选择" @change="xianshizhi2Change">
              <a-select-option :value="cd.calculatedValue" v-for="cd in formData.calculatedValue2"
                               :key="cd.calculatedValue">{{ cd.calculatedValue }}
              </a-select-option>

            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="排序序号：" name="sort" :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-input
              v-model:value="data.sort"
              placeholder="请输入"/>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button type="primary" @click="onSave">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>
<script>
import {CloseOutlined, SaveOutlined} from '@ant-design/icons-vue'
import {defineComponent, reactive, toRefs, ref} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
import {message} from 'ant-design-vue'

export default defineComponent({
  components: {
    CloseOutlined, SaveOutlined
  },
  props: {
    handleSearch: {type: Function},
    riskId: {type: [String,Number]}
  },
  setup(props, {expose}) {

    const formRef = ref()
    const formData = reactive({
      elementType1: [],
      elementType2: [],
      calculated1: [],
      calculated2: [],
      calculatedValue1: [],
      calculatedValue2: [],
    })
    const data = reactive({
      calculated1: undefined,
      calculated2: undefined,
      calculatedValue1: undefined,
      calculatedValue2: undefined,
      elementType1: undefined,
      elementType2: undefined,
      calculatedId1:undefined,
      calculatedId2:undefined,
      id: 0,
      riskId: 0,
      sort: undefined
    })
    const state = reactive({
      riskId: '',
      elementType: [],
      type: '',
      edit: true,
      visible3: false,
      isShoutitle: '',
      query: {
        data: {
          calculated1: '',
          calculated2: '',
          calculatedValue1: '',
          calculatedValue2: '',
          elementType1: '',
          elementType2: '',
          id: 0,
          riskId: 0,
          sort: 0
        },
        pagination: {
          current: 1,
          pageSize: 10,
          total: 0
        },
        sorters: [
          {
            field: '',
            order: ''
          }
        ]
      }

    })
    // 页面列表初始化
    const handleSearch = function () {
      request(getRequestURI('tbys_caclist'), state.query).then(res => {
        state.list = res.result
        console.debug(res)
      })
    }
    // 投保要素值下拉框
    const elementType = (oldData) => {
      console.debug(oldData)
      try {
        request(getRequestURI('tbys_category'), {data: {id: oldData}}).then(res =>
        {
          formData.elementType1 = res.result
          formData.elementType2 = res.result
        })
      } catch (error) {
        console.debug(error)
      }
    }
    const next = () => {
      data.calculated1 = ''
      data.calculatedValue1 = ''
      data.calculatedId1 = ''
      nextlist()
    }
    const next2 = () => {
      data.calculated2 = ''
      data.calculatedValue2 = ''
      data.calculatedId2= ''
      nextlist2()
    }
    // chang事件2
    const nextlist2 = () => {
      try {
        request(getRequestURI('tbys_show'), {
          data: {
            elementType: data.elementType2,
            riskId: state.query.data.riskId
          },
          pagination: {
            current: 1,
            pageSize: 10,
            total: 0
          },
          sorters: [
            {
              field: '',
              order: ''
            }
          ]
        }).then(res => {
          //  formData.calculated1 = res.result

          formData.calculated2 = res.result
          //  formData.calculatedValue1 = res.result
          formData.calculatedValue2 = res.result
          console.debug(res)
        })
      } catch (error) {
        console.debug(error)
      }
    }
    // 下拉框change事件
    const nextlist = () => {
      try {
        request(getRequestURI('tbys_show'), {
          data: {
            elementType: data.elementType1,
            riskId: state.query.data.riskId
          },
          pagination: {
            current: 1,
            pageSize: 10,
            total: 0
          }
        }).then(res => {
          formData.calculated1 = res.result
          formData.calculatedValue1 = res.result
        })
      } catch (error) {
        console.debug(error)
      }
    }
    const formData123 = function (value) {
      console.debug(formData.calculatedValue1, value)
      formData.calculatedValue1.forEach(item => {
        if (value === item.calculated) {
          data.calculatedValue1 = item.calculatedValue
          data.calculatedId1 = item.calculatedId
        }
      })
    }
    const formData1234 = function (value) {
      formData.calculatedValue1.forEach(item => {
        if (value === item.calculatedValue) {
          data.calculated1 = item.calculated
          data.calculatedId1 = item.calculatedId
        }
      })
    }
    const jisuzhi2Change = function (value) {
      formData.calculatedValue2.forEach(item => {
        if (value === item.calculated) {
          data.calculatedValue2 = item.calculatedValue
          data.calculatedId2 = item.calculatedId
        }
      })
    }
    const xianshizhi2Change = function (value) {
      formData.calculatedValue2.forEach(item => {
        if (value === item.calculatedValue) {
          data.calculated2 = item.calculated
          data.calculatedId2 = item.calculatedId
        }
      })
    }
    const showDrawer = (oldData = {}, num) => {
      console.debug(oldData, 45456)
      handleSearch()
      state.visible3 = true
      if (num === 1) {
        state.isShoutitle = '投保要素值关联增加'
        state.edit = true
        state.query.data.riskId = oldData
        data.riskId = oldData
        // state.riskId=oldData.riskId
        elementType(data.riskId)
        console.debug(data.riskId)
      } else if (num === 2) {
        state.isShoutitle = '投保要素值关联修改'
        state.query.data.riskId = oldData.riskId
        data.riskId = oldData.riskId
        elementType(data.riskId)
        state.edit = false
        Object.keys(data).forEach(key => {
          data[key] = oldData[key] || data[key]
        })
        console.debug(data)
        nextlist()
        nextlist2()
      }
    }
    // 关闭
    const onClose = () => {
      state.visible3 = false
      data.calculated1 = undefined,
        data.calculated2 = undefined,
        data.calculatedValue1 = undefined,
        data.calculatedValue2 = undefined,
        data.elementType1 = undefined,
        data.elementType2 = undefined,
        data.calculatedId1=undefined,
        data.calculatedId2=undefined,
        data.id = 0,
        data.riskId = 0,
        data.sort = undefined,
        formRef.value.clearValidate()
      data.riskId = ''
    }
    // 保存
    const onSave = async () => {
       await formRef.value.validate()
      if(data.elementType1 === data.elementType2){
        return message.warning('投保要素类别不能一致')
      }
      try {
       // const values = await formRef.value.validate()
        if (state.edit) {
          request(getRequestURI('tbys_gladd'), {data}, true, 'post').then(
            (res) => {
              if (res.code === 1) {
                message.success('保存成功')
                data.calculated1 = undefined
                data.calculated2 = undefined
                data.calculatedValue1 = undefined
                data.calculatedValue2 = undefined
                data.elementType1 = undefined
                data.elementType2 = undefined
                data.calculatedId1=undefined,
                data.calculatedId2=undefined,
                data.sort = undefined
                onClose()
                props.handleSearch({})
              } else {
                message.warning(res.message)
              }


            })
          // formRef.value.resetFields()
        } else {
          request(getRequestURI('tbys_glupdata'), {data}, true, 'post').then(
            (result) => {
              if (result.code === 1) {
                message.success('修改成功')
                handleSearch({})
                data.calculated1 = undefined
                data.calculated2 = undefined
                data.calculatedValue1 = undefined
                data.calculatedValue2 = undefined
                data.elementType1 = undefined
                data.elementType2 = undefined
                data.calculatedId1=undefined,
                data.calculatedId2=undefined,
                data.sort = undefined
                formRef.value.resetFields()
                onClose()
                props.handleSearch({})
              } else {
                message.warning(result.message)
              }

            }
          )
        }
     //   console.debug('Success:', values)
        // state.visible = false

      } catch (errorInfo) {
        console.debug('Failed:', errorInfo)
      }

    }


    expose({
      showDrawer
    })
    return {
      next,
      next2,
      nextlist,
      nextlist2,
      // handleSearch,

      formRef,
      data,
      formData,
      ...toRefs(state),
      onClose,
      onSave,
      elementType,
      formData123,
      formData1234,
      jisuzhi2Change,
      xianshizhi2Change
    }
  }

})
</script>
