<template>
  <a-drawer
    :title="isShoutitle"
    :width="'45%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form :model="formData" ref="formRef" :labelCol="{span: 6}" :wrapperCol="{span: 15}">
      <a-row :gutter="10">
        <a-col span="24">
          <a-form-item label="关联类型:">
            <a-input  placeholder="责任" disabled></a-input>
          </a-form-item>
        </a-col>
        <a-col span="24">
          <a-form-item label="投保要素类别：" name="elementType"
                       :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-select @change="handelElementTypeList" :options="elementTypeList" v-model:value="formData.elementType" placeholder="请选择投保要素类别">
            </a-select>
          </a-form-item>
        </a-col>

        <a-col span="24">
          <a-form-item name="insuranceValue" label="投保要素值：" :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-select v-model:value="formData.insuranceValue" placeholder="请选择投保要素值" :options="insuranceList">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="24">
          <a-form-item name="id" label="关联责任：" :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]" >
            <a-select v-model:value="formData.id" placeholder="请选择责任" :options="idList" @select="dutyChange">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="24">
          <a-form-item name="dutyAmount" label="关联责任保额：">
            <a-select v-model:value="formData.dutyAmount" placeholder="请选择责任保额" :options="amountList" >
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="24">
          <a-form-item name="isOptional" :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <template #label>
              <span>是否可选: </span>
              <span>
                <a-popover placement="right">
                  <template #content>
                  <p>1. 只有险种责任配置页面中，该责任的“是否可选责任”选择“是”时，才可以与投保要素进行关联
                    <br>2. 当选择“是"，该责任在前端为可选责任，且只在关联的投保要素下显示，当选择“否”时，该责任为必选责任，且只在关联的投保要素下显示
                    <br>3. 此处配置是否可选与{险种责任信息管理}模块中的配置的是否可选配置不相同时，会以此处的配置为准，即此处的优先级高于{险种责任信息管理}模块中的配置</p>
                  </template>
                  <template #title>
                  <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
                </span>
            </template>
            <a-select v-model:value="formData.isOptional" placeholder="请选择是否可选" :options="isOptionalList">
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button type="primary" @click="onSave">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>

<script>
import {defineComponent, onMounted, reactive, ref, toRefs} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
import {message} from 'ant-design-vue'
import { getDictionaryData } from '../../../utils/request_extend'

export default defineComponent({
  emits:['select'],
  setup(props,{emit}) {
    const state = reactive({
      visible: false,
      idList: [],
      insuranceList:[], // 投保要素值
      elementTypeList: [], // 投保要素类别List
      amountList: [],
      allDuty: [],
      isShoutitle:'',
      id:''
    })
    const formData = reactive({
      isOptional: 'N',
      id: undefined,
      elementType: undefined, //投保要素类别
      insuranceValue: undefined,
      dutyAmount: undefined
    })
    const showDrawer = async  function(shoulderObj,num,oldData) {
      state.idList=[]
      // console.log('子代shoulderObj和num以及oldData',oldData,num,shoulderObj)
      if(num===1){
        state.isShoutitle='关联险种责任增加'
        state.visible = true
        await init(shoulderObj)
        console.log('新增shoulderObj',shoulderObj)
        getInsuranceValue(shoulderObj.id)
      }else if(num===2){
        state.id=oldData.id
        console.log('执行了编辑',num)
        state.isShoutitle='关联险种责任修改'
        state.visible = true
        // await init(shoulderObj)
        state.idList = []
        state.allDuty = []
        // getInsuranceValue(shoulderObj.id)
        request(getRequestURI('selectRisk_dutyList'),{data: {...shoulderObj, riskId: shoulderObj.id}, pagination: {current: 1, pageSize: 999}}).then(res => {
          state.allDuty = res.result
          res.result.forEach(item => {
            let obj = {}
            obj.label = item.comDutyCode + ' - ' + item.dutyName + ' - ' + item.dutyCode
            obj.value = item.id
            state.idList.push(obj)
          })
          if(oldData.elementId){
            console.log('list----------',list)
            request(getRequestURI('tbys_list'), {data: {riskId:shoulderObj.id},pagination: {current: 1, pageSize: 999}}).then(res =>{
              console.log('获取投保要素', res)
              Object.assign(list,res.result)
              res.result.forEach(item =>{
                state.elementTypeList.push({value: item.id, label:item.elementName})
              })
              state.insuranceList=[]
              listTwo.value=list.filter(item=>item.id===oldData.elementId)
              listTwo.value[0].productElementListForm.forEach(item=>{
                state.insuranceList.push({value:item.id,label:item.calculated + ' - ' + item.calculatedValue})
              })
            })

            // listTwo.value=list.filter(item=>item.id===oldData.elementId)
            // getInsuranceList(listTwo.value)
          }
          if(oldData.riskDutyId){

              dutyChange(oldData.riskDutyId)
            if(oldData.dutyAmount){
              // console.log('dutyAmount',Number(oldData.dutyAmount))
              formData.dutyAmount=Number(oldData.dutyAmount)
            }
          }
        })
        console.log('编辑shoulderObj',shoulderObj)

        Object.keys(formData).forEach(key => {
          formData[key] = oldData[key] || ''
        })
        if(oldData.elementListId){
          formData.insuranceValue=oldData.elementListId
        }
        if(oldData.elementId){
          formData.elementType=oldData.elementId
        }
        if(oldData.riskDutyId){
          formData.id=oldData.riskDutyId
          // setTimeout(()=>{
          //
          //   dutyChange(oldData.riskDutyId)
          // },200)
        }


      }

    }
    const listTwo=ref([])
    // 获取投保要素值
    const getInsuranceList = (list) =>{
      state.insuranceList = []
      if(list && list.length){
        list.forEach(item =>{
          state.insuranceList.push({value:item.id,label:item.calculated + ' - ' + item.calculatedValue})
        })
      }
    }
    const list = reactive([])
    // 获取投保要素值在的位置
    const getInsuranceValue = (id) =>{
      request(getRequestURI('tbys_list'), {data: {riskId:id},pagination: {current: 1, pageSize: 999}}).then(res =>{
        console.log('获取投保要素', res)
        Object.assign(list,res.result)
        res.result.forEach(item =>{
          state.elementTypeList.push({value: item.id, label:item.elementName})
        })
      })

    }

    const init = function(oldData) {
      state.idList = []
      state.allDuty = []
     request(getRequestURI('selectRisk_dutyList'),{data: {...oldData, riskId: oldData.id}, pagination: {current: 1, pageSize: 999}}).then(res => {
       state.allDuty = res.result
       res.result.forEach(item => {
          let obj = {}
          obj.label = item.comDutyCode + ' - ' + item.dutyName + ' - ' + item.dutyCode
          obj.value = item.id
          state.idList.push(obj)
        })
     })
    }
    const dutyChange = (dutyId) => {
      // console.log(2222222222222,dutyId)
      console.log(2222222222222,state.allDuty)
      const dutyArr = state.allDuty.filter(duty => duty.id === dutyId)
      if (dutyArr.length === 1) {
        const amountArr = dutyArr[0]['riskDutyPremiumList'] || []
        state.amountList = amountArr.map(item => {
          return {label: item.coverage + '-' + item.coverageValue, value: item.coverage}
        })
        formData.dutyAmount = undefined
      }
    }
    const formRef = ref()
    const onSave = async () => {
      await formRef.value.validate()
      // let arr = []
      // if(formData && formData.id){
      //   arr.push(formData.id)
      // }
      if(state.isShoutitle==='关联险种责任增加'){
        let arr = []
        if(formData && formData.id){
          arr.push(formData.id)
        }
        request(getRequestURI('productElementRiskDutyLink_save'), {
          data: {
            elementId:formData.elementType,
            elementListId: formData.insuranceValue,
            riskDutyIds: arr,
            isOptional: formData.isOptional,
            dutyAmount: formData.dutyAmount
          }
        }).then(res => {
          if (res.code === 1) {
            message.success(res.message)
            emit('select')
            onClose()
          } else {
            message.warning(res.message)
          }
        })
      }else if(state.isShoutitle==='关联险种责任修改'){
        request(getRequestURI('productElementRiskDutyLink_update'), {
          data: {
            elementId:formData.elementType,
            elementListId: formData.insuranceValue,
            riskDutyId:formData.id,
            isOptional: formData.isOptional,
            dutyAmount: formData.dutyAmount,
            // id:'37e9270bb8cb41a1864a416640dfc584'
            id:state.id
          }
        }).then(res => {
          if (res.code === 1) {
            message.success(res.message)
            emit('select')
            onClose()
          } else {
            message.warning(res.message)
          }
        })
      }
    }
    // 投保要素类别 发生改变, 投保要素值也要改变
    const handelElementTypeList = (e) =>{
      console.log('eeeeeeeeeeee',e)
      // formData.insuranceValue = undefined
      let obj = list.filter(item =>{
        return item.id === e
      })
      //投保要素值
      getInsuranceList(obj[0].productElementListForm)
    }

    const onClose = function () {
      formRef.value.resetFields()
      formRef.value.clearValidate()
      state.visible = false
      state.elementTypeList = []
      formData.id = undefined
      formData.insuranceValue = undefined
      formData.elementType = undefined
      formData.isOptional= 'N'
      formData.dutyAmount = undefined
      state.insuranceList=[]
      state.idList=[]

      state.amountList=[]
    }
    const isOptionalList = ref([])
    onMounted(()=>{
      getDictionaryData(isOptionalList, 'yes_no')
    })
    return {
      isOptionalList,
      handelElementTypeList,
      list,
      formRef,
      ...toRefs(state),
      onClose,
      init,
      showDrawer,
      formData,
      onSave,
      getInsuranceList,
      getInsuranceValue,
      dutyChange,
      listTwo
    }
  }
})
</script>

<style scoped>

</style>
