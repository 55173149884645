<template>
  <a-drawer
    title="根据费率表生成投保要素"
    :width="'30%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form :model="formData" ref="formRef" autocomplete="off">
      <a-row :gutter="10">
        <a-col span="24">
          <a-form-item name="dutyId" label="责任名称："
                       :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-select v-model:value="formData.dutyId" :options="listData.dutyIdList">
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <upload-file filetype="xlsx" :key="time" :showImg="showImg" num="1" @fileChange="uploadCosFileChange"></upload-file>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button type="primary" @click="onSave">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>

<script>
import {message} from 'ant-design-vue'
import {defineComponent, reactive, ref, toRefs} from 'vue'
import uploadFile from '../../../components/extended/uploadFileNew'
import {getRequestURI, request} from '../../../utils/request'

export default defineComponent({
  components: {
    uploadFile
  },
  setup(props,{emit}) {
    const formRef = ref()
    const state = reactive({
      visible: false,
      showImg: [],
      time: null
    })
    const formData = reactive({
      riskId: '',
      dutyId: '',
      url: ''
    })
    const listData = reactive({
      dutyIdList: []
    })
    const onSave = async function() {
      await formRef.value.validate()
      if(!formData.url) {
        message.warning('费率表不能为空')
        return
      }
      request(getRequestURI('productLabel17_selectListForPage'), formData).then(res=> {
        console.log(res)
        if (res.code === 1) {
          onClose()
          emit('search')
          message.success('保存成功')
        } else {
          message.warning(res.message)
        }
      })
    }
    const chaxunsuoyouzeren = function(value) {
      listData.dutyIdList = [{
        value: '000',
        label: '基础责任',
      }]
      request(getRequestURI('selectRisk_dutyList'), {
        data: {riskId: value},
        pagination: {current: 1, pageSize: 10}
      }).then(res => {
        res.result.forEach(item => {
          let obj = {}
          obj.value = item.id
          obj.label = item.dutyName
          listData.dutyIdList.push(obj)
        })
      })
    }
    const uploadCosFileChange=(data)=>{
      formData.url=data.url
    }
    const onClose = function () {
      formRef.value.clearValidate()
      state.showImg = []
      Object.assign(formData, {
        riskId: '',
        dutyId: '',
        url: ''
      })
      state.time = new Date().getTime()
      state.visible = false
    }
    const showDrawer = function(oldData) {
      formData.riskId = oldData
      chaxunsuoyouzeren(formData.riskId)
      state.visible = true
    }
    return {
      ...toRefs(state),
      onClose,
      formData,
      showDrawer,
      onSave,
      uploadCosFileChange,
      chaxunsuoyouzeren,
      listData,
      formRef
    }
  }
})
</script>

<style scoped>

</style>
