<style scoped>
</style>

<template>
  <a-drawer
    :title="isShoutitle"
    :width="'70%'"
    :visible="visible2"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form :model="data" layout="vertical" ref="formRef" autocomplete="off">
      <a-row :gutter="10">
        <a-col span="12">
          <a-form-item name="elementType" label="要素类别："
                       :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-select v-model:value="data.elementType"
                      show-search
                      placeholder="请选择要素类别"
                      :filter-option="filterOption"
                      :options="elementTypeList"
                      :disabled="ischeck"
                      @change="mingzifuzhi">
              <!--        <a-select-option value="TransTrade" label="贸易方式">TransTrade-贸易方式</a-select-option>-->
              <!--        <a-select-option value="TransType" label="运输方式">TransType-运输方式</a-select-option>-->
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12" v-if="data.elementType === 'ElementDuty'">
          <a-form-item name="dutySort"
                       :rules="[{ required: true, message: '可选责任组合的顺序' ,trigger: 'change'}]">
            <template #label>
              <span>可选责任排序: </span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                  <p>主要标记多个可选责任类型情况下的投保要素组合，这里的顺序与现价表或费率表对应起来。</p>
                  </template>
                  <question-circle-outlined/>
                </a-popover>
                </span>
            </template>
            <a-input placeholder="请输入序号" v-model:value="data.dutySort" type="number" :disabled="ischeck"></a-input>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item name="elementName" label="要素名称："
                       :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-input placeholder="请输入要素名称" v-model:value="data.elementName" :disabled="ischeck"></a-input>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item name="isShowStatus" label="是否显示："
                       :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-select :options="isShowStatusList" v-model:value="data.isShowStatus" placeholder="请选择"
                      :disabled="ischeck">
              <!-- <a-select-option value="Y">是</a-select-option>
             <a-select-option value="N">否</a-select-option> -->
            </a-select>
          </a-form-item>
        </a-col>

        <a-col span="12">
          <a-form-item name="isMust" label="是否必填："
                       :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-select :options="isShowStatusList" v-model:value="data.isMust" placeholder="请选择" :disabled="ischeck">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item name="isEdit" label="是否可编辑："
                       :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-select :options="isShowStatusList" v-model:value="data.isEdit" placeholder="请选择" :disabled="ischeck">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item name="isPremiumCal" label=" 是否费率表列："
                       :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-select :options="isShowStatusList" v-model:value="data.isPremiumCal" placeholder="请选择"
                      :disabled="ischeck">
              <!-- <a-select-option value="Y">是</a-select-option>
             <a-select-option value="N">否</a-select-option> -->
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item
            label="显示顺序："
            name="sort"
            :rules="[
                {required: true, pattern:/^[1-9]\d*$/, message: '请输入整数' ,trigger: 'change'}]"
          >
            <a-input :maxlength="3" v-model:value="data.sort" placeholder="请输入显示顺序" :disabled="ischeck"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item name="dataType" label="数据类型："
                       :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-select v-model:value="data.dataType" placeholder="请选择数据类型" :disabled="ischeck"
                      :options="dataTypeList">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12" v-if="riskSignT===2">
          <a-form-item name="isWithMainRisk" :rules="[{ required: riskSignT==2, message: '必填信息' ,trigger: 'change'}]">
            <template #label>
              <span>是否同主险: </span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                    <p>
                      1.配置同主险后,若该要素值和主险要素值一致（投保年龄投保要素可以录入区间），在页面上附加险投保要素即可根据主险投保要素而变化。
                      <br>2.当操作类型为单选、开关时，页面依旧按照原样式展示，其他操作类型会显示文字“同主险”。
                    </p>
                  </template>
                  <template #title>
                    <span>附加险的同主险配置说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
              </span>
            </template>
            <a-select v-model:value="data.isWithMainRisk" placeholder="请选择" :disabled="ischeck"
                      :options="isShowStatusList">
             </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item name="operationType"
                       :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <template #label>
              <span>操作类型: </span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                  <p>1.	“日期”：配合数据类型“日期”，可以在页面上选择时间
                      <br>2.	“下拉选择”：会在页面下方出现滚轮页面，可以上下滑动选择数据
                      <br>3.	“开关”：左右横向滑动的开关，仅有开和关两个状态
                      <br>4.	“单选”：适用于数据量少，且值固定的场景，如性别，会在同一行显示两个选项
                      <br>5.	“城市选择”：无需配置要素值，会在页面上要求选择城市
                      <br>6.	“职业选择”：当投保要素为职业等级时，需要录入等级投保要素值，前端会返回职业等级。当投保要素为职业时，无需录入要素值，前端会返回职业编码
                      <br>7.	“展开单选”：在页面初始化时是折叠状态，点开后才会显示一列值，支持保额保费的自定义输入
                      </p>
                  </template>
                  <template #title>
                  <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
                </span>
            </template>
            <a-select v-model:value="data.operationType" placeholder="请选择" :disabled="ischeck"
                      :options="operationTypeList">
              <!--         <a-select-option value="Y">是</a-select-option>-->
              <!--        <a-select-option value="N">否</a-select-option>-->
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="要素说明：" name="elementDescription">
            <a-input v-model:value="data.elementDescription" placeholder="请输入要素说明" :disabled="ischeck"/>
          </a-form-item>
        </a-col>

        <a-col span="24">
          <div style="margin-bottom: 20px;"><label>要素定义说明:</label>
            <p style="color:red; padding-left: 5px; white-space: pre-line">
              {{ factorInputRule }}
            </p>
          </div>

        </a-col>
        <a-col span="24">
          <a-table :columns="columns2" :data-source="data.productElementListForm"
                   :pagination="false"
                   :row-key="(record) => record.id"
                   :row-selection="ischeck ? null : {
                     selectedRowKeys: selectedRowKeys2,
                     onChange: onSelectChanges2
                   }">
            <template #bodyCell="{ column,index,  record }">
              <template v-if="column.key === 'index'">
                {{ index + 1 }}
              </template>
              <template v-if="column.key === 'sort'">
                <a-input v-model:value="record.sort" placeholder="请输入" :disabled="ischeck">
                </a-input>
              </template>
              <template v-if="column.key === 'calculated'">
                <a-input v-model:value="record.calculated" placeholder="请输入" :disabled="ischeck">
                </a-input>
              </template>
              <template v-if="column.key === 'calculatedValue'">
                <a-input v-model:value="record.calculatedValue" placeholder="请输入" :disabled="ischeck">
                </a-input>
              </template>
              <template v-if="column.key === 'defaultShow'">
                <a-radio-group v-model:value="record.defaultShow" :disabled="ischeck"  @change="onRadioGroupListener(record.defaultShow, record)">
                  <a-radio value="Y">是</a-radio>
                  <a-radio value="N">否</a-radio>
                </a-radio-group>
              </template>
            </template>
          </a-table>
          <a-button @click="add" v-if="!ischeck">增加一行</a-button>&nbsp;
          <a-button @click="del" v-if="!ischeck">删除</a-button>&nbsp;
          <a-button @click="kuaisushengcheng" v-if="!ischeck">快速生成数据</a-button>
        </a-col>
      </a-row>
      <quickEnter ref="quickEnterRef" @tableChange="tableChange"></quickEnter>
    </a-form>

    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button type="primary" v-if="!ischeck" @click="onSave" :loading="loadingTwo">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>

<script>
import quickEnter from '../../../components/extended/quickEnter'
import {CloseOutlined, SaveOutlined} from '@ant-design/icons-vue'
import {defineComponent, onBeforeMount, reactive, ref, toRefs,} from 'vue'
import {getRequestURI, request,} from '../../../utils/request'
import {getDictionaryData, getDictionaryValue} from '../../../utils/request_extend'

import {message} from 'ant-design-vue'

export default defineComponent({
  components: {
    CloseOutlined, SaveOutlined, quickEnter
  },
  props: {
    handleSearch: {type: Function},
    riskId: {type: [String, Number]},
    riskSign: {type: [String, Number]}
  },
  setup(props, {expose}) {
    const ischeck = ref(false)//输入框不能用
    const addDrawerRef = ref()
    const formRef = ref()
    const loadingTwo=ref(false)
    const quickEnterRef = ref()
    const factorInputRule = ref('')
    const data = reactive({
      delListIds: [],
      operationType: undefined,
      dataType: undefined,
      elementDescription: undefined,
      elementName: undefined,
      elementType: undefined,
      dutySort: 0,
      id: 0,
      isEdit: 'N',
      isMust: 'Y',
      isPremiumCal: 'Y',
      isShowStatus: 'Y',
      userDefined: '',
      minValue: '',
      maxValue: '',
      isWithMainRisk: 'Y',
      productElementListForm: [
        {
          calculated: undefined,
          calculatedValue: undefined,
          defaultShow: 'Y',
          id: 0,
          isShowStatus: 'Y',
          productElementId: 0,
          sort: undefined
        }
      ],
      riskId: 0,
      sort: undefined,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      sorters: [
        {
          field: '',
          order: ''
        }
      ],
      factorRules: {
        Plan:
          '1. 计划的数据类型为字符，操作类型为文本，程序中对该要素进行了特殊处理，该要素会展示在要素的最上方位置，可实现单选的逻辑' +
          '\n 2. 计划一般用来描述动态的组合或标志' +
          '\n 3. 例: 计划一编码为A001,可配置要素计算值为A001，显示值为计划一',
        Amnt:
          '1. 保额的数据类型为字符，操作类型一般设置为下拉选择或展开单选，设置下拉选择时无法自定义输入' +
          '\n 2. 一般我们在计算值处录入真实数值，在显示值处录入数值描述' +
          '\n 3. 当前端页面需要进行自定义输入时，在列表最后一行“投保要素值计算值”输入“customCoverageFlag”，“投保要素值显示值”输入要显示的提示语即可' +
          '\n 4. 例：10万元，可配置要素计算值为100000，显示值为10万元',
        TextAge:
          '1. 年龄的数据类型和操作类型都需要配置为日期类型。' +
          '\n 2. 计算值如果是天数，请用D结尾，如大于28天即为28D。所有的年龄配置中只允许出现一个以D结尾的数值' +
          '\n 3. 整数岁请使用整数，如18岁，填18即可' +
          '\n 4. 年龄支持配置区间值，以-分隔。如18-30岁，18-30' +
          '\n 5. 例1：28天到1岁，可配置要素计算值为28D-1，显示值为28天-1岁' +
          '\n 6. 例2：1岁到18岁，可配置要素计算值为1-18，显示值为1岁-18岁',
        Sex:
          '1. 性别的数据类型为字符，操作类型一般为单选' +
          '\n 2. 要素值字典为1-男，2-女',
        Period:
          '1. 保险期限的数据类型为字符，操作类型一般设置为文本或下拉选择,为必选投保要素' +
          '\n 2. 保险期限(保多少年)字典为: L-终身 Y-年 M-月 D-天' +
          '\n 3. 保险期限(保至多少岁)字典为: xxA, 105A(终身)' +
          '\n 4. 如果为费率表列，保终身计算值用105A，不为费率表列计算值可用L' +
          '\n 5. 例：保一年: 1Y，保一月：1M，保到60岁：60A，保终身：L或105A',
        PayType:
          '1. 交费方式的数据类型为字符，操作类型一般设置为文本或下拉选择,为必选投保要素' +
          '\n 2. 要素值字典为：0-趸交, 1-月交, 3-季交, 6-半年交, 12-年交, -1-不定期交',
        FeeYear:
          '1. 交费年期的数据类型为字符，操作类型一般设置为文本、展开单选或下拉选择,为必选投保要素' +
          '\n 2. 要素字典值为：0-趸交,10Y-10年交, 20Y-20年交, 55A-交至55岁, 65A-交至65岁。',
        AppLevel: '投保档次-暂无描述',
        AreaType:
          '1. 投保地区的数据类型为字符，操作类型为城市选择，在页面的展示形式为地区层级' +
          '\n 2. 无需定义投保要素值，页面会默认选取地区编码为要素值',
        Grade: '产品级别-暂无描述',
        Mult:
          '1. 份数的数据类型为数字，操作类型为文本或下拉选择，当险种的份数计算公式为直接录入时，为必选投保要素' +
          '\n 2. 当需要在页面手动录入份数时，可以将操作类型设置为文本，是否可编辑属性设置为是即可，此时要素值仅设置一组默认值即可',
        Occupation:
          '1. 职业的数据类型为字符，操作类型为职业选择' +
          '\n 2. 无需定义投保要素值，页面会默认选取职业编码为要素值',
        Occup:
          '1. 职业类别的数据类型为字符，操作类型为职业选择' +
          '\n 2. 投保要素值需要定义职业级别，一般为1-1级，2-2级，4-4级',
        SocialFlag:
          '1. 是否有社保的数据类型为字符，操作类型为展开单选' +
          '\n 2. 字典值为：Y-有, N-无',
        FixedPremium:
          '1. 固定金额的数据类型为字符，操作类型为文本，配合险种保费计算公式为“保费=固定金额”使用，要素值有且仅有一个值',
        FixedRate:
          '1. 固定费率的数据类型为字符，操作类型为文本，配合险种保费计算公式为“保费=保额*固定费率”使用，要素值有且仅有一个值',
        BaseCoverage:
          '1. 基础保额/保费的数据类型为数字，操作类型为文本，配合险种份数计算方式为“xxx/基础保额”使用，要素值有且仅有一个值',
        Prem:
          '1. 保费的数据类型为字符或文本，操作类型一般设置为展开单选或下拉选择' +
          '\n 2. 当前端页面需要进行自定义输入时，在列表最后一行“投保要素值计算值”输入“customCoverageFlag”，“投保要素值显示值”输入要显示的提示语即可',
        Insurancedate:
          '1. 起保日期的数据类型为日期，操作类型一般设置为日期' +
          '\n 2. 投保要素值设置为xxxD,表示距当前时间xxx天' +
          '\n 3. 例：投保要素值为1D-1天......60D-60天，即页面选择范围为t+1到t+60天',
        EngineeringCost:
          '1. 工程造价的数据类型为数字，操作类型一般设置为展开单选或文本，是为华安装修保产品衍生出的投保要素' +
          '\n 2. 当前端页面需要进行自定义输入时，在列表最后一行“投保要素值计算值”输入“customCoverageFlag”，“投保要素值显示值”输入要显示的提示语即可',
        LtemizedAmount:
          '1. 分项保额的数据类型为数字，操作类型一般设置为展开单选或文本，是为华安装修保产品衍生出的投保要素',
        insuredNum:
          '1. 被保人人数的数据类型为数字，操作类型一般设置为下拉选择、展开单选或文本' +
          '\n 2. 一般在多被保人的险种下，根据人数打折的场景',
        renewinsurance:
          '1. 是否申请续保的数据类型为字符，操作类型一般设置为展开单选或单选' +
          '\n 2. 字典值为：Y-是，N-否',
        getRate:
          '1. 赔付比例的数据类型为数字，操作类型一般设置为展开单选或文本' +
          '\n 2. 投保要素计算值为小数值' +
          '\n 3. 例: 0.5-50%',
        Secondarydeductible:
          '1. 次免赔额的数据类型为数字，操作类型一般设置为展开单选或文本',
        Annualdeductible:
          '1. 年免赔额的数据类型为数字，操作类型一般设置为展开单选或文本',
        RiskTip:
          '1. 风险提示的数据类型为数字，操作类型一般设置为展开单选或文本',
        renew:
          '1. 保险费自动垫交的数据类型为字符，操作类型一般设置为展开单选或文本' +
          '\n 2. 字典值为：1-自动垫交, 2-不自动垫交',
        relaPrtNo: '关联投保单号-暂无描述: ',
        ElementDuty:
          '1. 可选责任是将责任配置为投保要素，数据类型为字符，此操作类型一般设置为展开单选或文本',
        Aidad:
          '1. 意外伤害身故和残疾的数据类型为数字，操作类型一般设置为展开单选或文本，是由平安财的团体意外险衍生而来',
        Ait:
          '1. 意外伤害医疗的数据类型为字符，操作类型一般设置为展开单选或文本，是由平安财的团体意外险衍生而来',
        Aha:
          '1. 意外住院津贴的数据类型为字符，操作类型一般设置为展开单选或文本，是由平安财的团体意外险衍生而来',
        CommonA:
          '1. 通用的一种投保要素，没有固定的约束，适用于无复杂逻辑，且后台支持适配的场景',
        CommonB:
          '1. 通用的一种投保要素，没有固定的约束，适用于无复杂逻辑，且后台支持适配的场景',
        CommonC:
          '1. 通用的一种投保要素，没有固定的约束，适用于无复杂逻辑，且后台支持适配的场景',
        CommonD:
          '1. 通用的一种投保要素，没有固定的约束，适用于无复杂逻辑，且后台支持适配的场景',
        CommonE:
          '1. 通用的一种投保要素，没有固定的约束，适用于无复杂逻辑，且后台支持适配的场景',
        IdNo:
          '1. 身份唯一标识的数据类型为字符，操作类型为文本，是由德华安顾的被保人首单打折衍生而来' +
          '\n 2. 要素值设置默认值即可，0-0，程序中会在获取到被保人身份证号后补充要素值，后台根据身份证号判断是否为首单，从而判断是否打折',
        deduction:
          '1. 扣除数的数据类型为字符，操作类型为文本' +
          '\n 2. 配合保费计算公式“保费=阶梯费率表枚举-扣除数”使用',
      }
    })
    const state = reactive({
      delIdList: [],
      riskSignT:'',
      clean: false,
      tableshow: false,
      butshow: false,//按钮显示
      datasource: [
        {
          calculated: '',
          sort: '',
          calculatedValue: ''
        }
      ],//要素值列表
      list: [],//表格数据
      columns2: [
        {
          title: '序号',
          width: 140,
          fixed: 'left',
          key: 'index',
          dataIndex: 'index'
        },
        {
          title: '投保要素值序号',
          width: 140,
          fixed: 'left',
          key: 'sort',
          dataIndex: 'sort'
        },
        {
          title: '投保要素值计算值',
          width: 120,
          align: 'center',
          key: 'calculated',
          dataIndex: 'calculated',
        },
        {
          title: '投保要素值显示值',
          width: 120,
          align: 'center',
          key: 'calculatedValue',
          dataIndex: 'calculatedValue',
        },
        {
          title: '是否显示',
          width: 120,
          align: 'center',
          key: 'isShowStatus',
          dataIndex: 'isShowStatus',
          customRender: (text) => getDictionaryValue('yes_no', text.value)
        },
        {
          title: '默认显示',
          width: 120,
          align: 'center',
          key: 'defaultShow',
          dataIndex: 'defaultShow',
          customRender: (text) => getDictionaryValue('yes_no', text.value)
        },

      ],
      loading: false,
      visible2: false,
      // elementTypeList: [],
      isShoutitle: '',//title名字
      issho: false,
      selectedRowKeys2: [],
      isisis: true,//编辑还是新增
      checkids: [],
      query: {
        data: {
          dataType: '',
          elementDescription: '',
          elementName: '',
          elementType: '',
          dutySort: 0,
          id: 0,
          isEdit: 'N',
          isMust: 'Y',
          isPremiumCal: 'Y',
          isShowStatus: 'Y',
          productElementListForm: [
            {
              calculated: '',
              calculatedValue: '',
              defaultShow: 'N',
              id: 0,
              isShowStatus: 'Y',
              productElementId: 0,
              sort: 0
            }
          ],
          riskId: 0,
          sort: 0
        },
        pagination: {
          current: 1,
          pageSize: 10,
          total: 0
        },
        sorters: [
          {
            field: '',
            order: ''
          }
        ]
      }
    })
    const filterOption = (input, option) => {
      return option.label.indexOf(input) !== -1
    }
    const onRadioGroupListener = (value, record) => {
      // 如果当前选中的值是 'Y'，则将数据源中的所有元素的 defaultShow 设置为 'N'
      if (value === 'Y') {
        data.productElementListForm.forEach(item => {
          if (item !== record) {
            item.defaultShow = 'N'
          }
        })
      }
    }
    const changMaxValue = (event) => {
      //  console.log('@@@@@@@@', event.target.value, event.target.value< data.minValue)
      if (!data.minValue) {
        message.warn('请先填写最小值')
        data.maxValue = ''
      } else if (parseFloat(event.target.value) < parseFloat(data.minValue)) {
        message.warn('请输入大于' + data.minValue + '的值')
        data.maxValue = ''
      }
    }
    // 编辑
    const mingzifuzhi = function (item) {
      console.debug('----', item, data.factorRules[item])
      factorInputRule.value = data.factorRules[item]
    }
    const tableChange = function (list, status) {
      if (status === false) {
        data.productElementListForm = []
        list.forEach((item, index) => {
          item.sort = index + 1
          if (index === 0) {
            item.defaultShow = 'Y'
          } else {
            item.defaultShow = 'N'
          }
          item.isShowStatus = 'Y'
        })
        data.productElementListForm.push(...list)
      } else {
        let index = data.productElementListForm.length
        list.forEach((item) => {
          index++
          item.sort = index
          item.defaultShow = 'N'
          item.isShowStatus = 'Y'
        })
        data.productElementListForm.push(...list)
      }
    }
    const kuaisushengcheng = function () {
      quickEnterRef.value.show()
    }
    const showDrawer = (oldData = {}, num) => {
      getElemengtype()
      state.query.data.riskId = oldData.riskId
      data.riskId = state.query.data.riskId
      console.log(data.riskId)
      state.visible2 = true
      state.riskSignT=props.riskSign
      console.log('data.riskSign',state.riskSignT)
      if (num === 1) {
        state.isShoutitle = '险种投保要素增加'
        ischeck.value = false
        state.isisis = true
        data.riskId = props.riskId
        console.log(data.riskId)
      } else if (num === 2) {
        if (oldData && oldData.productElementListForm) {
          oldData.productElementListForm.forEach(item => {
            state.delIdList.push(item.id)
          })
        }
        state.isShoutitle = '险种投保要素修改'
        state.isisis = false
        ischeck.value = false
      } else {
        state.isShoutitle = '险种投保要素查看'
        ischeck.value = true
        // state.issho=false
        state.isisis = false
        Object.assign(data, oldData[0])

      }
      Object.keys(data).forEach(key => {
        data[key] = oldData[key] || data[key]
      })
      factorInputRule.value = data.factorRules[data.elementType]
      console.log('aaaaa', data)

    }

    const onClose = () => {
      state.issho = false
      state.visible2 = false
      state.clean = false
      state.issho = false
      state.checkids = []
      state.visible = false
      formRef.value.resetFields()
      state.list = []
      data.dataType = undefined
      data.elementDescription = undefined
      data.elementName = ''
      data.elementType = undefined
      data.dutySort = 0
      data.isEdit = 'N'
      data.isMust = 'Y'
      data.isPremiumCal = 'Y'
      data.isShowStatus = 'Y'
      data.sort = undefined
      data.delListIds = []
      data.productElementListForm = [
        {
          calculated: undefined,
          calculatedValue: undefined,
          defaultShow: 'Y',
          id: 0,
          isShowStatus: 'Y',
          productElementId: 0,
          sort: undefined
        }
      ],
        state.selectedRowKeys2 = []
      props.handleSearch({})

    }
    // 增加一行
    let id = 1
    const add = () => {
      id++
      if (data.productElementListForm.length === 0) {
        data.productElementListForm.push({
          calculated: undefined,
          calculatedValue: undefined,
          defaultShow: 'Y',
          id: id,
          isShowStatus: 'Y',
          productElementId: 0,
          sort: undefined
        })
      } else {
        data.productElementListForm.push({
          calculated: undefined,
          calculatedValue: undefined,
          defaultShow: 'N',
          id: id,
          isShowStatus: 'Y',
          productElementId: 0,
          sort: undefined
        })
      }
    }

    // 删除
    const del = () => {
      if (!state.selectedRowKeys2.length) message.warning('请选择数据')
      state.selectedRowKeys2.forEach((item) => {
        data.productElementListForm.forEach((item1, index) => {
          if (item1.id == item) {
            data.productElementListForm.splice(index, 1)
          }
        })
      })
      state.selectedRowKeys2.forEach(item => {
        state.delIdList.forEach(del => {
          if (item === del) {
            data.delListIds.push(item)
          }
        })
      })
      state.selectedRowKeys2 = []
    }
    // 保存
    const onSave = async () => {
      await formRef.value.validate()
      loadingTwo.value=true
      if (state.isisis) {
        // if(data.sort.length > 3){
        //   console.log(222)
        //   message['warning']('显示顺序输入长度不能超过3位数')
        //   return
        // }
        if (data.isShowStatus == '是') {
          data.isShowStatus = 'Y'
        } else if (data.isShowStatus == '否') {
          data.isShowStatus = 'N'
        }
        if (data.isEdit == '是') {
          data.isEdit = 'Y'
        } else if (data.isEdit == '否') {
          data.isEdit = 'N'
        }
        if (data.isMust == '是') {
          data.isMust = 'Y'
        } else if (data.isMust == '否') {
          data.isMust = 'N'
        }
        if (data.isPremiumCal == '是') {
          data.isPremiumCal = 'Y'
        } else if (data.isPremiumCal == '否') {
          data.isPremiumCal = 'N'
        }
        // if (typeof data.dutySort == 'string') {
        //   data.dutySort = Number(data.dutySort)
        // }
        if (typeof data.dutySort == 'number') {
          data.dutySort = data.dutySort + ''
        }
        request(getRequestURI('tbys_add'), {data}, true, 'post').then(
          (res) => {
            loadingTwo.value = false
            console.log(res)
            if (res.code !== 1) {
              message['warning'](res.message)
            } else {
              message['success']('保存成功')
              formRef.value.resetFields()
              data.productElementListForm = [
                {
                  calculated: undefined,
                  calculatedValue: undefined,
                  defaultShow: undefined,
                  id: 0,
                  isShowStatus: undefined,
                  productElementId: 0,
                  sort: undefined
                }
              ]
              onClose()
              props.handleSearch({})
            }
          }).catch(err => {
          console.error(err)
          loadingTwo.value = false
        })

      } else {
        if (data.isShowStatus == '是') {
          data.isShowStatus = 'Y'
        } else if (data.isShowStatus == '否') {
          data.isShowStatus = 'N'
        }
        if (data.isEdit == '是') {
          data.isEdit = 'Y'
        } else if (data.isEdit == '否') {
          data.isEdit = 'N'
        }
        if (data.isMust == '是') {
          data.isMust = 'Y'
        } else if (data.isMust == '否') {
          data.isMust = 'N'
        }
        if (data.isPremiumCal == '是') {
          data.isPremiumCal = 'Y'
        } else if (data.isPremiumCal == '否') {
          data.isPremiumCal = 'N'
        }

        data.productElementListForm.forEach(item => {
          if (!item.id.length || (typeof item.id === 'number')) {
            console.log(typeof item.id)
            item.id = null
          }
        })
        if (typeof data.dutySort == 'number') {
          data.dutySort = data.dutySort + ''
        }
        // 要素类别格式不对
        request(getRequestURI('tbys_updata'), {data}, true, 'post').then(
          (result) => {
            loadingTwo.value = false
            console.log(result)
            if (result.code !== 1) {
              message['warning'](result.message)
            } else {
              message['success']('修改成功')
              formRef.value.resetFields()
              data.delListIds = []
              data.productElementListForm = [{
                calculated: undefined,
                calculatedValue: undefined,
                defaultShow: undefined,
                id: 0,
                isShowStatus: undefined,
                productElementId: 0,
                sort: undefined
              }]
              onClose()
              props.handleSearch({})
            }
            //  handleSearch({})

          }
        ).catch(() => {
          loadingTwo.value = false
        })
      }
    }
    const onSelectChanges2 = selectedRowKeys => {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys2 = selectedRowKeys
    }
    //  要素类别下拉
    const getElemengtype = () => {


      // request(getExtendUri('dictionary_query_byType'), {data: {types: ['element_type']}}).then(res => {
      //   console.log(res)
      //   res.result.element_type.forEach(item => {
      //     let obj = {}
      //    obj.value = item.value
      //     obj.name = item.label
      //     obj.label = item.value +'-'+ item.label
      //     state.elementTypeList.push(obj)
      //   })
      // state.elementTypeList = res.result.element_type
      // })
    }
    // const init = function() {
    //   request(getExtendUri('dictionary_query_byType'), {data: {types: ['operation_type']}}).then(res => {
    //     console.log(res)
    //     // state.operationTypeList = res.result.operation_type
    //   })
    //   request(getExtendUri('dictionary_query_byType'), {data: {types: ['data_type']}}).then(res => {
    //     console.log(res)
    //     state.dataTypeList = res.result.data_type
    //   })
    // }
    // init()
    expose({
      showDrawer
    })

    const isShowStatusList = ref([])
    const operationTypeList = ref([])
    const userDefinedList = ref([
      {label: '是', value: 1},
      {label: '否', value: 2},
    ])
    const dataTypeList = ref([])
    const elementTypeList = ref([])

    onBeforeMount(() => {
      getDictionaryData(isShowStatusList, 'yes_no')
      getDictionaryData(operationTypeList, 'operation_type')
      getDictionaryData(dataTypeList, 'data_type')
      getDictionaryData(elementTypeList, 'element_type')
    })

    return {
      onRadioGroupListener,
      dataTypeList,
      operationTypeList,
      userDefinedList,
      isShowStatusList,
      elementTypeList,
      onSelectChanges2,
      getElemengtype,
      factorInputRule,
      ischeck,
      data,
      addDrawerRef,
      formRef,
      ...toRefs(state),
      onClose,
      onSave,
      add,
      del,
      mingzifuzhi,
      kuaisushengcheng,
      tableChange,
      quickEnterRef,
      changMaxValue,
      loadingTwo,
      filterOption
    }
  },
})
</script>
