<style scoped></style>

<template>
  <search-form
    ref="searchRef"
    :Condition="condition"
    :ColSpan="12"
    @resetAll="resetAll"
    @callback="handleSearch"
    @nameLinked="changes"
  ></search-form>
  <div class="btn-bar">
    <a-button type="primary" @click="addProduct()">
      <template #icon>
        <FileAddOutlined />
      </template>
      新增
    </a-button>
    <a-button danger @click="delMultiple">
      <template #icon>
        <DeleteOutlined />
      </template>
      删除
    </a-button>

    <a-button danger @click="exportClick">
      <template #icon>
        <ToTopOutlined />
      </template>
      导出
    </a-button>

    <a-button danger @click="environmentsProduct">
      <template #icon>
        <RetweetOutlined />
      </template>
      环境产品同步
    </a-button>
  </div>
  <a-table
    id="table"
    :columns="columns"
    :data-source="dataSource ? changTableList(dataSource['result']) : []"
    :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }"
    :row-key="(record) => record.id"
    :pagination="pagination"
    @change="handleTableChange"
    :loading="loading"
  >
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <div>
          <a
            href="javascript:void(0)"
            style="color: orange"
            @click="checkProduct(record, 1)"
            >查看</a
          >&nbsp;&nbsp;
          <a
            href="javascript:void(0)"
            style="color: orange"
            @click="modifyProduct(record, 2)"
            >编辑</a
          >&nbsp;&nbsp;
          <!--          <a href="javascript:void(0)" style="color: orange" @click="copyInformation(record)">复制</a>&nbsp;&nbsp;-->
          <!--          <a href="javascript:void(0)" style="color: orange" @click="copyInformation(record)">复制链接</a>&nbsp;&nbsp;-->
          <a
            href="javascript:void(0)"
            style="color: orange"
            @click="showPreview(record.id, record.productCode, 2)"
            >复制链接</a
          >&nbsp;&nbsp;
          <a
            :href="'/product/detail?productId=' + record.id"
            style="color: orange"
            target="_blank"
            @click.prevent="showPreview(record.id, record.productCode, 1)"
            >交易预览</a
          >
        </div>
      </template>
    </template>
  </a-table>
  <a-modal
    v-model:visible="visible"
    title="提示"
    @ok="handleOk"
    okText="确定"
    cancelText="取消"
  >
    <h2>是否确定删除该信息</h2>
  </a-modal>
  <a-modal
    width="60%"
    v-model:visible="isCopy"
    title="复制链接"
    @ok="handleCopyOk"
    okText="确定"
    cancelText="取消"
    @cancel="onCopyClose"
  >
    <a-form
      :model="productData"
      layout="horizontal"
      ref="productRef"
      autocomplete="off"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
    >
      <a-row :gutter="10">
        <a-col span="12">
          <a-form-item
            label="渠道名称:"
            name="channel"
            :rules="[
              { required: true, message: '请选择渠道名称', trigger: 'change' },
            ]"
          >
            <a-select
              show-search
              allow-clear
              :filter-option="filterOption"
              v-model:value="productData.channel"
              placeholder="请选择渠道名称"
            >
              <a-select-option
                :value="cn.channelCode"
                v-for="cn in ChannelTypeList"
                :key="cn.id"
                :label="cn.channelName"
                >{{ cn.channelName }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item
            label="渠道用户ID:"
            name="channelUserId"
          >
            <a-input
              v-model:value="productData.channelUserId"
              placeholder="请输入渠道用户ID"
            />
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item
            label="代理人ID:"
            name="agentId"
            :rules="[
              { required: true, message: '请输入代理人ID', trigger: 'change' },
            ]"
          >
            <a-input
              v-model:value="productData.agentId"
              placeholder="请输入代理人ID"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
  <edit-user :handleMainSearch="handleSearch" ref="userDrawerRef" :searchfFilters="searchfFilters" :current="pagination.current"></edit-user>
  <environments ref="environmentsRef"></environments>
</template>

<script>
//,provide
import {computed, defineComponent, onMounted, reactive, ref, toRefs} from 'vue'
import SearchForm from '../../../components/extended/search_form'
import editUser from './editInform'
import environments from './environments'
import {message, Modal} from 'ant-design-vue'


import useClipboard from 'vue-clipboard3'
import {getRequestURI, request, requestUsePagination} from '../../../utils/request.js'
import {DeleteOutlined, FileAddOutlined, FormOutlined, RetweetOutlined, ToTopOutlined} from '@ant-design/icons-vue'
import {getDictionaryData, getDictionaryValue, getExtendUri} from '../../../utils/request_extend'
import {getUrlParams} from '../../../utils/utlis'
import router from '../../../router'

export default defineComponent({
  components: {
    SearchForm,
    FileAddOutlined,
    DeleteOutlined,
    // eslint-disable-next-line vue/no-unused-components
    FormOutlined,
    RetweetOutlined,
    ToTopOutlined,
    editUser,
    environments,
  },
  setup () {
    const userDrawerRef = ref()//新增编辑
    const productSkipAddressList = ref()//新增编辑
    const environmentsRef = ref()//环境同步
    const { toClipboard } = useClipboard()//复制
    const state = reactive({
      refreshDom: true,
      isCopy: false,
      export1: '',//导出
      export2: '',//导出
      data: [],//批量删除
      condition: {
        supplierId: {
          label: '供应商名称',
          type: 'tree-select',
          urlKey: 'querySupplier',
          value: 'id',
          default: '',
          data: []
        },
        productId: {
          label: '产品名称',
          type: 'select',
          default: '',
          data: []
        },
        supProductCode: '供应商产品编码',
        productCode: '产品编码',
        status: {
          label: '上下架状态',
          type: 'select',
          urlKey: 'queryDictionaryType',
          queryParam: { type: 'materialUpDownStatus' }
        },
      },
      columns: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left',
        },
        {
          title: '产品编码',
          width: 100,
          align: 'center',
          key: 'productCode',
          dataIndex: 'productCode',
        },
        {
          title: '供应商产品编码',
          width: 100,
          align: 'center',
          key: 'supProductCode',
          dataIndex: 'supProductCode',
        },
        {
          title: '产品名称',
          width: 120,
          align: 'center',
          key: 'productName',
          dataIndex: 'productName',
        },
        {
          title: '产品类型',
          width: 100,
          align: 'center',
          key: 'productType',
          dataIndex: 'productType',
          customRender: (text)=> getProducrType(text.value)
        },
        {
          title: '产品类别',
          width: 120,
          align: 'center',
          key: 'productCategory',
          dataIndex: 'productCategory',
          customRender: (text)=> getDictionaryValue('product_category', text.value)
        },
        {
          title: '供应商名称',
          width: 100,
          align: 'center',
          key: 'supplierName',
          dataIndex: 'supplierName',
        },
        {
          title: '上下架状态',
          width: 100,
          align: 'center',
          key: 'statusName',
          dataIndex: 'statusName',
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 120,
        },
      ],
      // 服务id
      serviceName: '',
      tableList: [],
      selectedRowKeys: [],
      searchNow: {},//分页
      productTypeList: []
    })
    const productData = reactive({
      productCode: '',
      channel: undefined,
      channelUserId: '',
      agentId: '',
      agentCode: '1234567890123'
    })
    const getProducrType = (value = '') =>{
      if(value){
        const a = state.productTypeList['value'].filter(item => item.value === value)
        return a? a[0].label : ''
      }
    }

    const cahnpintiaozhuandizhi = function () {
      getDictionaryData(productSkipAddressList, 'productSkipAddress')
    }
    cahnpintiaozhuandizhi()
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('ppqa_selectInfo'))
    const changTableList = (params) => {
      let list = []
      if(params) {
        list =  params.map(item => {
          const productValue =  productSkipAddressList.value.find(d=> d.value == item.productSkipAddress )
          return {
            ...item,
            productSkipAddress: productValue ? productValue.label : (item.productSkipAddress == 1 ? '内部' : (item.productSkipAddress == 2 ? '外部' : item.productSkipAddress))
          }
        })
      }
      return list
    }
    //页面挂载调用数据
    onMounted(() => {
      getDictionaryData(state.productTypeList, 'ProductType')
      handleSearch({})
    })
    // 存储搜索组件传过来的数据
    const searchfFilters = ref({})
    //查询
    const handleSearch = function (filters, num = '') {
      if (filters) {
        searchfFilters.value = filters
      }
      state.searchNow = filters
      run({
        pagination: {
          current: num? num : 1,
          pageSize: 10
        },
        data: filters,
      })
    }

    // provide('handleMainSearch',handleSearch())
    //产品下拉查询
    const changes = function (value) {
      request(getRequestURI('select'), { data: { supplierId: value } }).then(result => {
        console.log(result)
        const arr = []
        const res = result.result
        const label = 'id'
        const sysName = res.reduce((all, next) => all.some((atom) => atom[label] == next[label]) ? all : [...all, next], [])
        for (let i = 0; i < sysName.length; i++) {
          arr.push({ label: sysName[i].productName, value: sysName[i].id })
        }
        state.condition.productId.data = arr
      })
    }
    const init = function () {
      request(getRequestURI('select'), { data: {} }).then(result => {
        const arr = []
        const res = result.result
        const label = 'id'
        const sysName = res.reduce((all, next) => all.some((atom) => atom[label] == next[label]) ? all : [...all, next], [])
        for (let i = 0; i < sysName.length; i++) {
          arr.push({ label: sysName[i].productName, value: sysName[i].id })
        }
        state.condition.productId.data = arr
      })
    }
    const resetAll = () => {
      state.condition.productId.data = []
      init()
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.log('===', filters)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.searchNow,
      })
    }
    // 复选的回调
    const onSelectChange = (selectedRowKeys, selectedRowrode) => {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
      state.export1 = selectedRowrode.supplierId
      state.export2 = selectedRowrode.id
    }
    // 批量删除
    const delMultiple = () => {
      let keys = state.selectedRowKeys
      if (keys.length === 0) {
        message.warning('请选择数据')
        return
      }
      request(getRequestURI('productInfocheck_Update'), { data: { ids: state.selectedRowKeys } }).then(res => {
        if (res.code === 1) {
          Modal.confirm({
            title: '确认删除吗?',
            async onOk () {
              request(getRequestURI('ppqa_delete'), {
                data: {
                  ids: keys
                }
              }, true, 'post').then(
                (result) => {
                  if (result.code === 1) {
                    message.success('删除成功')
                    state.selectedRowKeys = []
                    handleSearch({})
                    dataSource.value = result.result
                  } else {
                    message.warning(result.message)
                  }
                }
              )
            },
            onCancel () {
            }
          })
        } else {
          message.warning(res.message)
        }
      })

    }
    const handleOk = e => {
      console.log(e)
      visible.value = false
    }
    const searchRef = ref()
    //增加
    const addProduct = function () {
      // userDrawerRef.value.initFormData()

      userDrawerRef.value.showDrawer({}, 0, searchRef.value.State.treeData['supplierId'])

    }

    //编辑
    const modifyProduct = function (oldData, num) {
      // userDrawerRef.value.initFormData()
      request(getRequestURI('productInfocheck_Update'), { data: { ids: [oldData.id] } }).then(res => {
        if (res.code === 1) {
          oldData.productSkipAddress = dataSource.value['result'].filter(item => item.id === oldData.id)[0].productSkipAddress
          userDrawerRef.value.showDrawer(oldData, num, searchRef.value.State.treeData['supplierId'])
          userDrawerRef.value.checkDetails(oldData.id)
        } else {
          message.warning(res.message)
        }
      })

    }
    //查看
    const checkProduct = function (oldData, num) {
      userDrawerRef.value.showDrawer(oldData, num)
      userDrawerRef.value.checkDetails(oldData.id)
    }
    // 复制详情链接
    const copyInformation = async (data) => {
      try {
        await toClipboard(data)
        message.success('复制成功')
      } catch (err) {
        console.debug(err)
      }
    }

    const visible = ref(false)
    //导出
    const exportClick = () => {
      // let keys = state.selectedRowKeys
      // if (keys.length === 0) {
      //   message.warning('请选择数据')
      //   return
      // }
      console.log(dataSource.value)
      if (!dataSource.value || !dataSource.value['result']) {
        message.warning('暂无数据')
        return
      }
      request(getRequestURI('productInfo_export'),
        {
          data: {
            productInfoQueryForm: state.searchNow,
            id: state.selectedRowKeys
          }
        }, true, 'post').then(
          (result) => {
            console.log('导出的result', result)
            if(result.code===1){
              message.success(result.message)
            }
            if(result.code===0){
              message.warn(result.message)
            }
            if(result.result){
              window.location = result.result
            }
          }
        ).catch(e => {
          message.error(e)
        })
    }

    //环境产品同步
    const environmentsProduct = function (oldData) {
      environmentsRef.value.showDrawer(oldData)
    }
    init()
    const showPreview = (pId, pCode, flag) => {
      if (flag == 2) {
        getChannelName()
        productData.productCode = pCode
        state.isCopy = true
        return
      }
      // 文浩让先把代理人，渠道id这两个值写死
      // let agentId = 'b09e6904ca47461c8d9e88ac6328e642'
      let agentId = ''
      let staffNo = ''
      let agentCode = '1234567890123'
      // 直接交易链接的时候，固定赋值去通过后台校验

      if (process.env.NODE_ENV=== 'development') {
        agentId =  '2263d40832a3417ab7c973ccd15d1d41'
        staffNo = '2311000010'
        agentCode = '6257179510001'
      }else if(process.env.NODE_ENV === 'production'){
        agentId = '2216c44c70354e80aed08977aa3e0dfe'
        staffNo = '2141000067'
        agentCode =  '6696562530001'
      }
      let param = {
        agentId,
        staffNo,
        channel: 'web',
        channelUserId: '',
        productCode: pCode,
        agentCode
      }
      if (pId) {
        request(getExtendUri('createProductLink'), {
          data: param
        }).then(res => {
          console.log('res', JSON.stringify(res))
          if (res.code === 1 && res.result) {
            if (flag === 1) {
              jumpPage(res.result)
            } else {
              copyInformation(res.result)
            }
          } else {
            message.warning(res.message)
            console.warn(res.message)
          }
        }).catch(err => {
          console.error(err)
        })
      }
    }
    const jumpPage = (res) => {
      const lastIndex = res.split('?')[0].lastIndexOf('/')
      const url = res.split('?')[0].substring(lastIndex+1)
      const params = getUrlParams(res)
      console.log('param', params, url)
      window.open(router.resolve({
        path: `/app/${url}`,
        query: params
      }).href, '_blank')
    }
    const filterOption = (input, option) => {
      return option.label.indexOf(input) > -1
    }
    const productRef = ref()
    const handleCopyOk = async () => {
      await productRef.value.validate()
      request(getExtendUri('createProductLink'), {
        data: productData
      }).then(res => {
        if (res.code === 1 && res.result) {
          copyInformation(res.result)
          state.isCopy = false
          productRef.value.resetFields()
          productRef.value.clearValidate()
        } else {
          message.warning(res.message)
        }
      })
    }
    const onCopyClose = () =>{
      productRef.value.resetFields()
      productRef.value.clearValidate()
    }
    const getChannelName = () => {
      let data = {}
      request(getRequestURI('channel_select'), { data }, true, 'get').then(result => {
        ChannelTypeList.value = result.result
      })
    }
    const ChannelTypeList = ref([])
    onMounted(() => {
    })
    return {
      productRef,
      getChannelName,
      handleCopyOk,
      ChannelTypeList,
      productData,
      filterOption,
      searchRef,
      resetAll,
      ...toRefs(state),
      handleSearch,
      onSelectChange,
      handleTableChange,
      addProduct,
      modifyProduct,
      userDrawerRef,
      environmentsRef,
      pagination,
      total,
      loading,
      dataSource,
      checkProduct,
      visible,
      handleOk,
      copyInformation,
      delMultiple,
      environmentsProduct,
      exportClick,
      changes,
      showPreview,
      searchfFilters,
      changTableList,
      productSkipAddressList,
      onCopyClose
    }
  },
})
</script>
