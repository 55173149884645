<template>
  <div>
    <search-form ref="searchRef" :Condition="condition" :ColSpan="12" @callback="handleSearch"></search-form>
    <div class="btn-bar" v-if="isUpdateNum != 1">
      <a-button danger @click="handelDelete">
        <template #icon>
          <DeleteOutlined/>
        </template>
        批量删除
      </a-button>
      <a-button type="primary" @click="handelSave">
        <template #icon>
          <FileAddOutlined/>
        </template>
        新增
      </a-button>
    </div>
    <a-table
      :columns="columns"
      :data-source="dataSource ? dataSource['result'] : []"
      :pagination="pagination"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :row-key="(record) => record.id"
      @change="handleTableChange"
      :loading="loading"
      style="margin-top: 10px"
    >
      <a-spin :delay="200"/>
      <template #bodyCell="{ column, index }">
        <template v-if="column.key === 'index'">
          {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
        </template>
      </template>
    </a-table>
    <div>
      <a-modal v-model:visible="templateShow" width="60%" title="产品渠道新增" @cancel="close" @ok="handleOk">
        <a-form ref="formRef" :model="channelData" :label-col="{ span: 10 }" :wrapper-col="{ span: 16 }">
          <a-row :gutter="10">
            <a-col span="8">
              <a-form-item label="渠道名称" name="channelName" :rules="[{ required: true, message: '请选择渠道名称' ,trigger: 'change'}]">
                <a-select
                  @change="handelChannelName"
                  show-search
                  allow-clear
                  :filter-option="filterOption"
                  v-model:value="channelData.channelName" placeholder="请选择">
                  <a-select-option :value="cn.value" v-for="cn in condition.channelId.data" :key="cn.id">{{cn.label}}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="8">
              <a-form-item label="渠道编码" name="channelCode">
                <a-input v-model:value="channelData.channelCode" disabled/>
              </a-form-item>
            </a-col>
            <a-col span="8">
              <a-form-item label="渠道类型" name="channelType">
                <a-input v-model:value="channelData.channelType" disabled/>
              </a-form-item>
            </a-col>
            <a-col span="8">
              <a-form-item label="手续费率" name="procedureRate" :rules="[{pattern:/^[0-9]/, message: '支持数字' ,trigger: 'change'}]">
                <a-input v-model:value="channelData.procedureRate" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-modal>
    </div>
  </div>

</template>

<script>
import {computed, defineComponent, onMounted, reactive, ref, toRefs} from 'vue'
import {getRequestURI, request, requestUsePagination} from '../../../utils/request'
import SearchForm from '../../../components/extended/search_form.vue'
import {message, Modal} from 'ant-design-vue'

export default defineComponent({
  name: 'ProductChannel',
  components: {
    SearchForm
  },
  props: {
    parentData: {
      type: Object,
      default: () => {
      }
    },
    isUpdateNum: {
        type: [String, Number]
      },
      // 产品ID
      supId:{
        type: String
      }
    },
    setup(props){
      const state = reactive({
        templateShow: false,
        newChannel: {},
        selectedRowKeys: [],
        isDisable: '',
        condition: {
          channelId: {
            label: '渠道名称',
            type: 'select',
            placeholder: '请选择渠道名称',
            data: []
          },
          channelType: {
            label: '渠道类型',
            type: 'select',
            placeholder: '请选择渠道类型',
            urlKey: 'queryDictionaryType',
            queryParam: {type: 'channel_type'}
          }
        },
        columns: [
          {
            title: '序号',
            key: 'index',
            fixed: 'left',
            width: 60,
            align: 'left',
          },
          {
            title: '渠道名称',
            width: 140,
            align: 'center',
            key: 'channelName',
            dataIndex: 'channelName',
          },
          {
            title: '渠道编号',
            width: 100,
            align: 'center',
            key: 'channelCode',
            dataIndex: 'channelCode',
          },
          {
            title: '渠道类型',
            width: 100,
            align: 'center',
            key: 'channelType',
            dataIndex: 'channelType',
            customRender: (text)=> getChannelTypeDetail(text.value)
          },
          {
            title: '手续费率',
            width: 100,
            align: 'center',
            key: 'procedureRate',
            dataIndex: 'procedureRate',
            customRender: (text)=> {
              if(text.value==='%'){
                return ''
              }else{
                return text.value
              }
            }
          },
        ]
      })
      const filterOption = (input, option) => {
      return option.value.indexOf(input) > -1
    }
      // 新增的渠道数据
      const channelData = reactive({
        channelName:'',
        channelCode:'',
        channelType:'',
        procedureRate: ''
      })
      const searchRef = ref()
      // 更加渠道类型 返回对应的数据
      const getChannelTypeDetail = (value) =>{
        if(!value) return
        for (let i = 0; i < searchRef.value.State.treeData['channelType'].length; i++) {
          if(value == searchRef.value.State.treeData['channelType'][i].value){
            return searchRef.value.State.treeData['channelType'][i].label
          }
        }
      }
      const pagination = computed(() => ({
        current: current.value,
        pageSize: pageSize.value,
        total: total.value
      }))
      const {
        data: dataSource,
        run,
        loading,
        current,
        pageSize,
        total,
      } = requestUsePagination(getRequestURI('productChannelRelationship_selectChannelForPage'))
      //查询
      const handleSearch = function (filters) {
        filters.id = props.parentData.id
        state.newChannel = filters
        run({
          pagination: {
            current: 1,
            pageSize: 10
          },
          data: filters
        })
      }
      const handleTableChange = (pag, filters, sorter) => {
        console.debug('===', filters)
        run({
          pagination: {
            current: pag?.current,
            pageSize: pag?.pageSize
          },
          sorter,
          data: state.newChannel,
        })
      }
      // 多选
      const onSelectChange = (selectedRowKeys) => {
        state.selectedRowKeys = selectedRowKeys
      }
      // 批量删除
      const handelDelete = () => {
        if(state.selectedRowKeys&&state.selectedRowKeys.length===0){
          message.warning('请先选择数据')
          return
        }

        Modal.confirm({
          title: '确认删除吗?',
          async onOk() {
            request(getRequestURI('productChannelRelationship_deleteChannel'),{data:{ids: state.selectedRowKeys}}).then(res =>{
              if(res.code === 1){
                message.success('删除成功')
                state.selectedRowKeys = []
                handleSearch(state.newChannel)
              }else{
                message.warning(res.message)
              }
            })
          },
          onCancel() {
            Modal.destroyAll()
          }
        })

      }
      // 新增
      const handelSave = () => {
        state.templateShow = true
      }
      const formRef = ref()
      // 渠道名称发生变化时
      const handelChannelName = (e) =>{
        if(!e) return
        let type = state.condition.channelId.data.filter(item => item.value === e)[0]
        channelData.channelCode = type.channelCode
        channelData.channelType = getChannelTypeDetail(type.channelType)

      }
      // 返回时调用清除
      const clearSearchInput = () => {
        searchRef.value.clearSearchInput(['channelName', 'channelType', 'procedureRate', 'channelId'])
        state.newChannel = {}
        state.selectedRowKeys = []
        dataSource.value = {}

      }
      const handleOk = async () => {
        if(Number(channelData.procedureRate)>=100||Number(channelData.procedureRate)<0){
          return message.warn('手续费率只支持大于等于0小于100的数字')
        }
        await formRef.value.validate()
        let data = {
          channelId: channelData.channelName,
          productId: props.parentData.id,
          procedureRate: channelData.procedureRate
        }
        request(getRequestURI('productChannelRelationship_addChannel'),{data}).then(res =>{
          if(res.code === 1){
            message.success('产品渠道新增成功')
            close()
            handleSearch(state.newChannel)
          }else{
            message.warning(res.message)
          }
        })
      }
      const close = () => {
        formRef.value.resetFields()
        formRef.value.clearValidate()
        state.templateShow = false
      }
      // 获取渠道名称
      const getChannelName = () =>{
        let data = {}
        request(getRequestURI('channel_select'),{data},true,'get').then(result =>{
          let arr = []
          result.result.forEach(item => {
            arr.push({id: item.id, value:item.id, label: item.channelName, channelType: item.channelType, channelCode: item.channelCode|| ''})
          })
          state.condition.channelId.data = arr
        })
      }
      onMounted(()=>{
        // searchRef.value.deleteAllM()
        getChannelName()
      })
      return {
        formRef,
        channelData,
        filterOption,
        searchRef,
        pagination,
        total,
        loading,
        dataSource,
        handleOk,
        getChannelTypeDetail,
        ...toRefs(state),
        onSelectChange,
        handelDelete,
        handelSave,
        getChannelName,
        clearSearchInput,
        handleSearch,
        handleTableChange,
        close,
        handelChannelName
      }
    }
  })
</script>

<style scoped>

</style>
