<template>
  <div>
    <a-drawer
        title="产品利益详情"
        :width="'68%'"
        :visible="visible"
        :body-style="{ paddingBottom: '80px' }"
        :footer-style="{ textAlign: 'right' }"
        @close="onClose"
    >
      <div>
        <div>
          <a-form
              layout="inline"
              class="demo-form-inline"
          >
            <a-form-item label="险种名称">
              {{ formulaDetailData.riskName }}
            </a-form-item>
            <a-form-item label="险种编码">
              {{ formulaDetailData.riskCode }}
            </a-form-item>
          </a-form>
        </div>
        <div>
        </div>
        <div class="btn-bar">
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button type="primary" @click="onSaveBenefit">
            <template #icon>
              <FileAddOutlined/>
            </template>
            新增
          </a-button>
          <a-button danger>
            <template #icon>
              <DeleteOutlined/>
            </template>
            批量删除
          </a-button>
        </div>
        <a-table
            :columns="columns"
            :data-source="dataSource ? getNewDataSource(dataSource['result'].list) : []"
            :scroll="{ x: 800 }"
            :row-key="(record) => record.id"
            :pagination="pagination"
            @change="handleTableChange"
            :loading="loading"
            style="margin-top: 10px"
        >
          <template #bodyCell="{ column, index, record }">
            <template v-if="column.key === 'index'">
              {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
            </template>
            <template v-if="column.key === 'operation'">
              <div>
                <a
                    style="color: orange; margin-right: 10px"
                    @click="onCheckBenefit(record)"
                >查看</a
                >&nbsp;&nbsp;
                <a
                    style="color: orange; margin-right: 10px"
                    @click="onEditBenefit(record)"
                >编辑</a
                >&nbsp;&nbsp;
                <a
                    style="color: orange; margin-right: 10px"
                    @click="onDeleteSingleBenefit(record)"
                >删除</a
                >&nbsp;
              </div>
            </template>
          </template>
        </a-table>

      </div>
      <template #extra>
        <a-space>
          <a-button @click="onClose">
            <template #icon>
              <CloseOutlined/>
            </template>
            关闭
          </a-button>
        </a-space>
      </template>
    </a-drawer>
    <FormulaConfigSave ref="formulaConfigSaveRef" :formulaDetailData="formulaDetailData"/>
  </div>

</template>
<script>
import {CloseOutlined, DeleteOutlined, FileAddOutlined} from '@ant-design/icons-vue'
import {computed, onMounted, reactive, ref, toRefs} from 'vue'
import {getRequestURI, request, requestUsePagination} from '../../../../utils/request'
import {message, Modal} from 'ant-design-vue'
import FormulaConfigSave from './FormulaConfigSave'
import {getDictionaryData} from '../../../../utils/request_extend.js'

export default {
  name: 'FormulaConfigMod',
  components: {
    DeleteOutlined,
    FileAddOutlined,
    CloseOutlined,
    FormulaConfigSave
  },
  setup() {
    const state = reactive({
      riskName: '',
      riskCode: '',
      visible: false,
      condition: {
        riskName: {
          label: '险种名称',
          placeholder: '请输入',
          type: 'input',
        },
        riskCode: {
          label: '险种编码',
          placeholder: '请输入',
          type: 'input',
        },
      },
      columns: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left',
        },
        {
          title: '利益演示名称(父)',
          width: 140,
          align: 'center',
          key: 'parentName',
          dataIndex: 'parentName',
        },
        {
          title: '利益演示名称',
          width: 100,
          align: 'center',
          key: 'guaranteeName',
          dataIndex: 'guaranteeName',
        },
        {
          title: '利益演示描述',
          width: 150,
          align: 'center',
          key: 'guaranteeDescription',
          dataIndex: 'guaranteeDescription',
        },
        {
          title: '责任类型',
          width: 150,
          key: 'isSelected',
          dataIndex: 'isSelected',
          customRender: (text) => {
            if (text.value === 'Y') {
              return '必选'
            } else {
              return '可选'
            }
          }
        },
        {
          title: '公式列表',
          width: 150,
          align: 'center',
          key: 'formulas',
          dataIndex: 'formulas',
          customRender: (formulas) => {
            // 如果 formulas 是数组，获取数组中每个对象的 name 字段
            if (Array.isArray(formulas.value)) {
              return formulas.value.map(item => (item.calculateTypeValue + item.fourOperationsLabel + item.calculateValue)).join(';')
            }
            return '无'
          }
        },
        {
          title: '操作',
          key: 'operation',
          ellipsis: true,
          align: 'center',
          fixed: 'right',
          width: 150,
          scopedSlots: {customRender: 'operation'}
        },
      ],
      lastFilter: {},
      formulaDetailData: {},
      conditionTypeList: []
    })
    const insuranceElements = reactive({
      factors: [],
      duties: []
    })
    const formulaConfigSaveRef = ref()
    const showDrawer = async (oldData, type) => {
      console.log(oldData, type)
      state.formulaDetailData = oldData
      state.visible = true
      getSelectFactorsAndDuties()
    }
    const getNewDataSource = (list) => {
      console.log('闸按钮-----闸按钮-----闸按钮-----闸按钮-----')
      list.forEach((obj) => {
        obj.formulas.forEach(item => {
          if (!item.calculateTypeValue) {
            item.calculateTypeValue = getCaculateTypeValue(item.calculateType)
          }
        })
      })
      return list
    }

    const getCaculateTypeValue = (type) => {
      const element = insuranceElements.factors.find(item => item.factorType === type)
      return element ? element.elementName : ''
    }

    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('selectGuarantee'))
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.log('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.lastFilter,
      })
    }
    // 存储搜索组件传过来的数据
    const searchFilters = ref({})
    const handleSearch = function (filters, num = '') {
      if (filters) {
        searchFilters.value = filters
      }
      state.lastFilter = filters
      run({
        pagination: {
          current: num ? num : 1,
          pageSize: 100
        },
        data: {
          code: state.formulaDetailData.riskCode
        },
      })
    }
    // 查看
    const onCheckBenefit = (record) => {
      formulaConfigSaveRef.value.showDrawer(state.formulaDetailData, record, 'check', state.conditionTypeList)

    }
    // 编辑
    const onEditBenefit = (record) => {
      formulaConfigSaveRef.value.showDrawer(state.formulaDetailData, record, 'edit', state.conditionTypeList)
    }
    const onSaveBenefit = () => {
      formulaConfigSaveRef.value.showDrawer(state.formulaDetailData, {}, 'edit', state.conditionTypeList)
    }
    //批量删除   删除
    const onDeleteSingleBenefit = (record) => {
      Modal.confirm({
        title: '确认删除吗?',
        async onOk() {
          let uri = getRequestURI('deleteGuarantee')
          request(uri, {
            data: {
              code: record.guaranteeId
            }
          }).then((result) => {
            if (result.code === 1) {
              message['success']('删除成功')
              state.data = []
              state.selectedRowKeys = []
              handleSearch({})
            } else {
              message['warning']('删除失败')
            }
          })
        }
      })
    }
    const onDeleteBenefit = () => {
      // let keys = state.data
      // if (keys.length === 0) {
      //   message.warning('请选择数据')
      //   return
      // }
      // let obj = []
      // state.data.forEach(item => {
      //   obj.push(item.id)
      // })
      // request(getRequestURI('deleteMoreGuarantee'), { data: { ids: obj } }).then(res => {
      //   if (res.code === 1) {
      //     Modal.confirm({
      //       title: '确认删除吗?',
      //       async onOk () {
      //         let uri = getRequestURI('productRisk_delete')
      //         request(uri, { data: state.data }).then(
      //           (result) => {
      //             if (result.code === 1) {
      //               message['success']('删除成功')
      //               state.data = []
      //               state.selectedRowKeys = []
      //               handleSearch({})
      //             } else {
      //               message['warning']('险种被使用')
      //             }
      //             console.log('批量删除的结果:', result)
      //           }
      //         )
      //       },
      //       onCancel () {
      //       }
      //     })
      //   } else {
      //     message.warning(res.message)
      //   }
      // })
    }

    const onClose = () => {
      state.riskName = ''
      state.riskCode = ''
      state.visible = false
    }

    // 获取投保要素类型 可选责任
    const getSelectFactorsAndDuties = () => {
      request(getRequestURI('selectFactorsAndDuties'), {data: {code: state.formulaDetailData.riskCode}}).then(res => {
        if (res.code === 1) {
          insuranceElements.duties = res.result.duties.map(item => {
            return {value: item.dutyCode, label: item.dutyName}
          })
          insuranceElements.factors = res.result.factors
          handleSearch()
        }
      })
    }
    onMounted(() => {
      getDictionaryData(state.conditionTypeList, 'condition_type')
    })
    return {
      ...toRefs(state),
      ...toRefs(insuranceElements),
      getNewDataSource,
      searchFilters,
      formulaConfigSaveRef,
      showDrawer,
      onClose,
      pagination,
      dataSource,
      loading,
      handleTableChange,
      handleSearch,
      run,
      current,
      pageSize,
      total,
      onCheckBenefit,
      onEditBenefit,
      onDeleteBenefit,
      onDeleteSingleBenefit,
      onSaveBenefit


    }
  }
}
</script>


<style scoped>
.demo-form-inline {
  margin: 20px;
}
</style>
