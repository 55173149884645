<style scoped>
</style>

<template>
  <a-drawer
    title="产品投保须知信息管理查看"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
    :destroyOnClose="isDestroy"
  >
    <search-form v-if="look" :Condition="condition" :ColSpan="12" @callback="handleSearch"></search-form>
  <div>
    <a-table
      :columns="columns"
      :data-source="list ? list['result'] : []"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
        type: 'radio',
        hideSelectAll: true
        }"
      :row-key="record => record.id"
      :pagination="false"
      @change="handleTableChange"
      :loading="loading"
    >
      <template #bodyCell="{ column, index }">
        <template v-if="column.key === 'index'">
          {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
        </template>
      </template>
    </a-table>
  </div>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
        关闭
        </a-button>
      </a-space>
    </template>
  </a-drawer>
  <addUpdateDrawer ref="addDrawer"></addUpdateDrawer>
</template>

<script>
import {CloseOutlined} from '@ant-design/icons-vue'
import addUpdateDrawer from './addUpdateDrawer.vue'
import {defineComponent, reactive, toRefs, ref,computed} from 'vue'
import {getRequestURI,requestUsePagination} from '../../../utils/request'
import SearchForm from '../../../components/extended/search_form'
// import{Modal,message} from 'ant-design-vue'

export default defineComponent({
  components: {
    CloseOutlined,
    SearchForm,
    addUpdateDrawer
  },

  setup(props, {expose}) {
    const addDrawer = ref()
    const state = reactive({
      list: [],
      isDestroy: false,
      look: false,
      condition: {
          informationType: {
            label: '信息类型',
            type: 'select',
            default: '1',
            data : [
              {value: 1, label: 'HealthyShow-健康须知',},
              {value: 2, label: 'ClaimProcess-理赔流程',},
              {value: 3, label: 'CommonProblem-常见问题',},
              {value: 4, label: 'InsureTips-投保提示',},
            ]
          }
      },
      columns: [
        {
          title: '序号',
          width: 80,
          align: 'center',
          key: 'index',
          dataIndex: 'index'
        },
        {
          title: '信息类型',
          width: 140,
          align: 'center',
          key: 'informationTypeSign',
          dataIndex: 'informationTypeSign'
        },
        {
          title: '信息类型名称',
          width: 140,
          align: 'center',
          key: 'informationTypeSignName',
          dataIndex: 'informationTypeSignName'
        },
        {
          title: '显示方式',
          width: 250,
          align: 'center',
          key: 'displayTypeName',
          dataIndex: 'displayTypeName'
        },
      ],
      selectedRowKeys: [],  // 获取勾选框的数据
      loading: false,
      visible: false,
      isNew: true,
      transferStyle: {width: '50%'},
      newFilters: {},
      productInfoId: 0
    })
     const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('productInsureTotice_selectListForPageOne'))
    state.list = dataSource

    const onSelectChange = (selectedRowKeys,selectedRows) => {
      state.selectedRowKeys = selectedRowKeys
      addDrawer.value.showDrawer(selectedRows)
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const handleTableChange = (pag, filters, sorter) => {
      run({
        current: pag?.current,
        pageSize: pag?.pageSize,
        sorter,
        data: state.newFilters,
      })
    }
    const handleSearch = function (filters) {
      state.selectedRowKeys = []
      state.newFilters = {...filters, id: state.productInfoId}
      run({
        current: current.value,
        pageSize: pageSize.value,
        data:{...filters,id: state.productInfoId},
      })
    }
    const showDrawer = (oldData) => {
      state.look = true
      state.productInfoId = oldData.id
      state.visible = true
    }
    const onClose = () => {
      state.look = false
      state.visible = false
      state.condition.informationType.data = []
      state.selectedRowKeys = []
      state.isDestroy = true
      state.list = []
    }



    expose({
      showDrawer
    })

    return {
      ...toRefs(state),
      addDrawer,
      onSelectChange,
      handleTableChange,
      handleSearch,
      onClose,
      dataSource,
      pagination,
      loading,
    }
  },

})
</script>
