<style scoped>
.bg {
  margin-bottom: 20px;
}

.upload-file {
  /* display: flex;
  justify-content: flex-start; */
}

.file-up {
  /*width: 60%;*/
  /*position: relative;*/
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.download-file {
  /*position: absolute;*/
  /*left: 300px;*/
  /*bottom: 0;*/
  width: 65px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background: rgb(86, 56, 232);
  color: aliceblue;
  font-size: 12px;
}
.none{
  pointer-events:none;
  background: #f3f3f3;
}
</style>
<template>
  <a-drawer
    :title="isNew ? '通用素材编辑' : '通用素材新增'"
    :width="'50%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
   <a-form ref="formRef" :model="sourceData" labelAlign="left" :label-col="{ span: 3 }" :wrapper-col="{ span: 18 }">
     <a-row :gutter="10">
       <a-col :span="24">
         <a-form-item label="素材类型" name="summaryType" required>
           <a-select
             class="inputWidth"
             v-model:value="sourceData.summaryType"
             placeholder="请选择素材类型"
             :options="summaryTempList"/>
         </a-form-item>
       </a-col>
       <a-col :span="24" v-if="sourceData.summaryType === 'thank_you_config'">
         <a-form-item label="致谢函示例" name="summaryType" required>
           <br/>
           <p>尊敬的 ${userName}先生/女士:</p>
           <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;首先，感谢您对本公司以及我的信任，让我能有这样的机会为您提供专业化的保险服务，让我深感荣幸。</p>
           <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;根据您及您的家人目前的实际情况以及未来的保险需求，我专门为您设计了这份保险计划相信这份计划能够为您及您的家人提供需要的保障。</p>
           <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;如果您觉得这份保险计划还有什么不完善的地方，请您告诉我。我会根据您的意见进行相应的修改，确保这份计划能够完全满足您的需求。</p>
           <p>
             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;最后，衷心希望您能够抽出一些宝贵时间认真阅读这份保险计划。也许您会发现，这就是最适合您的那一份。
           </p>
           <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;恭祝</p>
           <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;身体健康，万事如意!</p>
           <p style="text-align: right">销售员 ${agentName} 敬</p>
           <p style="text-align: right">${newDate}</p>

         </a-form-item>
       </a-col>
       <a-col :span="24">
         <a-form-item label="素材名称" name="summaryName" required>
           <a-input v-model:value="sourceData.summaryName" placeholder="请输入素材名称"></a-input>
         </a-form-item>
       </a-col>
       <a-col :span="24">
         <a-form-item label="文章标题" name="mainTitle" required>
           <a-input v-model:value="sourceData.mainTitle" placeholder="请输入文章标题"></a-input>
         </a-form-item>
       </a-col>
       <a-col :span="24">
         <a-form-item label="文章副标题" name="secondaryTitle">
           <a-input v-model:value="sourceData.secondaryTitle" placeholder="请输入文章副标题"></a-input>
         </a-form-item>
       </a-col>
       <a-col :span="24" v-if="visible">
         <a-form-item label="内容模板" name="text" required>
           <wang-editor ref="editorRef" :contentHtml="sourceData.text" v-on:getWangEditorValue="getWangEditorValue"></wang-editor>
         </a-form-item>
       </a-col>
     </a-row>

   </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button type="primary" v-if="!isCheckInfo" @click="onSave">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>
<script>
import {CloseOutlined, SaveOutlined} from '@ant-design/icons-vue'
//,inject
import {defineComponent, nextTick, onMounted, reactive, ref, toRefs} from 'vue'
import {getDictionaryData} from '@/utils/request_extend'
import WangEditor from '@/components/extended/Editor'
import {getRequestURI, request} from '@/utils/request'
import {message} from 'ant-design-vue'
// import {convertRichTextToImage} from '../../../utils/rich_to_image.js'

export default defineComponent({
  components: {
    CloseOutlined,
    SaveOutlined,
    WangEditor
  },
  props: {
    handleMainSearch: {type: Function},
    searchFilters: {type: Object},
    current:{type: Number}
  },

  setup(props) {
    const state = reactive({
      sourceData: {
        id: null,
        summaryType: null,
        mainTitle: '',
        secondaryTitle: '',
        text: '',
        summaryName: ''
      },
      isCheckInfo: false,
      visible: false,
      isNew: false,
      summaryTempList: []
    })
    const menusList = ref([
      'head',
       'bold', //字体加粗
      'fontSize',//字号
      'fontName',//字体
      // 'italic',
      'underline',//下划线
      'strikeThrough',//删除线
      'indent',
      // 'lineHeight',
      // 'foreColor',
      // 'backColor',
      // 'link',
      //   'list',//列表
      // 'justify',//对其
      // 'quote',// 引用
      //  'emoticon',
      // 'video',//视频
      // 'table',//表格
      // 'code',
      // 'splitLine',
      'image',
      'undo',//撤销
      'redo',//恢复
    ])
    const sourceMaterialList = ref()
    const editorRef = ref()
    const formRef = ref()

    const showDrawer = (flag, data = {}, list = []) => {
      console.debug('操作类型：', flag, list)
      if (flag === 'edit') {
        state.summaryTempList = sourceMaterialList.value.filter(item => item.value === data.summaryType)
        console.log('操作类型 - eidt - list', state.summaryTempList)
      } else {
        state.summaryTempList = sourceMaterialList.value.filter(item => list.includes(item.value))
      }
      if(data.id){
        state.isNew = true
        Object.keys(data).forEach((key)=>{
          state.sourceData[key] = data[key]
        })
        console.log(data, editorRef)
      }
      state.visible = true
    }

    const getWangEditorValue = (str) => {
      state.sourceData.text = str
    }

    const onSave = async () =>{
      await formRef.value.validate()
      // 富文本转图片
      // convertRichTextToImage().then(res => {
      let res = ''
        console.debug(res)
        let param = {...state.sourceData, base64Text: res}
        request(getRequestURI('saveTemplateSummary'), {data: param}).then(res => {
          if (res.code === 1) {
            message.success(res.message)
            onClose()
            props.handleMainSearch(props.searchFilters, props.current)
          } else {
            message.error(res.message)
          }
        })
      // }).catch(err => {
      //   console.error(err)
      // })
    }

    const onClose = () =>{
      // editorRef.value.onClear()
      formRef.value.resetFields()
      formRef.value.clearValidate()
      Object.assign(state, {
        sourceData: {
          id: null,
          summaryType: null,
          mainTitle: '',
          secondaryTitle: '',
          text: '',
          summaryName: ''
        },
        isCheckInfo: false,
        isNew: false,
        visible: false
      })

    }
    onMounted(()=>{
      getDictionaryData(sourceMaterialList, 'general_materials')
      nextTick(()=>{
      })
    })
    return {
      ...toRefs(state),
      sourceMaterialList,
      editorRef,
      menusList,
      formRef,
      onClose,
      showDrawer,
      onSave,
      getWangEditorValue,
    }
  }
})
</script>
