<template>
	<upload-file ref="clearName"  filetype="excel" @fileChange="onFileUpload"></upload-file>
	<span style="color: red;font-size: smaller">填入字段要和投保要素定义范围严格一致，否则导入不成功!</span>
</template>

<script>
import { message } from 'ant-design-vue'
import { defineComponent, reactive, toRefs } from 'vue'
import UploadFile from '../../../components/extended/uploadFile.vue'
import { getRequestURI, request } from '../../../utils/request'

export default defineComponent({
	components:{
		UploadFile
	},
	props:{
		riskId:{
			type: [Number,String],
			default: () => null
		}
	},
  emits:['select'],
	setup(props, {expose, emit}){
		const state = reactive({
			riskId: props.riskId,
			fileUrl:undefined
		})
		// 导入文件的路径
		const onFileUpload = (file) =>{
			state.fileUrl = file.url
      console.log(state)
    }

		const importFileAdd = () =>{
			console.log('上传文件', state.riskId,state.fileUrl)
			request(getRequestURI('productRelation_importExcel'), {riskId: state.riskId, url: state.fileUrl})
			.then(res =>{
				if(res.code === 1){
					message.success(res.message)
          emit('select')
					importFileClose()
				} else{
					message.warning(res.message)
				}
			})
		}
		const importFileClose = () => {
			state.riskId = undefined
			state.fileUrl = undefined
		}
    expose({
      importFileAdd,
      importFileClose
    })
		return {
			...toRefs(state),
			importFileClose,
			onFileUpload,
			importFileAdd
		}
	}
})

</script>

<style scoped>

</style>
