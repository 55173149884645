<template>
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @callback="handleSearch"
  ></search-form>
  <div class="btn-bar">
    <a-button type="primary" @click="addUser('newAdd')">
      <template #icon>
        <FileAddOutlined />
      </template>
      新增
    </a-button>
<!--    <a-button danger @click="deleteUser">-->
<!--      <template #icon>-->
<!--        <DeleteOutlined />-->
<!--      </template>-->
<!--      批量删除-->
<!--    </a-button>-->
  </div>
  <a-table
    :columns="columns"
    :data-source="dataSource ? dataSource['result'] : []"
    :scroll="{ x: 800 }"
    :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }"
    :row-key="(record) => record.id"
    :pagination="pagination"
    @change="handleTableChange"
    :loading="loading"
  >
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <div>
          <a
            style="color: orange; margin-right: 10px"
            @click="modifyUser(record, 'edit')"
          >编辑</a
          >&nbsp;&nbsp;
          <a style="color: orange" @click="deleteUser(record,index)">删除</a>&nbsp;&nbsp;
        </div>
      </template>
    </template>
  </a-table>
  <generalConfigEditInfo ref="generalConfigRef" @handleSearch="handleSearch(searchFilters, pagination)"></generalConfigEditInfo>
</template>

<script>

import { computed, defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import { getRequestURI, request, requestUsePagination } from '@/utils/request'
import SearchForm from '../../../components/extended/search_form'
import { Modal, message } from 'ant-design-vue'
import {FileAddOutlined} from '@ant-design/icons-vue'
import GeneralConfigEditInfo from './generalConfigEditInfo.vue'
import {getDictionaryValue} from '@/utils/request_extend'
export default defineComponent({
  components: {
    GeneralConfigEditInfo,
    SearchForm,
    FileAddOutlined
  },

  setup () {
    onMounted(() => {
    })
    const generalConfigRef = ref()
    const state = reactive({
      condition: {
        generalConfigName: '通用模板名称',
      },
      columns: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left',
        },
        {
          title: '模板名称',
          width: 90,
          align: 'center',
          key: 'templateName',
          dataIndex: 'templateName',
        },
        {
          title: '创建时间',
          width: 90,
          align: 'center',
          key: 'createTime',
          dataIndex: 'createTime',
        },
        {
          title: '是否配置代理人',
          width: 90,
          align: 'center',
          key: 'haveAgent',
          dataIndex: 'haveAgent',
          customRender: (text) => getDictionaryValue('yes_no', text.value)
        },
        {
          title: '操作',
          key: 'operation',
          ellipsis: true,
          align: 'center',
          fixed: 'right',
          width: 140,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      selectedRowKeys: [],
      lastFilter: {},
      // 删除data
      data: [],
    })
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('templateList'))
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.log('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.lastFilter,
      })
    }
    //多选
    const onSelectChange = (selectedRowKeys, selectedRow) => {
      state.data.splice(0)
      state.selectedRowKeys = selectedRowKeys
      state.data = selectedRow

    }

    // 存储搜索组件传过来的数据
    const searchFilters = ref({})
    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    const handleSearch = function (filters, page = null) {
      if (filters) {
        searchFilters.value = filters
      }
      state.lastFilter = filters
      run({
        pagination: {
          current: page ? page.current : 1,
          pageSize: 10
        },
        data: filters,
      })
    }

    //新增
    const addUser = function () {
      generalConfigRef.value.showDrawer()
    }
    //编辑
    const modifyUser = function (record) {
      request(getRequestURI('templateDetail'), {data: {code:record.templateCode }}).then(res =>{
        if(res.code === 1){
          generalConfigRef.value.showDrawer(res.result)
        }else{
          message.warning(res.message)
        }
      })

    }

    // 删除
    const deleteUser = (data) => {
      Modal.confirm({
        title: '确认删除吗?',
        async onOk () {
          let uri = getRequestURI('deleteTemplate')
          request(uri, { data: {code: data.templateCode} }).then(
            (result) => {
              if (result.code === 1) {
                message['success']('删除成功')
                handleSearch({})
              } else {
                message['warning'](result.message)
              }
            }
          )
        },
        onCancel () {
        }
      })
    }


    return {
      ...toRefs(state),
      handleSearch,
      addUser,
      generalConfigRef,
      deleteUser,
      modifyUser,
      onSelectChange,
      pagination,
      dataSource,
      loading,
      handleTableChange,
      run,
      current,
      pageSize,
      total,
      searchFilters
    }
  },
})
</script>

<style scoped>
</style>
