<template>
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @callback="handleSearch"
  ></search-form>
  <a-table
    :columns="columns"
    :data-source="dataSource ? dataSource['result'] : []"
    filterMultiple="false"
    :row-key="(record) => record.id"
    :pagination="pagination"
    @change="handleTableChange"
    :loading="loading"
  >
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <div>
          <a
            style="color: orange; margin-right: 10px"
            @click="onViewDetails(record)"
          >查看</a
          >&nbsp;&nbsp;
          <a
            style="color: orange; margin-right: 10px"
            @click="handleDownload(record)"
          >下载</a
          >&nbsp;&nbsp;
        </div>
      </template>
    </template>
  </a-table>
  <checkPlanbook ref="checkPlanbookRef"></checkPlanbook>
</template>

<script>

import { computed, defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import { getRequestURI, requestUsePagination } from '@/utils/request'
import SearchForm from '../../../components/extended/search_form'
import {message} from 'ant-design-vue'
import  checkPlanbook from './checkPlanbook'
export default defineComponent({
  components: {
    SearchForm,
    checkPlanbook,
  },
  setup () {
    const userDrawerRef = ref()
    const checkPlanbookRef = ref()
    const state = reactive({
      condition: {
        supplierId: {
          label: '供应商名称',
          type: 'tree-select',
          urlKey: 'querySupplier',
          value: 'id',
          default: '',
          data: []
        },
        productId: {
          label: '产品名称',
          type: 'select',
          default: '',
          data: []
        },
        riskCustomer: {
          label: '客户名称',
        },
        riskTypeLargeCode: {
          label: '建议书名称',
          // type: 'select',
          // default: '',
          // urlKey: 'queryDictionaryType',
          // queryParam: { type: 'risk_type' }
        },
        startTime: {
          label: '创建开始时间',
          type: 'date-picker'
        },
        endTime: {
          label: '创建结束时间',
          type: 'date-picker'
        },
      },
      status: '',
      result: [],
      query: {
        data: {
          riskProduct: '',
          productId: '',
          supplierId: '',
          riskCustomer: '',
          riskTypeLargeCode: '',
          supRiskCode: '',
          startTime: '',
          endTime: ''
        }

      },
      columns: [
        {
          title: '序号',
          key: 'index',
          align: 'center',
          width: 60,
        },
        {
          title: '客户名称',
          width: 90,
          align: 'center',
          key: 'assuredNameList',
          dataIndex: 'assuredNameList',
          customRender: (text) => text.value.join(',').toString()
        },
        {
          title: '建议书名称',
          width: 90,
          align: 'center',
          key: 'prospectusName',
          dataIndex: 'prospectusName',
        },
        {
          title: '创建时间',
          width: 90,
          align: 'center',
          key: 'createTime',
          dataIndex: 'createTime',
        },
        {
          title: '操作',
          key: 'operation',
          ellipsis: true,
          align: 'center',
          width: 140,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      selectedRowKeys: [],
      lastFilter: {},
      // 删除data
      data: [],
    })
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('prospectusList'))
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.log('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.lastFilter,
      })
    }
    //多选
    const onSelectChange = (selectedRowKeys, selectedRow) => {
      state.data.splice(0)
      state.selectedRowKeys = selectedRowKeys
      state.data = selectedRow

    }

    // 存储搜索组件传过来的数据
    const searchFilters = ref({})
    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    const handleSearch = function (filters, num = '') {
      // 验证开始时间和结束时间
      if (filters && filters.startTime && filters.endTime) {
        const startTime = parseDate(filters.startTime)
        const endTime = parseDate(filters.endTime)
        if (!startTime || !endTime) {
          message.warning('请输入有效的开始时间和结束时间')
          return
        }
        if (startTime > endTime) {
          message.warning('开始时间不能晚于结束时间')
          return
        }
      }
      if (filters) {
        searchFilters.value = filters
      }
      state.lastFilter = filters
      run({
        pagination: {
          current: num ? num : 1,
          pageSize: 10
        },
        data: filters,
      })
    }
    const parseDate = (dateString) =>{
      const parsedDate = new Date(dateString)
      return !isNaN(parsedDate.getTime()) ? parsedDate : null
    }
    const handleDownload = (data) => {
      window.location.href = data.pdfUrl
    }

    //查看
    const onViewDetails = (data) => {
      checkPlanbookRef.value.showDrawer(data)
    }

    return {
      ...toRefs(state),
      onViewDetails,
      handleSearch,
      userDrawerRef,
      checkPlanbookRef,
      onSelectChange,
      pagination,
      dataSource,
      loading,
      handleTableChange,
      run,
      current,
      pageSize,
      total,
      handleDownload,
      searchFilters
    }
  },
})
</script>

<style scoped>
</style>
