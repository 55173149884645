<style scoped>
</style>

<template>
  <a-drawer
    :title="isNew"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-button danger @click="butdelet" style="margin-bottom:15px">
      <template #icon>
        <DeleteOutlined/>
      </template>
      批量删除
    </a-button>&nbsp;
    <a-button type="primary" @click="addindex(record,1)">
      <template #icon>
        <SaveOutlined/>
      </template>
      新增
    </a-button>&nbsp;
    <a-button danger @click="handelexportFile">
      <template #icon>
        <VerticalAlignBottomOutlined/>
      </template>
      下载模板
    </a-button>&nbsp;
    <a-button primary @click="handelImportFile">
      <template #icon>
        <to-top-outlined/>
      </template>
      导入
    </a-button>
    <div>
      <a-table :columns="columns"
               :data-source="dataSource ? dataSource['result'] : []"
               :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChanges }"
               :row-key="record => record.id"
               :pagination="pagination "
               @change="handleTableChange"
               :loading="loading"
               :scroll="{ x: 1500 }"
      >
        <template #bodyCell="{ column, index, record }">
          <template v-if="column.key === 'index'">
            {{ index + 1 }}
          </template>
          <template v-if="column.key === 'operation'">
            <a-button type="text" @click="modifyUser(record,2)" size="small" style="margin-right: 10px; color: orange;">
              修改
            </a-button>
          </template>
        </template>
      </a-table>
    </div>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <!-- <a-button type="primary" @click="onSave" >
          <template #icon>
            <SaveOutlined/>
          </template>
           提交
        </a-button> -->
      </a-space>
    </template>
  </a-drawer>
  <insurance ref="addddDrawerRef" :riskId="data.riskId" :handleSearch="handleSearch"></insurance>
  <!-- :footer="false"   style="width: 800px"-->
  <a-modal v-model:visible="importShow" title="导入文件" destroyOnClose @ok="handelOk" @cancel="handelClose">
    <ImportFile ref="fileData" @select="handleSearch1" :riskId="query.data.riskId"/>
  </a-modal>
</template>
<script>
import {CloseOutlined, SaveOutlined, DeleteOutlined} from '@ant-design/icons-vue'
import {defineComponent, reactive, toRefs, ref, computed} from 'vue'
import {getRequestURI, request, requestUsePagination} from '../../../utils/request'
import {Modal, message} from 'ant-design-vue'
import insurance from './insurance'
import ImportFile from './importFile'
// import AddShoulderList from './addShoulderList'

export default defineComponent({
  components: {
    CloseOutlined, SaveOutlined, DeleteOutlined, insurance, ImportFile
  },
  props: {
    oldDataId: {
      type: String,
      default: ''
    }
  },
  setup(props, {expose}) {
    const formRef = ref()
    const addddDrawerRef = ref()
    const addShoulderListRef = ref()
    const formData = reactive({
      elementType1: [],
      elementType2: [],
      calculated1: [],
      calculated2: [],
      calculatedValue1: [],
      calculatedValue2: []
    })
    const data = reactive({
      calculated1: undefined,
      calculated2: undefined,
      calculatedValue1: undefined,
      calculatedValue2: undefined,
      elementType1: undefined,
      elementType2: undefined,
      id: 0,
      riskId: 0,
      sort: undefined
    })
    const state = reactive({
      elementTypeList: [],
      dataLis: {},
      importShow: false, // 导入文件
      haoshou: false,//表格显示
      elementType: [],
      type: '',
      selectedRowKeys: [],
      result: [],
      edit: true,
      visible: false,
      isNew: '投保要素值关联',
      columns: [
        {
          title: '序号',
          width: 60,
          fixed: 'left',
          key: 'index',
          dataIndex: 'index'
        },
        {
          title: '投保要素类别1',
          width: 140,
          align: 'center',
          key: 'elementType1',
          dataIndex: 'elementType1',
          customRender: (text)=>getElementType(text.value)
        },
        {
          title: '投保要素计算值1',
          width: 140,
          align: 'center',
          key: 'calculated1',
          dataIndex: 'calculated1'
        },
        {
          title: '投保要素显示值1',
          width: 140,
          align: 'center',
          key: 'calculatedValue1',
          dataIndex: 'calculatedValue1'
        },
        {
          title: '投保要素类别2',
          width: 140,
          align: 'center',
          key: 'elementType2',
          dataIndex: 'elementType2',
          customRender: (text)=>getElementType(text.value)
        },
        {
          title: '投保要素计算值2',
          width: 140,
          align: 'center',
          key: 'calculated2',
          dataIndex: 'calculated2'
        },
        {
          title: '投保要素显示值2',
          width: 140,
          align: 'center',
          key: 'calculatedValue2',
          dataIndex: 'calculatedValue2'
        },
        {
          title: '排列序号',
          width: 140,
          align: 'center',
          key: 'sort',
          dataIndex: 'sort'
        },
        {
          title: '操作',
          key: 'operation',
          fixed: 'right',
          align: 'center',
          width: 160
        }
      ],
      query: {
        data: {
          calculated1: '',
          calculated2: '',
          calculatedValue1: '',
          calculatedValue2: '',
          elementType1: '',
          elementType2: '',
          id: 0,
          riskId: 0,
          sort: 0
        },
        pagination: {
          current: 1,
          pageSize: 10,
          total: 0
        },
        sorters: [
          {
            field: '',
            order: ''
          }
        ]
      }

    })
    // 修改投保要素类别
    const getElementType = (value) => {
      state.elementTypeList
      if(!value) return
        for (let i = 0; i < state.elementTypeList.length; i++) {
          if(value == state.elementTypeList[i].elementType){
            return state.elementTypeList[i].elementTypeName
          }
        }
    }



    // 导入按钮
    const handelImportFile = () => {
      console.debug(state.query.data.riskId) // id 值
      state.importShow = true
    }

    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value
    }))
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total
    } = requestUsePagination(getRequestURI('tbys_caclist'))

    const handleSearch = function (filters) {
      filters = state.query.data
      console.debug('搜索参数==', filters)
      run({
        pagination: {
          current: 1,
          pageSize: 10
        },
        data: filters
      })
    }
    // 页面发生改变回调
    const handleTableChange = (pag, filters, sorter) => {
      filters = state.query.data
      console.debug('===', filters)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        data: filters,
        sorter
      })
    }
    const handleSearch1 = function () {
      state.importShow = false
      handleSearch({})
    }
    const fileData = ref()
    // 导入确定
    const handelOk = () => {
      // state.importShow = false
      fileData.value.importFileAdd()
    }
    // 导入取消
    const handelClose = () => {
      fileData.value.importFileClose()
    }


    // 增加
    const addindex = function (oldData, num) {

      data.riskId = state.query.data.riskId
      addddDrawerRef.value.showDrawer(data.riskId, num)
    }
    // // 页面列表初始化
    // const handleSearch = function () {
    //     request(getRequestURI('tbys_caclist'), state.query).then(res=>{
    //     state.list = res.result
    //     console.debug(res)
    //      })
    //     }
    // 投保要素值下拉框
    const elementType = (oldData) => {
      console.debug(oldData)
      try {
        request(getRequestURI('tbys_category'), {data: {id: oldData.id}}).then(res => {
          state.elementTypeList = res.result
          formData.elementType1 = res.result.elementType
          formData.elementType2 = res.result.elementType
        })
      } catch (error) {
        console.debug(error)
      }
    }
    const next = () => {
      console.debug(666666666)
      nextlist()
    }
    const next2 = () => {
      console.debug(666666666)
      nextlist2()
    }
    // chang事件2
    const nextlist2 = () => {
      try {
        request(getRequestURI('tbys_show'), {
          data: {
            elementType: data.elementType2,
            riskId: state.query.data.riskId
          },
          pagination: {
            current: 1,
            pageSize: 10,
            total: 0
          },
          sorters: [
            {
              field: '',
              order: ''
            }
          ]
        }).then(res => {
          //  formData.calculated1 = res.result
          formData.calculated2 = res.result
          //  formData.calculatedValue1 = res.result
          formData.calculatedValue2 = res.result
          console.debug(res)
        })
      } catch (error) {
        console.debug(error)
      }
    }
    const handelexportFile = function () {
      const uri = getRequestURI('productElement_downloadd')
      request(uri, {dataNum: '1'}).then(res => {
        window.location = res.result
      })
    }
    // 下拉框change事件
    const nextlist = () => {
      try {
        request(getRequestURI('tbys_show'), {
          data: {
            elementType: data.elementType1,
            riskId: state.query.data.riskId
          },
          pagination: {
            current: 1,
            pageSize: 10,
            total: 0
          },
          sorters: [
            {
              field: '',
              order: ''
            }
          ]
        }).then(res => {
          formData.calculated1 = res.result
          //  formData.calculated2 = res.result
          formData.calculatedValue1 = res.result
          //  formData.calculatedValue2 = res.result
          console.debug(res)
        })
      } catch (error) {
        console.debug(error)
      }
    }
    const showDrawer = (oldData) => {
      elementType(oldData)
      state.query.data.riskId = oldData.riskId
      data.riskId = oldData.riskId
      state.query.data.riskId = oldData.id
      handleSearch({})
      state.visible = true
    }
    // 关闭
    const onClose = () => {
      state.visible = false
      data.calculated1 = undefined,
        data.calculated2 = undefined,
        data.calculatedValue1 = undefined,
        data.calculatedValue2 = undefined,
        data.elementType1 = undefined,
        data.elementType2 = undefined,
        data.id = 0,
        data.riskId = 0,
        data.sort = undefined,
        state.visible = false
      state.selectedRowKeys = []
      state.result = []
      //  formRef.value.clearValidate()
    }
    // 修改
    const modifyUser = (oldData, num) => {
      addddDrawerRef.value.showDrawer(oldData, num)
      state.haoshou = false
      Object.assign(data)
      state.edit = false
    }
//  删除
    const butdelet = () => {
      let keys = state.selectedRowKeys
      if (keys.length === 0) {
        message.warning('请选择数据')
        return
      }
      Modal.confirm({
        title: '确认删除吗?',
        async onOk() {

          // visible.value = true
          request(getRequestURI('tbys_gldel'), {
            data: {
              ids: keys
            },
            pagination: {
              current: 1,
              pageSize: 10,
              total: 0
            },
            sorters: [
              {
                field: '',
                order: ''
              }
            ]
          }, true, 'post').then(
            () => {
              // console.debug('批量删除的结果:', result)
              // state.selectedRowKeys = []
              // handleSearch()
              state.selectedRowKeys = []
              message.success('删除成功')
              state.result = []
              handleSearch({})
            }
          )
        },
        onCancel() {
        }
      })
    }
    const onSelectChanges = selectedRowKeys => {
      console.debug('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
    }
    expose({
      showDrawer
    })
    return {
      handelClose,
      fileData,
      handelImportFile,
      handelOk,
      next,
      next2,
      nextlist,
      nextlist2,
      handleSearch,
      butdelet,
      formRef,
      data,
      formData,
      ...toRefs(state),
      onClose,
      // onSave,
      handleSearch1,
      modifyUser,
      onSelectChanges,
      elementType,
      addddDrawerRef,
      addindex,
      handelexportFile,
      pagination,
      dataSource,
      loading,
      handleTableChange,
      addShoulderListRef,

    }
  }

})
</script>
