<style scoped>
</style>

<template>
  <a-drawer
    title="产品投保须知信息管理"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
    :destroyOnClose="true"
  >
    <search-form v-if="look" :Condition="condition" :ColSpan="12" @callback="handleSearch"></search-form>
    <a-button danger @click="delInformation" >
      <template #icon>
        <DeleteOutlined/>
      </template>
      批量删除
    </a-button>
    <a-button type="primary" style="margin-left: 10px; margin-bottom: 10px"  @click="openSave(data.productInfoId, 0)" >
      <template #icon>
        <FileAddOutlined/>
      </template>
      新增
    </a-button>
        <div>
          <a-table
            :columns="columns"
            :data-source="list ? list['result'] : []"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            :row-key="record => record.id"
            :pagination="false"
            @change="handleTableChange"
            :loading="loading"
  >
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <a-button type="text" @click="handelUpdate(record, 1)" size="small" style="margin-right: 10px; color: orange;">
          修改
        </a-button>
      </template>
    </template>
  </a-table>
  </div>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
      </a-space>
    </template>
  </a-drawer>
  <addUpdateDrawer :handleSearch="handleSearch" ref="addDrawer"></addUpdateDrawer>
</template>

<script>
import {CloseOutlined,} from '@ant-design/icons-vue'
import {defineComponent, reactive, toRefs, ref,computed} from 'vue'
import {getRequestURI,request,requestUsePagination} from '../../../utils/request'
import SearchForm from '../../../components/extended/search_form'
import addUpdateDrawer from './addUpdateDrawer.vue'
import { message, Modal } from 'ant-design-vue'

export default defineComponent({
  components: {
    CloseOutlined, SearchForm, addUpdateDrawer
  },

  setup(props, {expose}) {
    const addDrawer = ref()
    const data = reactive({
      productInfoId: 0,
      informationType: undefined,
      displayTypeName: undefined,
      displayInformation: undefined,
    })
    const formData = reactive({
      //信息类型
      informationType: [
        {id: 1, value: 'HealthyShow-健康须知'},
        {id: 2, value: 'ClaimProcess-理赔流程'},
        {id: 3, value: 'CommonProblem-常见问题'},
        {id: 4, value: 'InsureTips-投保提示'},
      ],
      //显示方式
      displayTypeName: [
        {id: 1, value: '不显示'},
        {id: 2, value: '弹出'},
        {id: 3, value: '内嵌'},
      ],
      displayMode: '',
      //显示信息
      displayInformation: ' '
    })

    const state = reactive({
      look: false,
      condition: {
        informationType: {
          label: '信息类型',
          type: 'select',
          default: '1',
          data : [
            {value: 1, label: 'HealthyShow-健康须知',},
            {value: 2, label: 'ClaimProcess-理赔流程',},
            {value: 3, label: 'CommonProblem-常见问题',},
            {value: 4, label: 'InsureTips-投保提示',}
          ]
        }
      },
      columns: [
        {
          title: '序号',
          width: 80,
          align: 'center',
          key: 'index',
          dataIndex: 'index'
        },
        {
          title: '信息类型',
          width: 140,
          align: 'center',
          key: 'informationTypeSign',
          dataIndex: 'informationTypeSign'
        },
        {
          title: '信息类型名称',
          width: 140,
          align: 'center',
          key: 'informationTypeSignName',
          dataIndex: 'informationTypeSignName'
        },
        {
          title: '显示方式',
          width: 250,
          align: 'center',
          key: 'displayTypeName',
          dataIndex: 'displayTypeName'
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 140
        }
      ],
      selectedRowKeys: [],
      loading: false,
      visible: false,
      newFilters: {},
      transferStyle: {width: '50%'},
      isUpdate: false,
      ids: [],
      list: []
    })


    const onSelectChange = (selectedRowKeys, selectList) => {
      state.ids = []
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
      state.ids = selectList
    }

    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('productInsureTotice_selectListForPageOne'))
    state.list = dataSource

    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))

    const handleTableChange = (pag, filters, sorter) => {
      console.log('handleTableChange', pag, filters, sorter)
      run({
        current: pag?.current,
        pageSize: pag?.pageSize,
        sorter,
        data: state.newFilters,
      })
    }

    const handleSearch = function (filters) {
      state.selectedRowKeys = []
      state.newFilters = {...filters, id: data.productInfoId}
      run({
        pagination:{
          current: 1,
          pageSize: 10
        },
        data:{...filters,id: data.productInfoId},
      })
    }
    const showDrawer = (oldData) => {
      state.look = true
      state.visible = true
      data.productInfoId = oldData.id
    }
    // 修改按钮
     const handelUpdate = function (oldData, num) {
       addDrawer.value.showDrawer(oldData,num)
    }
    const openSave = function(oldData, num){
      addDrawer.value.showDrawer(oldData,num)
    }
    // 批量删除
    const delInformation = ()=>{
      if(state.selectedRowKeys.length === 0) {
        return message.warning('请选择数据')
      }
      Modal.confirm({
        title: '删除提示',
        content: '是否删除已选中的信息?',
        onOk: ()=>{
          request(getRequestURI('productInsureTotice_delete'), {data: state.ids}).then(res => {
            if(res.code === 1){
              reloadList()
              message['success']('删除成功')
              state.selectedRowKeys = []
            }else {
              message['warning'](res.message)
            }
          })
        },
        onCancel: () => {
          Modal.destroyAll()
        }
      })
    }
    const reloadList = () => {
      run({
        pagination: {
          current: current.value,
          pageSize: pageSize.value
        },
        data: state.newFilters,
      })
    }
    const onClose = () => {
      state.look = false
      state.visible = false
      state.selectedRowKeys = []
      state.list.result = []
    }

    expose({
      showDrawer
    })
    return {
      ...toRefs(state),
      onClose,
      delInformation,
      handelUpdate,
      handleTableChange,
      handleSearch,
      onSelectChange,
      reloadList,
      openSave,
      data,
      dataSource,
      pagination,
      formData,
      loading,
      addDrawer
    }
  },

})
</script>
