<style scoped>
</style>

<template>
      <product-area></product-area>
</template>

<script>
import {defineComponent, reactive, computed, ref, toRefs, onMounted} from 'vue'
import ProductArea from './productArea.vue'
import {requestUsePagination, getRequestURI, request} from '../../../utils/request.js'
import {getExtendUri} from '../../../utils/request_extend'
import { message,Modal } from 'ant-design-vue'

export default defineComponent({
  components: {
    ProductArea
  },
  setup() {
    const userDrawerRef = ref()
    const state = reactive({
      condition: {
        channelName:{
          label: '渠道名称',
          placeholder: '请输入'
        },
        channelCode:{
          label: '渠道编码',
          placeholder: '请输入'
        },
        channelType:{
          label: '渠道类型',
          type: 'select',
          default: '1',
          placeholder: '请选择',
          data: []
        }
      },
      channelColumns: [  // 渠道
        {
          title: '序号',
          key: 'index',
          align: 'center',
          width: 60,
        },
        {
          title: '渠道名称',
          width: 100,
          align: 'center',
          key: 'channelName',
          dataIndex: 'channelName',
        },
        {
          title: '渠道编码',
          width: 100,
          align: 'center',
          key: 'channelCode',
          dataIndex: 'channelCode',
        },
        {
          title: '渠道类型',
          width: 100,
          align: 'center',
          key: 'channelType',
          dataIndex: 'channelType',
          customRender: (text)=>{
            if (text.text === '1') {
              return '个人'
            } else if (text.text === '2') {
              return '银行'
            } else if(text.text === '3') {
              return '电话'
            } else if(text.text === '4') {
              return '网销'
            } else if(text.text === '5') {
              return '卡单'
            }
          }
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 80,
        },
      ],
      selectedRowKeys: [],
      newChannel: {},
    })

    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('channel_queryListForPage'))

    const onSelectChange = (selectedRowKeys) => {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.log('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.newChannel,
      })
    }
    const handleSearch = function (filters) {
      console.log('handleSearch', filters)
      state.newChannel = filters
      run({
        pagination: {
          current: 1,
          pageSize: 10
        },
        data: filters,
      })
    }
    const addChannel = function (num) {
      userDrawerRef.value.showDrawer(num)
    }
    const updateChannel = function (oldData,num) {
      console.log(oldData)
      userDrawerRef.value.showDrawer(oldData,num)
    }
    const delChannel = record => {
      let data = {}
      Modal.confirm({
        title: '删除提示',
        content: '确定删除此数据吗?',
        onOk: () => {
          request(getRequestURI('channel_delete'), {data: {ids: [record.id]}}).then(res =>{
            if (res.code === 1) {
              message.success('删除成功')
              handleSearch(data)
            } else {
              message.warning(res.message)
            }
          })
        },
        onCancel: () => {
          Modal.destroyAll()   // 清除弹出框
        }
      })
    }


    // 渠道类型下拉选
    const getChannelType = () =>{
      request(getExtendUri('dictionary_query_byType'), {data: {types: ['channel_type']}}).then(res => {

        state.condition.channelType.data = res.result.channel_type
        console.log(222, state.condition.channelType.data)
      })
    }
    onMounted(()=> {
      getChannelType()
    })
    return {
      ...toRefs(state),
      handleSearch,
      onSelectChange,
      handleTableChange,
      addChannel,
      getRequestURI,
      getChannelType,
      updateChannel,
      delChannel,
      activeKey: ref('2'),
      userDrawerRef,
      pagination,
      loading,
      dataSource,

    }
  },
})
</script>
