<style scoped>
</style>
<template>
  <search-form
    :Condition="condition"
    @resetAll="resetAll"
    :ColSpan="12"
    @callback="handleSearch"
    @nameLinked="changes"
  ></search-form>
  <!-- 表格数据 -->
  <a-table
    :columns="columns"
    :data-source="dataSource ? dataSource['result'] : []"
    :pagination="pagination"
    @change="handleTableChange"
    :loading="loading"
    style="margin-top: 10px"
  >
    <a-spin :delay="200" />
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <a-button
          type="text"
          @click="checkInterest(record, 'check')"
          style="margin-right: 10px; color: orange"
          size="small"
        >
          查看
        </a-button>
        <a-button
          type="text"
          @click="checkInterest(record, 'edit')"
          style="margin-right: 10px; color: orange"
          size="small"
        >
          编辑
        </a-button>
      </template>
    </template>
  </a-table>
  <CheckInsureat ref="checkShowDrawer" />
</template>
<script>
import {computed, defineComponent, onMounted, reactive, ref, toRefs} from 'vue'
import SearchForm from '../../../components/extended/search_form'
import {getRequestURI, request, requestUsePagination} from '../../../utils/request.js'
import CheckInsureat from './checkInsureat'

export default defineComponent({
  components: {
    SearchForm,
    CheckInsureat
  },
  setup () {
    const checkShowDrawer = ref()
    const state = reactive({
      // 头部搜索框
      condition: {
        supId: {
          label: '供应商名称',
          type: 'tree-select',
          urlKey: 'querySupplier',
          value: 'id',
          default: '',
          data: []
        },
        riskName: {
          label: '险种名称',
          type: 'select',
          default: '',
          data: []
        },
        riskCode: '险种编码',

      },
      columns: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left',
        },
        {
          title: '险种编码',
          width: 140,
          align: 'center',
          key: 'riskCode',
          dataIndex: 'riskCode',
        },
        {
          title: '供应商险种编码',
          width: 140,
          align: 'center',
          key: 'supRiskCode',
          dataIndex: 'supRiskCode',
        },
        {
          title: '险种名称',
          width: 140,
          align: 'center',
          key: 'riskName',
          dataIndex: 'riskName',
        },
        {
          title: '供应商名称',
          width: 140,
          align: 'center',
          key: 'supName',
          dataIndex: 'supName',
        },
        {
          title: '操作',
          key: 'operation',
          ellipsis: true,
          align: 'center',
          width: 230,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      dataSource: [],
      searchNow: {}
    })
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('productRisk_selectListForPage'))
    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    const handleSearch = function (filters) {
      state.searchNow = filters
      run({
        pagination: {
          current: 1,
          pageSize: 10
        },
        data: filters,
      })
    }
    const changes = function (value) {
      request(getRequestURI('selectRisk'), {
        data: {
          supId: value,
          // riskName:''
        }
      }, true, 'post').then(
        (result) => {
          const arr = []
          const res = result.result
          for (let i = 0; i < res.length; i++) {
            arr.push({ label: res[i].riskName, value: res[i].riskName })
          }
          state.condition.riskName.data = arr
        }
      )
    }
    // 页面发生改变回调
    const handleTableChange = (pag, filters, sorter) => {
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        data: state.searchNow,
        sorter,
      })
    }
    const checkInterest = (data, str) => {
      request(getRequestURI('selectBenefit'), { data: { code: data.riskCode } }).then(res => {
        if (res.code === 1) {
          console.log(res.result, '987654321')
          checkShowDrawer.value.showDrawer(res.result, data, str)
        }
      })
    }
    //获取险种名称数据
    const getInsuranceName = () => {
      request(getRequestURI('selectRisk'), {
        data: {
          riskName: ''
        }
      }, true, 'post').then(
        (result) => {
          const arr = []
          const res = result.result
          for (let i = 0; i < res.length; i++) {
            arr.push({ label: res[i].riskName, value: res[i].riskName })
          }
          state.condition.riskName.data = arr
        }
      )
    }
    const resetAll = () => {
      state.condition.riskName.data = []
      getInsuranceName()
    }
    getInsuranceName()
    return {
      resetAll,
      ...toRefs(state),
      checkInterest,
      handleSearch,
      handleTableChange,
      checkShowDrawer,
      pagination,
      loading,
      dataSource,
      getInsuranceName,
      changes
    }
  }
})
</script>
