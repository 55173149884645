<template>
  <a-drawer
    :destroyOnClose="true"
    :title="isShow ? '编辑封面图' :'新增封面图'"
    :width="'35%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form layout="horizontal" :model="formData" ref="formRef" autocomplete="off">
      <a-row :gutter="15">
        <a-col span="24">
          <a-form-item label="素材名称" name="coverName" required>
            <a-input v-model:value="formData.coverName" placeholder="请输入素材名称"></a-input>
          </a-form-item>
        </a-col>
        <a-col span="24">
          <a-form-item label="封面类型" name="coverType" required>
            <a-select
              v-model:value="formData.coverType"
              placeholder="请选择封面类型"
              dropdown-class-name="value-select"
              show-search
              allow-clear
              :filter-option="filterOption"
              :options="coverTypeList"
            >
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="24">
          <a-form-item label="上传图片" name="coverUrl" :rules="[{required:true,trigger:'blur'}]">
            <uploadFileNew  @getFileList="getFileList" listType="picture-card" :showImg="showImg" :num="1" filetype="img"
                            @fileChange="uploadCosFileChange"></uploadFileNew>
          </a-form-item>
        </a-col>

      </a-row>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          取消
        </a-button>
        <a-button type="primary" @click="onSave">
          <template #icon>
            <SaveOutlined/>
          </template>
          保存
        </a-button>
      </a-space>
    </template>

  </a-drawer>
</template>

<script>
import {reactive, toRefs, defineComponent, ref, onMounted} from 'vue'
import UploadFileNew from '@/components/extended/uploadFileNew.vue'
import {SaveOutlined, CloseOutlined} from '@ant-design/icons-vue'
import {getRequestURI, request} from '@/utils/request'
import {getDictionaryData} from '@/utils/request_extend'
import {message} from 'ant-design-vue'
export default defineComponent({
  components: {
    UploadFileNew,
    SaveOutlined,
    CloseOutlined
  },
  setup(props, {emit}){
    const state = reactive({
      visible: false,
      showImg: [],
      isShow: false
    })
    const formData = reactive({
      id: null,
      coverName: '',
      coverType: undefined,
      coverUrl: '',
      fileList: []
    })

    const coverTypeList = ref()
    const formRef = ref()
    const showDrawer = (cover) =>{
      if(cover && cover.id){
        state.isShow = true
        Object.keys(cover).forEach(key =>{
          formData[key] = cover[key] || formData[key]
        })
        state.showImg.push({url: cover.coverUrl})
      }
      state.visible = true
    }


    const getFileList = (value)=>{
      formData.fileList = value.fileList
    }
    // 上传图片的返回值
    const uploadCosFileChange = (picData) => {
      console.log('上传后的返回值', picData)
      formData.coverUrl = picData.url

    }
    const onSave = async () =>{
      await formRef.value.validate()
      request(getRequestURI('prospectusCoverInsert'), {data: formData}).then(res => {
        if(res.code === 1){
          console.log(res)
          message.success('新增成功')
          emit('handleSearch')
          onClose()
        }else {
          message.error(res.message)
        }
      })
    }
    const onClose = () =>{
      formRef.value.resetFields()
      formRef.value.clearValidate()
      state.showImg = []
      Object.assign(formData, {
        id: null,
        coverName: '',
        coverType: undefined,
        coverUrl: '',
        fileList: []
      })
      state.isShow = false
      state.visible = false
    }
    const filterOption = (input, option) => {
      return option.label.indexOf(input) > -1
    }

    onMounted(()=>{
      getDictionaryData(coverTypeList, 'cover_type')
    })


    return{
    ...toRefs(state),
      formData,
      coverTypeList,
      formRef,
      uploadCosFileChange,
      getFileList,
      showDrawer,
      onSave,
      onClose,
      filterOption
    }
  }
})
</script>

<style scoped>
</style>

