<style>
</style>
<template>
  <a-drawer
    :title="titName"
    :width="'60%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <div class="footer">
      <a-form
        :model="data"
        name="basic"
        :wrapper-col="{ span: 16 }"
        autocomplete="off"
        :rules="formRules"
        ref="formRef"
      >
        <a-row :gutter="10">
          <a-col span="20">
            <a-form-item name="configType" label="配置类型: ">
              <a-select :disabled="isCheck" v-model:value="data.configType" placeholder="请选择配置类型"
                        @change="peizhileixing">
                <a-select-option :value="ct.value" v-for="ct in formData.configType" :key="ct.value">{{ ct.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col span="20" v-if="data.configType === 'LiaAmnt'">
            <a-form-item name="chooseDutyCode" label="选择责任">
              <a-select :disabled="isCheck" v-model:value="data.chooseDutyCode" placeholder="请选择">
                <a-select-option :value="c.dutyCode" v-for="c in formData.chooseDutyCode" :key="c.dutyCode">
                  {{ c.dutyName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col span="20">
            <a-form-item name="isShow" label="是否显示">
              <a-select :disabled="isCheck" v-model:value="data.isShow" placeholder="请选择">
                <a-select-option :value="is.value" v-for="is in formData.isShow" :key="is.value">{{ is.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col span="20" v-if="data.isShow === 'Y'">
            <a-form-item name="showSort" label="显示顺序" :rules="[{required:true,message:'必填信息',trigger:'change'},{max: 3, message: '输入长度不能超过3' ,trigger: 'change'},
          { pattern:/^\+?[1-9]\d*$/, message: '支持正整数格式' ,trigger: 'change'}
          ]">
              <a-input :disabled="isCheck" v-model:value="data.showSort" placeholder="请输入"/>
            </a-form-item>
          </a-col>
          <a-col span="20" v-if="data.isShow === 'Y'">
            <a-form-item name="showName" label="显示名称">
              <a-input :disabled="isCheck" v-model:value="data.showName" placeholder="请输入"/>
            </a-form-item>
          </a-col>
          <a-col span="4"></a-col>
          &nbsp;&nbsp;&nbsp;
          <a-col v-show="isCheck" style="display: block;">
            <a-form-item label="生成模板">
              <a-button type="primary" :disabled="isCheck" @click="onGenerate">生成模板</a-button>
            </a-form-item>
          </a-col>
          <a-col span="24">
            <a-form-item label="文件导入" name="fileUrl" :rules="[{required:true,message:'请选择文件',trigger:'change'}]">
              <upload-files :isUpdate="data.isUpdate" :uploadFileData="data.urlName" :isShowAble="isShowAble"
                            ref="clearInput" :key="time" name="data.fileUrl"
                            @fileChange="handleChange" :disabled="isCheck"></upload-files>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button v-show="!isCheck" html-type="submit" type="primary" @click="onSave">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>
<script>
import {message} from 'ant-design-vue'
import {defineComponent, reactive, toRefs, ref} from 'vue'
import {getRequestURI, request} from '../../../utils/request.js'
import uploadFiles from '../../../components/extended/uploadFile.vue'

export default defineComponent({
  components: {
    uploadFiles
  },
  props: {
    onSearch: {type: Function}
  },
  setup(props) {
    const formRules = reactive({
      fileUrl: [
        {required: true, message: '请选择文件'}
      ],
      configType: [
        {required: true, message: '请选择类型'}
      ],
      chooseDutyCode: [
        {required: true, message: '请选择责任'}
      ],
      isShow: [
        {
          required: true, message: '请填写'
        }
      ],

      showSort: [
        {
          required: true, message: '请填写'
        }
      ],
      showName: [
        {
          required: true, message: '请填写'
        }
      ]
    })

    const data = reactive({
      riskId: 0,
      chooseDuty: undefined,
      fileUrl: '',
      configType: undefined,
      chooseDutyCode: undefined,
      isShow: 'Y',
      showSort: '',
      showName: '',
      templateUrl: '',
      urlName: '',
      isUpdate: false
    })
    const formData = reactive(
      {
        // 文件导入的名字
        fileUrl: '',
        // 配置类型
        configType: [
          {id: 'EffAmnt', value: '有效保额'},
          {id: 'CashValue', value: '现金价值'},
          {id: 'LiaAmnt', value: '责任金'}
        ],
        // 选择责任
        chooseDutyCode: [],
        // 是否显示
        isShow: [
          {id: 'Y', value: '是'},
          {id: 'N', value: '否'}
        ],
        // 显示顺序
        showSort: '',
        // 显示名称
        showName: ''

      }
    )
    const state = reactive({
      isShowAble: false,
      visible: false,
      riskId: 0,
      time: null,
      isCheck: false,
      updateData: {},
      titName: '利益演示配置查看',
      isAdd: false,  // 用于新增或修改
      checkIds: [],  // 查看回显 用
      selectedRowKeys: [],  // 编辑 或 批量删除用
      ctValue: undefined,
      // 配置类型
      configType: [
        {id: 'EffAmnt', value: '有效保额'},
        {id: 'CashValue', value: '现金价值'},
        {id: 'LiaAmnt', value: '责任金'}
      ],
      columns: [
        {
          title: '序号',
          width: 80,
          align: 'center',
          key: 'index',
          dataIndex: 'index'
        },
        {
          title: '配置类型',
          width: 120,
          align: 'center',
          key: 'configType',
          dataIndex: 'configType'
        },
        {
          title: '选择责任',
          width: 120,
          align: 'center',
          key: 'chooseDuty',
          dataIndex: 'chooseDuty'
        },
        {
          title: '是否显示',
          width: 120,
          align: 'center',
          key: 'isShow',
          dataIndex: 'isShow'
        },
        {
          title: '显示顺序',
          width: 120,
          align: 'center',
          key: 'showSort',
          dataIndex: 'showSort'
        },
        {
          title: '操作',
          width: 140,
          align: 'center',
          key: 'operation',
          dataIndex: 'operation'
        }
      ],
      dataSource: [],
      pagination: {
        total: 1,
        current: 1,
        pageSize: 10
      }
    })
    const peizhileixing = function (value) {
      if (value !== 'LiaAmnt') {
        data.chooseDutyCode = ''
      }
      state.time = new Date().getTime()
    }
    const showDrawer = (oldData, num, dataId) => {
      state.visible = true
      if (num === 0) {
        state.titName = '利益演示配置新增'
        state.isAdd = true
        data.riskId = oldData
        getChooseDutyCode(oldData)
      } else if (num === 1) {
        state.isCheck = true
        state.isShowAble = true
        data.isUpdate = true
        state.titName = '利益演示配置查看'
        Object.assign(data, {...oldData, chooseDutyCode: oldData.chooseDuty, riskId: dataId})
        getChooseDutyCode(dataId)
        state.updateData = oldData
      } else {
        console.log(oldData)
        state.isCheck = true
        state.isShowAble = true
        state.titName = '利益演示配置查看'
        Object.assign(data, {...oldData[0], chooseDutyCode: oldData[0].chooseDuty})
      }
    }

    //上传
    const handleChange = info => {
      data.fileUrl = info.url
    }
    const clearInput = ref()

    const onSave = async () => {
      switch (data.configType) {
        case '有效保额':
          data.configType = 'EffAmnt'
          break
        case '现金价值':
          data.configType = 'CashValue'
          break
        case '责任金':
          data.configType = 'LiaAmnt'
          break
        default:
          break
      }
      try {
        await formRef.value.validate()
        // 修改
        let requestURI = getRequestURI('benefit-update')
        if (state.isAdd) {
          // 新增
          requestURI = getRequestURI('benefit-insert')
        }
        if (data.configType !== 'LiaAmnt') {
          data.chooseDutyCode = '000'
        }
        request(requestURI, {data}).then(res => {
          if (res.code === 1) {
            message['success']('保存成功')
            clearInput.value.cleInput.value = ''
            props.onSearch()
            onClose()
          } else {
            message['warning'](res.message)
          }
        })
      } catch (error) {
        // message['error'](error)
      }
    }

    const formRef = ref()
    const onClose = () => {
      state.checkIds = []
      state.visible = false
      state.isCheck = false
      state.isAdd = false
      state.isShowAble = false
      state.selectedRowKeys = []
      state.updateData = {}
      Object.assign(data, {
        id: undefined,
        riskId: 0,
        chooseDuty: undefined,
        fileUrl: '',
        configType: undefined,
        chooseDutyCode: undefined,
        isShow: 'Y',
        showSort: '',
        showName: '',
        templateUrl: ''
      })
      formRef.value.clearValidate()  //清除验证信息
      clearInput.value.cleInput.value = ''
      state.dataSource = []
    }
    // 选择责任的下拉框
    const getChooseDutyCode = (id) => {
      try {
        request(getRequestURI('benefit-selectProductDuty'), {data: {id: id}}).then(res => {
          const label = 'dutyCode'
          const sysName = res.result.reduce((all, next) => all.some((atom) => atom[label] == next[label]) ? all : [...all, next], [])
          formData.chooseDutyCode = sysName
          getChooseDuty(formData.chooseDutyCode)
        })
      } catch (error) {
        console.log(error)
      }
    }
    const init = function () {
      request(getRequestURI('dictionary_query_byType'), {data: {types: ['riskValueType']}}).then(res => {
        formData.configType = res.result.riskValueType
      })
      request(getRequestURI('dictionary_query_byType'), {data: {types: ['yes_no']}}).then(res => {
        formData.isShow = res.result.yes_no
      })
    }
    init()
    const getChooseDuty = (code) => {
      console.log('查看数据', state.updateData.chooseDuty)
      if (!state.updateData.chooseDuty) {
        return
      }
      // let arr = []
      Object.values(code).forEach(key => {
        let arr = key.dutyName.split('-')
        for (let i = 0; i < arr.length; i++) {
          if (arr[i] === state.updateData.chooseDuty) {
            data.chooseDutyCode = arr[i - 1]
          }
        }
      })
    }
    // 生成模板
    const onGenerate = () => {
      console.log(88888, data)
      const uri = getRequestURI('benefit-generateTemplate')
      try {
        request(uri, {
          data: {
            configType: data.configType,
            dutyCode: data.chooseDutyCode,
            riskId: data.riskId
          }
        }).then(res => {
          if (res.code === 1) {
            data.templateUrl = res.result
            window.location = res.result
            message['success']('正在下载模板,请稍等')
          } else {
            message.warning(res.message)
          }
        })
      } catch (error) {
        message['warning'](error)
      }

    }
    // onMounted(() => {
    //   getChooseDutyCode()
    // })

    return {
      ...toRefs(state),
      showDrawer,
      onSave,
      onClose,
      handleChange,
      getChooseDutyCode,
      getChooseDuty,
      onGenerate,
      formData,
      formRef,
      formRules,
      data,
      clearInput,
      peizhileixing
    }
  }

})
</script>
