<template>
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @callback="handleSearch"
    @nameLinked="changes"
    @treeName="getTreeName"
    @resetAll="resetAll"
  ></search-form>
  <a-table
    :columns="columns"
    :data-source="dataSource ? dataSource['result'] : []"
    :scroll="{ x: 800 }"
    :row-key="(record) => record.id"
    :pagination="pagination"
    @change="handleTableChange"
    :loading="loading"
  >
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <div style="display: flex">
          <a
            style="color: orange; margin-right: 25px"
            @click="checkUser(record, 'select')"
            >查看</a
          >
          <a
            style="color: orange; margin-right: 25px"
            @click="modifyUser(record, 'update')"
            >编辑</a
          >
        </div>
      </template>
    </template>
  </a-table>
  <drawerupdate :status="status" :idd="idd" ref="userDrawerRef"></drawerupdate>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import { getRequestURI, request, requestUsePagination } from '../../../utils/request'
import SearchForm from '../../../components/extended/search_form'
import drawerupdate from './drawerupdate.vue'

export default defineComponent({
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SearchForm,
    drawerupdate
  },
  setup () {
    const userDrawerRef = ref()
    const state = reactive({
      supCode: '',
      status: '',
      dataSourceList: [],
      condition: {
        supName: {
          label: '供应商名称',
          type: 'tree-select',
          urlKey: 'querySupplier',
          value: 'id',
        },
        productName: {
          label: '产品名称',
          type: 'select',
          default: '',
          data: []
        },
        productCode: '产品编码'
      },
      idd: '',
      values: [],
      result: [],
      list: [],
      lastFilter: {},
      queryG: {
        data: {
          cityCode: '',
          comAddress: '',
          comPhoneNumber: '',
          comUrl: '',
          countyCode: '',
          createTime: '',
          createUser: '',
          emailCode: '',
          id: 0,
          logo: '',
          parentId: 0,
          provinceCode: '',
          supCode: '',
          supEngName: '',
          supGrade: 0,
          supInitial: '',
          supIntroduce: '',
          supName: '',
          supShortName: '',
          supStatus: 0,
          supType: 0,
          updateTime: '',
          updateUser: ''
        },
        sorters: [
          {
            field: '',
            order: ''
          }
        ]
      },
      queryC: {
        data: {
          productCode: '',
          productId: null,
          supProductCode: '',
          supplierId: null,
          systemCode: ''
        },
      },
      query: {
        data: {
          checkNumber: 0,
          createTime: null,
          createUser: null,
          dataType: 0,
          delNumber: 0,
          factorType: 0,
          id: 0,
          isDel: 0,
          productBasicsId: 0,
          productCode: null,
          productId: 0,
          productName: '',
          remarks: null,
          supName: null,
          supProductCode: null,
          updateTime: null,
          updateUser: null,
          ynCheck: null,
          ynStart: null
        },
      },
      columns: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left'
        },
        {
          title: '产品编码',
          width: 100,
          align: 'center',
          key: 'productCode',
          dataIndex: 'productCode'
        },
        {
          title: '供应商产品编码',
          width: 100,
          align: 'center',
          key: 'supProductCode',
          dataIndex: 'supProductCode'
        },
        {
          title: '产品名称',
          width: 100,
          align: 'center',
          key: 'productName',
          dataIndex: 'productName'
        },
        {
          title: '供应商名称',
          width: 100,
          align: 'center',
          key: 'supName',
          dataIndex: 'supName'
        },
        {
          title: '操作',
          key: 'operation',
          fixed: 'right',
          width: 80
        }
      ]
    })
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('productInsureRule_selectListForPage'))
    const onSelectChange = (selectedRowKeys, selectionRows) => {
      state.selectedRowKeys = selectedRowKeys
      state.selectionRows = selectionRows
    }
    // const asd = function(item) {
    //   console.log(item)
    //   item.forEach(item1 => {
    //     state.values.push(item1)
    //   })
    //   if (item.children !== null && item.children !== undefined) {
    //     asd(item.children)
    //   } else {
    //     return
    //   }
    // }

    //产品下拉查询
    const changes = function (value, treeData) {
      state.treeName = getStateData(value, treeData)
      request(getRequestURI('select'), { data: { supplierId: value } }).then(result => {
        const arr = []
        const res = result.result
        const label = 'productName'
        const sysName = res.reduce((all, next) => all.some((atom) => atom[label] == next[label]) ? all : [...all, next], [])
        for (let i = 0; i < sysName.length; i++) {
          arr.push({ label: sysName[i].productName, value: sysName[i].productName })
        }
        state.condition.productName.data = arr
      })
    }
    // 得到供应商名称数据
    const getStateData = (value, treeData) => {
      let result = ''
      if (!treeData) {
        return result
      }
      for (let i = 0; i < treeData.length; i++) {
        let item = treeData[i]
        if (item.id === value) {
          result = item.supName
          // debugger
          return result
        } else if (item.children && item.children.length > 0) {
          // debugger
          result = getStateData(value, item.children)
          if (result) {
            return result
          }
        }
      }
      return result
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
      // pageSizeOptions: [10, 20, 50],
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.debug('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.lastFilter,
      })
    }
    const getTreeName = (value) => {
      state.treeName = value
    }
    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    const handleSearch = function (filters) {
      if (state.treeName === '') {
        state.treeName = undefined
      }
      filters.supName = state.treeName
      state.lastFilter = filters
      state.loading = true
      selectAll()
      state.loading = false
    }
    const selectAll = function () {
      run(
        {
          data: state.lastFilter,
          pagination: {
            current: current.value,
            pageSize: pageSize.value
          },
        }
      )
    }
    const selectUser = function () {}
    const addUser = function () {}
    const chongzhi = function () {}
    const modifyUser = function (oldData, status) {
      state.status = status
      state.idd = oldData.id
      userDrawerRef.value.showDrawer(oldData)
    }
    const resetAll = () => {
      state.treeName = ''
      state.condition.productName.data = []
      changes()
    }

    const checkUser = function (oldData, status) {
      state.status = status
      state.idd = oldData.id
      userDrawerRef.value.showDrawer(oldData)
    }
    const deleteUser = function () {
    }
    const showModal = () => {
    }
    const init = () => {
      // request(getRequestURI('select'),{data: {}}).then(result =>{
      //   console.log(res)
      //   const arr = []
      //   const res = result.result
      //   const label = 'productName'
      //   const sysName = res.reduce((all, next) => all.some((atom) => atom[label] == next[label]) ? all : [...all, next], [])
      //   for (let i = 0; i < sysName.length; i++) {
      //     arr.push({label: sysName[i].productName, value: sysName[i].id})
      //   }
      //   state.condition.productId.data = arr
      // })
      // const uri = getRequestURI('supplier_querySupLevelTh')
      // request(uri, state.queryG).then(res => {
      //   console.log(res)
      //   if (res.code == 1) {
      //     state.condition.supName.data = res.result
      //   }
      // })
      // const url = getRequestURI('select')
      // request(url, state.queryC).then(res => {
      //   console.log(res)
      //   if (res.code == 1) {
      //
      //   }
      // })
    }
    init()
    onMounted(() => {
      changes()
    })
    return {
      init,
      resetAll,
      userDrawerRef,
      ...toRefs(state),
      handleSearch,
      dataSource,
      run,
      loading,
      current,
      pageSize,
      selectAll,
      total,
      selectUser,
      addUser,
      chongzhi,
      checkUser,
      deleteUser,
      showModal,
      modifyUser,
      onSelectChange,
      pagination,
      handleTableChange,
      changes,
      getTreeName,
      getStateData
    }
  }
})
</script>

<style scoped>
</style>
