<style scoped>
</style>
<template>
  <a-drawer
    :title="isNew"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
        <search-form :Condition="condition" :ColSpan="12" @callback="handleSearch" v-if="clean"></search-form>
        <a-button type="primary" @click="delMultiple" v-if="butshow">
      批量删除
    </a-button>
        <div v-if="tableshow" >
          <a-table :columns="columns" :data-source="list"
           :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChanges }"
           :row-key="record => record.id"
           :pagination="false " @change="handleTableChange"
           :loading="loading"
           :scroll="{ x: 1500 }"
  >
    <template #bodyCell="{ column,index,record }">
      <template v-if="column.key === 'index'">
         {{ index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <a-button type="text" @click="revise(record)" size="small" style="margin-right: 10px; color: orange;">
          修改
        </a-button>
        <!-- <a-button type="text" @click="deleteProduct(record,index)" size="small" style="margin-right: 10px; color: orange;">
          删除
        </a-button> -->
      </template>
    </template>
  </a-table>
    </div>
    <div  v-if="!tableshow">
       <a-table :columns="columns" :data-source="list"
           :row-selection="{ selectedRowKeys: checkids,
            onChange: onSelectChange,  type:'radio',hideSelectAll:true
            }"
           :row-key="record => record.id"
           :pagination="false " @change="handleTableChange"
           :loading="loading"
           :scroll="{ x: 1500 }"
  >
        <template #bodyCell="{ column,index }">
          <template v-if="column.key === 'index'">
                {{ index + 1 }}
            </template>
              </template>
    </a-table>
    </div>

  <a-form :model="data"  layout="vertical" ref="formRef" autocomplete="off" v-if="issho==true">
    <a-row :gutter="10">
     <a-col span="12">
      <a-form-item name="tempNode" label="模板节点：" :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
       <a-select  v-model:value="data.tempNode" placeholder="请选择模板节点" :disabled="isccc"  >
        <a-select-option value="A">投保人节点</a-select-option>
        <a-select-option value="B">被保人节点</a-select-option>
        <a-select-option value="C">产品节点</a-select-option>
        <a-select-option value="S">受益人节点</a-select-option>
        <a-select-option value="Y">银行卡节点</a-select-option>
      </a-select>
     </a-form-item>
    </a-col>
    <a-col span="12">
      <a-form-item name="tempCode" label="模板编码：" :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
       <a-select v-model:value="data.tempCode" placeholder="请选择模板编码" :disabled="isccc">
         <a-select-option value="111">111</a-select-option>
        <a-select-option value="222">222</a-select-option>
      </a-select>
     </a-form-item>
      </a-col>
      <a-col span="12">
       <a-form-item label="模板显示名称：" name="tempName" :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-input v-model:value="data.tempName" placeholder="请输入模板显示名称" :disabled="isccc"/>
          </a-form-item>
    </a-col>
      <a-col span="12">
      <a-form-item name="inputType" label="输入类型：" :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
       <a-select v-model:value="data.inputType" placeholder="请选择输入类型" :disabled="isccc">
         <a-select-option value="cascadeselect">级联选择框</a-select-option>
        <a-select-option value="checkbox">复选框</a-select-option>
        <a-select-option value="date">日期格式</a-select-option>
        <a-select-option value="input">输入框</a-select-option>
        <a-select-option value="radio">单选框</a-select-option>
        <a-select-option value="relationselect">关系选择框</a-select-option>
        <a-select-option value="select">下拉选择</a-select-option>
      </a-select>
     </a-form-item>
    </a-col>
     <a-col span="12">
      <a-form-item name="textType" label="文本校验类型：">
       <a-select v-model:value="data.textType" placeholder="请选择文本校验类型" :disabled="isccc">
         <a-select-option value="address">地址</a-select-option>
        <a-select-option value="date">日期格式</a-select-option>
        <a-select-option value="email">邮箱</a-select-option>
        <a-select-option value="idno">身份证号</a-select-option>
        <a-select-option value="idnopassport">证件号码含护照</a-select-option>
        <a-select-option value="name">姓名</a-select-option>
        <a-select-option value="number">数字</a-select-option>
        <a-select-option value="phone">手机号</a-select-option>
      </a-select>
     </a-form-item>
    </a-col>
     <a-col span="12">
      <a-form-item name="showStatus" label="是否显示：" :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
       <a-select v-model:value="data.showStatus" placeholder="请选择是否显示" :disabled="isccc">
         <a-select-option value="Y">是</a-select-option>
        <a-select-option value="N">否</a-select-option>
      </a-select>
     </a-form-item>
    </a-col>
    <a-col span="12">

      <a-form-item name="isRequired" label="是否必填：" :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
       <a-select v-model:value="data.isRequired" placeholder="请选择是否必填" :disabled="isccc">
         <a-select-option value="Y">是</a-select-option>
        <a-select-option value="N">否</a-select-option>
      </a-select>
     </a-form-item>
    </a-col>
    <a-col span="12">
      <a-form-item name="isEdit" label="是否可编辑：" :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
       <a-select v-model:value="data.isEdit" placeholder="请选择是否可编辑" :disabled="isccc" >
         <a-select-option value="Y">是</a-select-option>
        <a-select-option value="N">否</a-select-option>
      </a-select>
     </a-form-item>
    </a-col>
    <a-col span="12">
       <a-form-item label="显示顺序：" name="sort"
                    :rules="[{ required: true, message: '必填信息' },
                    {max: 3, message: '输入长度不能超过3' }]"  >
            <a-input v-model:value="data.sort" placeholder="请输入显示顺序" :disabled="isccc"/>
          </a-form-item>
    </a-col>
     <a-col span="12">
      <a-form-item name="enumValue" label="选择枚举值：">
       <a-select v-model:value="data.enumValue" placeholder="请选择枚举值" :disabled="isccc">
         <a-select-option value="sex">性别</a-select-option>
        <a-select-option value="idtype">证件类型</a-select-option>
         <a-select-option value="occupationcode">职业编码</a-select-option>
         <a-select-option value="relatoappnt">与投保人关系</a-select-option>
         <a-select-option value="areacode">地区编码</a-select-option>
         <a-select-option value="relatoinsured">与被保人关系</a-select-option>
         <a-select-option value="degree">学历</a-select-option>
         <a-select-option value="apptype">缴费方式</a-select-option>
      </a-select>
     </a-form-item>
    </a-col>
    <a-col span="12">
      <a-form-item name="defaultValue" label="默认值：">
       <a-select v-model:value="data.defaultValue"  placeholder="请选择默认值" :disabled="isccc">
         <a-select-option value="china">是</a-select-option>
        <a-select-option value="usa">否</a-select-option>
      </a-select>
     </a-form-item>
    </a-col>
    <a-col span="12">
       <a-form-item label="提示语：" name="remind"
                    :rules="[ {max: 50, message: '输入长度不能超过50' ,trigger: 'change'}]" >
            <a-input v-model:value="data.remind" placeholder="请输入提示语" :disabled="isccc"/>
          </a-form-item>
    </a-col>
   </a-row>
  </a-form>
    <a-modal v-model:visible="isDell" :closable="false" title="提示" @ok="handleOk" okText="确定" cancelText="取消">
      <h3>是否确定删除该信息</h3>
    </a-modal>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
        关闭
        </a-button>
        <a-button type="primary" @click="onSave" v-if="isa">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>
<script>
import {CloseOutlined, SaveOutlined,} from '@ant-design/icons-vue'
import {defineComponent, reactive, toRefs, ref} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
import SearchForm from '../../../components/extended/search_form'
import{Modal,message} from 'ant-design-vue'
export default defineComponent({
  components: {
    CloseOutlined, SaveOutlined,SearchForm
  },
  setup(props, {expose}) {
    const isccc = ref(false)
    const formRef = ref()
    const data = reactive({
      productInfoId: undefined, // id
      tempNode:  undefined,//模板节点
      tempCode:  undefined,//模板编码
      tempName:  undefined,//模板显示名称
      inputType:  undefined,//输入类型
      textType:  undefined,//文本校验类型
      showStatus: undefined,//是否显示
      isRequired:  undefined,//是否必填
      isEdit:  undefined,//是否可编辑
      sort: undefined,//显示顺序
      enumValue: undefined,//枚举
      defaultValue: undefined,//默认值
      remind: undefined//提示语
    })
    const state = reactive({
         clean:false,
         tableshow:false,
         butshow:false,
         checkids:[],
         issho:false,
         isisis:true,
         condition: {
          tempNode: {
            label: '模板节点',
            type: 'select',
            default: '',
             data: [
            {
              label: '投保人节点',
              value: 'A'
            },
            {
              label: '被保人节点',
              value: 'B'
            },
            {
              label: '产品节点',
              value: 'C'
            },
            {
              label: '受益人节点',
              value: 'S'
            },
             {
              label: '银行卡节点',
              value: 'Y'
            }
          ]
          }
          },
         columns: [
        {
          title: '序号',
          width: 140,
          fixed: 'left',
          key: 'index',
          dataIndex: 'index'
        },
        {
          title: '模板节点',
          width: 120,
          align: 'center',
          key: 'tempNode',
          dataIndex: 'tempNode',

        },
        {
          title: '模板编码',
          width: 140,
          align: 'center',
          key: 'tempCode',
          dataIndex: 'tempCode'
        },
        {
          title: '模板显示名称',
          width: 250,
          align: 'center',
          key: 'tempName',
          dataIndex: 'tempName'
        },
        {
          title: '输入类型',
          width: 160,
          align: 'center',
          key: 'inputType',
          dataIndex: 'inputType'
        },
        {
          title: '文本校验类型',
          width: 100,
          align: 'center',
          key: 'textType',
          dataIndex: 'textType'
        },
        {
          title: '是否显示',
          width: 120,
          align: 'center',
          key: 'showStatus',
          dataIndex: 'showStatus'
        },
        {
          title: '是否必填',
          width: 120,
          align: 'center',
          key: 'isRequired',
          dataIndex: 'isRequired'
        },
        {
          title: '是否可编辑',
          width: 120,
          align: 'center',
          key: 'isEdit',
          dataIndex: 'isEdit'
        },
        {
          title: '显示顺序',
          width: 120,
          align: 'center',
          key: 'sort',
          dataIndex: 'sort'
        },

       ],
      deleteQuery:{},
      selectedRowKeys: [],
      // Check here to configure the default column
      loading: false,
      visible: false,
      isNew: true,
      isa:true,
      list:[],
      transferStyle: {width: '50%'},
       query: {
        data: {
              // createTime: '',
              // createUser: '',
              defaultValue: '',
              enumValue:'' ,
              id: 0,
              inputType:'' ,
              isDel: 0,
              isEdit: '',
              isRequired: '',
              productInfoId: 0,
              remind: '',
              showStatus:'' ,
              sort: 0,
              tempCode: '',
              tempName:'' ,
              tempNode: '',
              textType:'' ,
              // updateTime:'' ,
              // updateUser:''
            },
            pagination: {
              current: 1,
              pageSize: 10,
              total: 0
            },
            sorters: [
              {
                field:'' ,
                order: ''
              }
            ]
      },

    })
    const handleSearch = function (filters) {
        state.query.data.tempNode = filters.tempNode
        request(getRequestURI('papc_Inquire'), state.query).then(res=>{
         state.list = res.result.list
         state.list.filter(item=>{
          if(item.tempNode=='A'){
            item.tempNode='投保人节点'
          }else if(item.tempNode==='B'){
            item.tempNode='被投保人节点'
          }else if(item.tempNode=='C'){
             item.tempNode='产品节点'
          }else if(item.tempNode=='S'){
             item.tempNode='受益人节点'
          }else if(item.tempNode=='Y'){
             item.tempNode='银行卡节点'
          }
        })
         state.list.filter(item=>{
          if(item.showStatus=='Y'){
            item.showStatus='是'
          }else {
           item.showStatus='否'
          }
        })
         state.list.filter(item=>{
          if(item.isEdit=='Y'){
            item.isEdit='是'
          }else {
            item.isEdit='否'
          }
        })
         state.list.filter(item=>{
          if(item.isRequired=='Y'){
            item.isRequired='是'
          }else {
            item.isRequired='否'
          }
        })
         state.list.filter(item=>{
          if(item.inputType=='cascadeselect'){
            item.inputType='级联选择框'
          }else if(item.inputType==='checkbox'){
            item.inputType='复选框'
          }else if(item.inputType=='date'){
             item.inputType='日期格式'
          }else if(item.inputType=='input'){
             item.inputType='输入框'
          }else if(item.inputType=='radio'){
             item.inputType='单选框'
          }else if(item.inputType=='relationselect'){
             item.inputType='关系选择框'
          }else if(item.inputType=='select'){
             item.inputType='下拉选择'
          }
        })
         state.list.filter(item=>{
          if(item.textType=='address'){
            item.textType='地址'
          }else if(item.textType==='email'){
            item.textType='邮箱'
          }else if(item.textType=='date'){
             item.textType='日期格式'
          }else if(item.textType=='idno'){
             item.textType='身份证号'
          }else if(item.textType=='idnopassport'){
             item.textType='证件号码含护照'
          }else if(item.textType=='number'){
             item.textType='数字'
          }else if(item.textType=='phone'){
             item.textType='手机号'
          }else if(item.textType=='name'){
             item.textType='姓名'
          }
        })
      })
    // data.productInfoId=undefined, // id
      data.tempNode=undefined,//模板节点
      data.tempCode=undefined,//模板编码
      data.tempName=undefined,//模板显示名称
      data.inputType=undefined,//输入类型
      data.textType=undefined,//文本校验类型
      data.showStatus=undefined,//是否显示
      data.isRequired=undefined,//是否必填
      data.isEdit=undefined,//是否可编辑
      data.sort=undefined,//显示顺序
      data.enumValue=undefined,//枚举
      data.defaultValue=undefined,//默认值
      data.remind=undefined//提示语
      state.checkids=[]
      // state.issho=false
    }
    // 抽屉
    const showDrawer = (oldData = {},num) => {
      state.query.data.productInfoId = oldData.id
      data.productInfoId = oldData.id
      console.log(state.query.data.productInfoId,20202020)
      state.visible = true
       if (num === undefined) {
        state.clean= true
        state.isNew='新增页面'
        isccc.value=false
      } else if(num === 1) {
        state.clean= true
        state.isNew='查看页面'
        state.butshow=false
        state.tableshow=false
        isccc.value=true
        state.isa=false
        // data.productInfoId = oldData.id
        //  state.columns.pop()
        // console.log(data.productInfoId,456456)
      }else if (num === 2) {
        state.isNew='编辑页面'
        state.issho=true
        state.butshow=true
        state.tableshow=true
        isccc.value=false
        state.isa=true
        state.clean= true
        state.columns.push( {
          title: '操作',
          key: 'operation',
           align: 'center',
          fixed: 'right',
          width: 160
        })
      }
      console.log('editNewUser', state.isNew, oldData)
      Object.keys(data).forEach(key => {data[key] = oldData[key] || data[key]})
    }
     const isDell = ref(false)
    //删除
    // const deleteProduct = function(record, index) {
    //   state.del = index
    //   state.deleteQuery = record
    //   isDell.value = true

    // }
    // const handleOk = () => {
    //   // console.log(state.delId)
    //    let data=state.deleteQuery
    //   let uri = getRequestURI('pbpb_dle')
    //   request(uri, {data}).then(res=>{
    //     console.log(res)
    //     state.list.splice(state.del, 1)
    //   })
    //   isDell.value = false
    // }

    // 数据回显
     const revise = function (recorde) {
       state.isisis=false
       console.log(666)
       Object.assign(data, recorde)
       console.log(data)
       state.issho=true
        //  state.list.tempCode=data.tempCode
    }
    // 批量删除
    const delMultiple = () => {
      let ids = state.selectedRowKeys
          console.log(ids)
          if (ids.length === 0) {
            message.warning('请选择数据')
            return
          }
      Modal.confirm({
        title: '确认删除吗?',
        async onOk() {
          // visible.value = true
          request(getRequestURI('pppp_dele'), ids
          , true, 'post').then(
            () => {
              // console.log('批量删除的结果:', result)
              handleSearch({})
              state.selectedRowKeys = []
              message.success('删除成功')
              state.list=[]
            }
          ).catch(
          )
        },
        onCancel() {
        }
      })
    }
    // 关闭
    const onClose = () => {
      state.clean= false
      state.issho=false
      // state.isshow=false
      state.visible = false
      state.selectedRowKeys=[]
      state.checkids=[]
      state.columns= [
        {
          title: '序号',
          width: 140,
          fixed: 'left',
          key: 'index',
          dataIndex: 'index'
        },
        {
          title: '模板节点',
          width: 120,
          align: 'center',
          key: 'tempNode',
          dataIndex: 'tempNode',

        },
        {
          title: '模板编码',
          width: 140,
          align: 'center',
          key: 'tempCode',
          dataIndex: 'tempCode'
        },
        {
          title: '模板显示名称',
          width: 250,
          align: 'center',
          key: 'tempName',
          dataIndex: 'tempName'
        },
        {
          title: '输入类型',
          width: 160,
          align: 'center',
          key: 'inputType',
          dataIndex: 'inputType'
        },
        {
          title: '文本校验类型',
          width: 100,
          align: 'center',
          key: 'textType',
          dataIndex: 'textType'
        },
        {
          title: '是否显示',
          width: 120,
          align: 'center',
          key: 'showStatus',
          dataIndex: 'showStatus'
        },
        {
          title: '是否必填',
          width: 120,
          align: 'center',
          key: 'isRequired',
          dataIndex: 'isRequired'
        },
        {
          title: '是否可编辑',
          width: 120,
          align: 'center',
          key: 'isEdit',
          dataIndex: 'isEdit'
        },
        {
          title: '显示顺序',
          width: 120,
          align: 'center',
          key: 'sort',
          dataIndex: 'sort'
        },

       ]
      state.list=[]
      // formRef.value.resetFields()
      // formRef.value.clearValidate()
      data.productInfoId=undefined, // id
      data.tempNode=undefined,//模板节点
      data.tempCode=undefined,//模板编码
      data.tempName=undefined,//模板显示名称
      data.inputType=undefined,//输入类型
      data.textType=undefined,//文本校验类型
      data.showStatus=undefined,//是否显示
      data.isRequired=undefined,//是否必填
      data.isEdit=undefined,//是否可编辑
      data.sort=undefined,//显示顺序
      data.enumValue=undefined,//枚举
      data.defaultValue=undefined,//默认值
      data.remind=undefined//提示语
    }
  // 保存papb_addlist
    const onSave = async () => {
      //  const values = await formRef.value.validate()
     try {
        const values = await formRef.value.validate()

        if (state.isisis) {
          console.log(999999, data)
           if(data.showStatus=='是'){
                data.showStatus='Y'
        }else if( data.showStatus=='否'){
           data.showStatus='N'
        }
        if(data.isEdit=='是'){
                data.isEdit='Y'
        }else if( data.isEdit=='否'){
           data.isEdit='N'
        }
        if(data.isRequired=='是'){
                data.isRequired='Y'
        }else if( data.isRequired=='否'){
           data.isRequired='N'
        }

          request(getRequestURI('papb_addlist'), {data}, true, 'post').then(
            () => {
              handleSearch({})
              message.success('保存成功')
              // console.log('post success:', result)
              formRef.value.resetFields()
                console.log('新增状态',state.isisis)
            }
          )
        } else {
           if(data.showStatus=='是'){
                data.showStatus='Y'
        }else if( data.showStatus=='否'){
           data.showStatus='N'
        }
        if(data.isEdit=='是'){
                data.isEdit='Y'
        }else if( data.isEdit=='否'){
           data.isEdit='N'
        }
        if(data.isRequired=='是'){
                data.isRequired='Y'
        }else if( data.isRequired=='否'){
           data.isRequired='N'
        }
          request(getRequestURI('ppbc_updata'), {data}, true, 'post').then(
            (res) => {
               if (res.message == '数据重复重复') {
                    message['warning']('数据重复，请修改后重新保存')
          }
               formRef.value.resetFields()
               message.success('修改成功')
               handleSearch({})
              console.log('post success:', res)
              console.log(data)
              console.log(values)
              state.isisis=true
              console.log('修改状态',state.isisis)
            }
          )
        }
        console.log('Success:', values)
        // state.visible = false

      } catch (errorInfo) {
        console.log('Failed:', errorInfo)
      }

    }
    expose({
      showDrawer
    })
      const onSelectChanges = selectedRowKeys => {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
    }
    const onSelectChange = (selectedRowKeys,selectedRows) => {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      state.checkids = selectedRowKeys
        // state.selectedRowKeys = checkids
       state.issho=true
       state.isisis=false
       console.log(666)
       Object.assign(data, selectedRows[0])
       console.log(data)
    }
    return {
      onSelectChanges,
      delMultiple,
      // deleteProduct,
      revise,
      isDell,
      onSelectChange,
      // handleOk,
      handleSearch,
      formRef,
      data,
      ...toRefs(state),
      onClose,
      onSave,
      isccc
    }
  },

})
</script>
