<template>
  <a-drawer
    :width="'30%'"
    :title="title"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-checkbox v-model:checked="selectAllFlag" :disabled="disabled" @change="changeCheckEvent">全选</a-checkbox>
    <a-tree
      v-model:checkedKeys="checkedKeys"
      checkable
      :disabled="disabled"
      :field-names="fieldNames"
      :tree-data="areaData"
      @check="handleTree"/>

    <template #extra>
      <a-space v-if="!disabled">
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button type="primary" @click="onSave">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>

  </a-drawer>
</template>

<script>
import {message} from 'ant-design-vue'
import {defineComponent, nextTick, reactive, ref, toRefs} from 'vue'
import {getRequestURI, request} from '../../../utils/request.js'

export default defineComponent({
  props: {
    searchfFilters: {type: Object},
    handleSearch: {type :Function},
    current: {type: Number}
  },
  setup(props) {
    const state = reactive({
      selectAllFlag: false,
      replaceFields: {children: 'children', title: 'areaName', key: 'areaId'},
      id: null,
      areaData: [],
      title: '地区配置查看'
    })
		const showData = reactive({
			visible: false,
			disabled: false

		})
		const fieldNames = {
      key: 'areaId',
      title: 'areaName',
    }


		const showDrawer = (oldData, str) => {
			if(str === 'check'){
				showData.disabled =true
        state.title = '地区信息查看'
      } else {
        state.title = '地区信息编辑'
      }
      showData.visible = true
      state.id = oldData.id
      getAreaList(oldData.id)
    }
    const checkedKeys = ref([])
    const changeCheckEvent = (e) => {
      if (e.target.checked) {
        let inList = selectAll()
        checkedKeys.value = inList
        state.selectAllFlag = true
      } else {
        checkedKeys.value = []
        state.selectAllFlag = false
      }
    }
    const selectAll = () => {
      let inList = []
      function selectList(res) {
        for (let item of res) {
          inList.push(item.areaId)
          if (item.children != null && item.children.length) {
            selectList(item.children)
          }
        }
      }
      selectList(state.areaData)
      return inList
    }
    // 获取地区信息
    const getAreaList = (id) => {
      request(getRequestURI('channel_area_list'),
        {
          data: {productId: id},
          pagination: {
            current: 1,
            pageSize: 10,
            total: 0
          },
      }).then(res => {
        if(res.code === 1){
					const checkedArr = []
          state.areaData = getAreaData(res.result, checkedArr)
          nextTick(() => {
						checkedKeys.value = checkedArr
          })
        }
      })
    }
		const getAreaData = (data,checkedArr) => {
      state.selectAllFlag = true
      if(data && data.length > 0){
        let arr = []
        data.forEach(item => {
          let node = {
            areaId: item.areaId,
            areaName: item.areaName,
            select: item.select,
            children: getAreaData(item.children, checkedArr)
          }
          arr.push(node)
          if(item.select === 1){
            checkedArr.push(item.areaId)
          }else{
            state.selectAllFlag = false
          }
        })
        return arr
      }else {
        return []
      }
    }

		// 产品渠道新增
    const onSave = () => {
        if(checkedKeys.value.length > 0){
          let data_list = []
          checkedKeys.value.forEach(s => {
            console.log(88888, s)
            data_list.push({
              areaId: s,
              productId: state.id
            })
          })
          request(getRequestURI('channel_area_save'),{data: data_list}).then(res => {
            if(res.code === 1){
              message.success('保存成功')
              getAreaList(state.id)
              props.handleSearch(props.searchfFilters, props.current)
              onClose()
            } else {
              message.warning(res.message)
            }
          })
        } else {
          message.warning('请选择地区')
        }
    }
    const handleTree = (selectedKeys, info) => {
      checkedKeys.value = selectedKeys
      console.debug('selectedKeys', selectedKeys)
      console.debug('info', info)
    }

    const onClose = () => {
      state.selectAllFlag = false
      showData.visible = false
      showData.disabled = false
    }

    return {
      onSave,
      handleTree,
      showDrawer,
      changeCheckEvent,
      ...toRefs(showData),
      ...toRefs(state),
      getAreaList,
      onClose,
      checkedKeys,
      fieldNames,
    }

  }

})
</script>

<style scoped>

</style>
