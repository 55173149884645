<style scoped>
</style>
<template>
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @resetAll="resetAll"
    @callback="handleSearch"
    @nameLinked="changes"
  ></search-form>
  <!-- 表格数据 -->
  <a-table
    :columns="columns"
    :data-source="dataSource ? dataSource['result'] : []"
    :pagination="pagination"
    @change="handleTableChange"
    :loading="loading"
    style="margin-top: 10px"
  >
    <a-spin :delay="200" />
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <a-button
          type="text"
          @click="modifyUser(record, 1)"
          style="margin-right: 10px; color: orange"
          size="small"
        >
          查看
        </a-button>
        <a-button
          type="text"
          @click="modifyUsers(record, 2)"
          style="margin-right: 10px; color: orange"
          size="small"
        >
          编辑
        </a-button>
      </template>
    </template>
  </a-table>
  <add-users ref="userDrawerRef"></add-users>
</template>
<script>
import { defineComponent, reactive, ref, toRefs, computed, onMounted } from 'vue'
import SearchForm from '../../../components/extended/search_form'
import { requestUsePagination, getRequestURI, request } from '../../../utils/request.js'
import addUsers from './addUsers'
import { message } from 'ant-design-vue'
export default defineComponent({
  components: {
    SearchForm,
    addUsers
  },
  setup () {
    const userDrawerRef = ref()
    const state = reactive({
      newFilter:{},
      // 头部搜索框
      condition: {
        supId: {
          label: '供应商名称',
          type: 'tree-select',
          urlKey: 'querySupplier',
          value: 'id',
          default: '',
          data: []
        },
        riskName: {
          label: '险种名称',
          type: 'select',
          default: '',
          data: []
        },
        riskCode: '险种编码',

      },
      columns: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left',
        },
        {
          title: '险种编码',
          width: 140,
          align: 'center',
          key: 'riskCode',
          dataIndex: 'riskCode',
        },
        {
          title: '供应商险种编码',
          width: 140,
          align: 'center',
          key: 'supRiskCode',
          dataIndex: 'supRiskCode',
        },
        {
          title: '险种名称',
          width: 140,
          align: 'center',
          key: 'riskName',
          dataIndex: 'riskName',
        },
        {
          title: '供应商名称',
          width: 140,
          align: 'center',
          key: 'supName',
          dataIndex: 'supName',
        },
        {
          title: '操作',
          key: 'operation',
          ellipsis: true,
          align: 'center',
          width: 230,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      dataSource: [],
      query: {
        data: {
          riskId: 9
        },
        pagination: {
          current: 1,
          pageSize: 10,
          total: 0
        },
        sorters: [
          {
            field: '',
            order: ''
          }
        ]
      },
    })
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('productRisk_selectListForPage'))
    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    const handleSearch = function (filters) {
      console.log('搜索参数==', filters)
      state.newFilter = filters
      run({
        pagination: {
          current: current.value,
          pageSize: pageSize.value
        },
        data: filters,
      })
    }
    // 页面发生改变回调
    const handleTableChange = (pag, filters, sorter) => {
      console.log('===', filters)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        data: state.newFilter,
        sorter,
      })
    }
    const changes = function (value) {
      request(getRequestURI('selectRisk'), {
        data: {
          supId: value,
          // riskName:''
        }
      }, true, 'post').then(
        (result) => {
          const arr = []
          const res = result.result
          for (let i = 0; i < res.length; i++) {
            arr.push({ label: res[i].riskName, value: res[i].riskName })
          }
          state.condition.riskName.data = arr
        }
      )
    }
    // 查看
    const modifyUser = function (oldData, num) {
      console.log(oldData)
      //  state.query.data.tempNode = filters.tempNode
      //   request(getRequestURI('flgl_updat'), state.query).then(res=>{
      //   //  state.list = res.result.list
      //   console.log(res,123456)
      // })
      console.log(oldData)
      userDrawerRef.value.showDrawer(oldData, num)
    }
    const modifyUsers = function (record, status) {
      console.log(record, '8989898')
      state.status = status
      request(getRequestURI('productRisk_checkUpdate'), { data: { ids: [record.id] } }).then(res => {
        if (res.code === 1) {
          userDrawerRef.value.showDrawer(record, status)
        } else {
          message.warning(res.message)
        }
      })
    }
    //  const modifyUsers = function (oldData,num) {
    //   userDrawerRef.value.showDrawer(oldData,num)
    //
    // }
    //获取险种名称数据
    const getInsuranceName = () => {
      request(getRequestURI('selectRisk'), {
        data: {
          riskName: ''
        }
      }, true, 'post').then(
        (result) => {
          const arr = []
          const res = result.result
          for (let i = 0; i < res.length; i++) {
            arr.push({ label: res[i].riskName, value: res[i].riskName })
          }
          state.condition.riskName.data = arr
        }
      )
    }
    getInsuranceName()
    const resetAll = () => {
      state.condition.riskName.data = []
      getInsuranceName()
    }
    return {
      resetAll,
      ...toRefs(state),
      modifyUser,
      modifyUsers,
      handleSearch,
      handleTableChange,
      userDrawerRef,
      pagination,
      loading,
      dataSource,
      changes
    }
  }
})
</script>
