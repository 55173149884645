<style scoped>
</style>
<template>
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @callback="handleSearch"
    @nameLinked="changes"
    @resetAll="resetAll"
  ></search-form>
  <!-- 表格数据 -->
  <a-table
    :columns="columns"
    :data-source="dataSource ? dataSource['result'] : []"
    :pagination="pagination"
    @change="handleTableChange"
    :loading="loading"
    style="margin-top: 10px"
  >
    <a-spin :delay="200" />
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <a-button
          type="text"
          @click="modifyUser1(record, 1)"
          style="margin-right: 10px; color: orange"
          size="small"
        >
          查看
        </a-button>
        <a-button
          type="text"
          @click="asd(record)"
          style="margin-right: 10px; color: orange"
          size="small"
        >
          定义投保要素值关联 </a-button
        ><br />
        <a-button
          type="text"
          @click="modifyUsers(record, 2)"
          style="margin-right: 10px; color: orange"
          size="small"
        >
          编辑
        </a-button>
        <a-button
          type="text"
          @click="modifyUserInsurance(record)"
          size="small"
          style="color: orange"
        >
          投保要素关联险种责任
        </a-button>
      </template>
    </template>
  </a-table>
  <add-user ref="userDrawerRef" :oldDataId="oldDataId"></add-user>
  <insurance-add ref="DrawerRef" :oldDataId="oldDataId"></insurance-add>
  <add-shoulder-list ref="addShoulderListRef"></add-shoulder-list>
</template>
<script>
import { defineComponent, reactive, ref, toRefs, computed, onMounted } from 'vue'
import SearchForm from '../../../components/extended/search_form'
import { requestUsePagination, getRequestURI, request } from '../../../utils/request.js'
import addUser from './addUser'
import AddShoulderList from './addShoulderList'
import insuranceAdd from './insuranceAdd.vue'
// import { message } from 'ant-design-vue'

export default defineComponent({
  components: {
    SearchForm,
    insuranceAdd, addUser, AddShoulderList
  },
  setup () {
    const DrawerRef = ref()
    const userDrawerRef = ref()
    const state = reactive({
      newFilter: {},
      // 头部搜索框
      condition: {
        supId: {
          label: '供应商名称',
          type: 'tree-select',
          urlKey: 'querySupplier',
          // urlKey: 'supplierQuerySupLevelSt',
          value: 'id',
          default: '',
          data: []
        },
        riskName: {
          label: '险种名称',
          type: 'select',
          default: '',
          data: []
        },
        riskCode: '险种编码'
      },
      columns: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left'
        },
        {
          title: '险种编码',
          width: 140,
          align: 'center',
          key: 'riskCode',
          dataIndex: 'riskCode'
        },
        {
          title: '供应商险种编码',
          width: 140,
          align: 'center',
          key: 'supRiskCode',
          dataIndex: 'supRiskCode'
        },
        {
          title: '险种名称',
          width: 140,
          align: 'center',
          key: 'riskName',
          dataIndex: 'riskName'
        },
        {
          title: '供应商名称',
          width: 140,
          align: 'center',
          key: 'supName',
          dataIndex: 'supName'
        },
        {
          title: '操作',
          key: 'operation',
          ellipsis: true,
          align: 'center',
          width: 230,
          scopedSlots: { customRender: 'operation' }
        }
      ],
      dataSource: []
    })
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value
    }))
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total
    } = requestUsePagination(getRequestURI('productRisk_selectListForPage'))

    const handleSearch = function (filters) {
      // console.log('搜索参数==', filters)
      state.newFilter = filters
      run({
        pagination: {
          current: 1,
          pageSize: 10
        },
        data: filters
      })
    }
    const changes = function (value) {
      request(getRequestURI('selectRisk'), {
        data: {
          supId: value,
          // riskName:''
        }
      }, true, 'post').then(
        (result) => {
          const arr = []
          const res = result.result
          for (let i = 0; i < res.length; i++) {
            arr.push({ label: res[i].riskName, value: res[i].riskName })
          }
          state.condition.riskName.data = arr
        }
      )
    }
    const resetAll = () => {
      changes(state.newFilter.supId)
      getInsuranceName()
    }
    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    // 页面发生改变回调
    const handleTableChange = (pag, filters, sorter) => {
      // console.log('===', filters)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        data: state.newFilter,
        sorter
      })
    }
    const asd = function (oldData) {
      DrawerRef.value.showDrawer(oldData)
      // console.log(record)
    }
    const modifyUser = function (oldData, num) {
      userDrawerRef.value.showDrawer(oldData, num)
    }
    //获取险种名称数据
    const getInsuranceName = () => {
      request(getRequestURI('selectRisk'), {
        data: {
          riskName: ''
        }
      }, true, 'post').then(
        (result) => {
          const arr = []
          const res = result.result
          for (let i = 0; i < res.length; i++) {
            arr.push({ label: res[i].riskName, value: res[i].riskName })
          }
          state.condition.riskName.data = arr
        }
      )
    }
    const modifyUser1 = function (oldData, num) {
      userDrawerRef.value.showDrawer(oldData, num,oldData.riskSign)
    }

    const oldDataId=ref('')
    const modifyUsers = function (oldData, num) {
      // request(getRequestURI('select_ListRateTable'), { data: { id: oldData.id } }).then(res => {
      //   if (res.code === 1) {
      //     userDrawerRef.value.showDrawer(oldData, num)
      //   } else {
      //     message['warning'](res.message)
      //   }
      // })
      oldDataId.value=oldData.id
      console.log('----------0-----',oldDataId.value)
      userDrawerRef.value.showDrawer(oldData, num,oldData.riskSign)
    }
    getInsuranceName()
    const addShoulderListRef = ref()
    // 险种责任关联
    const modifyUserInsurance = function (record) {
      addShoulderListRef.value.showDrawer(record)
    }




    return {
      addShoulderListRef,
      modifyUserInsurance,
      ...toRefs(state),
      asd,
      modifyUser,
      modifyUsers,
      handleSearch,
      handleTableChange,
      userDrawerRef,
      DrawerRef,
      pagination,
      loading,
      dataSource,
      getInsuranceName,
      modifyUser1,
      changes,
      resetAll,
      oldDataId
    }
  }
}
)
</script>
