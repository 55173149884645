<template>
  <div>
    <a-drawer
        title="查看产品利益公式详情"
        :width="'65%'"
        :visible="visible"
        :body-style="{ paddingBottom: '80px' }"
        :footer-style="{ textAlign: 'right' }"
        @close="onClose"
    >
      <div>
        <div class="content-top">
          <h3>基础信息: </h3>
          <a-divider style="height: 2px; background-color: #7cb305;margin: 0 0 24px"/>
          <a-form v-bind="formItemLayout" layout="horizontal" :model="basicInformation" ref="formRef"
                  autocomplete="off">
            <a-row :gutter="16">
              <a-col span="24">
                <a-form-item label="利益演示父标题" name="pTitle">
                  <a-input v-model:value="basicInformation.pTitle" :disabled="!isEdit"
                           placeholder="请输入利益演示父标题"/>
                </a-form-item>
              </a-col>
              <a-col span="24">
                <a-form-item label="利益演示标题" name="title">
                  <a-input v-model:value="basicInformation.title" :disabled="!isEdit"
                           placeholder="请输入利益演示标题"/>
                </a-form-item>
              </a-col>
              <a-col span="24">
                <a-form-item label="利益演示描述" name="desc">
                  <a-input v-model:value="basicInformation.desc" :disabled="!isEdit"
                           placeholder="请输入利益演示描述"/>
                </a-form-item>
              </a-col>
              <a-col span="24">
                <a-form-item label="类型" required name="type">
                  <a-radio-group :disabled="!isEdit" v-model:value="basicInformation.type">
                    <a-radio value="Y">必选</a-radio>
                    <a-radio value="N">可选</a-radio>
                  </a-radio-group>
                </a-form-item>
              </a-col>
              <a-col span="24">
                <a-form-item label="排序" name="sort">
                  <a-input v-model:value="basicInformation.sort" type="number" :disabled="!isEdit"
                           placeholder="请输入顺序"/>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <div class="content-bottom">
          <h3>公式:
            <span class="button-save">
                <a-button type="primary" :disabled="!isEdit" @click="onSaveFormula">
              <template #icon>
                <FileAddOutlined/>
              </template>
              新增
            </a-button>
              </span>
          </h3>
          <a-divider style="height: 2px; background-color: #7cb305;margin: 0 0 24px"/>
          <!--          <div v-if="basicInformation.formulaObjList">-->
          <div v-if="conditionList">
            <div v-for="(fitem, findex) in conditionList" :key="fitem.id + '_' + findex">
              <FormulaItem :formula-list="fitem" :is-edit="isEdit" @getSelectFormulasForGuarantee="getSelectFormulasForGuarantee({guaranteeId: formulaConfig.guaranteeId})" @opFormula="editFprmula"/>
            </div>
          </div>
        </div>
      </div>
      <template #extra>
        <a-space>
          <a-button @click="onClose">
            <template #icon>
              <CloseOutlined/>
            </template>
            关闭
          </a-button>
          <a-button type="primary" v-if="isEdit" @click="onSave">
            <template #icon>
              <SaveOutlined/>
            </template>
            提交
          </a-button>
        </a-space>
      </template>
    </a-drawer>

    <!-- 新增公式配置 @ok="insuranceErrorHandleOk('add')" @cancel="insuranceErrorHandleOk('close')" okText="提交" cancelText="关闭"-->
    <a-modal style="width: 80%;"  v-model:visible="isShowFormulaConfig"
             :keyboard="false" :maskClosable="false" :closable="false" >
      <div v-if="isShowFormulaConfig" class="modal-content">
        <h3>公式配置: </h3>
        <a-divider style="height: 2px; background-color: #7cb305;margin: 0 0 24px"/>
        <p>公式预览: {{ gsprew }}</p>
        <a-form :model="formulaConfig" :wrapper-col="{ span: 12 }" layout="horizontal" ref="formulaRef"
                autocomplete="off">
          <a-row :gutter="5">
            <a-col span="8">
              <a-form-item label="投保要素" name="calculateType" required>
                <a-select
                    class="inputWidth"
                    v-model:value="formulaConfig.calculateType"
                    show-search
                    :disabled="isCheck"
                    placeholder="请选择投保要素类型"
                    :options="formulaValueTypeList"
                    :field-names="{ label: 'elementName', value: 'factorType', key: 'factorId' }"
                />
              </a-form-item>
            </a-col>
            <a-col span="8">
              <a-form-item label="操作符号" name="fourOperations" required>
                <a-select
                    class="inputWidth"
                    :options="operationSymbolsList"
                    v-model:value="formulaConfig.fourOperations"
                    show-search
                    :disabled="isCheck"
                    placeholder="请选择投保要素类型"
                />
              </a-form-item>
            </a-col>
            <a-col span="8">
              <a-form-item label="值"
                           :rules="[{pattern:/^-?\d+(\.\d{2})?$/, message: '只支持整数,小数(保留2位小数),负数' ,trigger: 'change'}]"
                           name="calculateValue" required>
                <a-input :disabled="isCheck" v-model:value="formulaConfig.calculateValue" placeholder="请输入值"/>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
        <!--  条件配置-->
        <div class="condition-config">
          <h3>条件配置:</h3>
          <a-divider style="height: 2px; background-color: #7cb305;margin: 0 0 24px"/>
          <div>
            <p>约束场景, 关系为或(可配置多条,满足其一即可达成执行公式的要求): </p>
            <div class="condition" v-if="formulaConfig.conditions && formulaConfig.conditions.length > 0">
              <div v-for="(item, pIndex) in formulaConfig.conditions" :key="pIndex" class="condition-card">
                <div class="card-title">场景{{pIndex+1}}:</div>
                <div v-for="(cond, index) in item.sonConditions" :key="index" >
                  <div class="card-content">{{index+1}}: {{cond.label}}</div>
                </div>
                <div class="card-button" v-if="!isCheck">
                  <span @click="onDelCondition(pIndex)">删除</span>
                  <span @click="onEditCondition(pIndex,item)">编辑</span>
                </div>
              </div>

            </div>
            <div v-else class="flex_class">
              <a-empty/>
            </div>
            <div style="text-align: right; margin-top: 28px" v-if="!isCheck">
              <a-button type="primary" @click="onSaveConditionConfig('add')">
                <template #icon>
                  <FileAddOutlined/>
                </template>
                新增条件配置
              </a-button>
            </div>
          </div>
        </div>

      </div>
      <template #footer>
        <a-button @click="insuranceErrorHandleOk('close')">关闭</a-button>
        <a-button type="primary" v-if="!isCheck" @click="insuranceErrorHandleOk('add')">提交</a-button>
      </template>
    </a-modal>
    <!--    新增条件配置-->
    <a-modal :keyboard="false" :maskClosable="false" destroyOnClose style="width: 80%;" :closable="false" v-model:visible="isShowSaveConditionConfig" @ok="handleSaveCondition"
             @cancel="onCloseSaveCondition" okText="提交" cancelText="关闭">
      <condition-config :formulaDetailData="formulaDetailData"
                        :conditionTypeList="conditionTypeList"
                        v-model:sonConditionList="sonConditionList"
                        v-model:pIndex="pIndex"
                        :operationType="operationType"
      ></condition-config>
    </a-modal>
  </div>

</template>
<script>
import {CloseOutlined, FileAddOutlined, SaveOutlined} from '@ant-design/icons-vue'
import {computed, onMounted, reactive, ref, toRefs, watch} from 'vue'
import {getRequestURI, requestUsePagination} from '../../../../utils/request'
import ConditionConfig from './ConditionConfig'
import {getDictionaryData} from '@/utils/request_extend'
import {request} from '../../../../utils/request.js'
import {message, Modal} from 'ant-design-vue'
import FormulaItem from './FormulaItem.vue'
import {newFormulaList} from '../../../../utils/utlis.js'
// import {deepClone} from '../../../../utils/utlis.js'

export default {
  name: 'FormulaConfigMod',
  props: {
    formulaDetailData: {
      type: Object,
      default: () => {
      }
    }
  },
  components: {
    FormulaItem,
    CloseOutlined,
    SaveOutlined,
    FileAddOutlined, ConditionConfig

  },
  setup() {
    const formItemLayout = {
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 12,
      },
    }
    const state = reactive({
      formulaValueTypeList: [],
      isEdit: false,
      isCheck: false,
      guaranteeId: '',
      // 公式配置list
      conditionConfigList: [],
      visible: false,
      isShowFormulaConfig: false,
      refreshFlag: true,
      isShowSaveConditionConfig: false,
      basicInformation: {
        pTitle: '',
        title: '',
        desc: '',
        type: 'Y',
        sort: '0',
        formulaObjList: [
          {
            id: 1,
            formula: {
              calculateType: '1',
              fourOperations: 'x',
              calculateValue: 'Amnt',
              participateInCalculations: 0.2
            },
            relationORConditions: [
              {
                condition: {
                  id: 'son1',
                  conditionType: '1',
                  calculateType: 'sexAge',
                  formulaId: '1',
                  calculateId: '1',
                  haveParent: 'N',
                  calculateMaxValue: 10,
                  calculateMinValue: 1,
                  dutyCode: [],
                  relationANDConditions: []
                }
              },
              {
                condition: {
                  id: 'son2',
                  conditionType: '1',
                  calculateType: 'Year',
                  formulaId: '1',
                  calculateId: '1',
                  haveParent: 'N',
                  calculateMaxValue: 10,
                  calculateMinValue: 1,
                  dutyCode: [],
                  relationANDConditions: []
                }
              },
              {
                condition: {
                  id: 'son3',
                  conditionType: '1',
                  calculateType: '1',
                  formulaId: '1',
                  calculateId: '1',
                  haveParent: 'N',
                  calculateMaxValue: 10,
                  calculateMinValue: 1,
                  dutyCode: [],
                  relationANDConditions: []
                }
              },
            ]
          },
          {
            id: 1,
            formula: 'a+b-c',
            relationORConditions: [
              {condition: 'a>15'},
              {condition: 'b>30'},
              {condition: 'c<10'},
            ]
          },
          {
            id: 2,
            formula: 'a+b-c',
            relationORConditions: [
              {condition: 'a>15'},
              {condition: 'b>30'},
              {condition: 'c<10'},
            ]
          }
        ],
      },
      // 提交的上传的对象
      ProspectusBenefitGuaranteeForm: {
        //  父级标识 Y/N
        parentName: '',
        /**
         * 保障名
         */
        guaranteeName: '',
        // /**
        //  * 保障编码
        //  */
        // guaranteeCode: '',

        /**
         * 险种id
         */
        riskId: '',
        /**
         * 险种编码
         */
        riskCode: '',

        /**
         * 是否可选 Y,N
         */
        isSelected: '',
        /**
         * 排序
         */
        sort: '0',

      },
      columns: [
        {
          key: 'index',
          title: '序号',
          width: 60,
          align: 'left',
          fixed: 'left',
        },
        {
          title: '公式',
          width: 180,
          align: 'center',
          key: 'elementName',
          dataIndex: 'elementName',
        },
        {
          title: '场景',
          width: 180,
          key: 'elementName',
          dataIndex: 'elementName',
          align: 'center',
        },
        {
          title: '操作',
          key: 'operation',
          ellipsis: true,
          align: 'center',
          fixed: 'right',
          width: 120,
          scopedSlots: {customRender: 'operation'}
        },
      ],
      lastFilter: {},
      // 公式配置
      gsprew: '基础保额 * 20%',
      formulaConfig: {
        id: '',
        guaranteeId: '',
        calculateType: undefined,
        fourOperations: undefined,
        calculateValue: undefined,
        participateInCalculations: undefined,
        conditions: []
      },
      conditionList: [],
      // 一条场景下的 条件集合
      sonConditionList: [],
      pIndex: undefined,
      operationType: '',
      riskData: {},
      typeList: []
    })
    // 公式的对象
    const ProspectusBenefitFormulaBean = reactive({
      id: undefined,
      /**
       * 参与计算的类型（投保要素、责任、其他）
       */
      calculateType: undefined,

      /**
       * 四则运算
       */
      fourOperations: undefined,

      /**
       * 参与计算的值
       */
      calculateValue: undefined,

      /**
       * 被计算的值
       */
      participateInCalculations: undefined,

      /**
       * 关系为 或 的子集条件
       */
      relationORConditions: []
    })
    const formulaRef = ref()

    const showDrawer = (formulaDetailData, oldData, type, typeList) => {
      console.log(oldData, type, typeList)
      getSelectFormulasForGuarantee(oldData)
      state.riskData = formulaDetailData
      state.typeList = typeList.value
      getSelectFactorsAndDuties(state.riskData)
      state.visible = true
      if (type === 'edit') {
        state.isEdit = true
      } else {
        state.isEdit = false
      }
      initDate(oldData, typeList)
    }
    const getSelectFormulasForGuarantee = (oldData) => {
      state.conditionList = []
      request(getRequestURI('selectFormulasForGuarantee'), {data:{code: oldData.guaranteeId}}).then(res => {
        if(res.code === 1){
          if (res.result && res.result.length > 0) {
            initGSForm(res.result, state.typeList)
          }
        }
      })
    }


    const initDate = (item) => {
      console.log(6666, item)
      state.formulaConfig.guaranteeId = item.guaranteeId
      state.conditionList = []
      state.basicInformation.pTitle = item.parentName
      state.basicInformation.title = item.guaranteeName
      state.basicInformation.type = item.isSelected || 'N'
      state.basicInformation.desc = item.guaranteeDescription
      state.basicInformation.sort = item.sort || '0'

    }

    const initGSForm = (formulas, typeList) => {
      for (let i = 0; i < formulas.length; i++) {
        let item = newFormulaList(formulas[i], typeList)
        state.conditionList.push(item)
      }
      console.log('=====================',state.conditionList, formulas)

    }

    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('prospectusList'))
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))

    const searchFilters = ref({})
    const handleSearch = function (filters, num = '') {
      if (filters) {
        searchFilters.value = filters
      }
      state.lastFilter = filters
      run({
        pagination: {
          current: num ? num : 1,
          pageSize: 10
        },
        data: filters,
      })
    }
    const handleTableChange = (pag, filters, sorter) => {
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.lastFilter,
      })
    }

    // 新增公式
    const onSaveFormula = async () => {
      if (state.formulaConfig.guaranteeId) {
        state.isShowFormulaConfig = true
      } else {
        state.formulaConfig.guaranteeId = await postBaseInfo()
        if (state.formulaConfig.guaranteeId) {
          state.isShowFormulaConfig = true
        }
      }
    }


    const postBaseInfo = async () => {
      let uri = getRequestURI('insertGuarantee')
      let param = {
        data: {}
      }
      if (!state.basicInformation.title) {
        message.warn('请输入标题')
        return
      }
      if (!state.basicInformation.desc) {
        message.warn('请输入描述')
        return
      }
      if (state.formulaConfig.guaranteeId) {
        state.ProspectusBenefitGuaranteeForm.id = state.formulaConfig.guaranteeId
        uri = getRequestURI('updateGuarantee')
      }
      state.ProspectusBenefitGuaranteeForm.riskCode = state.riskData.riskCode
      state.ProspectusBenefitGuaranteeForm.riskId = state.riskData.id
      state.ProspectusBenefitGuaranteeForm.isSelected = state.basicInformation.type
      state.ProspectusBenefitGuaranteeForm.parentName = state.basicInformation.pTitle
      state.ProspectusBenefitGuaranteeForm.guaranteeName = state.basicInformation.title
      state.ProspectusBenefitGuaranteeForm.guaranteeDescription = state.basicInformation.desc
      state.ProspectusBenefitGuaranteeForm.sort = state.basicInformation.sort
      param.data = state.ProspectusBenefitGuaranteeForm
      console.log(param)

      return await request(uri, param).then((res) => {
        console.log(res)
        if (res.code === 1) {
          return res.result
        }
        return undefined
      }).catch((err) => {
        console.log(err)
        return undefined
      })
    }

    const onSave = () => {
      postBaseInfo()
      onClose('end')
    }
    // 新增/ 编辑公式
    const onSaveFormulaConfig = (guaranteeId) => {
      request(getRequestURI('insertFormula'), {data: state.formulaConfig}).then((res) => {
        console.log(888, res, state.formulaConfig)
        if(res.code === 1){
          getSelectFormulasForGuarantee({guaranteeId})
        }
      })
    }
    const insuranceErrorHandleOk = (type) => {
      if(type === 'add'){
        onSaveFormulaConfig(state.formulaConfig.guaranteeId)
      }
      formulaRef.value.resetFields()
      state.formulaConfig = {
        id: '',
        guaranteeId: state.formulaConfig.guaranteeId,
        calculateType: undefined,
        fourOperations: undefined,
        calculateValue: undefined,
        conditions: []
      }
      state.sonConditionList = []
      state.pIndex = undefined
      state.operationType = ''
      state.gsprew = '基础保额 * 20%'
      state.riskData = {}
      state.isShowFormulaConfig = false

    }

    const onClose = (flag) => {
      state.visible = false
      console.log(flag)
      // if (flag === 'end') {
      //   context.emit('closeDrawer')
      // }
    }
    // 场景删除
    const onDelCondition = (pIndex) =>{
      Modal.confirm({
        title: '删除提示',
        content: '是否删除已选择的场景信息吗？',
        onOk: () => {
          state.formulaConfig.conditions.splice(pIndex, 1)
        },
        onCancel: () => {
          Modal.destroyAll()
        }
      })
    }
    // 场景编辑
    const onEditCondition = (pIndex, pData) => {
      console.log(pIndex, pData)
      state.pIndex = pIndex
      state.sonConditionList = pData.sonConditions
      state.isShowSaveConditionConfig = true
      state.isShowFormulaConfig = false
    }

    // 新增条件配置
    const onSaveConditionConfig = (type) => {
      state.sonConditionList = []
      state.pIndex = undefined
      state.operationType = type
      state.isShowSaveConditionConfig = true
      state.isShowFormulaConfig = false
    }
    // 新增条件配置提交
    const handleSaveCondition = () => {
      console.log(state)
      if (state.pIndex !== undefined) {
        state.formulaConfig.conditions[state.pIndex].sonConditions = state.sonConditionList
      }else{
        let obj = {
          id: '',
          haveParent: '',
          conditionType: '',
          calculateType: '',
          calculateId: '',
          showValue: '',
          calculateMaxValue: '',
          calculateMinValue: '',
          dutyCode: [],
          label: '',
          sonConditions: state.sonConditionList
        }
        state.formulaConfig.conditions.push(obj)
      }
      onCloseSaveCondition()
    }
    // 新增条件配置关闭
    const onCloseSaveCondition = () => {
      state.isShowSaveConditionConfig = false
      state.isShowFormulaConfig = true
      // state.sonConditionList = []
      // state.pIndex = undefined
    }
    const operationSymbolsList = ref()
    const conditionTypeList = ref()
    onMounted(() => {
      getDictionaryData(conditionTypeList, 'condition_type')
      getDictionaryData(operationSymbolsList, 'four_operations')
    })

    // 获取投保要素类型 可选责任
    const getSelectFactorsAndDuties = (item) => {
      request(getRequestURI('selectFactorsAndDuties'), {data: {code: item.id}}).then(res => {
        if (res.code === 1) {
          state.formulaValueTypeList = res.result.factors
        }
      })
    }
    const getLabelByKey = (key) => state.formulaValueTypeList.find(item => item.factorType === key)?.elementName
    // const getOPLabelByKey = (key) => {
    //   if (key && key.label) {
    //     return key.label
    //   }
    //   return ''
    // }

    const getVLabelByKey = (key) => {
      return key || ''
    }

    /**
     *
     * @param item 公式对象
     * @param type 编辑查看标识
     */
    const editFprmula = (item, type) => {
      initGSModal(item)
      if (type === 'edit') {
        console.log('编辑------>', item)
      } else {
        console.log('查看------>', item)
      }
      state.isShowFormulaConfig = true
      state.isCheck = type === 'check'
      item.relationORConditions.forEach(item => {
        if(item.relationANDConditions.length > 0){
          item.sonConditions = item.relationANDConditions
          item.sonConditions.forEach(child => {child.label = child.conditions_str})
        }
      })
      Object.assign(state.formulaConfig, {...state.formulaConfig,...item, conditions: item.relationORConditions})

    }

    // const getSymbols = (value) => {
    //   return operationSymbolsList.value.find(item => item.value === value)
    // }

    const initGSModal = (item) => {
      state.formulaConfig.calculateType = item.formula.calculateType
      state.formulaConfig.fourOperations = item.formula.fourOperations
      // state.formulaConfig.calculateValue = item.formula.participateInCalculations
      state.formulaConfig.calculateValue = item.formula.calculateValue
    }

    watch(() => state.formulaConfig, (val) => {
          console.log('state.formulaConfig--------》', val)
      if(state.formulaConfig.calculateType && operationSymbolsList.value && state.formulaConfig.calculateValue){
        state.gsprew = getLabelByKey(state.formulaConfig.calculateType) + operationSymbolsList.value.find(item => item.value === state.formulaConfig.fourOperations)?.label + getVLabelByKey(state.formulaConfig.calculateValue)
      }
    }, {deep: true})
    return {
      conditionTypeList,
      operationSymbolsList,
      insuranceErrorHandleOk,
      ...toRefs(state),
      ...toRefs(ProspectusBenefitFormulaBean),
      showDrawer,
      onClose, onSave,
      formItemLayout,
      dataSource,
      formulaRef,
      run,
      loading,
      pagination,
      current,
      pageSize,
      total,
      searchFilters,
      handleTableChange,
      handleSearch,
      onSaveFormula,
      onSaveConditionConfig,
      handleSaveCondition,
      onCloseSaveCondition,
      editFprmula,
      onDelCondition,
      onEditCondition,
      onSaveFormulaConfig,
      getSelectFormulasForGuarantee


    }
  }
}
</script>


<style scoped>
.content-top {
  margin-bottom: 22px;
}

.content-top h3 {
  font-weight: bold;
}

.content-bottom h3 {
  font-weight: bold;
}

.button-save {
  margin-left: 30px;
}

.condition {
  max-height: 350px;
  overflow: auto;
  padding: 28px 0;
}

.condition::-webkit-scrollbar {
  display: none;
}

.condition-card {
  margin: 0 8px 12px;
  min-height: 100px;
  border: 1px solid #e8e8e8;
  padding: 10px;
  border-radius: 8px;
  font-size: 14px;
}

.condition-card .card-title {
  font-weight: bold;
}

.condition-card .card-content {
  padding: 10px 22px;
}

.condition-card .card-button {
  text-align: right;
}

.condition-card .card-button span {
  display: inline-block;
  width: 64px;
  height: 28px;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #006eff;
  color: #006eff;
}

.condition-card .card-button span:last-child {
  margin-left: 10px;
  background: #006eff;
  color: #fff;
}

.flex_class {
  margin: 30px auto 0;
  color: #cecece;
}

</style>
