<style scoped>
</style>

<template>
  <a-drawer
    title="产品信息分享配置"
    :width="'45%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form
      :model="formData"
      layout="horizontal"
      ref="formRef"
      autocomplete="off"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
    >
      <a-row :gutter="10">
        <a-col span="20">
          <a-form-item
            :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]"
            label="是否允许微信分享:" name="shareChat">
            <a-select :options="receiptTypeList" v-model:value="formData.shareChat" allow-clear
                      placeholder="请选择是否允许微信分享"
                      :getPopupContainer="(triggerNode)=>triggerNode.parentNode">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="20">
          <a-form-item
            :rules="[{ required: formData.shareChat==='Y', message: '必填信息' ,trigger: 'change'},
            {max: 30, message: '输入长度不能超过30' ,trigger: 'change'}
            ]"
            label="微信分享标题:" name="shareTitle">
            <a-input
              v-model:value="formData.shareTitle"
              placeholder="请输入微信分享标题"
            />
          </a-form-item>
        </a-col>
        <a-col span="20">
          <a-form-item
            :rules="[{ required: formData.shareChat==='Y', message: '必填信息' ,trigger: 'change'},
            {max: 50, message: '输入长度不能超过50' ,trigger: 'change'}
            ]"
            label="微信分享副标题:" name="shareSecondTitle">
            <a-input
              v-model:value="formData.shareSecondTitle"
              placeholder="请输入微信分享副标题"
            />
          </a-form-item>
        </a-col>
        <a-col span="20">
      <a-form-item label="微信分享图标:" name="sharePhoto">
        <div @click="insertImg" :class="{none:formData.shareChat==='N'}"
             style="width: 105px; height: 105px;border: 1px solid antiquewhite;font-size: 30px;text-align: center;line-height: 95px">
          <img :src="formData.sharePhoto" v-if="formData.sharePhoto !== ''" style="width: 100%; height:100%"/><a
          v-if="formData.sharePhoto == ''">+</a></div>
        <p style="color: #bbbbbb;">用于微信分享展示</p>
      </a-form-item>
    </a-col>
      </a-row>
    </a-form>


    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button type="primary" @click="onSave">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
    <editfromImgList ref="editfromImgListRef" @List="List"></editfromImgList>
  </a-drawer>
</template>
<script>
import {CloseOutlined, SaveOutlined} from '@ant-design/icons-vue'
import {defineComponent, reactive, toRefs, ref, onMounted} from 'vue'
import {getDictionaryData} from '@/utils/request_extend'
import EditfromImgList from '@/pages/product/informationManage/editfromImgList'
import {getRequestURI, request} from '@/utils/request'
import {message} from 'ant-design-vue'
export default defineComponent({
  components: {
    EditfromImgList,
    CloseOutlined, SaveOutlined
  },
  props:{
    handleSearch:{
      type: Function
    },
    searchNow:{
      type: Object
    },
    current: {
      type: Number,
      default: () => 1
    }
  },
  setup(props) {
    const formRef = ref()
    const state = reactive({
      visible: false,
      formData: {
        id:'',
        productInfoId: '',
        shareChat: 'N',
        shareTitle: '',
        shareSecondTitle: '',
        sharePhoto: ''
      }
    })
    const List = function (list) {
      state.formData.sharePhoto = list[0].pictureUrl
    }

    const showDrawer = (oldData = {}) =>{
      state.visible = true
      state.formData.productInfoId = oldData.id
      let data = {
        productId: oldData.id
      }
      request(getRequestURI('share_details'), {data}).then(res =>{
        if(res.code === 1){
          if(res.result){
            Object.keys(state.formData).forEach(key => {
              state.formData[key] = res.result[key]
            })
          }else{
            state.formData = {
              id:'',
              productInfoId: oldData.id,
              shareChat: 'N',
              shareTitle: '',
              shareSecondTitle: '',
              sharePhoto: ''
            }
          }

        }
      })
    }
    const editfromImgListRef = ref()

    const insertImg = () =>{
      editfromImgListRef.value.showDrawer(3)
    }
    // 保存
    const onSave = async () =>{
      await formRef.value.validateFields()
      request(getRequestURI('share_add'), {data:state.formData}).then(res =>{
        if(res.code === 1){
          onClose()
          props.handleSearch(props.searchNow, props.current)
          message.success('产品分享配置成功')
        }else{
          message.warning(res.message)
        }
      })
    }

    const onClose = () =>{
      formRef.value.resetFields()
      formRef.value.clearValidate()
      state.visible = false
    }
    const receiptTypeList = ref([])
    const initTypeList = () =>{
      getDictionaryData(receiptTypeList, 'yes_no')
    }
    onMounted(()=>{
      initTypeList()
    })

    return {
      ...toRefs(state),
      formRef,
      receiptTypeList,
      editfromImgListRef,
      showDrawer,
      onClose,
      initTypeList,
      insertImg,
      List,
      onSave
    }
  }

})
</script>
