<template>
  <a-drawer
    :title="isNew"
    :width="'75%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <search-form :Condition="condition" :ColSpan="12" @callback="handleSearch"></search-form>
    <div class="a-check">
      <poster-tab ref="delrt" @getList="getList" :isShow="isShow" :dataInsert="1" :data="tab_data" :dataType="dataType"></poster-tab>
    </div>
    <a-pagination
      v-model:current="pagination.current"
      v-model:pageSize="pagination.pageSize"
      :total="pagination.total"
      @change="onShowSizeChange"
    />
  </a-drawer>
</template>

<script>
import {defineComponent, nextTick, reactive, toRefs} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
import {message} from 'ant-design-vue'
import SearchForm from '../../../components/extended/search_form'
import posterTab from '../../../components/extended/posterTabb'
export default defineComponent({
  components: {
    SearchForm,
    posterTab
  },
  setup(props, {expose,emit}) {
    const state = reactive({
      stu: undefined,
      isNew: '产品素材图片',
      visible: false,
      isShow: '2',
      activeKey: '1',
      idList: [],
      tab_data: [],
      dataType: 'img',
      condition: {
        productName: {
          label: '产品名称',
          placeholder: '请输入',
          type: 'input',
        },
        status: {
          label: '上架状态',
          type: 'select',
          default: '1',
          data: [
            {value: '0', label: '待上架'},
            {value: '1', label: '上架'},
            {value: '2', label: '下架'},
          ],
        },
      }
    })
    const pagination = reactive({
      current: 1,
      pageSize: 8,
      total: 0
    })
    // 查询
    const handleSearch = (filters) => {
      request(getRequestURI('productMaterial_selectProductMaterial'),{
        data:{
          ...filters,
          // createTimeStart: createTimeStart,
          // createTimeEnd:createTimeEnd,
          // updateTimeStart:updateTimeStart,
          // updateTimeEnd: updateTimeEnd,
          type: state.activeKey,
          // labelNameList:[...state.treeList]
        },
        pagination:{
          current: pagination.current,
          pageSize: pagination.pageSize
        }
      }).then(res => {
        console.log('切换的res', res)
        if(res.code === 1){
          state.tab_data = getResult(res.result)
          nextTick(()=>{
            Object.assign(pagination,res.pagination)
          })
          if(res.result.length > 0) {
            state.isDisabled = true
          }
        }else{
          message.warning(res.message)
        }
      })
    }
    handleSearch({type: 1})
    const showDrawer = (value) => {
      if (value === 1) {
        state.stu = 1
      } else if(value === 2){
        state.stu = 2
      }else{
        state.stu= 3
      }
      state.visible = true
    }
    const getResult = (res) => {
      res.forEach(item => {
        if(item.isTop === '1'){
          item.isTop = true
        }else{
          item.isTop = false
        }
        if(item.status === '0'){
          item.status = '待上架'
        }else if(item.status === '1'){
          item.status = '上架'
        }else{
          item.status = '下架'
        }
      })
      return res
    }
    const getList = (value) =>{
      state.idList = []
      console.log(value)
      if(value && value.length > 0){
        Object.assign(state.idList, [...value])
        emit('List', state.idList,state.stu)
        state.visible = false
      }
    }
    // 分页
    const onShowSizeChange = (current, pageSize) => {
      pagination.current = current
      pagination.pageSize = pageSize
      handleSearch({})
    }
    const onClose = function() {
      state.visible = false
    }
    expose({
      showDrawer
    })
    return {
      ...toRefs(state),
      onClose,
      handleSearch,
      pagination,
      onShowSizeChange,
      getList,
      getResult,
      showDrawer
    }
  }
})
</script>

<style scoped>
.a-check {
  width: 100%;
  margin: 20px 0 30px 30px;
}
</style>
