<style>
</style>
<template>
  <a-drawer
    :title="isSHowTitle ?  '利益演示配置查看': '利益演示配置编辑'"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
      <a-form
        :model="data"
        name="basic"
        :wrapper-col="{ span: 16 }"
        autocomplete="off"
        ref="formRef"
      >
        <a-row :gutter="10">
<!--          <a-col style="display: block;">-->
<!--            <a-form-item label="生成模板">-->
<!--              <a-button type="primary" :disabled="isSHowTitle" @click="onGenerate">生成模板</a-button>-->
<!--            </a-form-item>-->
<!--          </a-col>-->
          <!--          <a-col span="24">-->
          <!--            <a-form-item  label="文件导入" :rules="[{required:true,message:'请选择文件',trigger:'change'}]">-->
          <!--              <upload-file-new v-if="!isShowFormData" ref="uploadRef" :disabled="isSHowTitle" :num="1" listType="picture"-->
          <!--                               @fileChange="handleChange"-->
          <!--                               :is-read-excel="isReadExcel"-->
          <!--                               @excel_first_row="excel_first_row"></upload-file-new>-->
          <!--              <div class="updateUpload" v-if="isShowFormData">-->
          <!--                <a href="javascript:void(0)" @click="onPreviewFile">{{data.benefitShowFileName}}</a>-->
          <!--                <DeleteOutlined :disabled="isSHowTitle" style="margin-left: 10px" @click="handleRemove"/>-->
          <!--              </div>-->
          <!--            </a-form-item>-->
          <!--          </a-col>-->
        </a-row>
      </a-form>
    <div>
      <div>建表提示:</div>
      <p>
        &nbsp;&nbsp;&nbsp;1、表头为出生日期/投保年龄等用于展示年龄数据时，请填写整数，如18岁，填“18”即可；<br/>
        &nbsp;&nbsp;&nbsp;2、表头为性别列时，与要素值字典对应，为1-男，2-女，即男性填写“1”，女性填写“2”；<br/>
        &nbsp;&nbsp;&nbsp;3、表头为保险期限列时，保一年: 填写“1Y”、保一月填写“1M”、保到60岁填写“60A”，保终身填写“L”或“105A”；<br/>
        &nbsp;&nbsp;&nbsp;4、表头为缴费方式列时，趸交要填写成“0”、月交填写“1”、季交填写“3”、半年交填写“6”、年交填写“12”、不定期交填写“-1”；<br/>
        &nbsp;&nbsp;&nbsp;5、表头为缴费年前列时，趸交填写“0”、5年交填写“5Y”、10年交填写“10Y”，交至60岁填写“60A”、依次类推；<br/>
        &nbsp;&nbsp;&nbsp;6、当数据为金额时，则直接填写数额，不需要填写单位；</p>
    </div>
<!--    上传之后展示-->
    <div v-if="isShowFormData">
      <a-table :columns="columns" :data-source="formData"
               :pagination="false"
               :scroll="{ x: 1300 }"
               :row-key="(record) => record.id" :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChanges}">
        <template #bodyCell="{ column,index,  record }">
          <template v-if="column.key === 'index'">
            {{ index + 1 }}
          </template>
          <template v-if="column.key === 'excelHeaderName'">
            <a-select
              v-model:value="record.excelHeaderName"
              placeholder="请选择"
              show-search
              :disabled="isSHowTitle"
              :options="excelHeaderNameList"
              :filter-option="filterOption"
              style="width: 200px"
            ></a-select>
          </template>
          <template v-if="column.key === 'benefitShowType'">
            <a-select
              v-model:value="record.benefitShowType"
              placeholder="请选择"
              show-search
              :disabled="isSHowTitle"
              :options="benefitShowTypeList"
              :filter-option="filterOption"
              style="width: 200px"
            ></a-select>
          </template>
          <template v-if="column.key === 'isShow'">
            <a-select
              v-model:value="record.isShow"
              placeholder="请选择"
              :disabled="isSHowTitle"
              :options="showList"
              style="width: 100px"
            ></a-select>
          </template>
<!--          <template v-if="column.key === 'isCalculate'">-->
<!--            <a-select-->
<!--              v-model:value="record.isCalculate"-->
<!--              placeholder="请选择"-->
<!--              :disabled="isSHowTitle"-->
<!--              :options="showList"-->
<!--              style="width: 100px"-->
<!--            ></a-select>-->
<!--          </template>-->
          <template v-if="column.key === 'sort'">
            <a-input :disabled="isSHowTitle" style="text-align: center" v-model:value="record.sort" placeholder="请输入显示顺序">
            </a-input>
          </template>

        </template>
      </a-table>
      <a-button v-if="!isSHowTitle" @click="add" >增加一行</a-button>&nbsp;
      <a-button v-if="!isSHowTitle" @click="del" >删除</a-button>&nbsp;
    </div>
      <template #extra>
        <a-space>
          <a-button @click="onClose">
            <template #icon>
              <CloseOutlined/>
            </template>
            关闭
          </a-button>
          <a-button type="primary" v-if="!isSHowTitle" @click="onSave">
            <template #icon>
              <SaveOutlined/>
            </template>
            提交
          </a-button>
        </a-space>
      </template>
  </a-drawer>
</template>
<script>
import {CloseOutlined, SaveOutlined} from '@ant-design/icons-vue'
import {message} from 'ant-design-vue'
import {defineComponent, nextTick, onMounted, reactive, ref, toRefs} from 'vue'
import {getRequestURI, request} from '@/utils/request'
import {getDictionaryData} from '@/utils/request_extend'
// import UploadFileNew from '../../../components/extended/uploadFileNew.vue'
// import uploadFiles from "../../../components/extended/uploadFile.vue";

export default defineComponent({
  components:{
    // uploadFiles,
    // UploadFileNew,
    CloseOutlined,
    // DeleteOutlined,
    SaveOutlined,
  },
  setup() {
    const addUpdateDrawer = ref()
    const data = reactive({
      fileUrl: '',
      benefitShowFileName: '',
    })
    const state = reactive({
      riskCode: null,
      time: null,
      isUpdate: false,
      visible: false,
      isReadExcel: true,
      isShowFormData: true,
      isSHowTitle: true,
      isUpdateUpload: false,
      excelHeaderList: [],
      selectedRowKeys: [],
      showImg: [],
      headers: [],
      columns: [
        {
          title: '序号',
          width: 50,
          align: 'center',
          fixed: 'left',
          key: 'index',
          dataIndex: 'index'
        },
        {
          title: '配置名称',
          width: 120,
          align: 'center',
          key: 'excelHeaderName',
          dataIndex: 'excelHeaderName'
        },
        {
          title: '配置类型',
          width: 120,
          align: 'center',
          key: 'benefitShowType',
          dataIndex: 'benefitShowType'
        },
        {
          title: '是否显示',
          width: 100,
          align: 'center',
          key: 'isShow',
          dataIndex: 'isShow'
        },
        // {
        //   title: '是否计算',
        //   width: 100,
        //   align: 'center',
        //   key: 'isCalculate',
        //   dataIndex: 'isCalculate'
        // },
        {
          title: '显示顺序',
          width: 50,
          // fixed: 'right',
          align: 'center',
          key: 'sort',
          dataIndex: 'sort'
        },
      ],
      formData: []
    })
    const showList = ref()
    const benefitShowTypeList = ref()
    const excelHeaderNameList = ref()
    const uploadRef = ref()

    const showDrawer = (result = null, oldData, str) => {
      state.isSHowTitle = str === 'check'
      state.visible = true
      state.riskCode = oldData.riskCode
      if (result) {
        state.isShowFormData = true
        state.isUpdateUpload = true
        nextTick(()=>{
          state.formData = result.benefitShowConfigList || []
          if (str !== 'check') {
            addHead()
          }
        })
      }

    }
    //上传
    const handleChange = info => {
      console.log('上传', info)
      state.showImg = []
      data.benefitShowFileName = info.enclosureName
      data.fileUrl = info.url
      state.isShowFormData = true

      state.formData = []
      // request(getRequestURI('uploadBenefitFile'), {
      //   data:{
      //     fileUrl: info.url,
      //     benefitShowFileName: data.benefitShowFileName,
      //     riskCode: state.riskCode
      //   }
      // }).then(res => {
      //   if(res.code === 1){
      //     state.formData.push({
      //       id: 1,
      //       fileUrl: '',
      //       benefitShowType: undefined,
      //       excelHeaderName: undefined,
      //       isShow: undefined,
      //       isCalculate: undefined,
      //       sort: '',
      //     })
      //     // excelHeaderNameList.value = res.result.headers.map(item =>{
      //     //   return {value: item, label: item}
      //     // })
      //   }
      // })
    }

    // 下载
    const onPreviewFile = () =>{
      if (state.isSHowTitle) return
      window.location = data.fileUrl
    }
    // 删除文件
    const handleRemove = () =>{
      if (state.isSHowTitle) return
      state.isUpdateUpload = false
      // state.isShowFormData = false
      data.fileUrl = ''
      data.benefitShowFileName = ''
      state.formData = []
    }




    // 增加一行
    let id = 1
    const add = () => {
      id++
      if (state.formData.length === 0) {
        state.formData.push(
          {
            id: 1,
            fileUrl: '',
            benefitShowType: undefined,
            excelHeaderName: undefined,
            isShow: undefined,
            sort: '',
            isCalculate: undefined,
          }
        )
      } else {
        state.formData.push({
          id,
          fileUrl: '',
          benefitShowType: undefined,
          excelHeaderName: undefined,
          isShow: undefined,
          isCalculate: undefined,
          sort: '',
        })
      }
    }
    const addHead = () => {
      if (state.formData.length === 0) {
        for (let i = 0; i < excelHeaderNameList.value.length; i++) {
          state.formData.push(
            {
              id: i + 1,
              fileUrl: '',
              benefitShowType: undefined,
              excelHeaderName: excelHeaderNameList.value[i].label,
              isShow: undefined,
              sort: i,
              isCalculate: undefined,
            }
          )
        }
      }
    }

    const onSelectChanges = selectedRowKeys => {
      state.selectedRowKeys = selectedRowKeys
    }
    const del = () => {
      if (!state.selectedRowKeys.length) message.warning('请选择数据')
      state.selectedRowKeys.forEach((key) => {
        state.formData.forEach((item, index) => {
          if (item.id === key) {
            state.formData.splice(index, 1)
          }
        })
      })
      state.selectedRowKeys = []
    }
    const filterOption = (input, option) => {
      return option.label.indexOf(input) > -1
    }
    const onSave = () =>{
      // if (!data.fileUrl) return message.warning('请上传文件')
      if(state.formData.length){
        let sortMap = {}
        state.formData.forEach(obj => {
          if (!sortMap[obj.sort]) {
            sortMap[obj.sort] = []
          }
          sortMap[obj.sort].push(obj)
        })
        let objectsWithSameSort = Object.values(sortMap).filter(group => group.length > 1).flat()
        if(objectsWithSameSort && objectsWithSameSort.length > 1) return message.warning('排序不能重复')
      }

      request(getRequestURI('benefit-updateBenefit'), {
        data: {
          riskCode: state.riskCode,
          benefitShowFileName: data.benefitShowFileName,
          fileUrl: data.fileUrl,
          prospectusBenefitShowConfigList: state.formData,
          excelHeaderList: state.excelHeaderList
        }
      }).then(res => {
        if(res.code === 1) {
          message.success('保存成功')
          onClose()
        }
      })
    }

    const onClose = () => {
      if (uploadRef.value) {
        uploadRef.value.fileList = []
      }
      state.showImg = []
      state.formData = []
      state.visible = false
      state.isUpdateUpload = false
      state.selectedRowKeys = []
      state.time = new Date().getTime()
    }

    onMounted(() => {
      getDictionaryData(showList, 'yes_no')
      getDictionaryData(benefitShowTypeList, 'benefit_config_type')
      getDictionaryData(excelHeaderNameList, 'cashValueExcelHead')
    })
    const excel_first_row = (data) => {
      console.log('表头数据第一行', data)
      state.excelHeaderList = data
      excelHeaderNameList.value = data.map(item => {
        return {value: item, label: item}
      })
    }
    return {
      onSave,
      excel_first_row,
      ...toRefs(state),
      addUpdateDrawer,
      filterOption,
      showDrawer,
      onClose,
      handleChange,
      showList,
      benefitShowTypeList,
      excelHeaderNameList,
      data,
      uploadRef,
      onSelectChanges,
      add,del,
      onPreviewFile,
      handleRemove
    }
  },

})
</script>
