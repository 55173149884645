<template>
  <a-drawer
    :width="'70%'"
    title="产品渠道配置"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form :label-col="{ span: 10 }" :wrapper-col="{ span: 16 }">
      <a-row :gutter="10">
        <a-col span="10">
          <a-form-item label="产品名称">
            <a-input v-model:value="productInfo.productName" disabled/>
          </a-form-item>
        </a-col>
        <a-col span="10">
          <a-form-item label="产品编码">
            <a-input v-model:value="productInfo.productCode" disabled/>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <a-divider style="height: 2px; background-color: #c1c1c1" />
    <div class="chan-list">
      <h4>渠道规则参数列表</h4>
    </div>
    <a-form :model="channelData" :label-col="{ span: 10 }" :wrapper-col="{ span: 16 }">
      <a-row :gutter="10">
        <a-col span="10">
          <a-form-item label="渠道名称">
            <a-select @change="onSearch" :allowClear="true" v-model:value="channelData.channelName" placeholder="请选择">
                <a-select-option :value="cn.id" v-for="cn in channelFormData.channelName" :key="cn.id">{{cn.channelName}}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="10">
          <a-form-item label="渠道类型">
            <a-select @change="onSearch" :allowClear="true" v-model:value="channelData.channelType" placeholder="请选择">
                <a-select-option :value="ct.value" v-for="ct in channelFormData.channelType" :key="ct.value">{{ct.label}}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <div>
        <a-table
          :columns="channelColumns"
          :data-source="dataSource"
          :pagination="false"
          :loading="loading"
          :row-key="(record) => record.id"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
            type:'radio',
            hideSelectAll:true
          }"
        >
          <template #bodyCell="{ column, index, record}">
            <template v-if="column.key === 'index'">
              {{ index + 1 }}
            </template>
            <template v-if="column.key === 'channelName'">
              <a-select @change="handelChange(record,index)" style="width: 100px" v-model:value="record.channelName" placeholder="请选择">
                <a-select-option :value="cn.id" v-for="cn in channelFormData.channelName" :key="cn.id">{{cn.channelName}}</a-select-option>
              </a-select>
            </template>
            <template v-if="column.key === 'channelRule'">
              <div>
                <a-input v-model:value="record.channelRule"/>
              </div>
            </template>
            <template v-if="column.key === 'activityRule'">
              <div>
               <a-input v-model:value="record.activityRule"/>
              </div>
            </template>
            <template v-if="column.key === 'env'">
              <div>
                <a-input v-model:value="record.env"/>
              </div>
            </template>

          </template>
        </a-table>
        <div>
          <a-button class="pro-butt" @click="addDataSource">+增加一行</a-button>&nbsp;
          <a-button class="pro-butt" @click="delDataSource">-删除一行</a-button>
        </div>
      </div>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button type="primary" @click="onSave">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>

<script>
import { message } from 'ant-design-vue'
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import { getRequestURI, request } from '../../../utils/request'
// import { getDictionaryValue, getExtendUri } from '../../../utils/request_extend'
import { getExtendUri } from '../../../utils/request_extend'

export default defineComponent({
  setup() {

    const channelData = reactive({
      channelName: undefined,
      channelType: undefined
    })
    const channelFormData = reactive({
      //渠道名称
      channelName: [],
      //渠道类型
      channelType: []
    })
    const state = reactive({
      loading: true,
      selectedRowKeys:[],
      id:0,
      // 头部框
      productInfo: {
        productCode: '',
        productName: ''
      },
      visible: false,
      // 最下面的表格
      channelColumns:[
        {
          title: '序号',
          key: 'index',
          align: 'center',
          width: 60
        },
        {
          title: '渠道名称',
          align: 'center',
          key: 'channelName',
          dataIndex: 'channelName',
          width: 120
        },
        {
          title: '渠道类型',
          align: 'center',
          key: 'channelType',
          dataIndex: 'channelType',
          width: 120,
          customRender: (text)=> getChannelTypeDetail(text.value)
        },
        {
          title: '渠道规则',
          align: 'center',
          key: 'channelRule',
          dataIndex: 'channelRule',
          width: 120
        },
        {
          title: '活动规则',
          align: 'center',
          key: 'activityRule',
          dataIndex: 'activityRule',
          width: 120
        },
        {
          title: '环境',
          align: 'center',
          key: 'env',
          dataIndex: 'env',
          width: 120
        },
      ],
      dataSource: []
    })
      // 更加渠道类型 返回对应的数据
    const getChannelTypeDetail = (value) =>{
      if(!value) return
      for (let i = 0; i < channelFormData.channelType.length; i++) {
        if(value == channelFormData.channelType[i].value){
          return channelFormData.channelType[i].label
        }
      }
    }

    // 增加一行
    const addDataSource = () =>{
      state.selectedRowKeys = []
      let id = state.dataSource.length > 0 ?state.dataSource[state.dataSource.length-1].id : 1
      id += new Date()
      state.dataSource.push({
        id:id,
        productId:state.id,
        channelId: 0,
        channelName: '',
        channelType: '',
        channelRule: '',
        activityRule: '',
        env: ''
      })
      console.log(state.dataSource)
    }
    // 删除一行
    const delDataSource = () => {
      console.log(state.selectedRowKeys)
      if(state.selectedRowKeys.length === 0){
        return message.warning('请选择数据')
      }
      state.dataSource.forEach((data,index) => {
        state.selectedRowKeys.forEach(item => {
          console.log(item)
          if(item === data.id) {
            state.dataSource.splice(index,1)
          }
        })
      })
      state.selectedRowKeys = []
    }
    const onSelectChange = (selectedRowKeys) => {
      state.selectedRowKeys = selectedRowKeys
    }
    const showDrawer = (oldData) => {
      console.log(oldData.id)
      state.id = oldData.id
      state.visible = true
      state.productInfo.productName = oldData.productName
      state.productInfo.productCode = oldData.productCode
      handelSearch()
    }
    // 查询的方法
    const handelSearch = () => {
      request(getRequestURI('productInfo_queryListForPage'),{
        data:{productId:state.id, channelId: channelData.channelName,channelType: channelData.channelType},
        pagination: {
          current: 1,
          pageSize: 10,
          total: 0
      }}).then(res => {
        if(res.code == 1){
          setTimeout(()=>{
            state.loading = false
          }, 500)
          state.dataSource = res.result
        }
      })
    }
    // 渠道规则参数列表 的下拉框触发回调
    const onSearch = () => {
      handelSearch()
    }
    // 添加一行 选中渠道名称返回的 渠道类型
    const handelChange = (record,index) => {
      // console.debug(record,channelFormData)
      channelFormData.channelName.forEach(item => {
        if(item.id === record.channelName){
          state.dataSource[index].channelName = item.channelName
          state.dataSource[index].channelId = item.id
          state.dataSource[index].channelType = item.channelType
        }
      })
      console.log(state.dataSource,channelFormData)
    }
    // 增加和修改
    const onSave = () =>{
      state.dataSource.forEach(item => {
        if(item.channelName === ''){
          message.warning('请选择数据')
          throw '请选择数据'
        }
        // item.channelId = item.channelName
      })
          console.log(state.dataSource)
      try {
        request(getRequestURI('channel_productInfo_change'),{data:{productId:state.id,list:state.dataSource}}).then(res => {
          if(res.code === 1){
            message.success('保存成功')
            handelSearch()
          }else{
            message.warning(res.message)
          }
        })
      } catch (error) {
        message.error(error)
      }
    }
    const init = function() {
      request(getRequestURI('dictionary_query_byType'), {data: {types: ['channel_type']}}).then(res => {
        channelFormData.channelType = res.result.channel_type
      })
    }
    init()
    const onClose = () => {
      state.visible = false
      channelData.channelName = ''
      channelData.channelType = ''
      state.selectedRowKeys = []
    }

    const getChannelName = () =>{
      let data = {}
      request(getRequestURI('channel_select'),{data},true,'get').then(result =>{
        console.log(result)
        // const res = result.result
        // const label = 'channelName'
        // const channelNameList = res.reduce((all, next) => all.some((atom) => atom[label] == next[label]) ? all : [...all, next],[])
        channelFormData.channelName = result.result
      })
    }
    const getChannelType = () =>{
      request(getExtendUri('dictionary_query_byType'), {data: {types: ['channel_type']}}).then(res => {

        channelFormData.channelType = res.result.channel_type
      })
    }
    onMounted(() =>{
      getChannelName()
      getChannelType()
    })


    return {
      getChannelTypeDetail,
      ...toRefs(state),
      showDrawer,
      handelChange,
      onClose,
      onSearch,
      handelSearch,
      getChannelType,
      getChannelName,
      addDataSource,
      delDataSource,
      onSelectChange,
      onSave,
      channelData,
      channelFormData,
    }
  }
})

</script>

<style scoped>
.chan-list{
  margin-left: 100px;
  margin-bottom: 15px;
}
.pro-butt {
  margin-top: 15px;
  border-radius: 5px;
}
</style>
