<style scoped>
</style>

<template>
  <div>
    <search-form
      :Condition="condition"
      :ColSpan="8"
      @resetAll="resetAll"
      @callback="handleSearch"
      @nameLinked="changes"
    ></search-form>
    <a-table
      :columns="columns"
      :data-source="dataSource ? dataSource['result'] : []"
      :pagination="pagination"
      :row-key="(record) => record.id"
      @change="handleTableChange"
      :loading="loading"
    >
      <template #bodyCell="{ column, index, record }">
        <template v-if="column.key === 'index'">
          {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
        </template>
        <template v-if="column.key === 'operation'">
          <div>
            <a-button
              type="text"
              v-if="
                record.progressName !== '' &&
                record.progressName !== undefined &&
                record.progressName !== null
              "
              style="margin-right: 5px; color: red"
              size="small"
              @click="delUser(record)"
            >
              取消关联
            </a-button>
            <a-button
              type="text"
              size="small"
              style="margin-right: 5px; color: #00a0e9"
              @click="addUser(record, 'add')"
            >
              关联流程
            </a-button>
            <a-button
              type="text"
              v-if="
                record.progressName !== '' &&
                record.progressName !== undefined &&
                record.progressName !== null
              "
              size="small"
              style="color: blueviolet"
              @click="details(record)"
            >
              流程详情
            </a-button>
          </div>
        </template>
      </template>
    </a-table>
    <associated-process
      ref="associatedProcessRef"
      :handleSearch="handleSearch"
    ></associated-process>
    <process-details ref="processDetailsRef"></process-details>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import SearchForm from '../../../components/extended/search_form'
import { getRequestURI, request, requestUsePagination } from '../../../utils/request'
import { message, Modal } from 'ant-design-vue'
import AssociatedProcess from './associatedProcess'
import ProcessDetails from './processDetails'
export default defineComponent({
  components: {
    ProcessDetails,
    AssociatedProcess,
    SearchForm
  },
  setup () {
    const associatedProcessRef = ref()
    const processDetailsRef = ref()
    const state = reactive({
      lastFilter: {},
      columns: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left',
        },
        {
          title: '供应商',
          width: 100,
          fixed: 'left',
          align: 'center',
          key: 'supplierName',
          dataIndex: 'supplierName',
        },
        {
          title: '产品分类',
          width: 100,
          align: 'center',
          key: 'supTypeName',
          dataIndex: 'supTypeName',
        },
        {
          title: '产品名称',
          width: 100,
          align: 'center',
          key: 'productName',
          dataIndex: 'productName',
        },
        {
          title: '产品编号',
          width: 100,
          align: 'center',
          key: 'productCode',
          dataIndex: 'productCode',
        },
        {
          title: '流程名称',
          width: 100,
          align: 'center',
          key: 'progressName',
          dataIndex: 'progressName',
        },
        {
          title: '流程id',
          width: 80,
          align: 'center',
          key: 'progressId',
          dataIndex: 'progressId',
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          fixed: 'right',
          width: 190,
        },
      ],
      // selectedRowKeys: [],
      condition: {
        supplierId: {
          label: '供应商名称',
          type: 'tree-select',
          value: 'id',
          urlKey: 'querySupplier'
        },
        supType: {
          label: '产品分类',
          type: 'select',
          urlKey: 'queryDictionaryType',
          queryParam: { type: 'riskType' }
        },
        productId: {
          label: '产品名称',
          type: 'select',
          data: []
        },
        progressName: {
          label: '流程名称',
          type: 'select',
          data: []
        },
        progressId: '流程id'
      },
      // 当前流程
      positionList: [
        { id: 1, name: '健康告知' },
        { id: 2, name: '产品详情介绍' },
        { id: 3, name: '信息填写' },
      ]
    })
    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    const handleSearch = function (filters) {
      state.lastFilter = filters
      state.loading = true
      run(
        {
          data: state.lastFilter,
          pagination: {
            current: 1,
            pageSize: 10
          },
        }
      )
      state.loading = false
    }
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('productInfo_getListByProductAndProgress'))
    // const onSelectChange = (selectedRowKeys, selectedRow) => {
    //   state.selectedRowKeys = selectedRowKeys
    //   console.log('selectedRowKeys changed: ', selectedRow)
    // }
    const addUser = function (value, status) {
      if (value.progressName !== '' && value.progressName !== undefined && value.progressName !== null) {
        Modal.confirm({
          title: '产品【' + value.productName + '】已经关联了流程【' + value.progressName + '】,是否关联新的流程?如果关联新流程将覆盖旧流程!',
          async onOk () {
            associatedProcessRef.value.showDrawer(value, status)
          },
          onCancel () {
            // state.querydel.splice(0)
          }
        })
        return
      }
      associatedProcessRef.value.showDrawer(value, status)
    }
    const details = function (value) {
      processDetailsRef.value.showDrawer(value)
    }
    const liuchengchaxun = function () {
      state.condition.progressName.data = []
      request(getRequestURI('process_getProgressList'), { data:{},pagination:{pageSize: 9999, current: 1 }}).then(res => {
        res.result.forEach(item => {
          let obj = {}
          obj.value = item.progressName
          obj.label = item.progressName
          state.condition.progressName.data.push(obj)
        })
      })
    }
    liuchengchaxun()
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
      // pageSizeOptions: [10, 20, 50],
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.log('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.lastFilter,
      })
    }
    const delUser = (record) => {
      // state.delId = record
      console.log(record)
      Modal.confirm({
        title: '是否确认该产品取消与当前流程的关联?',
        async onOk () {
          // let keys = state.selectedRowKeys
          // if (keys.length === 0) {
          //   message.warning('请选择数据')
          //   return
          // }
          // visible.value = true
          const uri = getRequestURI('productInfo_associatedProcess12')
          console.log(record)
          request(uri, { productId: record.productId, progressId: null }).then(res => {
            console.log(res)
            if (res.code == 1) {
              console.log(res)
              message['success'](res.message)
              handleSearch({})
            } else {
              message['error'](res.message)
            }
          })
        },
        onCancel () {
          // state.querydel.splice(0)
        }
      })
    }
    const init = function () {
      request(getRequestURI('select'), { data: {} }).then(result => {
        const arr = []
        const res = result.result
        const label = 'productName'
        const sysName = res.reduce((all, next) => all.some((atom) => atom[label] == next[label]) ? all : [...all, next], [])
        for (let i = 0; i < sysName.length; i++) {
          arr.push({ label: sysName[i].productName, value: sysName[i].id })
        }
        state.condition.productId.data = arr
      })
    }
    //产品下拉查询
    const changes = function (value) {
      request(getRequestURI('select'), { data: { supplierId: value } }).then(result => {
        const arr = []
        const res = result.result
        const label = 'productName'
        const sysName = res.reduce((all, next) => all.some((atom) => atom[label] == next[label]) ? all : [...all, next], [])
        for (let i = 0; i < sysName.length; i++) {
          arr.push({ label: sysName[i].productName, value: sysName[i].id })
        }
        state.condition.productId.data = arr
      })
    }
    init()
    const resetAll = () => {
      state.condition.productId.data = []
      changes()
    }
    return {
      ...toRefs(state),
      resetAll,
      handleSearch,
      dataSource,
      loading,
      pagination,
      handleTableChange,
      delUser,
      associatedProcessRef,
      addUser,
      processDetailsRef,
      details,
      changes
    }
  }
})

</script>

