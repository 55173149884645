<template>
  <a-drawer
    title="产品渠道配置"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >

    <div>
    <search-form ref="searchRef" :Condition="condition" :ColSpan="12" @callback="handleSearch"></search-form>
    <div class="btn-bar">
      <a-button danger @click="handelDelete">
        <template #icon>
          <DeleteOutlined/>
        </template>
        批量删除
      </a-button>
      <a-button type="primary" @click="handelSave">
        <template #icon>
          <FileAddOutlined/>
        </template>
        新增
      </a-button>
    </div>
    <a-table
      :columns="columns"
      :data-source="dataSource ? dataSource['result'] : []"
      :pagination="pagination"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :row-key="(record) => record.id"
      @change="handleTableChange"
      :loading="loading"
      style="margin-top: 10px"
    >
      <a-spin :delay="200"/>
      <template #bodyCell="{ column, index, record }">
        <template v-if="column.key === 'index'">
          {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
        </template>
        <template v-if="column.key === 'operation'">
          <a style="color: orange; margin-right: 10px" @click="channelEdit(record)">
            编辑
          </a>
        </template>
      </template>
    </a-table>
    <div>
      <a-modal v-model:visible="templateShow" width="60%" title="产品渠道新增" @cancel="close" @ok="handleOk">
        <a-form ref="formRef" :model="channelData" :label-col="{ span: 10 }" :wrapper-col="{ span: 16 }">
          <a-row :gutter="10">
            <a-col span="12">
              <a-form-item label="渠道名称" name="channelId" :rules="[{ required: true, message: '请选择渠道名称' ,trigger: 'change'}]">
                <a-select
                  @change="handelChannelName"
                  show-search
                  allow-clear
                  :filter-option="filterOption"
                  v-model:value="channelData.channelId" placeholder="请选择渠道名称">
                  <a-select-option :value="cn.id" v-for="cn in condition.channelId.data" :key="cn.id">{{cn.label}}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="上下架状态" name="channelStatus" >
                <a-select
                  show-search
                  allow-clear
                  :options="channelStatusList"
                  :filter-option="filterOption"
                  v-model:value="channelData.channelStatus" placeholder="请选择上下架状态">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="渠道编码" name="channelCode">
                <a-input v-model:value="channelData.channelCode" disabled placeholder="请输入渠道编码"/>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="渠道类型" name="channelTypeName">
                <a-input v-model:value="channelData.channelTypeName" disabled placeholder="请输入渠道类型"/>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="手续费率" name="procedureRate" :rules="[{pattern:/^[0-9]/, message: '支持数字' ,trigger: 'change'}]">
                <a-input v-model:value="channelData.procedureRate" placeholder="请输入手续费率"/>
              </a-form-item>
            </a-col>

            <a-col span="12">
              <a-form-item label="是否热卖" name="isHotSelling">
                <a-select
                  show-search
                  allow-clear
                  :options="isHotSellingList"
                  :filter-option="filterOption"
                  v-model:value="channelData.isHotSelling" placeholder="请选择是否热卖">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="是否同产品渠道" name="isSameProduct">
                <a-select
                  show-search
                  allow-clear
                  :options="isHotSellingList"
                  @change="handelIsSameProduct"
                  v-model:value="channelData.isSameProduct" placeholder="请选择是否同产品渠道配置">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-if="channelData.isSameProduct === 'N'" span="12">
              <a-form-item
                :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]"
                label="列表页跳转方式:" name="listPageSkipType">
                <a-select
                  v-model:value="channelData.listPageSkipType"
                  allow-clear
                  :options="listPageSkipTypeList"
                  :getPopupContainer="(triggerNode)=>triggerNode.parentNode"
                  placeholder="请选择列表页跳转方式"
                  @change="handelDelSkipUrl('1')"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12" v-if="channelData.isSameProduct === 'N' && channelData.listPageSkipType!=='2'">
              <a-form-item
                label="产品模板:" name="templateCode"
                :rules="[{ required: channelData.listPageSkipType ==='1' && channelData.isSameProduct === 'N', message: '必填信息' ,trigger: 'change'}]"
              >
                <a-select
                  v-model:value="channelData.templateCode"
                  :options="templateCodeList"
                  :field-names="{label:'templateName',key:'templateCode',value:'templateCode'}"
                  show-search :filter-option="filterOption"
                  :getPopupContainer="(triggerNode)=>triggerNode.parentNode"
                  placeholder="请选择产品模板"
                />
              </a-form-item>
            </a-col>
            <a-col span="12" v-if="channelData.isSameProduct === 'N' && channelData.listPageSkipType==='2'">
              <a-form-item
                :rules="[{ required: channelData.listPageSkipType==='2' && channelData.isSameProduct === 'N', message: '必填信息' ,trigger: 'change'},]"
                label="列表页跳转链接地址:" name="listOtherPageSkipUrl">
                <a-input
                  v-model:value="channelData.listOtherPageSkipUrl"
                  placeholder="请输入列表页跳转链接地址"
                />
              </a-form-item>
            </a-col>
            <a-col span="12" v-if="channelData.isSameProduct === 'N' && channelData.listPageSkipType!=='2'">
              <a-form-item
                :rules="[{ required: channelData.isSameProduct === 'N', message: '必填信息' ,trigger: 'change'}]"
                label="详情页跳转方式:" name="detailPageSkipType">
                <a-select
                  v-model:value="channelData.detailPageSkipType"
                  allow-clear
                  :options="detailPageSkipTypeList"
                  :getPopupContainer="(triggerNode)=>triggerNode.parentNode"
                  placeholder="请选择详情页跳转方式"
                  @change="handelDelSkipUrl('2')"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12" v-if="channelData.isSameProduct === 'N' && (channelData.detailPageSkipType==='2' || channelData.detailPageSkipType==='4') && channelData.listPageSkipType!=='2'">
              <a-form-item
                :rules="[{ required: channelData.detailPageSkipType==='2' && channelData.isSameProduct === 'N', message: '必填信息' ,trigger: 'change'},]"
                label="详情页跳转链接地址:" name="detailOtherPageSkipUrl">
                <a-input
                  v-model:value="channelData.detailOtherPageSkipUrl"
                  placeholder="请输入详情页跳转链接地址"
                />
              </a-form-item>
            </a-col>
            <a-col span="12" v-if="channelData.isSameProduct === 'N' && channelData.listPageSkipType!=='2'">
              <a-form-item
                label="详情页跳转按钮名称:" name="detailPageSkipButtonName">
                <a-input
                  v-model:value="channelData.detailPageSkipButtonName"
                  placeholder="请输入详情页跳转按钮名称"
                />
              </a-form-item>
            </a-col>

            <a-col span="12">
              <a-form-item label="渠道的角标" name="corner">
                <a-select
                  show-search
                  allow-clear
                  :options="cornerList"
                  :filter-option="filterOption"
                  v-model:value="channelData.corner" placeholder="请选择渠道的角标">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="角标颜色:"  name="cornerColor">
                <div style="display: flex">
                  <a-input v-model:value="channelData.cornerColor" placeholder="请输入角标颜色" />
                  <span>
                    <a-popover placement="top" trigger="click">
                      <template #content>
                        <color-picker
                          @click="handelColor"
                          ref="colorRef"
                          :show-opacity="false"
                          v-model:hex="hexColor"
                          @finish="finishOk"
                        />
                      </template>
                      <template #title>
                      <span>颜色选择:</span>
                      </template>
                      <a-button type="primary" style="margin: 0 8px">颜色选择</a-button>
                    </a-popover>
                  </span>
                </div>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="渠道产品列表封面图" name="coverImageUrl" >
                <uploadFileNew v-if="isShow" @handelDel="handelDelImg" ref="uploadRef"  listType="picture-card" :showImg="showImg" :num="1" filetype="img"
                                @fileChange="uploadCosFileChange"></uploadFileNew>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-modal>
    </div>
  </div>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>

<script>
import {computed, defineComponent, nextTick, onMounted, reactive, ref, toRefs} from 'vue'
import {getRequestURI, request, requestUsePagination} from '../../../utils/request'
import SearchForm from '../../../components/extended/search_form.vue'
import {message, Modal} from 'ant-design-vue'
import {CloseOutlined,DeleteOutlined,FileAddOutlined} from '@ant-design/icons-vue'
import UploadFileNew from '@/components/extended/uploadFileNew.vue'
import {getDictionaryData} from '@/utils/request_extend'
// import { Chrome } from 'vue-color'
import ColorPicker from 'colorpicker-v3'
import 'colorpicker-v3/dist/style.css'

export default defineComponent({
  name: 'ProductChannel',
  components: {
    UploadFileNew,
    SearchForm,
    CloseOutlined,
    DeleteOutlined,
    FileAddOutlined,
    ColorPicker
  },
    setup(){
      const state = reactive({
        showImg:[],
        parentData:{},
        visible: false,
        templateShow: false,
        newChannel: {},
        selectedRowKeys: [],
        isDisable: '',
        condition: {
          channelId: {
            label: '渠道名称',
            type: 'select',
            placeholder: '请选择渠道名称',
            data: []
          },
          channelType: {
            label: '渠道类型',
            type: 'select',
            placeholder: '请选择渠道类型',
            urlKey: 'queryDictionaryType',
            queryParam: {type: 'channel_type'}
          }
        },
        columns: [
          {
            title: '序号',
            key: 'index',
            fixed: 'left',
            width: 60,
            align: 'left',
          },
          {
            title: '渠道名称',
            width: 140,
            align: 'center',
            key: 'channelName',
            dataIndex: 'channelName',
          },
          {
            title: '渠道编号',
            width: 100,
            align: 'center',
            key: 'channelCode',
            dataIndex: 'channelCode',
          },
          {
            title: '渠道类型',
            width: 100,
            align: 'center',
            key: 'channelType',
            dataIndex: 'channelType',
            customRender: (text)=> getChannelTypeDetail(text.value)
          },
          {
            title: '手续费率',
            width: 100,
            align: 'center',
            key: 'procedureRate',
            dataIndex: 'procedureRate',
            customRender: (text)=> {
              if(text.value==='%'){
                return ''
              }else{
                return text.value
              }
            }
          },
          {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            align: 'center',
            width: 80,
          },
        ],
        hexColor: '#ff1e02',
        isShow:true
      })
      const colorRef = ref()
      const finishOk = (e) => {
        channelData.cornerColor = e.hex
      }
      const handelColor = (e) =>{
        console.log(colorRef.value,e)
      }
      const filterOption = (input, option) => {
        return option.value.indexOf(input) > -1
      }
      const filterInputOption = (input, option) => {
        return option.label.indexOf(input) > -1
      }
      // 上传文件
      const uploadCosFileChange = (data) =>{
        channelData.coverImageUrl=data.url
      }
      const handelDelImg = () =>{
        state.showImg = []
        channelData.coverImageUrl = ''
      }
      onMounted(()=>{
        getTemplateCodeList()
      })
      // 新增的渠道数据
      const channelData = reactive({
        id: '',
        channelId: undefined,
        channelCode:'',
        channelType:'',
        channelTypeName:'',
        procedureRate: '',
        corner: undefined,
        cornerColor:'',
        channelStatus: 'N',
        isHotSelling: 'N',
        isSameProduct: 'Y',
        listPageSkipType: '1', // 列表跳转方式
        detailPageSkipType: '1',
        listOtherPageSkipUrl:'',
        detailOtherPageSkipUrl: '',
        detailPageSkipButtonName: '立即购买',
        coverImageUrl: '',
        templateCode:''
      })
      const searchRef = ref()
      // 更加渠道类型 返回对应的数据
      const getChannelTypeDetail = (value) =>{
        if(!value) return
        for (let i = 0; i < channelTypeList.value.length; i++) {
          if(value == channelTypeList.value[i].value){
            return channelTypeList.value[i].label
          }
        }
      }
      const pagination = computed(() => ({
        current: current.value,
        pageSize: pageSize.value,
        total: total.value
      }))
      const {
        data: dataSource,
        run,
        loading,
        current,
        pageSize,
        total,
      } = requestUsePagination(getRequestURI('productChannelRelationship_selectChannelForPage'))
      //查询
      const handleSearch = function (filters) {
        filters.id = state.parentData.id
        state.newChannel = filters
        run({
          pagination: {
            current: 1,
            pageSize: 10
          },
          data: filters
        })
      }
      const handleTableChange = (pag, filters, sorter) => {
        console.debug('===', filters)
        run({
          pagination: {
            current: pag?.current,
            pageSize: pag?.pageSize
          },
          sorter,
          data: state.newChannel,
        })
      }
      // 多选
      const onSelectChange = (selectedRowKeys) => {
        state.selectedRowKeys = selectedRowKeys
      }
      // 批量删除
      const handelDelete = () => {
        if(state.selectedRowKeys&&state.selectedRowKeys.length===0){
          message.warning('请先选择数据')
          return
        }

        Modal.confirm({
          title: '确认删除吗?',
          async onOk() {
            request(getRequestURI('productChannelRelationship_deleteChannel'),{data:{ids: state.selectedRowKeys}}).then(res =>{
              if(res.code === 1){
                message.success('删除成功')
                state.selectedRowKeys = []
                handleSearch(state.newChannel)
              }else{
                message.warning(res.message)
              }
            })
          },
          onCancel() {
            Modal.destroyAll()
          }
        })

      }
      const showDrawer = (oldData = {}) =>{
        state.visible = true
        state.parentData = oldData
        handleSearch({})
        getTemplateCodeList()
      }
      // 新增
      const handelSave = () => {
        channelData.id = ''
        state.templateShow = true
      }
      const channelEdit = (data) =>{
        Object.assign(channelData, {
          id: data.id,
          channelId: data.channelId,
          channelCode: data.channelCode,
          channelType: data.channelType,
          channelTypeName: getChannelTypeDetail(data.channelType),
          procedureRate: data.procedureRate.replace(/[^0-9]/ig,''),
          corner: data.corner,
          cornerColor: data.cornerColor,
          channelStatus: data.channelStatus,
          isHotSelling: data.isHotSelling,
          coverImageUrl: data.coverImageUrl,
          templateCode:data.templateCode,
          isSameProduct: data.isSameProduct,
          listPageSkipType: data.listPageSkipType, // 列表跳转方式
          detailPageSkipType: data.detailPageSkipType,
          listOtherPageSkipUrl: data.listOtherPageSkipUrl,
          detailOtherPageSkipUrl: data.detailOtherPageSkipUrl,
          detailPageSkipButtonName: data.detailPageSkipButtonName,
        })
        state.templateShow = true
        state.isShow = false
        nextTick(()=>{
          state.isShow = true
          if(data.coverImageUrl){
            state.showImg =[{url:data.coverImageUrl}]
          }
        })

      }
      const formRef = ref()
      // 渠道名称发生变化时
      const handelChannelName = (e) =>{
        if(!e) return
        let type = state.condition.channelId.data.filter(item => item.id === e)[0]
        channelData.channelCode = type.channelCode
        channelData.channelTypeName = getChannelTypeDetail(type.channelType)
        channelData.channelType = type.channelType

      }
      // 返回时调用清除
      const clearSearchInput = () => {
        searchRef.value.clearSearchInput(['channelName', 'channelType', 'procedureRate', 'channelId'])
        state.newChannel = {}
        state.selectedRowKeys = []
        dataSource.value = {}

      }
      const handleOk = async () => {
        if(Number(channelData.procedureRate)>=100||Number(channelData.procedureRate)<0){
          return message.warn('手续费率只支持大于等于0小于100的数字')
        }
        await formRef.value.validate()
        let data = {}

        Object.assign(data, {
          ...channelData,
          productId: state.parentData.id,
        })
        request(getRequestURI('productChannelRelationship_addChannel'),{data}).then(res =>{
          if(res.code === 1){
            message.success('产品渠道新增成功')
            close()
            handleSearch(state.newChannel)
          }else{
            message.warning(res.message)
          }
        })
      }
      const uploadRef = ref()
      const close = () => {
        formRef.value.resetFields()
        formRef.value.clearValidate()
        Object.assign(channelData, {
          id: '',
          channelId: undefined,
          channelCode:'',
          channelType:'',
          channelTypeName:'',
          procedureRate: '',
          corner: undefined,
          cornerColor:'',
          channelStatus: 'N',
          isHotSelling: 'N',
          isSameProduct: 'Y',
          // isVerifyAgent: 'Y',
          coverImageUrl: '',
          listPageSkipType: '1', // 列表跳转方式
          detailPageSkipType: '1',
          listOtherPageSkipUrl:'',
          detailOtherPageSkipUrl: '',
          detailPageSkipButtonName: '立即购买',
        })
        if (uploadRef.value) {
          uploadRef.value.fileList = []
        }
        state.showImg = []
        state.hexColor='#ff1e02'
        state.templateShow = false
      }
      const onClose = () =>{
        state.visible = false
        searchRef.value.deleteAllM()
      }
      // 获取渠道角标
      // const getCornerList = () =>{
      //   request(getExtendUri('dictionary_query_byType'), {data: {types: ['cornerContent']}}).then(result => {
      //     if (result.code === 1) {
      //       cornerList.value = result.result['cornerContent']
      //     }
      //   })
      // }
      // 获取渠道名称
      const getChannelName = () =>{
        let data = {}
        request(getRequestURI('channel_select'),{data},true,'get').then(result =>{
          let arr = []
          result.result.forEach(item => {
            arr.push({id: item.id, value:item.channelName, label: item.channelName, channelType: item.channelType, channelCode: item.channelCode|| ''})
          })
          state.condition.channelId.data = arr
        })
      }
      const handelIsSameProduct = () =>{
        if(channelData.isSameProduct === 'N'){
          channelData.listPageSkipType =  '1'
          channelData.detailPageSkipType = '1'
          channelData.listOtherPageSkipUrl = ''
          channelData.detailOtherPageSkipUrl = ''
          channelData.detailPageSkipButtonName= '立即购买'
        }
      }
      // 跳转切换 删除url
      const handelDelSkipUrl = (type) =>{
        type === '1' ? channelData.listOtherPageSkipUrl = '' : channelData.detailOtherPageSkipUrl = ''
        if(channelData.listPageSkipType === '2'){
          channelData.detailPageSkipType =  '1'
          channelData.templateCode = 'detail'
          channelData.detailOtherPageSkipUrl= ''
          channelData.detailPageSkipButtonName = '立即购买'
        }
        if(type === '2'){
          const obj = {'1': '立即购买', '2': '立即跳转', '3': '立即分享','4': '立即分享'}
          channelData.detailPageSkipButtonName =obj[channelData.detailPageSkipType]
        }

      }
      const isHotSellingList = ref()
      const channelStatusList = ref()
      const cornerList = ref()
      const channelTypeList = ref()
      const listPageSkipTypeList = ref()
      const templateCodeList = ref()
      const detailPageSkipTypeList = ref()
      // 获取产品模板
      const getTemplateCodeList = () =>{
        request(getRequestURI('productFlowTemplate_all'), {}).then(res =>{
          templateCodeList.value = res.result
        })
      }
      const initTypeList = () =>{
        getDictionaryData(detailPageSkipTypeList, 'detail_page_skip_type')
        getDictionaryData(isHotSellingList, 'yes_no')
        getDictionaryData(channelStatusList, 'channelUpDownStatus')
        getDictionaryData(cornerList, 'cornerContent')
        getDictionaryData(channelTypeList, 'channel_type')
        getDictionaryData(listPageSkipTypeList, 'list_page_skip_type')
        // getCornerList()

      }
      onMounted(()=>{
        // searchRef.value.deleteAllM()
        getChannelName()
        initTypeList()
      })
      return {
        detailPageSkipTypeList,
        handelDelSkipUrl,
        handelIsSameProduct,
        listPageSkipTypeList,
        uploadRef,
        cornerList,
        channelTypeList,
        isHotSellingList,
        channelStatusList,
        formRef,
        channelData,
        filterOption,
        searchRef,
        pagination,
        total,
        loading,
        dataSource,
        handleOk,
        getChannelTypeDetail,
        ...toRefs(state),
        onSelectChange,
        handelDelete,
        handelSave,
        getChannelName,
        clearSearchInput,
        handleSearch,
        handleTableChange,
        close,
        handelChannelName,
        onClose,
        showDrawer,
        uploadCosFileChange,
        channelEdit,
        finishOk,
        colorRef,
        handelColor,
        handelDelImg,
        filterInputOption,
        getTemplateCodeList,
        templateCodeList
      }
    }
  })
</script>

<style scoped>

</style>
