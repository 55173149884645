<style scoped></style>

<template>
  <search-form
      ref="searchRef"
      :Condition="condition"
      :ColSpan="12"
      @callback="handleSearch"
  ></search-form>
  <div class="btn-bar">
    <a-button type="primary" @click="addGeneral">
      <template #icon>
        <FileAddOutlined/>
      </template>
      新增
    </a-button>
    <a-button danger @click="delMultiple">
      <template #icon>
        <DeleteOutlined/>
      </template>
      删除
    </a-button>

  </div>
  <a-table
      id="table"
      :columns="columns"
      :data-source="dataSource ? dataSource['result'] : []"
      :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }"
      :row-key="(record) => record.summaryCode"
      :pagination="pagination"
      @change="handleTableChange"
      :loading="loading"
  >
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <div>&nbsp;
          <a
              href="javascript:void(0)"
              style="color: orange"
              @click="modifyProduct(record)"
          >编辑</a
          >
        </div>
      </template>
    </template>
  </a-table>
  <general-edit-info :handleMainSearch="handleSearch" ref="generalDrawerRef" :searchFilters="searchFilters"
                     :current="pagination.current"></general-edit-info>
</template>

<script>
import {computed, defineComponent, onMounted, reactive, ref, toRefs} from 'vue'
import SearchForm from '../../../components/extended/search_form'
import {message, Modal} from 'ant-design-vue'
import useClipboard from 'vue-clipboard3'
import {getRequestURI, request, requestUsePagination} from '@/utils/request'
import {DeleteOutlined, FileAddOutlined, FormOutlined} from '@ant-design/icons-vue'
import {getDictionaryData, getDictionaryValue} from '@/utils/request_extend'
import GeneralEditInfo from './generalEditInfo'

export default defineComponent({
  components: {
    SearchForm,
    FileAddOutlined,
    DeleteOutlined,
    // eslint-disable-next-line vue/no-unused-components
    FormOutlined,
    GeneralEditInfo
  },
  setup() {
    const generalDrawerRef = ref()//新增编辑
    const environmentsRef = ref()//环境同步
    const {toClipboard} = useClipboard()//复制
    const state = reactive({
      refreshDom: true,
      isCopy: false,
      export1: '',//导出
      export2: '',//导出
      data: [],//批量删除
      condition: {
        status: {
          label: '通用素材',
          type: 'select',
          urlKey: 'queryDictionaryType',
          queryParam: {type: 'general_materials'}
        },
      },
      columns: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left',
        },
        {
          title: '素材类型',
          width: 100,
          align: 'center',
          key: 'summaryType',
          dataIndex: 'summaryType',
          customRender: (text) => getDictionaryValue('general_materials', text.value)
        },
        {
          title: '素材名称',
          width: 100,
          align: 'center',
          key: 'summaryName',
          dataIndex: 'summaryName',
        },
        {
          title: '文章标题',
          width: 100,
          align: 'center',
          key: 'mainTitle',
          dataIndex: 'mainTitle',
        },
        {
          title: '文章副标题',
          width: 120,
          align: 'center',
          key: 'secondaryTitle',
          dataIndex: 'secondaryTitle',
        },

        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 80,
        },
      ],
      // 服务id
      serviceName: '',
      tableList: [],
      selectedRowKeys: [],
      searchNow: {},//分页
      productTypeList: []
    })
    const productData = reactive({
      productCode: '',
      channel: undefined,
      channelUserId: '',
      agentId: ''
    })

    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('templateSummaryList'))

    //页面挂载调用数据
    onMounted(() => {
      getDictionaryData(state.productTypeList, 'ProductType')
      handleSearch({})
    })
    // 存储搜索组件传过来的数据
    const searchFilters = ref({})
    //查询
    const handleSearch = function (filters, num = '') {
      if (filters) {
        searchFilters.value = filters
      }
      state.searchNow = filters
      run({
        pagination: {
          current: num ? num : 1,
          pageSize: 10
        },
        data: filters,
      })
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.log('===', filters)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.searchNow,
      })
    }
    // 复选的回调
    const onSelectChange = (selectedRowKeys, selectedRowrode) => {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
      state.export1 = selectedRowrode.supplierId
      state.export2 = selectedRowrode.id
    }
    // 批量删除
    const delMultiple = () => {
      if (state.selectedRowKeys.length === 0) {
        message.warning('请选择数据')
        return
      }
      let keys = []
      state.selectedRowKeys.forEach(item => {
        keys.push({code: item})
      })
      Modal.confirm({
        title: '确认删除吗?',
        async onOk() {
          request(getRequestURI('delTemplateSummary'), {
            data: keys
          }, true, 'post').then(
              (result) => {
                if (result.code === 1) {
                  message.success('删除成功')
                  state.selectedRowKeys = []
                  handleSearch({})
                } else {
                  message.warning(result.message)
                }
              }
          )
        },
        onCancel() {
        }
      })
    }
    const searchRef = ref()
    const canAdd = async () => {
      try {
        const response = await request(getRequestURI('summaryTypeList'), {data: {}})
        console.debug('获取可添加类型；---->', response)
        if (response.code === 1) {
          return response.result
        } else {
          return []
        }
      } catch (error) {
        console.error('请求可添加类型时出错：', error)
        return []
      }
    }
    //增加
    const addGeneral = async () => {
      let list = await canAdd()
      if (list.length > 0) {
        generalDrawerRef.value.showDrawer('add', {}, list)
      } else {
        message.warning('暂无可添加类型')
      }
    }

    //编辑
    const modifyProduct = function (oldData) {
      request(getRequestURI('templateSummaryDetail'), {data: {summaryCode: oldData.summaryCode}}).then(res => {
        if (res.code === 1) {
          generalDrawerRef.value.showDrawer('edit', res.result)
        } else {
          message.warning(res.message)
        }
      })

    }
    //查看
    const checkProduct = function (oldData, num) {
      generalDrawerRef.value.showDrawer(oldData, num)
      generalDrawerRef.value.checkDetails(oldData.id)
    }
    // 复制详情链接
    const copyInformation = async (data) => {
      try {
        await toClipboard(data)
        message.success('复制成功')
      } catch (err) {
        console.debug(err)
      }
    }

    const filterOption = (input, option) => {
      return option.label.indexOf(input) > -1
    }
    onMounted(() => {
    })
    return {
      productData,
      filterOption,
      searchRef,
      ...toRefs(state),
      handleSearch,
      onSelectChange,
      handleTableChange,
      addGeneral,
      modifyProduct,
      generalDrawerRef,
      environmentsRef,
      pagination,
      total,
      loading,
      dataSource,
      checkProduct,
      copyInformation,
      delMultiple,
      searchFilters,
    }
  },
})
</script>
