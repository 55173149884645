<style scoped></style>

<template>
  <search-form
    v-if="refreshCondition"
    :Condition="condition"
    :ColSpan="12"
    @callback="handleSearch"
    @nameLinked="changes"
    @resetAll="resetAll"
    @stateData="getStateData"
  ></search-form>
  <div class="btn-bar">
    <a-button type="primary" @click="alterState">
      <template #icon>
        <FileAddOutlined />
      </template>
      上架/下架
    </a-button>
    <a-button type="primary" @click="showDrawer">
      预约上架
    </a-button>
  </div>
  <a-table
    :columns="columns"
    :data-source="dataSource ? dataSource['result'] : []"
    :scroll="{ x: '100%' }"
    :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }"
    :row-key="(record) => record.id"
    :pagination="pagination"
    @change="handleTableChange"
    :loading="loading"
  >
    <template #bodyCell="{ column, index }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
    </template>
  </a-table>
  <a-drawer
    title="预约上架"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form
      :model="formData"
      :rules="formRules"
      layout="vertical"
      ref="formRef"
      autocomplete="off"
    >
      <a-row :gutter="10">
        <a-col :span="12">
          <a-form-item
            :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]"
            label="预约上架时间:" name="launchTimeInitial">
<!--            <a-input v-model:value="formData.launchTimeInitial" ></a-input>-->
              <a-date-picker show-time
                             placeholder="请选择预约上架时间"
                             @change="onChange"
                             @ok="onOk"
                             format="YYYY-MM-DD HH:mm"
                             v-model:value="formData.launchTimeInitial"
              />
          </a-form-item>
        </a-col>
      </a-row>

    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button html-type="submit" type="primary" @click="onSave()">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>


  </a-drawer>
</template>

<script>
import {computed, defineComponent, nextTick, onMounted, reactive, ref, toRefs} from 'vue'
// import {getExtendUri} from '../../../utils/request_extend'
// import {getRequestURI} from '../../../utils/request'
import SearchForm from '../../../components/extended/search_form'
import {getRequestURI, request, requestUsePagination} from '../../../utils/request.js'
import {message} from 'ant-design-vue'
import {FileAddOutlined,} from '@ant-design/icons-vue'

export default defineComponent({
  components: {
    FileAddOutlined,
    SearchForm,
  },
  setup() {
    const userDrawerRef = ref()
    const state = reactive({
      refreshCondition: true,
      showImg: [
        {
          url: 'https://static-1305332945.cos.ap-beijing.myqcloud.com/upload/common/product/2022/04/22/739de0d8_5a1f_4835_9003_bdb0be45b29b/184.jpg'
        }
      ],
      condition: {
        supId: {
          label: '供应商名称',
          type: 'tree-select',
          urlKey: 'querySupplier',
          value: 'id',
          default: '',
          data: []
        },
        productId: {
          label: '产品名称',
          placeholder: '产品名称，可根据关键字进行模糊查询',
          type:'select',
          default: '1',
          data:[]
        },

        productCode: '产品编码',
        status: {
          label: '上架状态',
          type: 'select',
          default: '1',
          // urlKey:'/product-service/product/supplier/common/querySupplier'
          data: [
            { value: '1', label: '上架' },
            { value: '2', label: '下架' },
            { value: '0', label: '待上架' },
          ],
        },
      },
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 30,
          align: 'center',
        },
        {
          title: '产品编码',
          width: 60,
          key: 'productCode',
          align: 'center',
          dataIndex: 'productCode',
        },
        {
          title: '产品名称',
          width: 80,
          align: 'center',
          key: 'productName',
          dataIndex: 'productName',
        },
        {
          title: '供应商名称',
          width: 110,
          align: 'center',
          key: 'supName',
          dataIndex: 'supName',
        },
        {
          title: '上架状态',
          width: 80,
          align: 'center',
          key: 'status',
          dataIndex: 'status',
          customRender: (text) => {
            if (text.text === 0) {
              return '待上架'
            } else if (text.text === 1) {
              return '上架'
            } else if (text.text === 2) {
              return '下架'
            }
          }
        },
        {
          title: '操作上下架时间',
          width: 100,
          align: 'center',
          key: 'updateTime',
          dataIndex: 'updateTime',
        },
        {
          title: '预约上架时间',
          width: 100,
          align: 'center',
          key: 'appointTime',
          dataIndex: 'appointTime',
        }
      ],
      selectedRowKeys: [],
      selectedRows: [],
      newArr: [],
      lastFilter: {},
    })
    const changes = function (value, treeData) {
      state.treeName = getStateData(value, treeData)
      request(getRequestURI('select'), { data: { supplierId: value } }).then(result => {
        const arr = []
        const res = result.result
        const label = 'id'
        const sysName = res.reduce((all, next) => all.some((atom) => atom[label] == next[label]) ? all : [...all, next], [])
        for (let i = 0; i < sysName.length; i++) {
          arr.push({value: sysName[i].id, label: sysName[i].productName })
        }
        state.condition.productId.data = arr
      })
    }
    const getStateData = (value, treeData) => {
      let result = ''
      if (!treeData) {
        return result
      }
      for (let i = 0; i < treeData.length; i++) {
        let item = treeData[i]
        if (item.id === value) {
          result = item.supName
          // debugger
          return result
        } else if (item.children && item.children.length > 0) {
          // debugger
          result = getStateData(value, item.children)
          if (result) {
            return result
          }
        }
      }
      return result
    }
    //预约上架
    const visible = ref(false)
    const showDrawer = () => {
      console.log(state.selectedRowKeys,state.selectedRows)
      const statusList = state.selectedRows.filter(item => item.status === 1)
      if(statusList && statusList.length) return message.warning('当前选择产品中有已上架产品, 请重新选择')
      if(state.selectedRowKeys.length){
        visible.value = true
      }else{
        message.warning('请选择数据')
      }

      // console.log('-----------')
    }
    const onClose = () => {
      visible.value = false
      formData.launchTimeInitial=''
    }
    const formData = reactive({
      launchTime:'',
      launchTimeInitial:'',
    })
    const formRules = {
      launchTime: [{required: true}],

    }
    const onChange = (value, dateString) => {
      formData.launchTime=dateString+':00'
      onOk(value)
    }
    const onOk = value => {
      console.log('onOk: ', value)

      console.log('----1---------',formData.launchTime)
    }
    const formRef = ref()
    const onSave = async () => {
      await formRef.value.validate()
      if(new Date(formData.launchTime).getTime() < new Date().getTime()) return message.warning('预约上架时间不能小于当前时间')

        state.selectedRows.forEach(res => {
          let obj = {
            id: res.id,
            productId: res.productId,
            launchTime:formData.launchTime
          }
          state.newArr.push(obj)
        })
        request(getRequestURI('productInfoPutOff'), {data:{productApptionTimeBaseForms: state.newArr}}).then(res => {
          if (res.code === 1) {
            message['success']('操作成功')
            handleSearch({})
          } else {
            message['error'](res.message)
          }
          state.newArr = []
          state.selectedRows = []
          state.selectedRowKeys = []
          onClose()
        })
    }
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('product_select'))
    const onSelectChange = (selectedRowKeys, selectedRows) => {
      console.log('selectedRows changed: ', selectedRows)
      state.selectedRowKeys = selectedRowKeys
      state.selectedRows = selectedRows
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.log('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.lastFilter,
      })
    }
    const resetCondition = () => {
      state.refreshCondition = false
      nextTick(() => {
        state.refreshCondition = true
      })
    }
    //页面挂载调用数据
    onMounted(() => {
      resetCondition()
      handleSearch({})
      changes()
    })
    const handleSearch = function (filters) {
      state.lastFilter = filters
      run({
        pagination: {
          current: current.value,
          pageSize: pageSize.value
        },
        data: filters,
      })
    }
    const resetAll = () => {
      state.condition.productId.data = []
      changes()
    }
    const alterState = function () {
      console.log('!@#$%', dataSource)
      if (state.selectedRowKeys.length > 0) {

        state.selectedRows.forEach(res => {
          let obj = {
            id: res.id,
            productId: res.productId,
            status: res.status
          }
          state.newArr.push(obj)
        })
        console.log('newArr', state.newArr)
        request(getRequestURI('product_updateToPutOff'), { data: { productPutOffBaseForms: state.newArr } }).then(res => {
          if (res.code === 1) {
            message['success']('操作成功')
            handleSearch(state.lastFilter)
          } else {
            message['error'](res.message)
          }
          state.newArr = []
          state.selectedRows = []
          state.selectedRowKeys = []
        })
      } else {
        message.warning('请选择数据')
        return false
      }
    }
    return {
      resetAll,
      ...toRefs(state),
      handleSearch,
      onSelectChange,
      handleTableChange,
      alterState,
      userDrawerRef,
      pagination,
      loading,
      dataSource,
      getRequestURI,
      visible,
      showDrawer,
      onClose,
      formData,
      formRules,
      onChange,
      onOk,
      formRef,
      onSave,
      changes,
      getStateData
    }
  },
})
</script>
