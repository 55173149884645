<template>
  <a-drawer
    :title="inNew"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <search-form :Condition="condition" :ColSpan="12" @callback="handleSearchProcess" ref="searchRef"></search-form>
    <a-table
      :columns="columns"
      :data-source="dataSource ? dataSource['result'] : []"
      :pagination="pagination"
      :row-key="(record) => record.id"
      @change="handleTableChange"
      :loading="loading"
    >
      <template #bodyCell="{ column, index, record }">
        <template v-if="column.key === 'index'">
          {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
        </template>
        <template v-if="column.key === 'operation'">
          <div>
            <a-button type="text" style="margin-right: 5px; color: #00a0e9;" size="small" @click="gaunlian(record)">
              关联
            </a-button>
            <a-button type="text" size="small" style="margin-right: 5px;color: orange;" v-if="record.labelLevel !== 3" @click="yvlan(record)">
              预览
            </a-button>
          </div>
        </template>
      </template>
    </a-table>
    <process-details ref="processDetailsRef"></process-details>
  </a-drawer>
</template>

<script>
import ProcessDetails from './processDetails'
import {message, Modal} from 'ant-design-vue'
import {computed, defineComponent, reactive, toRefs, ref, onMounted} from 'vue'
import SearchForm from '../../../components/extended/search_form'
import {getRequestURI, request, requestUsePagination} from '../../../utils/request'
import { getDictionaryValue } from '../../../utils/request_extend'
export default defineComponent({
  components: {
    SearchForm,
    ProcessDetails
  },
  props: {
    handleSearch: {type: Function}
  },
  setup(props) {
    const processDetailsRef = ref()
    const state = reactive({
      visible: false,
      lastFilter: {},
      formData: {},
      columns: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left',
        },
        {
          title: '流程名称',
          width: 100,
          fixed: 'left',
          align: 'center',
          key: 'progressName',
          dataIndex: 'progressName',
        },
        {
          title: '流程id',
          width: 100,
          align: 'center',
          key: 'progressId',
          dataIndex: 'progressId',
        },
        {
          title: '流程状态',
          width: 100,
          align: 'center',
          key: 'progressState',
          dataIndex: 'progressState',
          customRender: (text)=> getDictionaryValue('status', text.value)
        },
        {
          title: '创建时间',
          width: 100,
          align: 'center',
          key: 'createTime',
          dataIndex: 'createTime',
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          fixed: 'right',
          width: 120,
        },
      ],
      selectedRowKeys: [],
      condition: {
        progressName: {
          type: 'select',
          label: '流程名称',
          data: []
        },
        progressId: '流程id'
      }
    })
    const showDrawer = (oldData = {}) => {
      state.formData = oldData
      state.visible = true
      handleSearchProcess({})
      handelProgressName()
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
      // pageSizeOptions: [10, 20, 50],
    }))
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('process_getProgressList'))


    const handleSearchProcess = function (filters) {
      state.lastFilter = filters
      run({
        pagination: {
          current: current.value,
          pageNum: pageSize.value
        },
        data: filters,
      })
    }


    const handelProgressName = function() {
      state.condition.progressName.data = []
      request(getRequestURI('process_getProgressList'), {data:{},pagination :{pageSize:9999, pageNum:1 }}).then(res => {
        res.result.forEach(item => {
          let obj = {}
          obj.value = item.progressName
          obj.label = item.progressName
          state.condition.progressName.data.push(obj)
        })
      })
    }

    // const pagination = computed(() => ({
    //   current: current.value,
    //   pageNum: current.value,
    //   pageSize: pageSize.value,
    //   total: total.value,
    // }))
    const onSelectChange = (selectedRowKeys) => {
      state.selectedRowKeys = selectedRowKeys
    }
    const yvlan = function(value) {
      processDetailsRef.value.showDrawer(value)
    }
    const gaunlian = function(value) {
        if((value.progressState * 1) !== 1) return message.warning('当前流程状态未启用, 不可以关联')
        Modal.confirm({
          title: '关联提醒',
          content: '产品【'+ state.formData.productName + '】即将关联流程【' + value.progressName + '】,请确认是否执行该操作',
          async onOk() {
            // let keys = state.selectedRowKeys
            // if (keys.length === 0) {
            //   message.warning('请选择数据')
            //   return
            // }
            // visible.value = true
            const uri = getRequestURI('productInfo_associatedProcess12')
            console.log(value)
            request(uri,{progressId: value.progressId, productId: state.formData.productId}).then(res => {
              console.log(res)
              if(res.code == 1) {
                console.log(res)
                message['success'](res.message)
                props.handleSearch({})
                onClose()
              } else {
                message['error'](res.message)
              }
            })
          },
          onCancel() {
            // state.querydel.splice(0)
          }
        })
    }

    const handleTableChange = (pag, filters, sorter) => {
      state.lastFilter = filters
      run({
        pagination: {
          pageNum: pag?.current,
          pageSize: pag?.pageSize,
          current: pag?.current
        },
        sorter,
        data: state.lastFilter,
      })
    }
    const searchRef = ref()
    const onClose = function() {
      searchRef.value.deleteAll()
      state.visible = false
    }
    const inNew = computed(() => {
      return '关联流程'
    })
    onMounted(()=>{
      // handelProgressName()
    })
    return {
      searchRef,
      inNew,
      current,
      pageSize,
      ...toRefs(state),
      onClose,
      handelProgressName,
      showDrawer,
      onSelectChange,
      pagination,
      handleTableChange,
      dataSource,
      loading,
      gaunlian,
      yvlan,
      handleSearchProcess,
      processDetailsRef,
    }
  }
})
</script>

<style scoped>

</style>
