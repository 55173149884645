<style scoped>
  
</style>
<template>
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @callback="handleSearch"
  ></search-form>
    <!-- :data-source="dataSource ? dataSource['result'] : []" -->
  <a-table
    :columns="productColumns"
    :row-key="(record) => record.id"
    :data-source="dataSource ? dataSource['result'] : []"
    :pagination="pagination"
    @change="handleTableChange"
    :loading="loading"
    style="margin-top: 15px"
  >
    <template #bodyCell="{ column, index, record}">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <div>
          <a style="color: orange" @click="handelProduct(record)">产品渠道配置</a>&nbsp;&nbsp;
        </div>
      </template>
    </template>
  </a-table>
  <pro-addAndEdit ref="proDrawerRef"></pro-addAndEdit>
</template>

<script>
import SearchForm from '../../../components/extended/search_form'
import {defineComponent, reactive, toRefs, computed, ref, onMounted} from 'vue'
import {requestUsePagination, getRequestURI} from '../../../utils/request.js'
import ProAddAndEdit from './proAddAndEdit.vue'
export default defineComponent({
  components:{
    SearchForm,
    ProAddAndEdit
  },
  setup(){
    const proDrawerRef = ref()
    const state = reactive({
      condition: {
        productCode:'产品编码',
        productName: {
          label: '产品名称',
          type: 'input',
          default: '1',
          // data: []
          }
      },
      productColumns: [  // 产品渠道
        {
          key: 'index',
          title: '序号',
          width: 60,
          align: 'center',
        },
        {
          title: '产品编码',
          width: 140,
          align: 'center',
          key: 'productCode',
          dataIndex: 'productCode',
        },
        {
          title: '产品名称',
          width: 140,
          align: 'center',
          key: 'productName',
          dataIndex: 'productName',
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 80,
        },
      ],
      newChannel: {}

    })
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('ppqa_selectInfo'))

    const onSelectChange = (selectedRowKeys) => {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.log('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: filters,
      })
    }
    const handleSearch = function (filters) {
      console.log('handleSearch', filters)
      state.newChannel = filters
      run({
        pagination: {
          current: current.value,
          pageSize: pageSize.value
        },
        data: filters,
      })
    }



    const handelProduct = (oldData) =>{
      proDrawerRef.value.showDrawer(oldData)
    }

    onMounted(() => {

    })


    return{
      ...toRefs(state),
      onSelectChange,
      handleTableChange,
      handleSearch,
      handelProduct,
      proDrawerRef,
      pagination,
      loading,
      dataSource,
    }
  }
})
</script>