<template>
  <a-drawer
    title="关联险种责任"
    :width="'60%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <div style="margin-bottom: 5px;">
    <a-button danger @click="delUser">
      <template #icon>
        <DeleteOutlined/>
      </template>
      批量删除
    </a-button>&nbsp;
    <a-button type="primary" @click="relationInsurance(record,1)">
      <template #icon>
        <SaveOutlined/>
      </template>
      新增
    </a-button>
    </div>
    <a-table :columns="columns" :data-source="dataSource ? dataSource['result'] : []"
             :row-key="record => record.id"
             :pagination="pagination"
             @change="handleTableChange"
             :row-selection="{
			selectedRowKeys: selectedRowKeys,
			onChange: onSelectChange,
		}"

             :loading="loading"
             :scroll="{ x: 1500 }"
    >
    <template #bodyCell="{ column, index,record}">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <a-button type="text" @click="modifyUser(record,2)" size="small" style="margin-right: 10px; color: orange;">
          修改
        </a-button>
      </template>
    </template>
    </a-table>
  </a-drawer>
  <add-shoulder-list-add ref="addShoulderListAddRef" @select="handleSearch"></add-shoulder-list-add>
</template>

<script>
import {computed, defineComponent, reactive, toRefs,ref} from 'vue'
import {getRequestURI, request, requestUsePagination} from '../../../utils/request'
import AddShoulderListAdd from './addShoulderListAdd'
import {message, Modal} from 'ant-design-vue'
import { getDictionaryValue } from '../../../utils/request_extend'

export default defineComponent({
  components: {AddShoulderListAdd},
  setup() {
    const formData = reactive({
      // dutyCode:'',//责任编码
      comDutyCode:'',//供应商责任编码
      // dutyName:'',//责任名称
      dutyType: undefined,//责任类型
      dutyTypeName:'',//责任类型名称
      isShowStatus:'N',//页面是否显示
      coverageWay:'1',//保额选择方式
      sort: '',//显示顺序
      isRisk:'N',//是否可选责任
      mainFlag:undefined,//主附险标志
      deductibleExcess:'',//免赔额
      dutyIntroduce:'',//责任描述
      id: '0',
      riskId:'',//险种id
      riskDutyPremiumList:[
        {
          premium:'0',//	保额/起止保额
          premiumValue:'',//保额/起止保额显示值
          id:'',
          riskDutyId:''//险种责任id
        }
      ],
      riskDutyPremiumScopeList:[
        {
          endPremium:'0',//终止保额
          endPremiumValue:'',//终止保额显示值
          id:'',
          premium:'0',//保额/起止保额
          premiumValue:'',//保额/起止保额显示值
          riskDutyId:'',//险种责任id

        }
      ],

    })
    const addShoulderListAddRef = ref()
    const delUser = function() {
      if (state.selectedRowKeys.length == 0) {
        message['warning']('请选择数据')
      } else {
        Modal.confirm({
          title: '确认删除吗?',
          async onOk() {
            // let keys = state.selectedRowKeys
            // if (keys.length === 0) {
            //   message.warning('请选择数据')
            //   return
            // }
            // visible.value = true
            const uri = getRequestURI('productElementRiskDutyLink_delete')
            console.log(state.selectedRowKeys)
            request(uri,{data: {ids: state.selectedRowKeys}}).then(res => {
              console.log(res)
              if(res.code == 1) {
                console.log(res)
                state.selectedRowKeys = []
                message['success'](res.message)
                handleSearch()
              } else {
                message['error'](res.message)
              }
            })
          },
          onCancel() {
            // state.querydel.splice(0)
          }
        })
      }
    }
    const state = reactive({
      visible: false,
      formData: {},
      selectedRowKeys: [],
      id: 0,
      columns: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 20,
          align: 'left',
        },
        {
          title: '要素类别',
          width: 60,
          align: 'center',
          key: 'elementName',
          dataIndex: 'elementName'
        },
        {
          title: '要素类别名称',
          width: 60,
          align: 'center',
          key: 'elementName',
          dataIndex: 'elementName'
        },
        {
          title: '投保要素值',
          width: 60,
          key: 'elementListName',
          dataIndex: 'elementListName'
        },
        {
          title: '责任名称',
          width: 60,
          key: 'dutyName',
          dataIndex: 'dutyName'
        },
        {
          title: '责任编码',
          width: 60,
          align: 'center',
          key: 'dutyCode',
          dataIndex: 'dutyCode'
        },
        {
          title: '供应商责任编码',
          width: 60,
          align: 'center',
          key: 'comDutyCode',
          dataIndex: 'comDutyCode'
        },
        {
          title: '是否可选',
          width: 60,
          align: 'center',
          key: 'isOptional',
          dataIndex: 'isOptional',
          customRender: (text)=>getDictionaryValue('yes_no', text.value)
        },
        {
          title: '责任类型',
          width: 60,
          align: 'center',
          fixed: 'right',
          key: 'dutyTypeName',
          dataIndex: 'dutyTypeName',
        },
        {
          title: '操作',
          key: 'operation',
          fixed: 'right',
          align: 'center',
          width: 60
        }
      ]
    })
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value
    }))
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total
    } = requestUsePagination(getRequestURI('productElementRiskDutyLink_selectByElementId'))
    const handleSearch = function (filters) {
      console.log('搜索参数==', filters)
      run({
        pagination: {
          current: 1,
          pageSize: 10
        },
        data: {
          id: state.id
        }
      })
    }
    const modifyUser = (oldData, num) => {
      console.log('&&&&&&&&&&&&&&&&&&&',oldData,num)
      addShoulderListAddRef.value.showDrawer( shoulderObj,num,oldData)
      // state.haoshou = false
      // Object.assign(data)
      // state.edit = false
    }
    const onClose = function () {
      state.visible = false
    }
    const onSelectChange = (selectedRowKeys) => {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
    }
    const shoulderObj = reactive({})
    const showDrawer = (oldData = {}, dataList = {}) => {
      Object.assign(shoulderObj, oldData)
      state.id = oldData.id
      state.visible = true
      Object.keys(formData).forEach((key) => {
        formData[key] = dataList[key] || formData[key]
      })
      formData.riskId=dataList.id
      handleSearch()
    }
    // 页面发生改变回调
    const handleTableChange = (pag, filters, sorter) => {
      console.log('===', filters)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        data: {
          id: state.id
        },
        sorter
      })
    }
    // 险种关联
      const relationInsurance = function(oldData,num) {
        console.log('新增时候的oldData',oldData,num)
      addShoulderListAddRef.value.showDrawer(shoulderObj,num)
    }





    return {
      shoulderObj,
      relationInsurance,
      addShoulderListAddRef,
      ...toRefs(state),
      onClose,
      dataSource,
      loading,
      pageSize,
      total,
      run,
      delUser,
      handleSearch,
      pagination,
      handleTableChange,
      showDrawer,
      formData,
      onSelectChange,
      modifyUser
    }
  }
})
</script>

<style scoped>

</style>
