<style scoped>
.bg {
  margin-bottom: 20px;
}

.upload-file {
  /* display: flex;
  justify-content: flex-start; */
}

.file-up {
  /*width: 60%;*/
  /*position: relative;*/
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.download-file {
  /*position: absolute;*/
  /*left: 300px;*/
  /*bottom: 0;*/
  width: 65px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background: rgb(86, 56, 232);
  color: aliceblue;
  font-size: 12px;
}
.none{
  pointer-events:none;
  background: #f3f3f3;
}
</style>
<template>
  <a-drawer
    v-if="isRefreshNew"
    :title="isNew"
    :width="'80%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-tabs v-model:activeKey="activeKey" @change="tabChanges">
      <a-tab-pane key="1" tab="产品基础信息">
        <a-form
          :model="data"
          layout="horizontal"
          ref="formRef"
          autocomplete="off"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-row :gutter="10">
            <a-col span="12">
              <a-form-item label="供应商名称:" name="supplierName"
                           :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
                <a-tree-select v-model:value="data.supplierName" show-search allow-clear tree-default-expand-all
                               :disabled="isCheckInfo"
                               @change="getSupplierName"
                               :tree-data="listData.gongyingshangmingcheng" placeholder="请输入供应商名称"
                               :filterTreeNode="filterTreeOption"
                               :fieldNames="{children:'children', label:'supName', key:'id', value:'id'}"
                               :getPopupContainer="(triggerNode)=>triggerNode.parentNode">
                </a-tree-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item
                :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]" name="underwriteCompanyName">
                <template #label>
                  <span>承保公司名称:</span>
                  <span>
                <a-popover placement="right">
                  <template #content>
                  <p>
                    表示承保公司，会在客户告知书使用。承保公司未必一定是产品供应商，可为代理公司。
                  </p>
                  </template>
                  <template #title>
                  <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
                </span>
                </template>
                <a-input v-model:value="data.underwriteCompanyName" :disabled="isCheckInfo" placeholder="请输入承保公司名称"/>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item
                :rules="[{ required: true, message: '必填信息' ,trigger: 'change'},
                {max: 20, message: '输入长度不能超过20' ,trigger: 'change'},
                { pattern:/^[0-9a-zA-Z]/, message: '产品编码只支持数字和字母' ,trigger: 'change'}]"
                label="产品编码:" name="productCode">
                <a-input
                  @blur="productCodeBlur"
                  v-model:value="data.productCode"
                  placeholder="请输入产品编码"
                  :disabled=" isCheckInfo"
                />
              </a-form-item>
            </a-col>
            <a-col span="12">
<!-- { pattern:/^[0-9a-zA-Z_]{1,}$/, message: '产品编码只支持数字、字母和下划线' ,trigger: 'blur'}-->
              <a-form-item
                :rules="[{ required: true, message: '必填信息' ,trigger: 'change'},
                {max: 50, message: '输入长度不能超过50' ,trigger: 'change'}]"
                label="供应商产品编码:" name="supProductCode">
                <a-input
                  v-model:value="data.supProductCode"
                  :disabled="isCheckInfo"
                  placeholder="请输入供应商产品编码"
                />
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item
                :rules="[{ required: true, message: '必填信息' ,trigger: 'change'},
                {max: 50, message: '输入长度不能超过50' ,trigger: 'change'}]"
                label="产品名称:" name="productName">
                <a-input
                  v-model:value="data.productName"
                  :disabled="isCheckInfo"
                  placeholder="请输入产品名称"
                />
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item
                :rules="[{ required: true, message: '必填信息' ,trigger: 'change'},
                  {max: 50, message: '输入长度不能超过50' ,trigger: 'change'}]"
                label="产品简称:" name="productShortName">
                <a-input
                  v-model:value="data.productShortName"
                  :disabled="isCheckInfo"
                  placeholder="请输入产品简称"
                />
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item
                :rules="[
                {max: 50, message: '输入长度不能超过50' ,trigger: 'change'}]"
                label="产品英文名称:" name="productEngName">
                <a-input
                  @blur="productEnglishBlur"
                  v-model:value="data.productEngName"
                  :disabled="isCheckInfo"
                  placeholder="请输入产品英文名称"
                />
              </a-form-item>
            </a-col>

            <a-col span="12">
              <a-form-item
                :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]"
                label="产品状态:" name="productStatus">
                <a-select
                  v-model:value="data.productStatus"
                  allow-clear
                  :options="productStatusList"
                  :disabled="isCheckInfo"
                  :getPopupContainer="(triggerNode)=>triggerNode.parentNode"
                  placeholder="请输入产品状态"
                >
                  <!-- <a-select-option value="1">有效</a-select-option>
                  <a-select-option value="2">无效</a-select-option> -->
                </a-select>
              </a-form-item>
            </a-col>

            <a-col span="12">
              <a-form-item
                :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]"
                label="产品类型:" name="productType">
                <a-select :options="productTypeList" v-model:value="data.productType" allow-clear
                          placeholder="请选择产品类型" :disabled="isCheckInfo"
                          :getPopupContainer="(triggerNode)=>triggerNode.parentNode">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item
                :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]"
                label="产品类别:" name="productCategory">
                <a-select :options="productCategoryList" v-model:value="data.productCategory" allow-clear
                          placeholder="请选择产品类别" :disabled="isCheckInfo"
                          :getPopupContainer="(triggerNode)=>triggerNode.parentNode">
                </a-select>
              </a-form-item>
            </a-col>
<!--{pattern:/(^[1-9](\d+)?(\.\d{1,2})?$)|(^\d\.\d{1,2}$)/, message: '支持大于0的整数或两位小数' ,trigger: 'change'}-->
            <a-col span="12">
              <a-form-item label="产品起始价格:" name="productStartPrice"
                           :rules="[
                    { required: true, message: '请输入产品起始价格' ,trigger: 'change'},
                ]">
                <a-input
                  v-model:value="data.productStartPrice"
                  :disabled="isCheckInfo"
                  placeholder="请输入产品起始价格"
                />
              </a-form-item>
            </a-col>
<!--            detailPagePremiumCountFlag-->
            <a-col span="12">
              <a-form-item
                :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]"
                label="详情页是否进行保费计算:" name="detailPagePremiumCountFlag">
                <a-select v-model:value="data.detailPagePremiumCountFlag" allow-clear :disabled="isCheckInfo"
                          :getPopupContainer="(triggerNode)=>triggerNode.parentNode"
                          :options="needDoubleRecordList"
                          placeholder="请选择详情页是否进行保费计算">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="产品特色:" name="productFeature"
                           :rules="[{ required: true, message: '请输入产品特色' ,trigger: 'change'}]">
                <a-input
                  v-model:value="data.productFeature"
                  :disabled="isCheckInfo"
                  placeholder="请输入产品特色，用于产品详情页展示"
                />
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="产品投保年龄范围:" name="ageGroup"
                           :rules="[{ required: true, message: '请输入产品投保年龄范围' ,trigger: 'change'}]">
                <a-input
                  v-model:value="data.ageGroup"
                  :disabled="isCheckInfo"
                  placeholder="请输入产品投保年龄范围"
                />
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="是否显示浮窗:" name="isFloating"
                           :rules="[{ required: true, message: '请选择是否显示浮窗' ,trigger: 'change'}]">
                <a-select v-model:value="data.isFloating" allow-clear :disabled="isCheckInfo"
                          :getPopupContainer="(triggerNode)=>triggerNode.parentNode"
                          :options="needDoubleRecordList"
                          placeholder="请选择是否显示浮窗">
                </a-select>
              </a-form-item>
            </a-col>

            <a-col span="12" v-if="data.isFloating === 'Y'">
              <a-form-item label="浮窗内容:" name="floatingContent"
                           :rules="[{ required: data.isFloating === 'Y', message: '请输入浮窗内容' ,trigger: 'change'}]">
                <a-input
                  v-model:value="data.floatingContent"
                  :disabled="isCheckInfo"
                  placeholder="请输入浮窗内容"
                />
              </a-form-item>
            </a-col>

            <a-col span="24">
              <a-form-item label="产品描述:" name="productDescription"
                           :rules="[{ required: true, message: '请输入产品描述' ,trigger: 'change'}]"
                           :label-col="{span: 4, offset: 0}">
                <a-textarea
                  v-model:value="data.productDescription"
                  :disabled="isCheckInfo"
                  placeholder="请输入产品描述，用于产品详情页展示"
                />
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="产品封面图:" name="productCoverImg">
                <div @click="insertImg"
                     style="width: 105px; height: 105px;border: 1px solid antiquewhite;font-size: 30px;text-align: center;line-height: 95px">
                  <img :src="data.productCoverImg" v-if="data.productCoverImg !== ''" style="width: 100%; height:100%"/><a
                  v-if="data.productCoverImg == ''">+</a>
                </div>
                <p style="color: #bbbbbb;">用于产品列表页展示</p>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="产品标题图:" name="productBackImg">
                <div @click="insertImg1"
                     style="width: 105px; height: 105px;border: 1px solid antiquewhite;font-size: 30px;text-align: center;line-height: 95px">
                  <img :src="data.productBackImg" v-if="data.productBackImg !== ''" style="width: 100%; height:100%"/><a
                  v-if="data.productBackImg == ''">+</a></div>
                <p style="color: #bbbbbb;">用于产品详情页头部展示</p>
              </a-form-item>
            </a-col>
            <a-col span="24">
              <h3 style="font-weight: bold">产品跳转流程配置: </h3>
            </a-col>
            <a-divider style="height: 2px; background-color: #7cb305;margin: 0 0 24px"/>
            <a-col span="12">
              <a-form-item
                :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]"
                label="列表页跳转方式:" name="listPageSkipType">
                <a-select
                  v-model:value="data.listPageSkipType"
                  allow-clear
                  :options="listPageSkipTypeList"
                  :disabled="isCheckInfo"
                  :getPopupContainer="(triggerNode)=>triggerNode.parentNode"
                  placeholder="请选择列表页跳转方式"
                  @change="handelDelSkipUrl('1')"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12" v-if="data.listPageSkipType!=='2'">
              <a-form-item
                label="产品模板:" name="templateCode"
                :rules="[{ required: data.listPageSkipType ==='1', message: '必填信息' ,trigger: 'change'}]"
              >
                <a-select
                  v-model:value="data.templateCode"
                  :options="templateCodeList"
                  :disabled="isCheckInfo"
                  :field-names="{label:'templateName',key:'templateCode',value:'templateCode'}"
                  show-search :filter-option="filterOption"
                  :getPopupContainer="(triggerNode)=>triggerNode.parentNode"
                  placeholder="请选择产品模板"
                />
              </a-form-item>
            </a-col>
            <a-col span="12" v-if="data.listPageSkipType==='2'">
              <a-form-item
                :rules="[{ required: data.listPageSkipType==='2', message: '必填信息' ,trigger: 'change'},]"
                label="列表页跳转链接地址:" name="listOtherPageSkipUrl">
                <a-input
                  v-model:value="data.listOtherPageSkipUrl"
                  :disabled="isCheckInfo"
                  placeholder="请输入列表页跳转链接地址"
                />
              </a-form-item>
            </a-col>
            <a-col span="12" v-if="data.listPageSkipType!=='2'">
              <a-form-item
                :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]"
                label="详情页跳转方式:" name="detailPageSkipType">
                <a-select
                  v-model:value="data.detailPageSkipType"
                  allow-clear
                  :options="detailPageSkipTypeList"
                  :disabled="isCheckInfo"
                  :getPopupContainer="(triggerNode)=>triggerNode.parentNode"
                  placeholder="请选择详情页跳转方式"
                  @change="handelDelSkipUrl('2')"
                >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12" v-if="(data.detailPageSkipType==='2' || data.detailPageSkipType==='4') && data.listPageSkipType!=='2'">
              <a-form-item
                :rules="[{ required: data.detailPageSkipType==='2', message: '必填信息' ,trigger: 'change'},]"
                label="详情页跳转链接地址:" name="detailOtherPageSkipUrl">
                <a-input
                  v-model:value="data.detailOtherPageSkipUrl"
                  :disabled="isCheckInfo"
                  placeholder="请输入详情页跳转链接地址"
                />
              </a-form-item>
            </a-col>
            <a-col span="12" v-if="data.listPageSkipType!=='2'">
              <a-form-item
                label="详情页跳转按钮名称:" name="detailPageSkipButtonName">
                <a-input
                  v-model:value="data.detailPageSkipButtonName"
                  :disabled="isCheckInfo"
                  placeholder="请输入详情页跳转按钮名称"
                />
              </a-form-item>
            </a-col>

            <a-col span="24">
              <h3 style="font-weight: bold">流程配置项: </h3>
            </a-col>
            <a-divider style="height: 2px; background-color: #7cb305;margin: 0 0 24px"/>
            <a-col span="12">
              <a-form-item
                :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]" name="supplierPayment">
                <template #label>
                  <span>是否供应商页面支付:</span>
                  <span>
                <a-popover placement="right">
                  <template #content>
                  <p>
                    决定是否由保司提供的页面进行支付，默认为是，选否时会关联支付方式，在支付时会使用产品工厂自有的支付页面
                  </p>
                  </template>
                  <template #title>
                  <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
                </span>
                </template>
                <a-select :options="bankCardFlagList" v-model:value="data.supplierPayment" allow-clear
                          placeholder="请选择是否供应商页面支付" :disabled="isCheckInfo"
                          :getPopupContainer="(triggerNode)=>triggerNode.parentNode"
                          @change="collecting">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item
                :rules="[{ required: !daiShou, message: '必填信息' ,trigger: 'change'}]"
                label="支付方式:" name="payType">
                <a-checkbox-group @change="handlePayTypeChange" v-model:value="data.payType" :options="plainOptions"
                                  :disabled="isCheckInfo || daiShou">
                </a-checkbox-group>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item
                :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]" name="payUrlType">
                <template #label>
                  <span>支付链接形式: </span>
                  <span>
                <a-popover placement="right">
                  <template #content>
                  <p>
                    决定程序投保流程中所需支付链接的来源形式，下拉选项有
                    <br>1. 核保返回：由保司在核保之后返回
                    <br>2. 拼接：按照保司的要求，由系统自动拼接成合法的支付链接
                    <br>3. 保司接口：根据保司专门提供的获取支付链接接口获取
                  </p>
                  </template>
                  <template #title>
                  <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
                </span>
                </template>
                <a-select :options="payUrlTypeList" v-model:value="data.payUrlType" allow-clear
                          placeholder="请选择支付链接形式" :disabled="isCheckInfo"
                          :getPopupContainer="(triggerNode)=>triggerNode.parentNode">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item
                :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]"
                label="是否供应商回执:" name="receiptType">
                <a-select :options="receiptTypeList" v-model:value="data.receiptType" placeholder="请选择是否供应商回执"
                          allow-clear :disabled="isCheckInfo"
                          :getPopupContainer="(triggerNode)=>triggerNode.parentNode">
                  <!-- <a-select-option value="1">中介公司回执</a-select-option>
                  <a-select-option value="2">供应商回执</a-select-option> -->
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="是否可以制作建议书:" name="prospectus"
                           :rules="[{ required: true, message: '请选择' ,trigger: 'change'}]">
                <!--                <a-radio-group :disabled="isCheckInfo" v-model:value="data.prospectus" :options="healthNoticeList" />-->
                <a-select v-model:value="data.prospectus" allow-clear :disabled="isCheckInfo"
                          :getPopupContainer="(triggerNode)=>triggerNode.parentNode"

                          :options="healthNoticeList"
                          placeholder="请输入是否可以制作建议书">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item
                :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]"
                label="是否需要双录:" name="needDoubleRecord">
                <a-select v-model:value="data.needDoubleRecord" allow-clear :disabled="isCheckInfo"
                          :getPopupContainer="(triggerNode)=>triggerNode.parentNode"
                          @change="collecting2"
                          :options="needDoubleRecordList"
                          placeholder="请输入是否需要双录">
                </a-select>
              </a-form-item>
            </a-col>

            <a-col span="12" v-show="doubleRecord">
              <a-form-item
                :rules="[{ required: doubleRecord, message: '必填信息' ,trigger: 'change'}]"
                label="双录方式:" name="doubleRecordMode">
                <a-select v-model:value="data.doubleRecordMode" allow-clear :disabled="isCheckInfo || !doubleRecord"
                          :getPopupContainer="(triggerNode)=>triggerNode.parentNode"

                          :options="doubleRecordModeList"
                          placeholder="请输入双录方式">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12" v-show="doubleRecord">
              <a-form-item
                :rules="[{ required: doubleRecord, message: '必填信息' ,trigger: 'change'}]"
                label="双录系统展示方式:" name="doubleRecordSystemDisplayMode">
                <a-select v-model:value="data.doubleRecordSystemDisplayMode" allow-clear
                          :disabled="isCheckInfo || !doubleRecord"
                          :getPopupContainer="(triggerNode)=>triggerNode.parentNode"

                          :options="doubleRecordSystemDisplayModeList"
                          placeholder="请输入双录系统展示方式">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item
                :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]"
                name="isMultiPage">
                <template #label>
                  <span>是否展示多被保人预览页: </span>
                  <span>
                <a-popover placement="right">
                  <template #content>
                  <p>
                    当产品为多被保人且选择“是”时，在信息填写页面和订单确认页面会增加每个被保人信息确认页面
                  </p>
                  </template>
                  <template #title>
                  <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
                </span>
                </template>
                <a-select :options="isMultiPageList" v-model:value="data.isMultiPage" allow-clear
                          placeholder="请选择是否展示多被保人预览页" :disabled="isCheckInfo"
                          :getPopupContainer="(triggerNode)=>triggerNode.parentNode">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="线上/线下产品:" name="productOnOffLineType"
                           :rules="[{ required: true, message: '请选择' ,trigger: 'change'}]">
                <a-select v-model:value="data.productOnOffLineType" allow-clear :disabled="isCheckInfo"
                          :getPopupContainer="(triggerNode)=>triggerNode.parentNode"
                          :options="productOnOffLineTypeList"
                          placeholder="请选择线上/线下产品">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="是否验真手机号:" name="isVerifyMobile"
                           :rules="[{ required: true, message: '请选择' ,trigger: 'change'}]">
                <a-select v-model:value="data.isVerifyMobile" allow-clear :disabled="isCheckInfo"
                          :getPopupContainer="(triggerNode)=>triggerNode.parentNode"
                          :options="bankCardFlagList"
                          placeholder="请选择是否验真手机号">
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
        <editfromImgList ref="editfromImgListRef" @List="List"></editfromImgList>
      </a-tab-pane>
      <a-tab-pane key="2" tab="关联险种信息" v-if="isNew !== '产品信息增加'">
        <!--        <search-form-->
        <!--          :Condition="condition2"-->
        <!--          :ColSpan="12"-->
        <!--          @callback="handleSearch"-->
        <!--          v-if="look"-->
        <!--        ></search-form>-->
        <div style="margin-bottom: 5px;">
          <a-button danger @click="delMultiple" v-if="!zhuangTai2">
            <template #icon>
              <DeleteOutlined/>
            </template>
            批量删除
          </a-button>&nbsp;&nbsp;
          <a-button default @click="addMultiple" v-if="!zhuangTai2">
            <template #icon>
              <FileAddOutlined/>
            </template>
            添加主险
          </a-button>
        </div>
        <div v-if="!zhuangTai2">
          <a-table
            class="bg"
            :columns="columns"
            :data-source="result"
            :row-selection="{
			selectedRowKeys: selectedRowKeys,
			checkStrictly: false,
			onChange: onSelectChange,
		}"
            :pagination="false"
            :row-key="record => record.id"
            @change="handleTableChange"
            :loading="loading"
          >
            <template #bodyCell="{ column, index, record }">
              <template v-if="column.key === 'index'">
                {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
              </template>
              <template v-if="column.key === 'operation'">
                <a-button v-if="record.riskPid === '0'" type="text" @click="addSonMultiple(record)"
                          style="margin-right: 10px; color: orange;" size="small">
                  添加附加险
                </a-button>
                <a-button @click="delMultiple1(record)" type="text" style="margin-right: 10px; color: orange;"
                          size="small">
                  删除
                </a-button>
              </template>
            </template>
          </a-table>
        </div>
        <div v-if="zhuangTai2">
          <a-table :columns="columns" :data-source="result"
                   :row-key="record => record.id"
                   :pagination="false" @change="handleTableChange"
                   :loading="loading"
          >
          </a-table>
        </div>
        <addmainaccessoryRisk ref="addmainaccessoryRiskRef" @select="handleSearch({})"></addmainaccessoryRisk>
      </a-tab-pane>
      <a-tab-pane key="3" tab="拓展信息" v-if="isNew !== '产品信息增加'">
        <a-form
          :model="formData4"
          layout="horizontal"
          ref="formRef"
          autocomplete="off"
          :label-col="{ span: 8 }"
          :labelWrap="true"
          :wrapper-col="{ span: 16 }"
        >
          <a-input v-model="formData4.id" v-show="false"/>
          <a-row :gutter="10">
<!--            <a-col span="12">-->
<!--              <a-form-item name="productSkipAddress"-->
<!--                           :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">-->
<!--                <template #label>-->
<!--                  <span>产品跳转地址: </span>-->
<!--                  <span>-->
<!--                <a-popover placement="right">-->
<!--                  <template #content>-->
<!--                  <p>-->
<!--                    “产品跳转地址”与“链接地址”有关联，该字段用来区分该产品是H5产品还是API产品。-->
<!--                    <br>当产品跳转地址选择“外部”时，“链接地址”需要输入H5链接地址，其中可变参数使用%s占位符，在系统中进行适配替换-->
<!--                  </p>-->
<!--                  </template>-->
<!--                  <template #title>-->
<!--                  <span>规则说明:</span>-->
<!--                  </template>-->
<!--                  <question-circle-outlined />-->
<!--                </a-popover>-->
<!--                </span>-->
<!--                </template>-->
<!--                <a-select v-model:value="formData4.productSkipAddress" :disabled="isCheckInfo"-->
<!--                          :options="listData.productSkipAddressList">-->
<!--                </a-select>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
<!--            <a-col span="12" v-if="formData4.productSkipAddress === '2'">-->
<!--              <a-form-item label="链接地址:" name="outAddressLink"-->
<!--                           :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">-->
<!--                <a-input v-model:value="formData4.outAddressLink" :disabled="isCheckInfo">-->
<!--                </a-input>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
            <a-col span="12">
              <a-form-item label="中介公司支持退保类型:" name="refundInsuranceType">
                <a-select v-model:value="formData4.refundInsuranceType" :disabled="isCheckInfo"
                          :getPopupContainer="(triggerNode)=>triggerNode.parentNode" :options="refundInsuranceTypeList">
                  <!--                  <a-select-option value="1">生效前退保</a-select-option>-->
                  <!--                  <a-select-option value="2">犹豫期内退保</a-select-option>-->
                  <!--                  <a-select-option value="3">犹豫期外退保</a-select-option>-->
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="中介公司支持退费类型:" name="refundPremiumType">
                <a-select v-model:value="formData4.refundPremiumType" :disabled="isCheckInfo"
                          :getPopupContainer="(triggerNode)=>triggerNode.parentNode" :options="refundPremiumTypeList">
                  <!--                  <a-select-option value="1">生效前退保退费</a-select-option>-->
                  <!--                  <a-select-option value="2">犹豫期内退保退费</a-select-option>-->
                  <!--                  <a-select-option value="3">犹豫期外退保退费</a-select-option>-->
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="产品是否可续保:" name="renewal">
                <a-select v-model:value="formData4.renewal" :disabled="isCheckInfo"
                          :getPopupContainer="(triggerNode)=>triggerNode.parentNode" :options="listData.renewalList">
                  <!--                  <a-select-option value="Y">是</a-select-option>-->
                  <!--                  <a-select-option value="N">否</a-select-option>-->
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="可续保至年龄:" name="renewalYear"
                           :rules="[
         {max: 3, message: '输入长度不能超过3' ,trigger: 'change'},
          { pattern:/^\+?[1-9]\d*$/, message: '支持正整数格式' ,trigger: 'change'}
          ]">
                <a-input

                  v-model:value="formData4.renewalYear"
                  :disabled="isCheckInfo"
                />
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="供应商是否提供电子发票查询接口:" name="supplierQueryInterface">
                <a-select v-model:value="formData4.supplierQueryInterface" :disabled="isCheckInfo"
                          :getPopupContainer="(triggerNode)=>triggerNode.parentNode"
                          :options="listData.supplierQueryInterfaceList">
                  <!--                  <a-select-option value="Y">是</a-select-option>-->
                  <!--                  <a-select-option value="N">否</a-select-option>-->
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="续期前是否需要查询供应商扣费锁单:" name="renewalBeforeQuerySync">
                <a-select v-model:value="formData4.renewalBeforeQuerySync" :disabled="isCheckInfo"
                          :getPopupContainer="(triggerNode)=>triggerNode.parentNode"
                          :options="listData.renewalBeforeQuerySyncList">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="生成续期计划犹豫期是否需要依据节假日:" name="gracePeriodHoliday">
                <a-select v-model:value="formData4.gracePeriodHoliday" :disabled="isCheckInfo"
                          :getPopupContainer="(triggerNode)=>triggerNode.parentNode"
                          :options="listData.gracePeriodHolidayList">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="是否支持续期一次性缴清:" name="renewalOncePay">
                <a-select v-model:value="formData4.renewalOncePay" :disabled="isCheckInfo"
                          :getPopupContainer="(triggerNode)=>triggerNode.parentNode"
                          :options="listData.renewalOncePayList">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="前端是否开放退保流程:" name="refundInsuranceProcess">
                <a-select v-model:value="formData4.refundInsuranceProcess" :disabled="isCheckInfo"
                          :getPopupContainer="(triggerNode)=>triggerNode.parentNode"
                          :options="listData.refundInsuranceProcessList">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="前端是否展示退保协议:" name="refundInsuranceProtocol">
                <a-select v-model:value="formData4.refundInsuranceProtocol" :disabled="isCheckInfo"
                          :getPopupContainer="(triggerNode)=>triggerNode.parentNode"
                          :options="listData.refundInsuranceProtocolList">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="供应商是否提供失效保单信息文件接口:" name="lapsedPolicyInterface	">
                <a-select v-model:value="formData4.lapsedPolicyInterface	" :disabled="isCheckInfo"
                          :getPopupContainer="(triggerNode)=>triggerNode.parentNode"
                          :options="listData.lapsedPolicyInterfaceList">
                </a-select>
              </a-form-item>
            </a-col>

            <a-col span="12">
              <a-form-item label="是否开启次日回传:" name="nextDayTransfer">
                <a-select v-model:value="formData4.nextDayTransfer" :disabled="isCheckInfo"
                          :getPopupContainer="(triggerNode)=>triggerNode.parentNode"
                          :options="listData.nextDayTransferList">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="是否城市保险:" name="cityInsurance">
                <a-select v-model:value="formData4.cityInsurance" :disabled="isCheckInfo"
                          :getPopupContainer="(triggerNode)=>triggerNode.parentNode"
                          :options="listData.cityInsuranceList">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item
                :rules="[
                {max: 100, message: '输入长度不能超过100' ,trigger: 'change'}]"
                label="客服链接配置:" name="customerServiceUrl">
                <a-input
                  v-model:value="formData4.customerServiceUrl"
                  :disabled="isCheckInfo"
                />
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="CPS推广代码:" name="cpsId">
                <a-input v-model:value="formData4.cpsId" :disabled="isCheckInfo"/>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="是否使用校验文件:" name="jsCheckStatus">
                <a-select v-model:value="formData4.jsCheckStatus" :disabled="isCheckInfo"
                          :options="jsCheckStatusList">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="上传校验文件:" name="jsCheckUrl">
                <!-- :disabled="isCheckInfo" -->
                <div class="upload-file">
                  <div class="file-up">
                    <upload-file-new ref="uploadRef" :disabled="isCheckInfo" :num="1" listType="picture"
                                     @fileChange="onFileUpload"></upload-file-new>
                    <div class="download-file" @click="onDownloadFile">下载文件</div>
                  </div>
                </div>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-tab-pane>
<!--      <a-tab-pane key="4" tab="产品渠道配置" v-if="isNew !== '产品信息增加'">-->
<!--        <product-channel ref="channelRef" :parentData="parentData" :supId="supId"-->
<!--                         :isUpdateNum="isUpdateNum"></product-channel>-->
<!--      </a-tab-pane>-->
    </a-tabs>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button type="primary" v-if="!isCheckInfo" @click="onSave">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>
<script>
import {CloseOutlined, DeleteOutlined} from '@ant-design/icons-vue'
//,inject
import {computed, defineComponent, nextTick, onBeforeMount, reactive, ref, toRefs} from 'vue'
import {getRequestURI, request, requestUsePagination} from '../../../utils/request'
import addmainaccessoryRisk from './addmainaccessoryRisk'
import editfromImgList from './editfromImgList'
import uploadFileNew from '../../../components/extended/uploadFileNew'
import {message, Modal} from 'ant-design-vue'
// import ProductChannel from './ProductChannel'
import {getDictionaryData, getExtendUri} from '../../../utils/request_extend'
import {defaultFormData4, defaultListData, defaultState, defaultValues, resetData, resetData2} from './date'

//支付方式
const plainOptions = ref([])
export default defineComponent({
  components: {
    CloseOutlined,
    addmainaccessoryRisk,
    uploadFileNew,
    DeleteOutlined,
    editfromImgList,
    // ProductChannel
  },
  props: {
    handleMainSearch: {type: Function},
    searchfFilters: {type: Object},
    current:{type: Number}
  },

  setup(props, {expose}) {
    const formRef = ref()
    const editfromImgListRef = ref()
    const isCheckInfo = ref(false)
    // const formData = reactive({
    //   value: '',
    // })
    const supNameList = ref()
    const isRefreshNew = ref(true)
    const listData = reactive({...defaultListData})

    const insertImg = function () {
      if (state.isNew === '产品信息查看') return
      editfromImgListRef.value.showDrawer(1)
    }
    const insertImg1 = function () {
      if (state.isNew === '产品信息查看') return
      editfromImgListRef.value.showDrawer(2)
    }
    const insertImg2 = function () {
      if (state.isNew === '产品信息查看') return
      editfromImgListRef.value.showDrawer(3)
    }
    const isDisabled = computed(() => {
      return isCheckInfo.value || data.shareChat === 'N'
    })
    // 跳转切换 删除url
    const handelDelSkipUrl = (type) =>{
      type === '1' ? data.listOtherPageSkipUrl = '' : data.detailOtherPageSkipUrl = ''
      if(data.listPageSkipType === '2'){
        data.detailPageSkipType =  '1'
        data.templateCode = 'detail'
        data.detailOtherPageSkipUrl= ''
        data.detailPageSkipButtonName = '立即购买'
      }
      if(type === '2'){
        const obj = {'1': '立即购买', '2': '立即跳转', '3': '立即分享','4': '立即分享'}
        data.detailPageSkipButtonName =obj[data.detailPageSkipType]
      }

    }


    // 创建响应式对象
    const data = reactive({...defaultValues})
    const checkDetails = (id) => {
      if (data.healthNotice == 'Y') {
        state.ziDuan = true
      } else {
        state.ziDuan = false
        state.ziDuanOne = false
      }
      if (data.checkInsuranceType == '1') {
        state.ziDuanOne = true
      } else {
        state.ziDuanOne = false
      }
      if (data.supplierPayment === 'Y') {
        state.daiShou = true
      } else {
        state.daiShou = false
      }
      request(getRequestURI('ppqa_send'), {
        data: {
          id: id
        }
      }, true, 'post').then(
        (result) => {
          const res = result.result
          Object.assign(formData4, {
            supplierName: res.supplierName,
            // supChannelCode: res.supChannelCode,
            productCode: res.productCode,
            supProductCode: res.supProductCode,
            productName: res.productName,
            productShortName: res.productShortName,
            productEngName: res.productEngName,
            healthNotice: res.healthNotice,
            healthNoticeType: res.healthNoticeType,
            checkInsuranceType: res.checkInsuranceType,
            receiptType: res.receiptType,
            payType: res.payType,
            productStatus: res.productStatus,
            productFeature: res.productFeature,
            ageGroup: res.ageGroup,
            productSkipAddress: res.productSkipAddress+'',
            outAddressLink: res.outAddressLink,
            prospectus: res.prospectus, // 建议书
            productOnOffLineType: res.productOnOffLineType,
            isVerifyMobile: res.isVerifyMobile, // 是否验真手机号
            sameCustomerFlag: res.sameCustomerFlag,//是否相似客户
            needSign: res.needSign,//是否需要签名
            signType: res.signType,//是否供应商提供签名文件
            needDoubleRecord: res.needDoubleRecord,//是否需要双录
            doubleRecordMode: res.doubleRecordMode,
            doubleRecordSystemDisplayMode: res.doubleRecordSystemDisplayMode,
            productCategory: res.productCategory, // 产品类别
            detailPagePremiumCountFlag: res.detailPagePremiumCountFlag,
            isFloating: res.isFloating,
            floatingContent: res.floatingContent,
            listPageSkipType: res.listPageSkipType,
            detailPageSkipType: res.detailPageSkipType,
            templateCode: res.templateCode,
            detailPageSkipButtonName: res.detailPageSkipButtonName,
            listOtherPageSkipUrl: res.listOtherPageSkipUrl,
            detailOtherPageSkipUrl: res.detailOtherPageSkipUrl,
            productDescription: res.productDescription,
            underwriteCompanyName: res.underwriteCompanyName,
            productStartPrice: res.productStartPrice,
            productCoverImg: res.productCoverImg,
            productBackImg: res.productBackImg
          })
          Object.assign(data, formData4)
          console.debug(formData4)
        }
      )
    }
    const initFormData = () => {
      resetData(data)
      resetData2(formData4)
    }
    const formData1 = reactive({
      productInfoId: '',
      riskCode: '',
      riskName: '',
      options: [],
      supId: ''
    })
    const addmainaccessoryRiskRef = ref()
    const formData4 = reactive({...defaultFormData4})

    const healthNoticeList = ref([])
    onBeforeMount(() => {
      getDictionaryData(healthNoticeList, 'yes_no')
    })
    const state = reactive({...defaultState})
    const cahnpintiaozhuandizhi = function () {
      request(getExtendUri('dictionary_query_byType'), {data: {types: ['productSkipAddress']}}).then(res => {
        let result = res.result || {}
        listData.productSkipAddressList = result.productSkipAddress
      })
    }
    cahnpintiaozhuandizhi()

    const List = function (list, stu) {
      if (stu === 1) {
        data.productCoverImg = list[0].pictureUrl
      } else if(stu===2){
        data.productBackImg = list[0].pictureUrl
      }else{
        data.sharePhoto = list[0].pictureUrl
      }

    }
    //只支持字母和数字
    const productCodeBlur = function () {
      let pattern = new RegExp('[\u4E00-\u9FA5]+')
      if (pattern.test(data.productCode)) {
        message['warning']('录入信息格式不正确，请重新录入')
        data.productCode = ''
      }
    }
    const addMultiple = function () {
      addmainaccessoryRiskRef.value.showDrawer(state.supId, 1, productId.value)
    }
    const filterTreeOption = function (input, option) {
      return option.supName.includes(input)
    }
    const addSonMultiple = function (value) {
      addmainaccessoryRiskRef.value.showDrawer(state.supId, 2, productId.value, value.riskId)
    }
    // tab切换
    const tabChanges = function (value) {
      console.debug('切换的值', value)
      if (value === '3') {

        if (state.isUpdateNum == 1) {
          isCheckInfo.value = true
        } else {
          isCheckInfo.value = false
        }
        request(getRequestURI('queryProductInfoExtend'), {data: {productInfoId: formData4.id}}).then(res => {
          state.tuozhanxinxi = res.result || {}
          Object.keys(formData4).forEach(item => {
            formData4[item] = state.tuozhanxinxi[item] || formData4[item]
          })
          formData4.productSkipAddress = formData4.productSkipAddress + ''
        })
      } else if (value === '2') {
        handleSearch({})
        isCheckInfo.value = true
      } else if (value === '1') {
        if (state.isUpdateNum == 1) {
          isCheckInfo.value = true
        } else {
          isCheckInfo.value = false
        }
      }
    }
    //只支持字母
    const productEnglishBlur = function () {
      let pattern = new RegExp('[^a-zA-Z]+')
      if (pattern.test(data.productEngName)) {
        message['warning']('录入信息格式不正确，请重新录入')
        data.productEngName = ''
      }
    }
    //是否告知
    const gaozhi = (val) => {
      console.debug(val, '赠险')
      if (val == 'Y') {
        state.ziDuan = true
        if (data.healthNoticeType === '2') {
          state.ziDuanOne = true
        }
      } else {
        state.ziDuan = false
        state.ziDuanOne = false
      }
    }
    //核保方式
    const hebao = (val) => {
      console.debug(val, '赠险')
      if (val == '2') {
        state.ziDuanOne = true
      } else {
        state.ziDuanOne = false
      }
    }
    //是否代收
    const collecting = (val) => {
      data.payType = ''
      if (val === 'Y') {
        state.daiShou = true
      } else {
        state.daiShou = false
      }
    }
    const collecting1 = (val) => {
      console.debug(val, '签名')
      if (val === 'Y') {
        state.sign = true
      } else {
        state.sign = false
        data.signType = undefined
      }
    }
    const collecting2 = (val) => {
      console.debug(val, '双录')
      if (val == 'Y') {
        state.doubleRecord = true
      } else {
        state.doubleRecord = false

        data.doubleRecordMode = undefined
        data.doubleRecordSystemDisplayMode = undefined
      }
    }
    const collecting3 = (val) => {
      // console.debug(val, '双录')
      if (val == 'Y') {
        state.execute = true
      } else {
        state.execute = false
        data.executeSequence = undefined
      }
    }

    const {
      data: dataSource,
      // run,
      loading,
      current,
      pageSize,
      total
    } = requestUsePagination(
      getRequestURI('user_list'),
      {},
      {
        mock: false,
        code: 1,
        message: 'success',
        pagination: {
          total: 200,
          current: 1,
          pageSize: 10
        },
        result: []
      }
    )

    //支付方式
    const handlePayTypeChange = val => {
      // console.debug(val)
      data.payType = val.join(',')
      console.debug(data.payType)

    }
    //复选
    const onSelectChange = (selectedRowKeys, selectedRowKeysList) => {
      console.debug('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
      state.data = selectedRowKeysList
    }
    //分页
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
      pageSizeOptions: [10, 20, 50]
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.debug('handleTableChange', pag, filters, sorter)
    }
    //关联险种查询
    const handleSearch = function (filters) {
      if (state.isNew === '产品信息查看' || state.isNew === '产品信息修改') {
        request(getRequestURI('queryProductInfoRisk'),
          {
            data: {
              productInfoId: productId.value,
              riskCode: filters.riskCode ? filters.riskCode : '',
              riskId: filters.riskName ? filters.riskName : ''
            }
          }, true, 'post').then(
          (result) => {
            if (result.code === 1) {
              // message.success('关联险种查询' + result.message)
              state.result = result.result
            }
          }
        ).catch(e => {
          console.debug(e)
        })
      } else if (state.isNew === '产品信息增加') {
        return
      }
    }
    const isVisible = ref(false)
    const delMultiple1 = (data) => {
      let ids = [data.id]
      if (data.children && data.children.length) {
        data.children.forEach(item => {
          ids.push(item.id)
        })
      }
      Modal.confirm({
        title: '确认删除吗?',
        async onOk() {
          request(getRequestURI('deleteProductInfoRisk'), {
            data: {
              ids
            }
          }, true, 'post').then(
            (result) => {
              // console.debug('批量删除的结果:', result)
              // state.selectedRowKeys = []
              handleSearch({})
              message.success('删除成功')
              dataSource.value = result.result

            }
          ).catch(e => {
            message.error(e)
          })
        },
        onCancel() {
        }
      })

    }
    //  批量删除
    const delMultiple = () => {
      let keys = state.selectedRowKeys
      if (keys.length === 0) {
        message.warning('请选择数据')
        return
      }
      Modal.confirm({
        title: '确认删除吗?',
        async onOk() {
          request(getRequestURI('deleteProductInfoRisk'), {
            data: {
              ids: keys
            }
          }, true, 'post').then(
            (result) => {
              // console.debug('批量删除的结果:', result)
              // state.selectedRowKeys = []
              handleSearch({})
              message.success('删除成功')
              dataSource.value = result.result

            }
          ).catch(e => {
            message.error(e)
          })
        },
        onCancel() {
        }
      })

    }
    //ids
    const productId = ref()
    //抽屉
    const showDrawer = (oldData = {}, num, supplierIdData = []) => {
        isRefreshNew.value = false
        nextTick(() => {
          isRefreshNew.value = true
        })
      getTemplateCodeList()
      state.parentData = oldData
      listData.gongyingshangmingcheng = supplierIdData
      state.isUpdateNum = num
      // 添加主附险用
      state.supId = oldData.supplierId
      state.supplierName = oldData.supplierName
      if (oldData.healthNotice === 'Y') {
        state.ziDuan = true
      } else {
        state.ziDuan = false
        oldData.healthNoticeType = '1'
      }
      if (oldData.healthNoticeType === '2') {
        state.ziDuanOne = true
      } else {
        state.ziDuanOne = false
      }
      productId.value = oldData.id
      formData1.supId = oldData.supplierId
      state.visible = true
      if (num === 0) {
        state.isNew = '产品信息增加'
        initFormData()
        isCheckInfo.value = false
        state.zhuangTai2 = false
        //默认第一页
        activeKey.value = '1'
      } else if (num === 1) {
        state.look = true
        state.isNew = '产品信息查看'
        // formData4.productInfoId = oldData.productId
        activeKey.value = '1'
        isCheckInfo.value = true
        state.zhuangTai2 = true
        if (state.columns.length == 3) {
          state.columns.pop()
        }
        oldData !== {} && oldData.id && getExtendInfo(oldData.id)
      } else if (num === 2) {
        handleSearch({})
        state.look = true
        formData4.productInfoId = oldData.productId
        activeKey.value = '1'
        isCheckInfo.value = false
        state.isNew = '产品信息修改'
        state.zhuangTai2 = false
        oldData !== {} && oldData.id && getExtendInfo(oldData.id)
      }
      Object.keys(data).forEach((key) => {
        data[key] = oldData[key] || data[key]
      })
      Object.keys(formData4).forEach((key) => {
        formData4[key] = oldData[key] || formData4[key]
      })
      if (data.supplierPayment === 'Y') {
        state.daiShou = true
      } else {
        state.daiShou = false
      }
      data.id = oldData.id
      formData4.productInfoId = oldData.id
      // getSupName()
    }
    //查看信息拓展
    const getExtendInfo = (id) => {
      request(getRequestURI('queryProductInfoExtend'), {
        data: {
          productInfoId: id
        }
      }, true, 'post').then(
        (result) => {
          //  result应该是一个JSON对象
          //  data.value = result.result
          const res = result.result || {}
          Object.keys(formData4).forEach(item => {
            formData4[item] = res[item] || formData4[item]
          })
          formData4.productSkipAddress = formData4.productSkipAddress + ''
        }
      )
    }
    const uploadRef = ref()
    const channelRef = ref()
    //关闭 清空
    const onClose = async () => {
      if (uploadRef.value) {
        uploadRef.value.fileList = []
      }
      if (channelRef.value) {
        await channelRef.value.clearSearchInput()
      }
      state.look = false
      state.visible = false
      state.isUpdateNum = null
      formRef.value.clearValidate()
      formRef.value.resetFields()
      state.result = []
      data.productDescription = ''
      data.productStartPrice = ''
      data.productType=undefined
      data.productCategory = '1'
      data.detailPagePremiumCountFlag= 'Y'
      data.isFloating = 'N'
      data.floatingContent =''
      data.ageGroup = ''
      data.productOnOffLineType = 'A'
      data.isVerifyMobile = 'N'
      data.listPageSkipType= '1'
      data.detailPageSkipType= '1'
      data.templateCode= 'detail'
      data.detailPageSkipButtonName = '立即购买'
      data.listOtherPageSkipUrl = ''
      data.detailOtherPageSkipUrl= ''
      data.underwriteCompanyName = ''
      data.productCoverImg = ''
      data.productBackImg = ''
      data.shareChat='N'
      data.shareTitle=''
      data.shareSecondTitle=''
      data.sharePhoto=''
      Object.keys(formData4).forEach(item => {
        formData4[item] = ''
      })
      props.handleMainSearch(props.searchfFilters,props.current)
    }
    //重置
    const onFormClean = () => {
      formRef.value.resetFields()
      state.targetKeys = []
      state.visible = false
    }
    //单选 回填到下面的框
    const onSelectChanges = (selectedRowKeys, selectedRows) => {
      state.selectedRowKeys = selectedRowKeys
      formData1.riskName = selectedRows[0].riskName

    }
    const getSpId = (sId) => {
      for (let i = 0; i < listData.gongyingshangmingcheng.length; i++) {
        if (sId === listData.gongyingshangmingcheng[i].supName) {
          return listData.gongyingshangmingcheng[i].id
        }
      }
      return sId
    }
    //保存  通过key判断不同的tab页
    const activeKey = ref('1')
    const onSave = async () => {
      try {
        const values = await formRef.value.validate()
        // 新增编辑都给供应商id赋值
        data.supplierId = getSpId(data.supplierName)
        if (state.isNew === '产品信息增加') {
          if (activeKey.value === '1') {
            request(getRequestURI('ppqa_analytic_record'), {data}, true, 'post').then(
              (result) => {
                if (result.code === 1) {
                  props.handleMainSearch()
                  data.productStartPrice = ''
                  data.productCoverImg = ''
                  data.productBackImg = ''
                  data.productDescription = ''
                  data.productType=undefined
                  data.productCategory = '1'
                  data.detailPagePremiumCountFlag = 'Y'
                  data.productOnOffLineType = 'A'
                  data.isVerifyMobile = 'N'
                  data.isFloating = 'N'
                  data.floatingContent = ''
                  data.ageGroup = ''
                  data.listPageSkipType= '1'
                  data.detailPageSkipType= '1'
                  data.templateCode= 'detail'
                  data.detailPageSkipButtonName = '立即购买'
                  data.listOtherPageSkipUrl = ''
                  data.detailOtherPageSkipUrl= ''
                  data.underwriteCompanyName = ''
                  message.success('产品信息增加' + result.message)
                } else {
                  message.warning('产品信息增加' + result.message)
                }
              }
            )
          }
        } else if (state.isNew === '产品信息修改') {
          if (activeKey.value === '1') {
            if (data.healthNotice == 'Y') {
              state.ziDuan = true
            } else {
              state.ziDuan = false
              state.ziDuanOne = false
            }
            if (data.checkInsuranceType == '1') {
              state.ziDuanOne = true
            } else {
              state.ziDuanOne = false
            }
            if (data.supplierPayment === 'Y') {
              state.daiShou = true
            } else {
              state.daiShou = false
            }
            // console.debug('产品信息修改',data)
            request(getRequestURI('productInfo_update'), {data}, true, 'post').then(
              (result) => {
                if (result.code == 1) {
                  data.productStartPrice = ''
                  data.productCoverImg = ''
                  data.productBackImg = ''
                  data.productDescription = ''
                  data.productType=undefined
                  data.productCategory = '1'
                  data.listPageSkipType= '1'
                  data.detailPageSkipType= '1'
                  data.templateCode= 'detail'
                  data.productOnOffLineType= 'A'
                  data.isVerifyMobile= 'N'
                  data.detailPageSkipButtonName = '立即购买'
                  data.listOtherPageSkipUrl = ''
                  data.detailOtherPageSkipUrl= ''
                  data.underwriteCompanyName = ''
                  props.handleMainSearch(props.searchfFilters,props.current)
                  message.success('产品信息修改' + result.message)
                } else {
                  message.warning(result.message)
                }
              }
            )
          } else if (activeKey.value === '2') {
            console.debug('a')
          } else if (activeKey.value === '3') {
            if (state.tuozhanxinxi !== null) {
              request(getRequestURI('updateProductInfoExtend'), {data: formData4}, true, 'post').then(
                (result) => {
                  // console.debug('拓展信息的', result)
                  props.handleMainSearch(props.searchfFilters,props.current)
                  message.success('拓展信息修改' + result.message)
                }
              )
            } else {
              request(getRequestURI('saveProductInfoExtend'), {data: formData4}, true, 'post').then(
                (result) => {
                  // console.debug('拓展信息的', result)
                  props.handleMainSearch(props.searchfFilters)

                  message.success('拓展信息新增' + result.message)
                }
              )
            }

          }else if (activeKey.value === '4'){
            if (channelRef.value) {
              channelRef.value.clearSearchInput()
            }
          }
        }
        console.debug('', values)
        console.debug('', values)
        state.visible = false
        initFormData()
      } catch (e) {
        console.debug(e)
      }
    }
    const getSupplierName = (value,label) =>{
      console.debug(value)
      data.underwriteCompanyName =label.length ?label[0] : ''
    }
    const init = function () {
      // request(getRequestURI('dictionary_query_byType'), {data: {types: ['hesitationPeriodFlag']}}).then(res => {
      //   listData.refundInsuranceTypeList = res.result.hesitationPeriodFlag
      //   listData.refundPremiumTypeList = res.result.hesitationPeriodFlag
      // })
      request(getRequestURI('dictionary_query_byType'), {data: {types: ['yes_no']}}).then(res => {
        let result = res.result || {}
        listData.renewalBeforeQuerySyncList = result.yes_no,
          listData.gracePeriodHolidayList = result.yes_no,
          listData.renewalOncePayList = result.yes_no,
          listData.refundInsuranceProcessList = result.yes_no,
          listData.refundInsuranceProtocolList = result.yes_no,
          listData.lapsedPolicyInterfaceList = result.yes_no,
          listData.nextDayTransferList = result.yes_no,
          listData.cityInsuranceList = result.yes_no
        listData.renewalList = result.yes_no
        listData.supplierQueryInterfaceList = result.yes_no
      })
    }
    init()
    expose({
      showDrawer,
      checkDetails
    })
    const handleOk = e => {
      console.debug(e)
      isVisible.value = false
    }
    const onFileUpload = (file) => {
      formData4.jsCheckUrl = file.url
    }
    const onDownloadFile = () => {
      if (!formData4.jsCheckUrl) return
      window.location = formData4.jsCheckUrl
    }
    // 获取产品模板
    const getTemplateCodeList = () =>{
      request(getRequestURI('productFlowTemplate_all'), {}).then(res =>{
        templateCodeList.value = res.result
      })
    }
    const filterOption = (input, option) => {
      return option.templateName.indexOf(input) > -1
    }


    const receiptTypeList = ref([])
    const productStatusList = ref([])
    const refundInsuranceTypeList = ref([])
    const refundPremiumTypeList = ref([])
    const jsCheckStatusList = ref([])
    const productTypeList = ref([])
    const productCategoryList = ref([])
    const sameCustomerFlagList = ref([])
    const needSignList = ref([])
    const signTypeList = ref([])
    const needDoubleRecordList = ref([])
    const bankCardFlagList = ref([])
    const executeSequenceList = ref([])
    const doubleRecordSystemDisplayModeList = ref([])
    const doubleRecordModeList = ref([])
    const payUrlTypeList = ref([])
    const isMultiPageList = ref([])
    const listPageSkipTypeList = ref([])
    const detailPageSkipTypeList = ref([])
    const productOnOffLineTypeList = ref([])
    const templateCodeList = ref([])

    onBeforeMount(() => {
      getDictionaryData(listPageSkipTypeList, 'list_page_skip_type')
      getDictionaryData(detailPageSkipTypeList, 'detail_page_skip_type')
      getDictionaryData(productOnOffLineTypeList, 'product_type')

      getDictionaryData(receiptTypeList, 'yes_no')
      getDictionaryData(refundInsuranceTypeList, 'hesitationPeriodFlag')
      getDictionaryData(refundPremiumTypeList, 'hesitationPeriodFlag')
      getDictionaryData(productStatusList, 'status')
      getDictionaryData(jsCheckStatusList, 'yes_no')
      getDictionaryData(productTypeList, 'ProductType')
      getDictionaryData(productCategoryList, 'product_category')
      getDictionaryData(sameCustomerFlagList, 'yes_no')
      getDictionaryData(needSignList, 'yes_no')
      getDictionaryData(signTypeList, 'operator_object')
      getDictionaryData(needDoubleRecordList, 'yes_no')
      getDictionaryData(payUrlTypeList, 'pay_url_type')
      getDictionaryData(bankCardFlagList, 'yes_no')
      getDictionaryData(isMultiPageList, 'yes_no')
      getDictionaryData(executeSequenceList, 'execute_sequence')
      getDictionaryData(doubleRecordSystemDisplayModeList, 'dr-system-display-type')
      getDictionaryData(doubleRecordModeList, 'double-record-type')
      getDictionaryData(plainOptions, 'payment_way')
    })
    return {
      getTemplateCodeList,
      templateCodeList,
      payUrlTypeList,
      isMultiPageList,
      doubleRecordModeList,
      doubleRecordSystemDisplayModeList,
      executeSequenceList,
      bankCardFlagList,
      needDoubleRecordList,
      signTypeList,
      needSignList,
      uploadRef,
      productTypeList,
      productCategoryList,
      onDownloadFile,
      onFileUpload,
      receiptTypeList,
      productStatusList,
      healthNoticeList,
      jsCheckStatusList,
      formRef,
      formData1,
      formData4,
      checkDetails,
      activeKey,
      ...toRefs(state),
      onClose,
      channelRef,
      onSave,
      onFormClean,
      addmainaccessoryRiskRef,
      loading,
      dataSource,
      handleSearch,
      onSelectChange,
      handleTableChange,
      pagination,
      delMultiple,
      checked: ref(false),
      isCheckInfo,
      handleOk,
      productCodeBlur,
      productEnglishBlur,
      isVisible,
      filterOption,
      initFormData,
      data,
      handlePayTypeChange,
      // getSupName,
      supNameList,
      getExtendInfo,
      productId,
      onSelectChanges,
      gaozhi,
      hebao,
      collecting,
      listData,
      plainOptions,//支付方式
      tabChanges,
      addMultiple,
      addSonMultiple,
      delMultiple1,
      filterTreeOption,
      editfromImgListRef,
      insertImg,
      insertImg1,
      insertImg2,
      List,
      refundInsuranceTypeList,
      refundPremiumTypeList,
      sameCustomerFlagList,
      listPageSkipTypeList,
      detailPageSkipTypeList,
      productOnOffLineTypeList,
      collecting1,
      collecting2,
      collecting3,
      isDisabled,
      isRefreshNew,
      handelDelSkipUrl,
      getSupplierName
    }
  }
})
</script>
