<template>
  <!--  列表---险种对应关联的利益演示公式-->
  <div>
    <div class="search_style">
      <search-form
          :Condition="condition"
          :ColSpan="12"
          @callback="handleSearch"
      ></search-form>
    </div>
    <div class="list_style">
      <a-table
          :columns="columns"
          :data-source="dataSource ? dataSource['result'] : []"
          :scroll="{ x: 800 }"
          :row-key="(record) => record.id"
          :pagination="pagination"
          @change="handleTableChange"
          :loading="loading"
      >
        <template #bodyCell="{ column, index, record }">
          <template v-if="column.key === 'index'">
            {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
          </template>
          <template v-if="column.key === 'operation'">
            <div>
              <a
                  style="color: orange; margin-right: 10px"
                  @click="checkConfig(record, 'details')"
              >查看配置</a>&nbsp;&nbsp;
              <a
                  style="color: orange; margin-right: 10px"
                  @click="modifyConfig(record, 'edit')"
              >编辑配置</a>&nbsp;&nbsp;
            </div>
          </template>
        </template>
      </a-table>
      <formula-config-mod
          ref="formulaConfigRef"
      ></formula-config-mod>
    </div>
  </div>
</template>
<script>
import SearchForm from '../../../components/extended/search_form.vue'
import {computed, onMounted, reactive, ref, toRefs} from 'vue'
import {getRequestURI, requestUsePagination} from '../../../utils/request.js'
import FormulaConfigMod from './FormulaConfiguration/FormulaConfigMod.vue'

export default {
  name: 'FormulaConfiguration',
  components: {FormulaConfigMod, SearchForm},
  setup() {
    // 存储搜索组件传过来的数据
    const searchfFilters = ref({})
    const formulaConfigRef = ref()
    const state = reactive({
      condition: {
        supId: {
          label: '供应商名称',
          type: 'tree-select',
          urlKey: 'querySupplier',
          // urlKey: 'supplierQuerySupLevelSt',
          value: 'id',
          default: '',
          data: []
        },
        // supRiskCode:'供应商险种编码',
        riskName: '险种名称',
        riskCode: '险种编码',
        riskTypeLargeCode: {
          label: '险种类型大类',
          type: 'select',
          default: '',
          urlKey: 'queryDictionaryType',
          queryParam: {type: 'risk_type'}
        },
        riskLengthSign: {
          label: '长短险标志',
          type: 'select',
          default: '',
          urlKey: 'queryDictionaryType',
          queryParam: {type: 'riskLSType'}
        },
        riskSign: {
          label: '主附险标志',
          type: 'select',
          default: '',
          urlKey: 'queryDictionaryType',
          queryParam: {type: 'riskMAType'}
        },
      },
      status: '',
      result: [],
      query: {
        data: {
          riskCode: '',
          riskLengthSign: '',
          riskName: '',
          riskSign: '',
          riskTypeLargeCode: '',
          supRiskCode: '',
        }

      },
      columns: [
        {
          title: '险种编码',
          width: 90,
          align: 'center',
          key: 'riskCode',
          dataIndex: 'riskCode',
        },
        {
          title: '供应商险种编码',
          width: 90,
          align: 'center',
          key: 'supRiskCode',
          dataIndex: 'supRiskCode',
        },
        {
          title: '险种名称',
          width: 90,
          align: 'center',
          key: 'riskName',
          dataIndex: 'riskName',

        },
        {
          title: '险种类别大类',
          width: 90,
          align: 'center',
          key: 'riskTypeLargeCodeName',
          dataIndex: 'riskTypeLargeCodeName',
        },
        {
          title: '长短险标志',
          width: 90,
          align: 'center',
          key: 'riskLengthSignName',
          dataIndex: 'riskLengthSignName',
        },
        {
          title: '主附险标志',
          width: 90,
          align: 'center',
          key: 'riskSign',
          dataIndex: 'riskSign',
          customRender: (text) => {
            if (text.value === 1) {
              return '主险'
            } else {
              return '附险'
            }
          }
        },
        {
          title: '操作',
          key: 'operation',
          ellipsis: true,
          align: 'center',
          fixed: 'right',
          width: 140,
          scopedSlots: {customRender: 'operation'}
        },
      ],
      selectedRowKeys: [],
      lastFilter: {},
      // 删除data
      data: [],
      datas: '',
      searchNow: {},
      del: '',
      deleteQuery: {},
    })
    const handleTableChange = (pag, filters, sorter) => {
      console.log('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.lastFilter,
      })
    }
    const handleSearch = (filters, num = '') => {
      if (filters) {
        searchfFilters.value = filters
      }
      console.log('handleSearch', filters)
      state.query.data.riskCode = state.searchNow.riskCode
      state.query.data.riskSign = state.searchNow.riskSign
      state.query.data.riskLengthSign = state.searchNow.riskLengthSign
      state.lastFilter = filters
      run({
        pagination: {
          current: num ? num : 1,
          pageSize: 10
        },
        data: filters,
      })
    }
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('productRisk_selectListForPage'))
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))

    // 查看配置
    const checkConfig = async (record, type) => {
      formulaConfigRef.value.showDrawer(record, type)
    }

    // 编辑配置
    const modifyConfig = async (record, type) => {
      formulaConfigRef.value.showDrawer(record, type)
    }

    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    return {
      ...toRefs(state),
      formulaConfigRef,
      searchfFilters,
      handleSearch,
      run,
      pagination,
      dataSource,
      loading,
      checkConfig,
      modifyConfig,
      handleTableChange
    }
  }
}

</script>

<style scoped>

</style>
