<template>
  <div style="height: 1000px">
    <search-form
      :Condition="condition"
      :ColSpan="12"
      @callback="handleSearch"
      @nameLinked="changes"
      @resetAll="resetAll"
    ></search-form>
    <!-- <div class="area-header"> -->
    <!-- :row-selection="{type:'radio',selectedRowKeys: selectedRowKeys,  onChange: onSelectChange, }" -->
    <a-table
      :columns="columns"
      :data-source="dataSource ? dataSource['result'] : []"
      :pagination="pagination"
      :row-key="(record) => record.id"
      @change="handleTableChange"
      ref="formRef"
    >
      <template #bodyCell="{ column, index, record }">
        <template v-if="column.key === 'index'">
          {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
        </template>
        <template v-if="column.key === 'operation'">
          <div>
            <a style="color: orange" @click="handelCheck(record, 'check')"
              >查看</a
            >&nbsp;&nbsp;
            <a style="color: orange" @click="handelEdit(record, 'edit')">编辑</a
            >&nbsp;&nbsp;
          </div>
        </template>
      </template>
    </a-table>
  </div>
  <AreaDetail :handleSearch="handleSearch" ref="areaDetailRef" :searchfFilters="searchfFilters" :current="pagination.current"/>
</template>

<script>
import { defineComponent, reactive, toRefs, computed, onMounted, ref } from 'vue'
import SearchForm from '../../../components/extended/search_form'
import AreaDetail from './areaDetail.vue'
import {requestUsePagination, getRequestURI, request} from '../../../utils/request.js'
export default defineComponent({
  components: {
    SearchForm,
    AreaDetail
  },
  setup () {
    const state = reactive({
      condition: {
        supplierId: {
          label: '供应商名称',
          type: 'tree-select',
          urlKey: 'querySupplier',
          value: 'id',
        },
        productId: {
          label: '产品名称',
          type: 'select',
          default: '',
          data: []
        },
        productCode: '产品编码'
      },
      columns: [
        {
          key: 'index',
          title: '序号',
          width: 60,
          align: 'center',
        },
        {
          title: '产品名称',
          width: 140,
          align: 'center',
          key: 'productName',
          dataIndex: 'productName',
        },
        {
          title: '产品编码',
          width: 140,
          align: 'center',
          key: 'productCode',
          dataIndex: 'productCode',
        },
        {
          title: '操作',
          width: 120,
          align: 'left',
          key: 'operation',
          dataIndex: 'operation'
        }
      ],
      areaData: [],
      newChannel: {},

    })

    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('ppqa_selectInfo'))

    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.log('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.newChannel,
      })
    }
    const changes = function (value) {
      request(getRequestURI('select'), { data: { supplierId: value } }).then(result => {
        console.log(result)
        const arr = []
        const res = result.result
        const label = 'id'
        const sysName = res.reduce((all, next) => all.some((atom) => atom[label] == next[label]) ? all : [...all, next], [])
        for (let i = 0; i < sysName.length; i++) {
          arr.push({ label: sysName[i].productName, value: sysName[i].id })
        }
        state.condition.productId.data = arr
      })
    }
    const resetAll = () => {
      state.condition.productId.data = []
      init()
    }
    const init = function () {
      request(getRequestURI('select'), { data: {} }).then(result => {
        const arr = []
        const res = result.result
        const label = 'id'
        const sysName = res.reduce((all, next) => all.some((atom) => atom[label] == next[label]) ? all : [...all, next], [])
        for (let i = 0; i < sysName.length; i++) {
          arr.push({ label: sysName[i].productName, value: sysName[i].id })
        }
        state.condition.productId.data = arr
      })
    }
    init()
    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    // 存储搜索组件传过来的数据
    const searchfFilters = ref({})
    const handleSearch = function (filters, num='') {
      if (filters) {
        searchfFilters.value = filters
      }
      state.newChannel = filters
      run({
        pagination: {
          current: num ? num : 1,
          pageSize: 10
        },
        data: filters,
      })
    }
    const areaDetailRef = ref()

    // 查看按钮
    const handelCheck = (oldData, str) => {
      areaDetailRef.value.showDrawer(oldData, str)
    }
    // 编辑按钮
    const handelEdit = (oldData, str) => {
      areaDetailRef.value.showDrawer(oldData, str)
    }

    onMounted(() => {

    })

    return {
      handelCheck,
      ...toRefs(state),
      handleSearch,
      handleTableChange,
      handelEdit,
      areaDetailRef,
      dataSource,
      loading,
      pagination,
      searchfFilters,
      changes,
      resetAll
    }
  }

})
</script>

<style scoped>
.butt {
  width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.area-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>
