<style scoped>
</style>

<template>
  <a-drawer
    :title="isNew"
    :width="'70%'"
    :visible="visible"
    destroyOnClose
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <p>险种投保要素列表：</p>
    <div>
      <a-table :columns="columns" :data-source="list"
               :row-key="record => record.id"
               :pagination="false"
               :loading="loading"
      >
        <template #bodyCell="{ column,index, record }">
          <template v-if="column.key === 'index'">
            {{ index + 1 }}
          </template>
          <template v-if="column.key === 'operation'">
            <a-button type="text" @click="revise(record)" size="small" style="margin-right: 10px; color: orange;">
              修改
            </a-button>
            <a-button type="text" @click="deleteProduct(record.id)" size="small"
                      style="margin-right: 10px; color: orange;">
              删除
            </a-button>
          </template>
        </template>
      </a-table>
    </div>
    <p>创建费率表信息：</p>
    <a-form :model="formData" layout="vertical" ref="formRef" autocomplete="off">
      <a-row :gutter="10">
        <a-col span="12" v-if="chankan === true">
          <a-form-item name="dutyCode">
            <template #label>
              <span>责任编码: </span>
              <span>
                <a-popover placement="right">
                  <template #content>
                  <p>
                    创建费率表时，会根据险种进行一些校验，规则如下：
                      <br>&emsp;&emsp;1. 必须存在的基础投保要素
                        <br>&emsp;&emsp;&emsp;&emsp;a). 缴费方式，缴费期间，保险期间
                      <br>&emsp;&emsp;2. 险种份数计算方式关联的投保要素
                        <br>&emsp;&emsp;&emsp;&emsp;a). 直接录入: 表示客户在页面录入后，直接获取。配置投保要素时需要配置份数投保要素
                        <br>&emsp;&emsp;&emsp;&emsp;b). 险种保额/基础保额：最通用的计算方式，配置投保要素时需要配置基础保额投保要素，并有且仅有一个值，还要配置一个保额投保要素。例如一般的费率表都是以1000保额为基数给出保费，这里的1000即为基础保额，险种保额一般由客户在前端选择。如险种保额为10万，基础保额为1000，份数即为100份，但是此时的100并不能真实的表达份数，他只是一个计算倍数，所以保费计算结果中给出的份数默认为1份。
                        <br>&emsp;&emsp;&emsp;&emsp;c). 主险保费*缴费年期/基础保额：比较特殊的计算方式，实际份数为1。配置投保要素时需要配置基础保额投保要素，并有且仅有一个值，还要配置一个缴费年期投保要素。
                        <br>&emsp;&emsp;&emsp;&emsp;d). 长险保费和/基础保额：使用于豁免险的计算方式，实际份数为1。配置投保要素时需要配置基础保额投保要素，并有且仅有一个值，还要同时向后台传递该单中所有的险种信息。	保费计算方式：必填项，下拉选择，下拉选项有
                      <br>&emsp;&emsp;3. 险种保费计算方式关联的投保要素
                        <br>&emsp;&emsp;&emsp;&emsp;a). 保额算保费：最常用的计算方式，必须有保额投保要素
                      <br>&emsp;&emsp;4. 险种保费计算公式关联的投保要素
                        <br>&emsp;&emsp;&emsp;&emsp;a). 保费=固定金额，配置投保要素时需要配置一个固定金额投保要素，有且仅有一个值。适用于单份产品为固定值的场景，如车票，
                        <br>&emsp;&emsp;&emsp;&emsp;b). 保费=保额*固定费率，按照保额的固定费率收取保费，配置投保要素时需要配置一个固定费率投保要素，有且仅有一个值。还需要配置保额投保要素。如保额为10万，按照5%收取，保费即为5000
                        <br>&emsp;&emsp;&emsp;&emsp;c). 保费=阶梯费率表枚举-扣除数：根据保额不同的阶段先按照比例收取保费，再减去该阶段固定的扣除数，必须要有扣除数投保要素。如0-1万收1%，扣除数为0，1-5万收2%，扣除数为100，该计算方式最常见于交税。需要在投保要素中配置一个扣除数投保要素，在费率表中的保费列录入费率
                  </p>
                  </template>
                  <template #title>
                  <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
                </span>
            </template>
            <a-select v-model:value="code" :disabled="isccc" placeholder="请选择责任编码">
              <a-select-option :value="cd.dutyCode" v-for="cd in formData.dutyCode" :key="cd.dutyCode">
                {{ cd.dutyCode + '-' + cd.dutyName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12" v-if="chankan === true"></a-col>
        <br/>
        <span style="margin-left: 5px" v-if="chankan === true">
          <a-button @click="revise" v-if="butshow">
            <template #icon>
              <FileAddOutlined/>
            </template>
            创建费率表
          </a-button>
        </span>
        <span style="margin-left: 13px" v-if="chankan === true">
          <a-button danger @click="deleteProduct" v-if="butshow">
            <template #icon>
              <DeleteOutlined/>
            </template>
            删除费率表
          </a-button>
        </span>
      </a-row>
    </a-form>
    <div v-if="tableshow" style="margin-top: 5px">
      <a-table :columns="columns2" :data-source="result" :pagination="false"
               :loading="loading" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChanges, type: 'radio' }"
               :row-key="record => record.id"
      >
        <template #bodyCell="{ column, index, record }">
          <template v-if="column.key === 'index'">
            {{ index + 1 }}
          </template>
          <template v-if="column.key === 'name'">
            <a @click="download(record)">{{ record.name }}</a>
          </template>
          <template v-if="column.key === 'name1'">
            <a :style="{color: record.color}" @click="download1(record)">{{ record.name1 }}</a>
          </template>
          <template v-if="column.key === 'operation'">
            <upload-files ref="uploadf" filetype="excel" @fileChange="(urlObj) => handleChange(urlObj, record)" :file-name-not-have-spaces="1"></upload-files>
          </template>
        </template>
      </a-table>
    </div>
    <div v-if="!tableshow">
      <a-table :columns="columns2" :data-source="result"
               :loading="loading" :pagination="false"
      >
        <template #bodyCell="{ column, index }">
          <template v-if="column.key === 'index'">
            {{ index + 1 }}
          </template>
        </template>
      </a-table>
    </div>
    <a-col span="24" v-if="shouinput">
    </a-col>
    <div v-if="!shouinput">
      <a-form-item label="费率表导入：" name="remind">
        <a-input v-model:value="data.remind" :disabled="isccc"/>
      </a-form-item>
    </div>
  </a-drawer>
</template>

<script>
import {defineComponent, reactive, toRefs, ref, onMounted} from 'vue'
import {getRequestURI, request} from '../../../utils/request.js'
import uploadFiles from '../../../components/extended/uploadFile.vue'
import {message, Modal} from 'ant-design-vue'
import {
  FileAddOutlined,
  DeleteOutlined
} from '@ant-design/icons-vue'

export default defineComponent({
  components: {
    uploadFiles, FileAddOutlined,
    DeleteOutlined
  },
  setup(props, {expose}) {
    const isccc = ref(false)
    const uploadf = ref()
    const formRef = ref()
    const formData = reactive({
      dutyCode: []//责任编码
    })
    const data = reactive({
      riskId: 0,
      fileUrl: ''
    })
    const state = reactive({
      time: null,
      shouinput: true,
      isa: true,
      chankan: '',
      showImg: [],
      tableshow: false,
      butshow: false,
      url: '',//下载地址
      result: [],
      list: [],
      node: '',
      code: '000',//责任下拉框
      query: {
        data: {
          riskId: 0
        },
        pagination: {
          current: 1,
          pageSize: 10,
          total: 0
        },
        sorters: [
          {
            field: '',
            order: ''
          }
        ]
      },
      columns2: [
        {
          title: '序号',
          width: 30,
          align: 'center',
          key: 'index',
          dataIndex: 'index'
        },
        {
          title: '险种编码',
          width: 30,
          align: 'center',
          key: 'productInfoCode',
          dataIndex: 'productInfoCode'
        },
        {
          title: '责任编码',
          width: 30,
          align: 'center',
          key: 'productDutyCode',
          dataIndex: 'productDutyCode'
        },
        {
          title: '费率表模板下载',
          width: 30,
          align: 'center',
          slot: 'name',
          key: 'name',
          dataIndex: 'name'
        },
        {
          title: '费率表下载',
          width: 30,
          align: 'center',
          slot: 'name1',
          key: 'name1',
          dataIndex: 'name1'
        }

      ],
      columns: [
        {
          title: '序号',
          width: 80,
          align: 'center',
          key: 'index',
          dataIndex: 'index'
        },
        {
          title: '要素编码',
          width: 120,
          align: 'center',
          key: 'elementType',
          dataIndex: 'elementType'
        },
        {
          title: '要素名称',
          width: 120,
          align: 'center',
          key: 'elementName',
          dataIndex: 'elementName'
        },
        {
          title: '是否费率表列',
          width: 80,
          align: 'center',
          key: 'isPremiumCal',
          dataIndex: 'isPremiumCal',
          customRender: (text) => {
            if (text.value === 'Y') {
              return '是'
            } else {
              return '否'
            }
          }
        }
      ],
      dataId: '',
      selectedRowKeys: [],
      // Check here to configure the default column
      loading: false,
      visible: false,
      isNew: true,
      transferStyle: {width: '50%'}
    })
    // 列表初始化
    const modifyUser = function () {
      // console.log(oldData)
      //  state.query.data.tempNode = filters.tempNode
      request(getRequestURI('flgl_updat'), state.query).then(res => {
        // state.showImg.push(res.result.productInfoRateVOList.url)
        state.list = res.result.productRateElementVOList
        state.result = res.result.productInfoRateVOList
        formData.dutyCode = res.result.productRateDutyVOList
        state.result.forEach(item => {
          if (item.tableUrl !== null) {
            item.name1 = item.tableName
          } else {
            item.name1 = '未上传'
            item.color = '#000'
          }
        })
        // state.idss=res.result.productInfoRateVOList.id
        // state.result.forEach(item => {
        //   let obj = []
        //   let obj1 = {}
        //   obj1.url = item.url
        //   obj.push(obj1)
        //   item.url = obj
        // })
        // console.log('sdfd', state.list)
      })
    }
    onMounted(() => {
      //  modifyUser()
    })
    // 数据回显
    const showDrawer = (oldData = {}, num) => {
      state.query.data.riskId = oldData.id
      console.log(state.query.data.riskId)
      state.visible = true
      if (num === 0) {
        console.log('1111', oldData)
        state.chankan = true
        state.isNew = '新增页面'
        isccc.value = false
      } else if (num === 1) {
        state.isNew = '查看页面'
        modifyUser()
        state.tableshow = true
        state.butshow = true
        state.chankan = false
        isccc.value = false
        state.isa = false
        //  state.columns2.pop()
      } else if (num === 2) {
        state.isNew = '编辑页面'
        state.chankan = true
        modifyUser()
        state.tableshow = true
        state.butshow = true
        isccc.value = false
        // getChooseDutyCode(oldData)
        state.columns2.push({
          title: '导入操作',
          key: 'operation',
          fixed: 'right',
          width: 30
        })
      }
      console.log('editNewUser', state.isNew, oldData)
      Object.keys(formData).forEach(key => {
        formData[key] = oldData[key] || formData[key]
      })
    }
    // 删除
    const deleteProduct = () => {
      let keys = state.selectedRowKeys
      if (keys.length === 0) {
        message.warning('请选择数据')
        return
      }
      Modal.confirm({
        title: '确认删除吗?',
        async onOk() {
          // visible.value = true
          request(getRequestURI('tbfl_del'), {
            data: {
              ids: keys,
              riskId: state.query.data.riskId
            }
          }, true, 'post').then(
            () => {
              // console.log('批量删除的结果:', result)

              // handleSearch()
              message.success('删除成功')
              modifyUser({})
              // dataSource.value = result.result
              state.selectedRowKeys = []
            }
          ).catch(e => {
            message.error(e)
          })
        }
      })
    }
    // 生成费率表
    const revise = function () {
      //  if()productInfoRateVOList
      if (state.list.length == 0) {
        message.warning('请先配置产品的投保要素')
        return
      }
      request(getRequestURI('tbfl_addtable'), {
        data: {
          productDutyCode: state.code,
          riskId: state.query.data.riskId
        },
        pagination: {
          current: 1,
          pageSize: 10,
          total: 0
        },
        sorters: [
          {
            field: '',
            order: ''
          }
        ]
      }).then(res => {
        if (res.code === 1) {
          modifyUser({})
        } else {
         message.warning(res.message)
        }
      })
    }
    // 关闭
    const onClose = () => {
      state.showImg = []
      // state.isshow=false
      state.visible = false
      // state.targetKeys = []
      state.columns2 = [
        {
          title: '序号',
          width: 30,
          align: 'center',
          key: 'index',
          dataIndex: 'index'
        },
        {
          title: '险种编码',
          width: 30,
          align: 'center',
          key: 'productInfoCode',
          dataIndex: 'productInfoCode'
        },
        {
          title: '责任编码',
          width: 30,
          align: 'center',
          key: 'productDutyCode',
          dataIndex: 'productDutyCode'
        },
        {
          title: '费率表模板下载',
          width: 30,
          align: 'center',
          slot: 'name',
          key: 'name',
          dataIndex: 'name'
        },
        {
          title: '费率表下载',
          width: 30,
          align: 'center',
          slot: 'name1',
          key: 'name1',
          dataIndex: 'name1'
        }

      ]
      formData.dutyCode = []
      state.code = ''
      state.chankan = false
      state.selectedRowKeys = []
      state.time = new Date().getTime()
    }
    const onSelectChanges = (selectedRowKeys, selectedRowKeysList) => {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
      state.dataId = selectedRowKeysList[0].id
      data.fileUrl = selectedRowKeysList[0].url
    }
    // 模板下载
    const handleChange = (urlObj, item) => {
      console.log('handleChange', urlObj, item)
      onSave(item.id, urlObj.url)
    }
    // recode
    const download = (recode) => {
      window.location = recode.url
      data.fileUrl = recode.url
      state.dataId = recode.id
      console.log(state.dataId)
    }

    // recode
    const download1 = (recode) => {
      if (recode.name1 === '未上传') {
        console.log('')
      } else {
        window.location = recode.tableUrl
      }

    }
    // 保存按钮
    const onSave = (id, url ) => {
      request(getRequestURI('fldr_save'), {
        data: {
          id: id,
          url: url
        },
        pagination: {
          current: 1,
          pageSize: 10,
          total: 0
        },
        sorters: [
          {
            field: '',
            order: ''
          }
        ]
      }).then(res => {
        if (res.code === 1) {
          message['success'](res.message)
          modifyUser()
        } else {
          uploadf.value.clearFile()
          message['error'](res.message)
        }
      })
    }
    expose({
      showDrawer
    })
    //    const onSelectChange = selectedRowKeys => {
    //   console.log('selectedRowKeys changed: ', selectedRowKeys)
    //   state.selectedRowKeys = selectedRowKeys
    // }
    return {
      onSelectChanges,
      handleChange,
      deleteProduct,
      revise,
      onMounted,
      data,
      uploadFiles,
      formRef,
      formData,
      ...toRefs(state),
      onClose,
      download,
      onSave,
      isccc,
      uploadf,
      modifyUser,
      download1
    }
  }

})
</script>
