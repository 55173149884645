<style scoped>
</style>

<template>
  <a-drawer
    :title="isNew ? '渠道新增' : '渠道修改'"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form :model="formData" :rules="formRules" layout="vertical" ref="formRef" autocomplete="off">
      <a-form-item name="id" v-if="false">
        <a-input v-model:value="formData.id" />
      </a-form-item>
      <a-row :gutter="10">
        <a-col span="12">
          <a-form-item label="渠道名称: " name="channelName" required>
            <a-input v-model:value="formData.channelName" placeholder="请输入渠道名称"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="渠道编码: " name="channelCode" required>
            <a-input v-model:value="formData.channelCode" placeholder="请输入渠道编码"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="渠道类型:" name="channelType">
            <a-select v-model:value="formData.channelType" placeholder="请选择渠道类型">
              <a-select-option :value="type.value" v-for="type in ChannelTypeList" :key="type.value">{{type.label}}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="渠道说明:" name="channelDesc">
            <a-input v-model:value="formData.channelDesc" placeholder="请输入工号"/>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button type="primary" @click="onSave">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>

<script>
import {CloseOutlined, SaveOutlined} from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import {defineComponent, reactive, toRefs, ref, onMounted} from 'vue'
import {getRequestURI, request} from '../../../utils/request.js'
import {getExtendUri} from '../../../utils/request_extend'
 
export default defineComponent({
  components: {
    CloseOutlined, SaveOutlined
  },
  props: {
    handleSearch: {type: Function}
  },
  setup(props, {expose}) {
    const formRef = ref()
    const formData = reactive({
      id: 0,
      //渠道名称
      channelName: undefined,
      //渠道编码
      channelCode: undefined,
      //渠道类型
      channelType: undefined,
      //渠道说明
      channelDesc: undefined
    })
    const formRules = {
      realName: [
        {required: true,}
      ],
      phoneNo: [
        {required: true,}
      ],
      email: [
        {required: true, type: 'email'}
      ],
      userStatus: [
        {
          required: true,
          type: 'number'
        }
      ],
      startDate: [
        {
          required: true,
          type: 'date'
        }
      ]
    }
    const state = reactive({
      visible: false,
      isNew: true,
      ChannelTypeList: []
    })

    const showDrawer = (oldData, num) => {
      if(oldData === 1 || num === 1) {
        state.isNew = true
      } else if(num === 2) {
        state.isNew = false
        if(oldData.channelType === '1') {
          oldData.channelType = '个人' 
        } else if(oldData.channelType === '2'){
          oldData.channelType = '银行'
        } else if(oldData.channelType === '3'){
          oldData.channelType = '电话'
        } else if(oldData.channelType === '4'){
          oldData.channelType = '网销'
        } else if(oldData.channelType === '5'){
          oldData.channelType = '卡单'
        }
        Object.keys(formData).forEach(key => formData[key] = oldData[key])
      }
      state.visible = true
    }

    
    const onSave = async () => {
      let data = {}
      try {
        await formRef.value.validate()
        let url = getRequestURI('channel_update')
        if (state.isNew) {
          url = getRequestURI('channel_save')
          } 
        request(url, {data: formData}).then(result => {
          if(result.code === 1){
            message.success('保存成功')
            state.visible = false
            props.handleSearch(data)
          }else{
            message.warning(result.message)
          }
        })
      } catch (err) {
        message.error(err)
      }
    }
    const onClose = () => {
      // formRef.value.resetFields()
      Object.assign(formData, {
          id: 0,
          //渠道名称
          channelName: '',
          //渠道编码
          channelCode: '',
          //渠道类型
          channelType: '',
          //渠道说明
          channelDesc: ''
      })
      state.visible = false
    }
    //下拉框
    const getChannelType = () =>{
      request(getExtendUri('dictionary_query_byType'), {data: {types: ['channel_type']}}).then(res => {
        
        state.ChannelTypeList = res.result.channel_type
        console.log(222, state.ChannelTypeList)
      })
    }
    onMounted(()=>{
      getChannelType()
    })
    expose({
      showDrawer
    })
    return {
      formRef,
      formData,
      formRules,
      ...toRefs(state),
      onClose,
      onSave,
      onMounted,
      getChannelType
    }
  },

})
</script>
