<style scoped></style>

<template>
  <search-form
    ref="searchRef"
    :Condition="condition"
    :ColSpan="12"
    @resetAll="resetAll"
    @callback="handleSearch"
    @nameLinked="changes"
  ></search-form>
  <a-table
    id="table"
    :columns="columns"
    :data-source="dataSource ? dataSource['result'] : []"
    :pagination="pagination"
    @change="handleTableChange"
    :loading="loading"
  >
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <div>
          <a
            href="javascript:void(0)"
            style="color: orange"
            @click="sharingConfig(record)"
          >分享配置</a>&nbsp;
        </div>
      </template>
    </template>
  </a-table>
  <detail-share ref="shareRef" :handleSearch="handleSearch" :searchNow="searchNow" :current="pagination.current"></detail-share>
</template>

<script>
//,provide
import {computed, defineComponent, onMounted, reactive, ref, toRefs} from 'vue'
import SearchForm from '../../../components/extended/search_form'
import {message} from 'ant-design-vue'
import {getRequestURI, request, requestUsePagination} from '../../../utils/request.js'
import {getDictionaryData, getDictionaryValue, getExtendUri} from '../../../utils/request_extend'
import detailShare from './detailShare'

export default defineComponent({
  components: {
    SearchForm,
    detailShare
  },
  setup () {
    const productCategoryList = ref()//新增编辑
    const state = reactive({
      refreshDom: true,
      isCopy: false,
      export1: '',//导出
      export2: '',//导出
      data: [],//批量删除
      condition: {
        supplierId: {
          label: '供应商名称',
          type: 'tree-select',
          urlKey: 'querySupplier',
          value: 'id',
          default: '',
          data: []
        },
        productId: {
          label: '产品名称',
          type: 'select',
          default: '',
          data: []
        },
        supProductCode: '供应商产品编码',
        productCode: '产品编码',
      },
      columns: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left',
        },
        {
          title: '产品编码',
          width: 100,
          align: 'center',
          key: 'productCode',
          dataIndex: 'productCode',
        },
        {
          title: '供应商产品编码',
          width: 100,
          align: 'center',
          key: 'supProductCode',
          dataIndex: 'supProductCode',
        },
        {
          title: '产品名称',
          width: 120,
          align: 'center',
          key: 'productName',
          dataIndex: 'productName',
        },
        {
          title: '产品类型',
          width: 100,
          align: 'center',
          key: 'productType',
          dataIndex: 'productType',
          customRender: (text)=> getProducrType(text.value)
        },
        {
          title: '产品类别',
          width: 120,
          align: 'center',
          key: 'productCategory',
          dataIndex: 'productCategory',
          customRender: (text)=> getDictionaryValue('product_category', text.value)
        },
        {
          title: '供应商名称',
          width: 100,
          align: 'center',
          key: 'supplierName',
          dataIndex: 'supplierName',
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 120,
        },
      ],
      // 服务id
      serviceName: '',
      tableList: [],
      selectedRowKeys: [],
      searchNow: {},//分页
      productTypeList: []
    })
    const productData = reactive({
      productCode: '',
      channel: undefined,
      channelUserId: '',
      agentId: '',
      agentCode: '1234567890123'
    })
    const cahnpintiaozhuandizhi = function () {
      getDictionaryData(productCategoryList, 'product_category')
    }
    cahnpintiaozhuandizhi()
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('ppqa_selectInfo'))
    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    getDictionaryData(state.productTypeList, 'ProductType')
    // 存储搜索组件传过来的数据
    const searchfFilters = ref({})
    //查询
    const handleSearch = function (filters, num = '') {
      if (filters) {
        searchfFilters.value = filters
      }
      state.searchNow = filters
      run({
        pagination: {
          current: num? num : 1,
          pageSize: 10
        },
        data: filters,
      })
    }

    //产品下拉查询
    const changes = function (value) {
      request(getRequestURI('select'), { data: { supplierId: value } }).then(result => {
        console.log(result)
        const arr = []
        const res = result.result
        const label = 'id'
        const sysName = res.reduce((all, next) => all.some((atom) => atom[label] == next[label]) ? all : [...all, next], [])
        for (let i = 0; i < sysName.length; i++) {
          arr.push({ label: sysName[i].productName, value: sysName[i].id })
        }
        state.condition.productId.data = arr
      })
    }
    const init = function () {
      request(getRequestURI('select'), { data: {} }).then(result => {
        const arr = []
        const res = result.result
        const label = 'id'
        const sysName = res.reduce((all, next) => all.some((atom) => atom[label] == next[label]) ? all : [...all, next], [])
        for (let i = 0; i < sysName.length; i++) {
          arr.push({ label: sysName[i].productName, value: sysName[i].id })
        }
        state.condition.productId.data = arr
      })
    }
    const resetAll = () => {
      state.condition.productId.data = []
      init()
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.log('===', filters)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.searchNow,
      })
    }
    const searchRef = ref()
    const visible = ref(false)
    init()
    const filterOption = (input, option) => {
      return option.label.indexOf(input) > -1
    }
    const shareRef = ref()
    // 分享配置
    const sharingConfig = (data) =>{
      console.log('--------',data)
      shareRef.value.showDrawer(data)
    }
    const productRef = ref()
    const handleCopyOk = async () => {
      await productRef.value.validate()
      request(getExtendUri('createProductLink'), {
        data: productData
      }).then(res => {
        if (res.code === 1 && res.result) {
          state.isCopy = false
          productRef.value.resetFields()
          productRef.value.clearValidate()
        } else {
          message.warning(res.message)
        }
      })
    }
    const getChannelName = () => {
      let data = {}
      request(getRequestURI('channel_select'), { data }, true, 'get').then(result => {
        ChannelTypeList.value = result.result
      })
    }
    const getProducrType = (value = '') =>{
      if(value){
        const a = state.productTypeList['value'].filter(item => item.value === value)
        return a? a[0].label : ''
      }
    }

    const ChannelTypeList = ref([])
    return {
      productRef,
      shareRef,
      getChannelName,
      handleCopyOk,
      ChannelTypeList,
      productData,
      filterOption,
      searchRef,
      resetAll,
      ...toRefs(state),
      handleSearch,
      handleTableChange,
      pagination,
      total,
      loading,
      dataSource,
      visible,
      changes,
      searchfFilters,
      productCategoryList,
      sharingConfig
    }
  },
})
</script>
