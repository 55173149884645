<template>
  <search-form
    :Condition="condition"
    :ColSpan="8"
    @resetAll="resetAll"
    @callback="handleSearch"
    @nameLinked="changes"
  ></search-form>
  <div class="btn-bar">
    <a-button danger @click="delUser()">
      <template #icon>
        <DeleteOutlined />
      </template>
      撤单
    </a-button>
<!--    <a-input v-model:value="inputValue"></a-input>-->
<!--    <a-button danger @click="lookShow">-->
<!--      回溯-->
<!--    </a-button>-->
  </div>
  <a-table
    :columns="columns"
    :data-source="dataSource ? dataSource['result'] : []"
    :scroll="{ x: 1200 }"
    :pagination="pagination"
    :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }"
    :row-key="(record) => record.id"
    @change="handleTableChange"
    :loading="loading"
  >
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <div>
          <a
            style="color: orange; margin-right: 20px"
            @click="checkUser(record)"
            >查看详情</a
          >
          <p @click="rrwebShow(record)">查看回溯</p>
        </div>
      </template>
    </template>
  </a-table>
  <a-drawer
    v-model:visible="visible"
    class="custom-class"
    placement="right"
    width="70%"
    @after-visible-change="afterVisibleChange"
  >
    <template #title>
      <div class="dtitle">
        查看操作视频
        <span class="d-time">录制开始时间：{{ vTime }}</span>
        <span class="d-time">录制结束时间：{{ vEndTime }}</span>
      </div>
    </template>
    <div id="operation"></div>
  </a-drawer>
  <guarantee-manageadit ref="guaranteemanageaditRef"></guarantee-manageadit>
</template>

<script>
import {computed, defineComponent, onBeforeMount, onMounted, reactive, ref, toRefs} from 'vue'
import SearchForm from '../../../components/extended/search_form.vue'
import {getRequestURI, request, requestUsePagination} from '../../../utils/request'
import GuaranteeManageadit from './guaranteeManageadit'
import {message, Modal} from 'ant-design-vue'
import rrwebPlayer from 'rrweb-player'
import 'rrweb-player/dist/style.css'
import {getDictionaryDataInApi} from '../../../utils/request_extend'

export default defineComponent({
  components: {
    GuaranteeManageadit,
    SearchForm
  },
  setup () {
    const guaranteemanageaditRef = ref()
    const replayer = ref(null)
    const state = reactive({
      inputValue: '',
      payStateDicList: [],
      underwritingStatusDicList: [],
      visible: false,
      vTime: '',
      vEndTime: '',
      selectedRow: [],
      lastFilter: {},
      columns: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left',
        },
        {
          title: '投保单号',
          width: 110,
          align: 'center',
          key: 'prtNo',
          dataIndex: 'prtNo',
        },
        {
          title: '保单号',
          width: 110,
          align: 'center',
          key: 'contNo',
          dataIndex: 'contNo',
        },
        {
          title: '流水号',
          width: 110,
          align: 'center',
          key: 'tradeNo',
          dataIndex: 'tradeNo',
        },
        {
          title: '供应商名称',
          width: 140,
          align: 'center',
          key: 'supName',
          dataIndex: 'supName',
        },
        {
          title: '产品名称',
          width: 140,
          align: 'center',
          key: 'productInfoName',
          dataIndex: 'productInfoName',
        },
        {
          title: '投保人名称',
          width: 110,
          align: 'center',
          key: 'applicantName',
          dataIndex: 'applicantName',
        },
        {
          title: '被保人名称',
          width: 110,
          align: 'center',
          key: 'protectedmanName',
          dataIndex: 'protectedmanName',
        },
        {
          title: '订单状态',
          width: 110,
          align: 'center',
          key: 'orderStatusName',
          dataIndex: 'orderStatusName',
        },
        {
          title: '订单日期',
          width: 120,
          align: 'center',
          key: 'polApplyDate',
          dataIndex: 'polApplyDate',
          customRender: (text) => {
            let obj = text.value ? text.value.split(' ') : ['']
            return obj[0]
          }
        },
        {
          title: '订单金额',
          width: 120,
          align: 'center',
          key: 'payMoney',
          dataIndex: 'payMoney',
        },
        {
          title: '操作',
          key: 'operation',
          fixed: 'right',
          width: 120,
        },
      ],
      selectedRowKeys: [],
      condition: {
        supId: {
          label: '供应商名称',
          type: 'tree-select',
          urlKey: 'querySupplier',
          value: 'id'
        },
        productInfoId: {
          label: '产品名称',
          type: 'select',
          default: '',
          data: []
        },
        contStatus: {
          label: '保单状态',
          type: 'select',
          urlKey: 'queryDictionaryType',
          queryParam: { type: 'policy_status' }
        },
        prtNo: '投保单号',
        contNo: '保单号',
        tradeNo: '流水号',
        protectedmanName: '被保人名称',
        applicantName: '投保人名称',
        polApplyStartDate: {
          label: '订单日期开始',
          type: 'date-picker'
        },
        polApplyEndDate: {
          label: '订单日期结束',
          type: 'date-picker'
        }
      }
    })
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('productInsured_queryListForPage'))
    const onSelectChange = (selectedRowKeys, selectedRow) => {
      console.debug('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
      state.selectedRow = selectedRow
      console.debug('selectedRowKeys changed: ', selectedRow)
    }
    const afterVisibleChange = bool => {
      if (!bool) {
        let box = document.getElementById('newOperation')
        if (box) {
          box.parentNode.removeChild(box)
        }
      }
      console.debug('visible', bool)
    }
    const rrwebShow = function (val) {
      state.visible = true
      state.vTime = val.videoStartTime
      state.vEndTime = val.videoEndTime
      request(getRequestURI('rwebData_getData'), { tradeNo: val.tradeNo }).then(res => {
        if (res.code === 1 && res.result) {
          let events = JSON.parse(res.result)
          let operationDiv = document.getElementById('operation')
          var newnode = document.createElement('div')
          newnode.id = 'newOperation'
          operationDiv.appendChild(newnode)
          replayer.value = new rrwebPlayer({
            target: document.getElementById('newOperation'), // 可以自定义 DOM 元素
            props: {
              events,
              speedOption: [1, 2, 5, 10],
            },
          })
          // setTimeout(() =>{
          //   while (operationDiv.hasChildNodes()){
          //     operationDiv.removeChild(operationDiv.firstChild)
          //   }
          //   let time = 300000
          //   console.log(2222, replayer.value)
          //   let newChildNode = document.createElement('div')
          //   newChildNode.id = 'newOperation'
          //   operationDiv.appendChild(newChildNode)
          //   replayer.value = new rrwebPlayer({
          //     target: document.getElementById('newOperation'), // 可以自定义 DOM 元素
          //     props: {
          //       events,
          //       speedOption: [1, 2, 5, 10],
          //     },
          //   })
          //   replayer.value.playRange(time)
          // }, 5000)
          return {}
        } else {
          message.error(res.message)
        }
      })
      console.debug(val)
    }
     const lookShow = () =>{
       rrwebShow(
         {tradeNo: state.inputValue}
       )
     }


    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    const handleSearch = function (filters) {
      console.debug(filters)
      if (filters.polApplyStartDate !== undefined && filters.polApplyStartDate !== null) {
        console.debug(filters.polApplyStartDate)
        filters.polApplyStartDate = filters.polApplyStartDate + ' ' + '00:00:00'
      }
      if (filters.polApplyEndDate !== undefined && filters.polApplyStartDate !== null) {
        console.debug(filters.polApplyEndDate)
        filters.polApplyEndDate = filters.polApplyEndDate + ' ' + '23:59:59'
      }
      state.lastFilter = filters
      state.loading = true

      run(
        {
          data: state.lastFilter,
          pagination: {
            current: 1,
            pageSize: pageSize.value
          },
        }
      )
      state.loading = false
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
      // pageSizeOptions: [10, 20, 50],
    }))
    const delUser = function () {
      if (state.selectedRowKeys.length == 0) {
        message['warning']('请选择数据')
      } else {
        console.debug('sdsadsds', state.selectedRow)
        for (let item of state.selectedRow) {
          if (item.prtStatusName === '撤单') {
            message['warning']('所选记录已撤单，请勿重复操作')
            return
          }
        }
        Modal.confirm({
          title: '是否确定对所选记录进行撤单？',
          async onOk () {
            // let keys = state.selectedRowKeys
            // if (keys.length === 0) {
            //   message.warning('请选择数据')
            //   return
            // }
            // visible.value = true
            const uri = getRequestURI('productInsured_cancelInsuranceSlip1')
            request(uri, { ids: state.selectedRowKeys }).then(res => {
              console.debug(res)
              if (res.code == 1) {
                console.debug(res)
                message['success'](res.message)
                handleSearch({})
              } else {
                message['error'](res.message)
              }
              state.selectedRowKeys = []
            })
          },
          onCancel () {
            // state.querydel.splice(0)
          }
        })
      }
      // request(getRequestURI('productInsured_cancelInsuranceSlip1'), {ids: []}).then(res => {
      //
      // })
    }
    const checkUser = function (value) {
      request(getRequestURI('productInsured_queryDetail'), { id: value.id }).then(res => {
        if (res.code === 1) {
          guaranteemanageaditRef.value.showDrawer(res.result, state.payStateDicList, state.underwritingStatusDicList)
        } else {
          message.error(res.message)
        }
      })
    }
    const handleTableChange = (pag, filters, sorter) => {
      console.debug('handleTableChange', pag, filters, sorter)

      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.lastFilter,
      })
    }
    //产品下拉查询
    const changes = function (value) {
      request(getRequestURI('select'), { data: { supplierId: value } }).then(result => {
        const arr = []
        const res = result.result
        const label = 'productName'
        const sysName = res.reduce((all, next) => all.some((atom) => atom[label] == next[label]) ? all : [...all, next], [])
        for (let i = 0; i < sysName.length; i++) {
          arr.push({ label: sysName[i].productName, value: sysName[i].id })
        }
        state.condition.productInfoId.data = arr
      })
    }
    const init = function () {
      request(getRequestURI('select'), { data: {} }).then(result => {
        const arr = []
        const res = result.result
        const label = 'productName'
        const sysName = res.reduce((all, next) => all.some((atom) => atom[label] == next[label]) ? all : [...all, next], [])
        for (let i = 0; i < sysName.length; i++) {
          arr.push({label: sysName[i].productName, value: sysName[i].id})
        }
        state.condition.productInfoId.data = arr
      })
    }
    init()
    const resetAll = () => {
      state.condition.productInfoId.data = []
      init()
    }

    onBeforeMount(() => {
      getDictionaryDataInApi(state.payStateDicList, 'pay_status')
      getDictionaryDataInApi(state.underwritingStatusDicList, 'underwriting_status')
    })

    return {
      resetAll,
      ...toRefs(state),
      changes,
      dataSource,
      loading,
      current,
      pageSize,
      total,
      onSelectChange,
      pagination,
      handleTableChange,
      handleSearch,
      delUser,
      guaranteemanageaditRef,
      checkUser,
      rrwebShow,
      afterVisibleChange,
      lookShow
    }
  }
})
</script>

<style scoped>
.d-time {
  display: inline-block;
  margin-left: 30px;
}
</style>
