<template>
  <a-drawer
    :title="isTitle"
    :width="'40%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form
      :model="formData1"
      layout="horizontal"
      ref="formRef"
      autocomplete="off"
    >
      <a-row :gutter="10">
        <a-col span="24">
          <a-form-item label="险种名称:" name="riskName"
                       :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-select v-model:value="formData1.riskName"
                      show-search
                      :filter-option="filterOption"
                      :getPopupContainer="(triggerNode)=>triggerNode.parentNode"
                      :options="formData1.options">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="24" v-if="riskSign === 2">
          <a-form-item label="顺   序:" name="riskOrder" :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}, {max: 3, message: '输入长度不能超过3' ,trigger: 'change'},
        { pattern:/^\+?[1-9]\d*$/, message: '支持正整数格式' ,trigger: 'change'}]">
            <a-input v-model:value="formData1.riskOrder"></a-input>
          </a-form-item>
        </a-col>
        <a-col span="24" v-if="riskSign === 2">
          <a-form-item label="默认选中:" name="isSelected"
                       :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-select v-model:value="formData1.isSelected"
                      :options="formData1.options2">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="24" v-if="riskSign === 2">
          <a-form-item label="互斥关键字:" name="repulsionKey"
                       :rules="[{max: 128, message: '输入长度不能超过128' ,trigger: 'change'}]"
          >
            <a-input v-model:value="formData1.repulsionKey" placeholder="请输入互斥关键字"/>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button type="primary" @click="onSave">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>

<script>
import {defineComponent, reactive, ref, toRefs} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
import {message} from 'ant-design-vue'

export default defineComponent({
  setup(props, {emit, expose}) {
    const formRef = ref()
    const isTitle = ref()
    const state = reactive({
      visible: false,
      riskSign: -1,
      riskPid: 0,
      condition2: {
        riskCode: '险种编码',
        riskName: {
          label: '险种名称',
          type: 'select',
          default: '',
          data: []
        }
      }
    })
    const getRiskSign = (riskName) => {
      if (formData1.options.length === 0) {
        return ''
      } else {
        return formData1.options.find((item) => item.value === riskName).riskSign
      }
    }
    const formData1 = reactive({
      productInfoId: '',
      riskCode: '',
      riskOrder: undefined,
      riskName: '',
      options: [],
      supId: '',
      isSelected: 0,
      repulsionKey: '',
      options2: [
        {value: 0,label: '否',},
        {value: 1,label: '是',}
      ],
    })
    const onClose = function () {
      state.visible = false
      state.riskSign = -1
      formData1.productInfoId = ''
      formData1.riskCode = ''
      formData1.riskOrder = undefined
      formData1.riskName = ''
      formData1.options = []
      formData1.supId = ''
      formData1.isSelected = 0
      formData1.repulsionKey = ''
    }
    const filterOption = (input, option) => {
      return option.label.indexOf(input) > -1
      // return option.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    const onSave = async function () {
      const values = await formRef.value.validate()
      console.log(values)
      if (formData1.riskName === '') {
        message.warning('请输入险种名称')
        return
      }
      // 互斥关键字
      if (formData1.repulsionKey.length > 128) {
        message['warning']('互斥关键字输入长度不能超过128')
        return
      }
      request(getRequestURI('saveProductInfoRisk'), {
        data: {
          productInfoId: state.productId,
          riskCode: formData1.riskName ? formData1.riskName : '',
          riskId: formData1.riskName,
          riskOrder: formData1.riskOrder,
          riskPid: state.riskPid,
          isSelected: formData1.isSelected,
          repulsionKey: formData1.repulsionKey,
          riskSubFlag: (state.riskPid !== 0 && getRiskSign(formData1.riskName) !== '') ? 1 : 0,
        }
      }, true, 'post').then(
        (result) => {
          if (result.code === 1) {
            // message.success('关联险种的保存' + result.message)
            message.success('关联成功' )
            onClose()
            emit('select')
          } else {
            message.warning(result.message)
          }
          // console.log('关联险种的保存:', result)
          formData1.riskName = ''
        }
      )
    }
    //险种下拉选
    const getInsuranceName = (supId, riskSign) => {
      request(getRequestURI('selectRisk_BySupIdhh'), {
        data: {
          supId: supId,
          riskSign: Number(riskSign) === 2 ? [2,3]: [Number(riskSign)]
        }
      }, true, 'post').then(
        (result) => {
          console.log('险种下拉信息==', result)
          const arr = []
          const res = result.result
          console.log('险种下拉信息==', res)
          for (let i = 0; i < res.length; i++) {
            arr.push({label: res[i].riskName, value: res[i].riskId, riskSign})
          }
          state.condition2.riskName.data = arr
          formData1.options = arr
          console.log(formData1.options)
        }
      )
    }
    const showDrawer = function (supId, riskSign, productId, pid) {
      state.visible = true
      // 获取主页面的id
      state.productId = productId
      state.riskSign = riskSign
      getInsuranceName(supId, riskSign)
      if (riskSign === 1) {
        state.riskPid = 0
        isTitle.value = '添加主险'
      } else if (riskSign === 2) {
        state.riskPid = pid
        isTitle.value = '添加附加险'
      }
    }
    expose({
      showDrawer
    })
    return {
      onClose,
      ...toRefs(state),
      formData1,
      getInsuranceName,
      onSave,
      filterOption,
      formRef,
      isTitle
    }
  }
})
</script>

<style scoped>

</style>
