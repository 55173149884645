<style scoped>
</style>

<template>
  <search-form
    @treeName="getTreeName"
    :Condition="condition"
    :ColSpan="12"
    @callback="handleSearch"
    @nameLinked="changes"
    @stateData="getStateData"
  ></search-form>
  <a-table
    :columns="columns"
    :data-source="dataSource ? dataSource['result'] : []"
    :row-key="(record) => record.id"
    :pagination="pagination"
    @change="handleTableChange"
    :loading="loading"
  >
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <a-button
          type="text"
          @click="checkDetail(record)"
          style="margin-right: 10px; color: orange"
          size="small"
        >
          查看
        </a-button>
        <a-button
          type="text"
          @click="addAndUpdate(record)"
          style="margin-right: 10px; color: orange"
          size="small"
        >
          编辑
        </a-button>
      </template>
    </template>
  </a-table>
  <checkManagement ref="userDrawerRef"></checkManagement>
  <addAndUpdateManage ref="addDrawerRef"></addAndUpdateManage>
</template>

<script>
import { defineComponent, reactive, computed, ref, toRefs, onMounted } from 'vue'
import SearchForm from '../../../components/extended/search_form'
import checkManagement from './checkManagement'
import addAndUpdateManage from './addAndUpdateManage'
import { requestUsePagination, getRequestURI, request } from '../../../utils/request.js'
import { message } from 'ant-design-vue'
export default defineComponent({
  components: {
    SearchForm,
    checkManagement,
    addAndUpdateManage
  },
  setup () {
    const userDrawerRef = ref()
    const addDrawerRef = ref()
    const state = reactive({
      treeName: '',
      condition: {
        supName: {
          label: '供应商名称',
          type: 'tree-select',
          urlKey: 'querySupplier',
          value: 'id',
        },
        productName: {
          label: '产品名称',
          type: 'select',
          default: '1',
          data: []
        },
        productCode: {
          label: '产品编码',
          placeholder: '请输入产品编码，可根据关键字进行模糊查询'
        },
      },
      columns: [
        {
          title: '序号',
          width: 80,
          align: 'center',
          key: 'index',
          dataIndex: 'index'
        },
        {
          title: '产品编码',
          width: 140,
          align: 'center',
          key: 'productCode',
          dataIndex: 'productCode'
        },
        {
          title: '供应商产品编码',
          width: 140,
          align: 'center',
          key: 'supProductCode',
          dataIndex: 'supProductCode'
        },
        {
          title: '产品名称',
          width: 250,
          align: 'center',
          key: 'productName',
          dataIndex: 'productName'
        },
        {
          title: '供应商名称',
          width: 140,
          align: 'center',
          key: 'supName',
          dataIndex: 'supName'
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 140
        }
      ],
      dataSource: [],
      lastFile: {},
    })

    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('productInsureTotice_selectListForPage'))

    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    // 查询
    const handleSearch = function (filters) {
      if (state.treeName === '') {
        state.treeName = undefined
      }
      filters.supName = state.treeName
      state.lastFile = filters
      run({
        pagination: {
          current: 1,
          pageSize: 10
        },
        data: filters,
      })
    }
    const addAndUpdate = function (oldData) {
      // userDrawerRef.value.initFormData()
      request(getRequestURI('productInfocheck_Update'), { data: { ids: [oldData.id] } }).then(res => {
        if (res.code === 1) {
          addDrawerRef.value.showDrawer(oldData, 1)
        } else {
          message.warning(res.message)
        }
      })

    }
    const handleTableChange = (pag, filters, sorter) => {
      console.log(pag)
      if (state.lastFile.supName === '') {
        state.lastFile.supName = undefined
      }
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        data: state.lastFile,
        sorter
      })
    }

    const checkDetail = function (oldData, num) {
      userDrawerRef.value.showDrawer(oldData, num)
    }
    //产品下拉查询
    const changes = function (value, treeData) {
      state.treeName = getStateData(value, treeData)
      request(getRequestURI('select'), { data: { supplierId: value } }).then(result => {
        const arr = []
        const res = result.result
        const label = 'productName'
        const sysName = res.reduce((all, next) => all.some((atom) => atom[label] == next[label]) ? all : [...all, next], [])
        for (let i = 0; i < sysName.length; i++) {
          arr.push({ value: sysName[i].productName, label: sysName[i].productName })
        }
        state.condition.productName.data = arr
      })
    }
    // 得到供应商名称数据
    const getStateData = (value, treeData) => {
      let result = ''
      if (!treeData) {
        return result
      }
      for (let i = 0; i < treeData.length; i++) {
        let item = treeData[i]
        if (item.id === value) {
          console.log(123)
          result = item.supName
          // debugger
          return result
        } else if (item.children && item.children.length > 0) {
          // debugger
          result = getStateData(value, item.children)
          if (result) {
            return result
          }
        }
      }
      return result
    }
    const getTreeName = (value) => {
      state.treeName = value
    }
    onMounted(() => {
      changes()
    })
    return {
      handleSearch,
      getTreeName,
      ...toRefs(state),
      handleTableChange,
      addAndUpdate,
      checkDetail,
      changes,
      getStateData,
      userDrawerRef,
      addDrawerRef,
      pagination,
      loading,
      dataSource
    }
  }
})
</script>
